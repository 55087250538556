//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { connect } from 'react-redux'
import  { myShopAddUpdateLink, myShopDeleteLink } from '../../../../../../redux/myBazaar'
import { NoItem  , ActionButton, Alert, ProfileImage, MyButton, Confirm , MyImage, MyIcon, MyText} from '../../../../../../globalComponents/components'
import MdlAddUpdateLink from './mdlAddUpdateLink'
import Divider from '../../../../../../commonComponents/divider'
import uuid from 'react-uuid'
import { arrSocialNetwork } from '../../../../../../globalDef'
 
const LinkComponent= props =>{
    const {id , link , socialNetworkIcon , onDeleteLink , onSelect , imageWidth} = props
    const deleteLink =() =>onDeleteLink(id)

    return(
            <View    style={{width:imageWidth , marginRight:10 }}>
                <TouchableOpacity style={{width:"100%" , flexDirection:"column"  , alignItems:"center"  }}  onLongPress={deleteLink} onPress={onSelect} >
                        <View  style={{padding:1 , borderWidth:1 , borderColor:"#eeeeee" , borderRadius:imageWidth}}>
                            <MyImage source={ socialNetworkIcon} style={{width:imageWidth-4 , height:imageWidth-4 , borderRadius:imageWidth    }}   />
                        </View>
                </TouchableOpacity>       
            </View>

    )
}

const ShopLink=(props)=>{
//-----------------------------------GLOBAL VAR EREA
const {myBazaarState , onAddUpdateSocialNetworkLink , onDeleteSocialNetworkLink , containerStyle={} }=props
const arrLink=myBazaarState.shopInfo.arrLink
//-----------------------------------REF EREA
const mdlLink=useRef()
const alertModal=useRef()
const confirmModal=useRef()
//-----------------------------------STATE EREA
const [imageWidth , setImageWidth]=useState(40)
const onLayout= event =>{
    // const {width} = event.nativeEvent.layout
    // // setImageWidth(width/7)
    // setImageWidth(200)
}
//-----------------------------------USE_EFFECT EREA
//-----------------------------------FUNCTION EREA
const onAddUpdateLink= linkInfo =>  mdlLink.current.show(linkInfo)
const addUpdateLink=(linkInfo)=>{
    if(linkInfo.link.indexOf("https://")===-1 && linkInfo.link.length<10){
        alertModal.current.show("خطا در عملیات","آدرس صفحه به درستی وارد نشده است!!!.","error")
        return
    }
    if(!linkInfo.socialNetworkId){
        alertModal.current.show("خطا در عملیات","شبکه اجتماعی مورد نظر را انتخاب نمایید.","error")
        return
    }
    onAddUpdateSocialNetworkLink(linkInfo)
    mdlLink.current.hide()
}
const onDeleteLink= linkId=>{
    confirmModal.current.show("حذف یک دسته بندی" , 
                                                 "آیا از حذف این آدرس صفحه اجتماعی اطمینان دارید؟" , 
                                                 "error" ,
                                                 ()=>deleteLink(linkId) , 
                                                 "تایید و ادامه")
}
const deleteLink= linkId => onDeleteSocialNetworkLink(linkId)
//--------------------------------------------------
   return(
        <View onLayout={onLayout} style={{backgroundColor:"#FFF" , paddingHorizontal:6 , marginVertical:15   , borderRadius:10 , paddingBottom:3} }>
            {/* <MyText tinyFont  >{" صفحات فروشگاه در شبکه های اجتماعی"}</MyText> */}
            <ScrollView horizontal  style={{ paddingBottom:6  ,transform: [{ scaleX: -1 }],}}  contentContainerStyle={{flexDirection:"row-reverse" ,direction:"revert" }} >
                        {
                            arrLink.map(link =><LinkComponent key={link.id} {...link} imageWidth={imageWidth}
                                                                                                            onDeleteLink={onDeleteLink}
                                                                                                            socialNetworkIcon={arrSocialNetwork.filter(socialNetwork => socialNetwork.id===link.socialNetworkId)[0].icon} 
                                                                                                            onSelect={()=>onAddUpdateLink(link)} />)
                        }
                        <View   style={{width:imageWidth, height:imageWidth ,padding:1 , paddingBottom:2  , justifyContent:"flex-start" , alignItems:"center"  }} >
                            <TouchableOpacity style={{ width:"100%" , height:"100%" }} 
                                                        onPress={()=>onAddUpdateLink({id:uuid() , link:'' , socialNetworkId:0 })}>
                                    <View style={{width:"100%" , height:"100%" , justifyContent:"center" , alignItems:"center" , borderRadius:imageWidth , backgroundColor:"#FFFFFF" , padding:3 , elevation:2}}>
                                        <View style={{width:"100%" , height:"100%" , justifyContent:"center" , alignItems:"center" , borderRadius:imageWidth , backgroundColor:"#b0b0b0" , elevation:2}}>
                                        <MyIcon iconName="add_link" iconSize={30} color="#FFF" />
                                        </View>
                                    </View>
                            </TouchableOpacity>
                        </View>
                <MdlAddUpdateLink ref={mdlLink} onAddUpdateLink={addUpdateLink} />
                <Alert ref={alertModal} />
                <Confirm ref={confirmModal} />
            </ScrollView>
        </View>
       
   )
}
const mapSTateToProps= state => ({
    profileState : state.profileReducer ,
    myBazaarState:state.myBazaarReducer  , 
})
const mapDispatchToProps= dispatch =>({
    myShopAddUpdateLink : linkinfo => dispatch(myShopAddUpdateLink(linkinfo)) ,
    myShopDeleteLink : linkId => dispatch(myShopDeleteLink(linkId))
})
export default connect(mapSTateToProps , mapDispatchToProps)(ShopLink);
//=========================================================================================