import React , {useEffect , useRef, useState} from 'react';
import { connect } from 'react-redux';
import { PageContainer , Alert, Confirm, MyText} from '../../../globalComponents/components'
import Fetch from '../../../globalComponents/fetch'
// import { useBackHandler } from '@react-native-community/hooks';
import { setToastMessage } from '../../../redux/toastMessage';
import ChatComponent from '../../../commonComponents/chatComponent';
import { updateInitialInfo } from '../../../redux/initialInfo';
//======================================================================================================================

//======================================================================================================================           
const ShareFolderMessage = (props) =>{
    //---------------------------------------
    // useBackHandler(()=>{
    //     onBackClick()
    //     return true
    // })

    const imgDefManAvatar = require("./../../../assets/images/act/imgDefManAvatar.jpg")
    const imgDefWomanAvatar = require("./../../../assets/images/act/imgDefWomanAvatar.jpg")

    const onBackClick=()=>props.navigation.goBack()
    //---------------------------------------
    const alertModal=useRef()
    const fetchModal=useRef()
    const confirmModal=useRef()
    //-----------------------------------------------------------------------------------
    const [messageInfo , setMessageInfo]=useState({arrMessage:[] , finished:false , refresh:false})
    const [ fetchState , setFetchState ]=useState({ fetchFlag:false , errorFlag:false });
    //------------------------------------------------------------------------------------                       
    //-----------------------------------------
    const getMessage=()=>{
        const objData={
            jwt : props.profileState.jwt,
            lastMessageId :messageInfo.arrMessage.length ? messageInfo.arrMessage[messageInfo.arrMessage.length-1].id     : 0
        }
        fetchModal.current.fetchData("user/getMessageList" , 
                                    objData ,
                                    {
                                        onStart      : ()=>setFetchState({fetchFlag:true , errorFlag:false}) , 
                                        onError      : ()=>setFetchState({fetchFlag:true , errorFlag:false}) ,
                                        onSuccess  :  responseOfGetMessage
                                    }  , 
                                    false , 
                                    messageInfo.arrMessage.length ? true : false)
    }
    const responseOfGetMessage=(data)=>{
        alert(JSON.stringify(data))
        setFetchState({fetchFlag:false  , errorFlag:false})
        setMessageInfo({arrMessage:[...messageInfo.arrMessage , ...data.arrMessage] , finished:data.finished })
        props.updateInitialInfo({hasNewMessage:false})
    }
    //--------------------------------------
    const sendMessage=(newMessage /* {uuid , matn , arrFIle} */)=>{
        if(newMessage.matn.replace(/\n+/g , "\n").replace(/ +/g , " ").length<2){
               alertModal.current.show("خطا در ورود اطلاعات","متن پیام را با حداقل 5 حرف وارد نمایید","error")
               return
        }

        const arrNewImage=newMessage.arrFile.filter(file => typeof(file.id)==="string" )
                                                                      .map( (image ,index) => ({
                                                                                                                        name :"imageForMessage"+index ,
                                                                                                                        filename:'imageForMessage.esi'+image.id,
                                                                                                                        uri:image.uri ,
                                                                                                                        type:image.type,    
                                                                                                            }))
                                                                                                            

        // const arrNewImage = arrFile.length ?  arrFile.map( (file , index)=>({name:"imageForMessage"+index  , file}) ) : []
 
        const objData={
                                     message:newMessage.matn.replace(/\n+/g , "\n").replace(/ +/g , " ") , 
                                     uuid:newMessage.uuid , 
                                     jwt:props.profileState.jwt ,
                             }
 
        fetchModal.current.uploadData( "user/addUpdateMessage" ,
                                                                objData ,
                                                                arrNewImage,
                                                                {
                                                                       onSuccess : responseOfSendMessage ,
                                                                       onStart:()=>{},
                                                                       onError:()=>{},
                                                                } , 
                                                                true ,true , true )  
    }
    const responseOfSendMessage= data =>{
        data.message.hasAnimation=true
        data.message.avatar = props.profileState.avatar ? {uri : props.profileState.avatar }
                                                                            : props.profileState.sex===1 ? imgDefManAvatar : imgDefWomanAvatar
            setMessageInfo({  ...messageInfo , arrMessage : [data , ...messageInfo.arrMessage]   })
            if(data.ptm)
                    props.setToastMessage("ثبت پیام به موفقیت انجام شد.")
    }
    //-----------------------------------------
    return(
        <PageContainer title="ارتباط با دهیاری" showBackIcon={true} onBackIconClick={onBackClick} backgroundColor="#e0f7fa"
                                subTitle={" روستای "+props.profileState.objCity.name}
                                containerPadding={0} hasFlatList >
                            {/* نمایش خطا در دریافت اطلاعات  */}
                <ChatComponent  messageInfo={messageInfo}
                                          fetchState={fetchState}
                                          onGetMessage={getMessage} 
                                          onSendMessage={sendMessage}
                                          canSendmessage={true}
                                          strErrorMessage={"در دریافت اطلاعات خطایی روی داده است!!!!."+"\n"+"این مشکل ممکن است در نتیجه کندی و یا قطعی اینترنت شما باشد."} 
                                          strNoMessage="هیچ پیامی ثبت نشده است!!!"     />
                <Alert ref={alertModal} />
                <Fetch ref={fetchModal} />
                <Confirm ref={confirmModal} />
           </PageContainer>
    )
}
//==================================================================
const mapStateToProps=(state)=>({
    profileState:state.profileReducer,
})
const mapDispatchToProps=(dispatch)=>({
    setToastMessage : strMessage => dispatch(setToastMessage(strMessage)) ,
    updateInitialInfo : info => dispatch(updateInitialInfo(info))
})
export default connect(mapStateToProps , mapDispatchToProps)( ShareFolderMessage );