
import React, { useEffect } from "react"
import { Provider } from "react-redux"
import reducer  from "./redux"
import { createStore } from "redux"
import Navigation from "./navigation"

import "./styles/w3.css"
import 'react-photo-view/dist/react-photo-view.css';


const store=createStore(reducer  )

const App= props =>{

     useEffect(()=>{
          window.history.pushState(null, document.title, window.location.href);
          window.addEventListener('popstate', function (event){
               window.history.pushState(null, document.title,  window.location.href);
          });
     } , [])

      return(
          <Provider store={store}>

               <Navigation />
       
          </Provider>
     )
}

export default App
