//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
import {FlatList, Linking, TouchableOpacity, View} from "react-native"
import { NoItem, PageContainer, Alert, ProfileImage, MyText, WaitModal, Confirm } from '../../../globalComponents/components'
import Fetch from "../../../globalComponents/fetch"
import TabComponent from '../../../commonComponents/tabComponent'
import { connect } from 'react-redux'
import { setToastMessage } from '../../../redux/toastMessage'
import MdlBillInfo from './mdlBillInfo'
import { formatNumber } from '../../../globalComponents/rejax'
import GoodsShopPlaceholder from '../../../commonComponents/goodsShopPlaceholder'
//  import {useBackHandler} from "@react-native-community/hooks"
 import Styles from '../../../styles'
import { updateInitialInfo } from '../../../redux/initialInfo'
//==========================================================================================
const BillComponent= props =>{
    const { id , billName  ,billAvatar , durationName , saveDate , startDate  , paymentDeadLine , 
                 paymentDeadLineTimestamp , payDate  ,refId , billDescription , billPrice , previousDebt   , onClick}=props
    const [imagePanelWidth , setImagePanelWidth]=useState(0 )

    const onLayout=event =>{
        const {width}=event.nativeEvent.layout
        setImagePanelWidth(width)
    }

    const avatar = billAvatar ? {uri : billAvatar} : require("../../../assets/images/act/imgDefImage.png")
    return(
       <TouchableOpacity   onPress={()=>onClick(id, false)}
               style={{width:"100%" , padding:0 , marginTop:5 }}>
                <View      style={{  flexDirection:"row" , borderBottomWidth:1 , borderColor:"#eeeeee"  }}>
                    <View  style={Object.assign({} , { padding:4} , Styles.l1 , Styles.m2 , Styles.s3  )}  onLayout={onLayout}>
                            <ProfileImage className="" source={avatar} width={imagePanelWidth-8}  />
                    </View>
                    <View  style={{flex:1 , padding:8}}>
                        <MyText isBlock isBold color="#00bcd4"   smallFont>{billName}</MyText>
                        <MyText smallFont isBold color="#00bcd4"   >{durationName}</MyText>
                        {  billDescription ?  <MyText smallFont  tinyFont  color="#29B6F6" style={{marginVertical:5}} >{billDescription}</MyText> : null  }
                        <MyText isBlock   smallFont>{"شناسه قبض : "+id}</MyText>
                        <MyText isBlock   smallFont>{"تاریخ انتشار : "+saveDate}</MyText>
                        {  startDate ?  <MyText isBlock  smallFont>{"دوره : "+startDate+" لغایت "+ startDate}</MyText> : null  }
                        {
                            !refId ? <MyText isBlock   smallFont>{"مهلت پرداخت : "+paymentDeadLine}</MyText> : null
                        }
                        <MyText smallFont  style={{marginTop:5 ,backgroundColor:"#e1f5fe" , padding:1 , paddingHorizontal:5 , borderRadius:5 , marginBottom:6 }}  >
                            {"مبلغ قابل پرداخت : "+formatNumber(billPrice+(previousDebt ? previousDebt : 0)) + " تومان"}
                        </MyText>
                        {
                            refId ? <MyText isBlock color="#03a9f4" tinyFont  >{"تاریخ پرداخت قبض : "+payDate}</MyText> : null
                        }
                        {
                            !refId && paymentDeadLineTimestamp<new Date().getTime() ? 
                            <MyText tinyFont color="#e57373" style={{}}>مهلت پرداخت این قبض به اتمام رسیده است</MyText>  : null
                        }
                    </View>
                </View>
       </TouchableOpacity>
    )
}
const CustomBillComponent= props =>{
    const { id , durationName  ,description , saveDate  , paymentDeadLine , paymentDeadLineTimestamp , billDescription , payDate , refId ,billAvatar   , billPrice   , onClick}=props

    const [imagePanelWidth , setImagePanelWidth]=useState(0 )

    const onLayout=event =>{
        const {width}=event.nativeEvent.layout
        setImagePanelWidth(width)
    }
    const avatar = billAvatar ? {uri : billAvatar} : require("../../../assets/images/act/imgDefImage.png")
    return(
       <TouchableOpacity //disabled={paymentDeadLineTimestamp<new Date().getTime()}   
                                    //  onPress={paymentDeadLineTimestamp>new Date().getTime() ? ()=>onClick(id) : null}
                                    onPress={()=>onClick(id , true)}
               style={{width:"100%" , padding:0 , marginTop:5 }}>
                <View /*onLayout={onLayout}*/  style={{flexDirection:"row" , borderBottomWidth:1 ,borderColor:"#eeeeee"  }}>
                <View  style={Object.assign({} , { padding:4} , Styles.l1 , Styles.m2 , Styles.s3  )}  onLayout={onLayout}>
                            <ProfileImage className="" source={avatar} width={imagePanelWidth-8}  />
                    </View>
                    <View  style={{flex:1 , padding:8}}>
                        <MyText isBlock isBold color="#00bcd4"   >{durationName}</MyText>
                        <MyText isBlock color="#909090"   smallFont>{description}</MyText>
                        <MyText isBlock   smallFont style={{marginTop:10}}>{"شناسه قبض : "+id}</MyText>
                        <MyText isBlock   smallFont>{"تاریخ انتشار : "+saveDate}</MyText>
                        {
                            !refId ? <MyText isBlock   smallFont>{"مهلت پرداخت : "+paymentDeadLine}</MyText> : null
                        }
                        
                        {  billDescription ?  <MyText isBlock  tinyFont  color="#29B6F6" style={{marginTop:5}} >{billDescription}</MyText> : null  }
                        <MyText  style={{marginTop:5 ,backgroundColor:"#e1f5fe" , padding:1 , paddingHorizontal:5 , borderRadius:5 , marginBottom:6 }}  >
                            {"مبلغ قابل پرداخت : "+formatNumber(billPrice) + " تومان"}
                        </MyText>
                        {
                            refId ? <MyText isBlock color="#03a9f4" tinyFont  >{"تاریخ پرداخت قبض : "+payDate}</MyText> : null
                        }
                        {
                            paymentDeadLineTimestamp<new Date().getTime() ? 
                            <MyText tinyFont color="#e57373" style={{}}>مهلت پرداخت این قبض به اتمام رسیده است</MyText>  : null
                        }
                    </View>
                </View>
       </TouchableOpacity>
    )
}
//==========================================================================================
const Bill=(props)=>{
//-----------------------------------REF EREA
const fetchModal=useRef()
const alertModal=useRef()
const mdlBillInfo=useRef()
const confirmModal=useRef()
//-----------------------------------STATE EREA
const [ fetchState , setFetchState ]=useState({ fetchFlag:true , errorFlag:false });
const [billInfo , setBillInfo]=useState({
                                                                        arrBill : [] , 
                                                                        arrCustomBill:[] , 
                                                                        billFinished : false ,
                                                                        customBillFinished:false ,  
                                                                        refresh : false ,
                                                                        billType : 1  //mustPay : 1  ----  archive : 2
                                                                    })
const [pageWasLoaded , setPageWasLoaded]=useState(false)
//-----------------------------------USE_EFFECT EREA
useEffect(()=>{
    if(pageWasLoaded)
            getBillList()
    else{
            setBillInfo({
                ...billInfo , 
                arrBill : [] , 
                arrCustomBill:[] ,
                billFinished : false , 
                customBillFinished:false , 
                refresh : false ,
            })
            setTimeout(()=>setPageWasLoaded(true) , 100)
    }
        
} , [ pageWasLoaded ])
useEffect(()=>{
    if(pageWasLoaded && billInfo.refresh)
            getBillList()
} , [billInfo.refresh   ])
useEffect(()=>{
    if(pageWasLoaded)
            getBillList()
} , [ billInfo.billType  ])
useEffect(()=>{
    if(props.route.params?.mustUpdateInfo){
        setPageWasLoaded(false)
        setFetchState({fetchFlag:true , errorFlag:false})
    }

} , [props.route.params?.mustUpdateInfo])

//-----------------------------------FUNCTION EREA
const arrTabs=[
    {
           id:1 ,
           title:"قبوض قابل پرداخت",
           onClick:()=> setBillInfo({ arrBill:[] ,arrCustomBill:[]  , billFinished:false , customBillFinished:false , refresh:false , billType:1}) , 
           selected  : billInfo.billType===1 
    } , 
    {
           id:2 , 
           title:"بایگانی قبوض",
           onClick:()=> setBillInfo({ arrBill:[] ,arrCustomBill:[]  , billFinished:false , customBillFinished:false , refresh:false , billType:2}) , 
           selected  : billInfo.billType===2
    } , 
]
//------------------------------------
// useBackHandler(()=>{
//         onBackClick()
//         return true
// })
const onBackClick=()=>props.navigation.goBack()

const getBillList=()=>{
    const objData={
        jwt:props.profileState.jwt,
        lastBillId:billInfo.arrBill.length ? billInfo.arrBill[billInfo.arrBill.length-1].id : 0 ,
        lastCustomBillId:billInfo.arrCustomBill.length ? billInfo.arrCustomBill[billInfo.arrCustomBill.length-1].id : 0 , 
        showNoPayedBill: billInfo.billType===1 ? true : false ,
        mustGetBill:!billInfo.billFinished , 
        mustGetCustomBill : !billInfo.customBillFinished 
    }
    setFetchState({fetchFlag : true , errorFlag:false})
    setTimeout(()=>{
                                    fetchModal.current.fetchData("user/getBillList" , objData , 
                                                                                {
                                                                                        onStart:()=>setFetchState({fetchFlag:true , errorFlag:false}) , 
                                                                                        onError:()=>setFetchState({fetchFlag:false , errorFlag:true}) , 
                                                                                        onBackFromConfirmError:()=>setFetchState({fetchFlag:false , errorFlag:false}) , 
                                                                                        onSuccess:responseOfGetBillList
                                                                                } , false , 
                                                                                        billInfo.arrBill.length || billInfo.arrCustomBill.length ? true : false , 
                                                                                        true)
                                } , billInfo.arrBill.length || billInfo.arrCustomBill.length  ? 0 : 700  )
    

}
const responseOfGetBillList= async (data) =>{
        setFetchState({fetchFlag:false , errorFlag:false}) 
        setBillInfo({
                ...billInfo , 
                arrBill:[...billInfo.arrBill , ...data.arrBill] , 
                arrCustomBill:[...billInfo.arrCustomBill , ...data.arrCustomBill] , 
                billFinished:data.billFinished , 
                customBillFinished : data.customBillFinished , 
                refresh:false
        })

        props.updateInitialInfo({newBillCount:0})
}
//--------------------------------------------------
const payBill=(billId , isCustomBill=false)=>{
    mdlBillInfo.current.hide()
    const objData={
        jwt:props.profileState.jwt ,
        billId ,
        isCustomBill: isCustomBill ? true : false
    }
    fetchModal.current.fetchData("user/payBill" , objData ,
                                                       {
                                                        onSuccess : responseOfPayBill
                                                       } , true , true , true)
}
const responseOfPayBill = data =>{
    if(typeof(data==="string")){
        if(data.indexOf("BILL_LAST_PAYED_")!==-1){
            const strBillIdPayDate=data.substring( 16 )
            const first_index=strBillIdPayDate.indexOf("_")
            const billId=strBillIdPayDate.substring( 0  , first_index )
            const payDate=strBillIdPayDate.substring(first_index+1)
            
            if(billInfo.billType===1)
                setBillInfo({...billInfo , arrBill:billInfo.arrBill.filter(bill => bill.id!== parseInt( billId ) )})
            else
                setBillInfo({...billInfo , arrBill : billInfo.arrBill.map( bill=> bill.id===parseInt(billId) ? Object.assign({} , bill , { payDate   })   : bill  )})
            props.setToastMessage("این قبض قبلا پرداخت شده است")
        }
        else if(data.indexOf("CUSTOME_BILL_LAST_PAYED_")!==-1){
            const first_index=data.indexOf("_")
            const last_index=data.lastIndexOf("_")
            const billId=data.substring(first_index+1 , last_index)
            const payDate=data.substring(last_index+1)
            if(billInfo.billType===1)
                setBillInfo({...billInfo , arrCustomBill:billInfo.arrCustomBill.filter(bill => bill.id!== parseInt( billId ) )})
            else
                setBillInfo({...billInfo , arrCustomBill : billInfo.arrCustomBill.map( bill=> bill.id===parseInt(billId) ? Object.assign({} , bill , { payDate   })   : bill  )})
            props.setToastMessage("این قبض قبلا پرداخت شده است")
        }
        else{
            try{
                Linking.openURL(data)
            }
            catch{}
        } 
    } 
}
const showBillInfo= (billId , isCustomBill) =>{
    mdlBillInfo.current.show(isCustomBill ?  Object.assign({} , billInfo.arrCustomBill.filter(bill => bill.id===billId)[0]  , {isCustomBill} )
                                                                     :  Object.assign({} ,billInfo.arrBill.filter(bill => bill.id===billId)[0] , {isCustomBill} ) )
}
const refreshBill=()=>setBillInfo({...billInfo ,   arrBill:[]  ,arrCustomBill:[]  ,  billFinished:false , customBillFinished:false ,  refresh:true    })
//---------------------------------------------------
const emptyListComponent=<View style={{}}>
                                                            {
                                                                fetchState.errorFlag && !billInfo.arrBill.length && !billInfo.arrCustomBill.length ? 
                                                                <View style={{flex:1}}>
                                                                    <NoItem visible title="خطا در دریافت اطلاعات" 
                                                                                message={ "در دریافت اطلاعات قبوض خطایی روی داده است!!!"+"\n"+
                                                                                                "این مشکل ممکن است در نتیجه کندی و یا قطعی شبکه اینترنت شما باشد."}
                                                                                showTryAgainButton tryAgain={getBillList} />
                                                                </View>:null
                                                            }
                                                            {
                                                                !fetchState.errorFlag && !fetchState.fetchFlag && 
                                                                !billInfo.arrBill.length && !billInfo.arrCustomBill.length && 
                                                                billInfo.billFinished && billInfo.customBillFinished ? 
                                                                <View style={{}} >
                                                                    <NoItem visible title="گزارش برنامه" icon={require("../../../assets/images/logo/logo.png")} noShadow={false}
                                                                                message={ billInfo.billType===1 ? "هم روستایی محترم"+"\n\n"+
                                                                                                                                         "تمامی قبوض قبلی شما پرداخت و تایید شده است."+"\n"+
                                                                                                                                         "از اینکه با پرداخت به موقع قبوض , ما را در خدمت رسانی بهتر یاری می رسانید از شما صمیمانه سپاسگذاریم."+"\n\n"+
                                                                                                                                         "دهیاری روستای "+props.profileState.objCity.name 
                                                                                                                                         : "هیچ قبصی برای شما ثبت نشده است!!!"} />
                                                                </View>:null
                                                            }
                                            </View>
const waitingComponent=()=>{
    if(fetchState.fetchFlag){
            if(billInfo.arrBill.length && billInfo.arrCustomBill.length)
                return <WaitModal visible />
            else
                return <View>
                                    <GoodsShopPlaceholder />
                                    <GoodsShopPlaceholder />
                                    <GoodsShopPlaceholder />
                            </View>
    }
    return null
}
const initBillToShow=()=>{
    return [ ...billInfo.arrBill , ...billInfo.arrCustomBill ].sort((a , b)=>a.saveDateTimestamp - b.saveDateTimestamp)
}
//--------------------------------------------------
   return(
    <PageContainer title="قبض های من " 
                            subTitle={"روستای "+props.profileState.objCity.name}
                            containerPadding={0}   
                            showBackIcon={true}  
                            onBackIconClick={onBackClick}
                            hasFlatList  >
            <TabComponent arrTabs={arrTabs} />
            <FlatList data={initBillToShow()} style={{}}
                            keyExtractor={(item , index)=>index.toString()}
                            onEndReachedThreshold={0.15}
                            onEndReached={(  (!billInfo.billFinished  &&  billInfo.arrBill.length) || 
                                                        (!billInfo.customBillFinished  &&  billInfo.arrCustomBill.length))  &&     pageWasLoaded   ? getBillList :  ()=>{} }
                            refreshing={billInfo.refresh }
                            onRefresh={refreshBill}
                            ListFooterComponent={waitingComponent()}
                            ListEmptyComponent={emptyListComponent}
                            renderItem={({item})=> item.isCustomBill ? <CustomBillComponent {...item} onClick={ showBillInfo }   /*onClick={ onPayBillClick }*/ />
                                                                                                  :  <BillComponent {...item} onClick={ showBillInfo } />}
             />
            <MdlBillInfo ref={mdlBillInfo} onPayClick={payBill} />
            <Fetch ref={fetchModal}/>
            <Alert ref={alertModal}/>
            <Confirm ref={confirmModal} />
       </PageContainer>
   )
}

const mapStateToProps= state =>({
    profileState:state.profileReducer
})
const mapDispatchToProps= dispatch =>({
    setToastMessage: strMessage => dispatch(setToastMessage(strMessage)) ,
    updateInitialInfo : initialInfo =>dispatch(updateInitialInfo(initialInfo))
})
export default connect(mapStateToProps , mapDispatchToProps)(Bill);
//=========================================================================================