//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
import { MyIcon, MySwitch, MyText } from '../globalComponents/components';
//----------------------------------------------------------------------------------------
const AnswerSelectComponent= props =>{
       const {id , answer , percent , onSelectAnswer}=props
       return(
              <div className='w3-border-bottom w3-border-white  w3-tiny' style={{padding:"4px 0"}}>
                     <MySwitch title={answer} useIcon iconName={"task_alt"} useIconOpacity iconSize={20} noSelectedColor="#9e9e9e" selectedColor="#456e7a"
                                      tinyFont  isBold={false}    containerStyle={{ marginTop:4 ,width:"100%" , flexDirection:"row" , padding:"0 0 2px 0" }} 
                                                        containerClassName="displayFlexRowReverse   w3-border-bottom w3-border-light-gray "
                                      onClick={()=>onSelectAnswer(id) }/>
              </div>
       )
}
const AnswerResultComponent= props =>{
       const {id , answer , percent , userAnswerSelectedId , onSelectAnswer}=props
       return(
              <div className=' w3-tiny ' style={{padding:"2px 0"}}>
                     <div className='displayFlexRow w3-margin-left ' style={{justifyContent:"space-between" , marginBottom:-8}}>
                            <MyText isBlock  tinyFont  className="w3-text-gray " >{answer}</MyText>
                            <MyText isBlock  tinyFont style={{color:"#a0a0a0"}}>{percent+"%"}</MyText>
                     </div>
                    <div className='displayFlexRow' style={{alignItems:"center"}} >
                            
                            <div className='w3-row-reverse  w3-round-xlarge displayFlexGrow w3-light-gray ' style={{overflow:"hidden" , height:4 , backgroundColor:"#FFFFFF"}}>
                                   <div className=' w3-round-xlarge ' style={{height:4 , width:`${percent}%` , backgroundColor:"#29b6f6"}} />
                            </div>
                            <div>        
                                   <MyIcon iconName="check_circle" color={userAnswerSelectedId!==id ? "#FFFFFF" : "#e57373"} iconSize={15} style={{}} /> 
                            </div>
                    </div>
              </div>
       )
}
//----------------------------------------------------------------------------------------
 
const SurveyComponent=(props)=>{
const {question , arrAnswer , userAnswerSelectedId , onSelectAnswer=()=>{} , showLoading=false} = props
//--------------------------------------------------
   return(
       <div>
              <div>
                     <MyText isBlock isBold smallFont color="#456e7a">{question}</MyText>
                     {
                            userAnswerSelectedId ? 
                            <div className='w3-margin-right w3-small- w3-text-gray'>
                            {
                                    arrAnswer.map(answer => <AnswerResultComponent key={answer.id} {...answer} onSelectAnswer={onSelectAnswer} userAnswerSelectedId={userAnswerSelectedId} />)
                             }
                             </div> : 
                             <div className='w3-margin-right w3-small- w3-text-gray'>
                             {
                                    arrAnswer.map(answer => <AnswerSelectComponent key={answer.id} {...answer} onSelectAnswer={onSelectAnswer} userAnswerSelectedId={userAnswerSelectedId} />)
                             }
                             </div>
                     }
                     
              </div>
       </div>
   )
}

export default SurveyComponent;
//=========================================================================================