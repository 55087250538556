//=========================================================================================================
const UPDATE_MAIN_ADVERTISING_INFO="UPDATE_MAIN_ADVERTISING_INFO"
//========================================================================================================
export const updateMainAdvertisingInfo=(advertisingInfo)=>({type:UPDATE_MAIN_ADVERTISING_INFO , advertisingInfo})
//=========================================================================================================
const mainAdvertisingInfoInit={
       fetchState:{fetchFlag:true , errorFlag:false} , 
       info:{
              title:'' , 
              subTitle:'' , 
              url:'' , 
              avatar:''
       }
}

export default (state=mainAdvertisingInfoInit , action)=>{
       switch(action.type){
              case UPDATE_MAIN_ADVERTISING_INFO : return Object.assign({} , state , action.advertisingInfo)
              default:return state
       }
}