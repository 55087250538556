//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react';
import { ScrollView, View } from "react-native";
import { Alert, Confirm, MyButton , PageContainer  , MyBorderInput, MySwitch, MyText} from '../../../globalComponents/components';
import uuid from 'react-uuid';
import Fetch from '../../../globalComponents/fetch';
import { connect } from 'react-redux';
import { setToastMessage } from '../../../redux/toastMessage';
import TabComponent from "../../../commonComponents/tabComponent";
// import {useBackHandler  } from "@react-native-community/hooks";
import MdlImagePicker from '../../../commonComponents/mdlImagePicker';
import ImageFileComponent from '../../../commonComponents/imageFileComponent';
import { TabView, SceneMap } from 'react-native-tab-view';
import { addUpdateChapter  } from '../../../redux/chapter';
//-------------------------------------------------------------------------------------------------
const ChapterTitleMatn= props =>{
       const {title , matn , onChangeTextInfo , chapterName="" , chapterTypeName , canAddNews=false , canAddBulletin=false , onSelectChapterTypeName=()=>{}} = props
       return(
              <ScrollView className={`w3-padding-small- displayFlexGrow    `} style={{overflow:"auto" , overflowX:"hidden" , padding:8}}>
                     {
                            canAddBulletin || canAddNews ? 
                            <View style={{ padding:10}}>
                                   <MySwitch title="ثبت سند در گفتگو های روستا"
                                                                                         useImage useImageBackgroundColor useImageOpacity 
                                                                                         selected={chapterTypeName==="CONVERSATION"}
                                                                                         smallFont
                                                                                         onClick={()=>onSelectChapterTypeName("CONVERSATION")} />
                                   {
                                          canAddBulletin ? <MySwitch title="ثبت سند در تابلو اعلانات روستا"
                                                                                         useImage useImageBackgroundColor useImageOpacity 
                                                                                         selected={chapterTypeName==="BULLETIN"}
                                                                                         smallFont
                                                                                         containerStyle={{marginTop:10}}
                                                                                         onClick={()=>onSelectChapterTypeName("BULLETIN")} /> : null
                                   }
                                   {
                                          canAddBulletin ? <MySwitch title="ثبت سند در اخبار روستا"
                                                                                         useImage useImageBackgroundColor useImageOpacity 
                                                                                         selected={chapterTypeName==="NEWS"}
                                                                                         smallFont
                                                                                         containerStyle={{marginTop:10}}
                                                                                         onClick={()=>onSelectChapterTypeName("NEWS")} /> : null
                                   }
                            </View> : null
                     }
                     <MyBorderInput title={`عنوان ${chapterName}  جدید`} id={"title"}
                                                 maxLength={255} 
                                                 isBold={false}
                                                 placeholder={`عنوان ${chapterName} جدید....`}    
                                                 value={title} 
                                                 onChangeText={ onChangeTextInfo } 
                                                 containerStyle={{marginTop:30}}
                                                 inputStyle={{}}      />

                     <MyBorderInput title={`متن ${chapterName} جدید.....`} id="matn"
                                                 maxNumberOfLines={15}
                                                 maxLength={10000} 
                                                 isBold={false}
                                                 iconStyle={{borderWidth:1}}
                                                 placeholder={`متن ${chapterName} جدید.....`}
                                                 value={matn}
                                                 onChangeText={ onChangeTextInfo } 
                                                 containerStyle={{marginTop:40 , padding:10  , }}
                                                 multiline   
                                                 minNumberOfLines={15}
                                                 inputContainerStyle={{ alignItems:"flex-start"}}
                                                 inputStyle={{textAlignVertical:"top" }}    />
              </ScrollView>
       )
}
const ChapterImage= props =>{
       const {arrImageFile , onDeleteImage ,onClickToShowImageModal
        } =props
       return(
               <View>
                     {
                            arrImageFile.length ? 
                            <MyText tinyFont isBold color="#e57373" style={{textAlign:"center" , marginTop:6}}>برای حذف یک تصویر , تصویر مورد نظر را کلیک کنید و نگه دارید</MyText> : null
                     }
                     <View style={{padding:16 , flexDirection:"row-reverse" , flexWrap:"wrap"}}>
                            {
                                   [{id :uuid() , filename:'' } , ...arrImageFile ].map(imageFile => <ImageFileComponent key={imageFile.id} 
                                                                                                                                                                                                    {...imageFile} 
                                                                                                                                                                                                    filename={imageFile.uri}
                                                                                                                                                                                                    onDeleteImage={()=>onDeleteImage(imageFile.id)} 
                                                                                                                                                                                                    onClick={onClickToShowImageModal} />   )
                            }
                     </View>
               </View>
       )
}
const ChapterSurvey= props =>{
       const {question , arrAnswer ,onChangeTextInfo  } = props
       return(
              <View className='displayFLexGrow-reverse w3-padding-small ' style={{marginTop:30 , padding:8}}>
                            <MyBorderInput placeholder='سوال نظرسنجی....' 
                                                 id="question"  
                                                 title="سوال نظرسنجی را وارد نمایید"
                                                 //     iconName="help" 
                                                 value={question} 
                                                 maxLength={255}
                                                 multiline
                                                 minNumberOfLines={1}
                                                 maxNumberOfLines={3}
                                                 onChangeText={onChangeTextInfo}
                                                 containerStyle={{marginBottom:40}}
                                                 inputStyle={{color:"gray" , textAlign:"right"}} smallFont  />

                     {
                            arrAnswer.map((answer , index) => <MyBorderInput title={`جواب نظرسنجی ${index+1} `}    
                                                                                                                              placeholder={`جواب نظرسنجی ${index+1} .....`}    
                                                                                                                              value={answer.answer}  
                                                                                                                              id ={'newAnswer_'+answer.id} key={index}
                                                                                                                              maxLength={255}
                                                                                                                              multiline
                                                                                                                              minNumberOfLines={1}
                                                                                                                              maxNumberOfLines={3}
                                                                                                                              onChangeText={onChangeTextInfo}
                                                                                                                              containerStyle={{marginTop:20}}
                                                                                                                              inputStyle={{color:"gray" , textAlign:"right"}} smallFont  /> )
                     }
              </View>
       )
}
//-------------------------------------------------------------------------------------------------
const AddUpdateChapter=(props)=>{
//-----------------------------------GLOBAL VAR EREA
const { selectedChapterInfo={ id:0 , 
                                                 chapterTypeName:'' ,  
                                                 title:'' , 
                                                 matn:'' , 
                                                 arrImageFile:[{id:uuid() , filename:''}/*{id , filename} */] , 
                                                 question:'' , 
                                                 arrAnswer:[{id:uuid() , answer:''}] /*{id , answer} */} , 
              // onAddUpdateChapter=()=>{} 
       } = props.route.params

//-----------------------------------REF EREA
const mdlGetImage=useRef()
const fetchModal=useRef()
const alertModal=useRef()
const confirmModal=useRef()
//-----------------------------------STATE EREA
const [chapterUUID , setChapterUUID]=useState(uuid())
const [chapterInfo , setChapterInfo]=useState({ ...selectedChapterInfo })
const [index, setIndex] = useState(0);
//-----------------------------------USE_EFFECT EREA
useEffect(()=>{
       if(!chapterInfo.chapterTypeName){
              setChapterInfo({...chapterInfo , chapterTypeName:"CONVERSATION"})
       }
} , [])
useEffect(()=>{
   if(chapterInfo.arrAnswer.length ){
          const answerHaveContent=chapterInfo.arrAnswer.filter(answer => answer.answer.trim())
          const answerIsEmpty = chapterInfo.arrAnswer.filter(answer => !answer.answer.trim())
          if(answerHaveContent.length){
                 if(!answerIsEmpty.length)
                        setChapterInfo({...chapterInfo , arrAnswer :[...chapterInfo.arrAnswer , {id :uuid() , answer:'' } ]})
                 if(answerIsEmpty.length>1)
                        setChapterInfo({...chapterInfo , arrAnswer :[...answerHaveContent , answerIsEmpty[0] ]})
          }          
   }
} , [chapterInfo.arrAnswer])
//-----------------------------------FUNCTION EREA
// useBackHandler(()=>{
//        onBackClick()
//        return true
// })
const onBackClick=()=>props.navigation.goBack()
//---------------------------------------------------------------------------------------
const onDeleteImage= imageId =>  setChapterInfo({...chapterInfo , arrImageFile:chapterInfo.arrImageFile.filter(imageFIle => imageFIle.id!==imageId)})
const onChangeTextInfo=(txtId , newText)=>{
    if(txtId.indexOf("newAnswer_")===-1)
          setChapterInfo({...chapterInfo , [txtId] : newText ? newText : "" })
    else{
           const selectedAnswerId= isNaN(txtId.substring(10)) ? txtId.substring(10) : parseInt(txtId.substring(10))
           setChapterInfo({...chapterInfo , arrAnswer :  chapterInfo.arrAnswer
                                                                                        .map(answer => answer.id===selectedAnswerId ?
                                                                                                                                                                  {
                                                                                                                                                                         ...answer,
                                                                                                                                                                         answer : newText ? newText  : ""  
                                                                                                                                                                  } 
                                                                                                                                                                  : answer )   })
    }
}
//-------------------------------------------------
const preAddUpdateChapter=()=>{
       const arrValideAnswer=chapterInfo.arrAnswer.filter(answer => answer.answer.trim().replace(/ + /g , " ").replace(/\n+/g , "\n"))

       if(chapterInfo.title.trim().replace(/ +/g , " ").length<5){
              alertModal.current.show("خطا در انجام عملیات",` عنوان  ${ chapterName }  را با حداقل 5 حرف وارد نمایید. `,"error")
              return
       }
       if(chapterInfo.matn.trim().replace(/ +/g , " ").replace(/\n+/g , "\n").length<5){
              alertModal.current.show("خطا در انجام عملیات",` متن  ${ chapterName }  را با حداقل 5 حرف وارد نمایید. `,"error")
              return
       }
       if(chapterInfo.question)
              if(chapterInfo.question.trim().replace(/ +/g , " ").replace(/\n+/g , "\n").length<5){
                     alertModal.current.show("خطا در انجام عملیات","سوال نظرسنجی ر ا با حداقل 5 حرف وارد نمایید و یا آن را خالی بگذارید.","error")
                     return
              }
       if(chapterInfo.question){
              if(arrValideAnswer.length<2){
                     alertModal.current.show("خطا در انجام عملیات","حداقل 2 جواب برای نظرسنجی وارد نمایید.","error")
                     return
              }
       }
       if(arrValideAnswer.length){
              for(let answer of arrValideAnswer){
                     if(answer.answer.length<2){
                            alertModal.current.show("خطا در انجام عملیات","جواب های نظر سنجی را با حداق 2 حرف وارد نمایید","error")
                            return
                     }
              }
       }

       const strChapterName=chapterInfo.chapterTypeName==="BULLETIN" ? "سند"  :  "گفتگو"

       if(!chapterInfo.arrImageFile.length )
              confirmModal.current.show("گزارش برنامه","شما هیچ تصویری برای این  "+ strChapterName +"  انتخاب نکرده اید!!!"+"\n"+
                                                                                 "آیا "+strChapterName+" بدون انتخاب تصویر ثبت گردد؟","question",addUpdateChapter , "تایید و ادامه")
       else
              addUpdateChapter()
}
const addUpdateChapter=()=>{
       const arrValideAnswer=chapterInfo.arrAnswer.filter(answer => answer.answer.trim().replace(/ +/g , " ").replace(/\n+/g , "\n"))

       const arrNewFile=chapterInfo.arrImageFile.filter(imageFile => typeof(imageFile.id) ==="string")
       const arrNewImage =  arrNewFile.map((image , index)=>({
                                                                                                                name:'imageForChapter'+index , 
                                                                                                                filename:'imageForChapter.esi'+"_"+image.imageId  , 
                                                                                                                type:image.type  ,
                                                                                                                uri:image.uri
                                                                                                  }))

       const objData={
              chapterInfo : {
                     id:chapterInfo.id ,
                     title:chapterInfo.title.trim().replace(/ +/g , " "),
                     matn : chapterInfo.matn.replace(/ +/g , " ").replace(/\n+/g , "\n"),
                     question:chapterInfo.question ? chapterInfo.question.replace(/ +/g , " ").replace(/\n+/g , "\n") : "",
                     arrAnswer : arrValideAnswer ,
                     managerDescription : chapterInfo.managerDescription , 
                     arrImageFile : chapterInfo.arrImageFile.filter(imageFile => imageFile.filename) ,
                     chapterTypeName : chapterInfo.chapterTypeName,
                     chapterUUID  , 
              },
              
              jwt:props.profileState.jwt
       }

       fetchModal.current.uploadData( "user/addUpdateChapter" ,
                                                        objData ,
                                                        arrNewImage,
                                                        {
                                                               onSuccess : responseOfAddUpdateChapter ,
                                                               onStart:()=>{},
                                                               onError:()=>{},
                                                        } , 
                                                        true , )  
}
const responseOfAddUpdateChapter = data => {
       if(typeof(data)==="string"){
              if(data=="ERROR_IN_SEND_IMAGE_DATA"){
                     alertModal.current.show("خطا در بارگزاری تصویر ",
                                          "در ارسال تصویر مقاله خطایی روی داده است و لازم است تصویر مجددا انتخاب و ارسال گردد.",
                                          "error")
                     return
              }
              if(data=="DATA_IS_NOT_VALIDATE"){
                     alertModal.current.show("خطا در بارگزاری تصویر ",
                                          "اطلاعات ارسال شده معتبر نمی باشد",
                                          "error")
                     return
              }
              if(data=="NO_VALID_IMAGE_FORMAT"){
                     alertModal.current.show("خطا در بارگزاری تصویر ",
                                          "تنها تصاویری با فرمتهای "+"\n"+
                                          "jpg"+"\n"+
                                          "jpeg"+"\n"+
                                          "png"+"\n"+
                                          "برای تصویر پروفایل قابل پذیرش می باشد.",
                                          "error")
                     return
              }
              if(data=="ERROR_IN_SAVE_DATA_LASTLY"){
                     alertModal.current.show("خطا در بارگزاری تصویر ",
                                          "در ثبت نهایی تصویر خطایی روی داده است و لازم است تصویر مجددا انتخاب و ارسال گردد.",
                                          "error")
                     return
              }
              if(data==="ABOUT_US_DESCRIPTION_LAST_DELETED"){
                     alertModal.current.show("خطا در نجام عملیات",
                                                               "این بلوک توضیح قبلا  حذف شده است !!!",
                                                               "error")
                     return
              }

              if(data==="CHAPTER_LAST_DELETED"){
                     props.deleteChapter()
                     props.setToastMessage(`این ${chapterName} قبلا حذف شده است!!!!`)
              }
       }
       else{
              props.setToastMessage(`ثبت ${chapterName}  با موفقیت انجام شد.`)
              props.addUpdateChapter(data)
              onBackClick()
       }
}
//-------------------------------------------------
const leftComponent=()=>{
       return(
              <View>
                     <MyButton onClick={preAddUpdateChapter}
                                      title='ثبت سند' 
                                      iconName='save' 
                                      buttonStyle={{backgroundColor:"#FFF"}}
                                      buttonIconStyle={{color:"#e57373"}}
                                      buttonTextStyle={{color:"#456e7a"}} />
              </View>
       )
}
//-------------------------------------------------
const handleImageUpload = async  arrImage => { 
//       if(e.target.files && e.target.files.length > 0){
//                const arrFile=[]
//                for(let file of e.target.files)
//                   arrFile.push({ id:uuid() ,  filename : file })
//                setChapterInfo({...chapterInfo , arrImageFile:[...chapterInfo.arrImageFile , ...arrFile ]})
//       }
       if(arrImage.length)
       setChapterInfo({...chapterInfo , arrImageFile:[...chapterInfo.arrImageFile , ...arrImage.map(file => ({id:uuid() ,  ... file})    ) ]})
};
//---------------------------------------------------
const  chapterName= chapterInfo.chapterTypeName==="CONVERSATION" ? "گفتگو" 
                                                                               :chapterInfo.chapterTypeName==="BULLETIN" ?  "سند"  :"خبر"
//---------------------------------------------------
const onSelectChapterTypeName= chapterTypeName => setChapterInfo({...chapterInfo , chapterTypeName})
//--------------------------------------------------
const arrTabs=[
       {
              title:'عنوان و متن '+chapterName , 
              selected:index===0 ,
              onClick:()=>setIndex(0)
       },
       {
              title:'تصاویر '+chapterName , 
              selected:index===1,
              onClick:()=>setIndex(1)
       },
       {
              title:'نظرسنجی' , 
              selected:index===2,
              onClick:()=>setIndex(2)
       },
]

const renderScene = ({route}) => {
       switch (index) {
           case 0:
               return <ChapterTitleMatn title={chapterInfo.title} chapterTypeName={chapterInfo.chapterTypeName}
                                                         matn={chapterInfo.matn}  onChangeTextInfo={onChangeTextInfo} 
                                                         chapterName={chapterName} 
                                                         canAddBulletin={props.profileState.canAddBulletin} 
                                                         canAddNews={props.profileState.canAddNews}
                                                         onSelectChapterTypeName={onSelectChapterTypeName} />
           case 1:
               return <ChapterImage arrImageFile={chapterInfo.arrImageFile} onDeleteImage={onDeleteImage} onClickToShowImageModal={()=>mdlGetImage.current.showModal(true , 1280 , 1280 , handleImageUpload  )} />
           case 2:
               return <ChapterSurvey question={chapterInfo.question} arrAnswer={chapterInfo.arrAnswer}  onChangeTextInfo={onChangeTextInfo}  />
           default:
               return null
       }
   }
const [routes] = React.useState([
       { key: 'ChapterTitleMatn', title: 'ChapterTitleMatn' },
       { key: 'ChapterImage', title: 'ChapterImage' },
       { key: 'ChapterSurvey', title: 'ChapterSurvey' },
     ]);
//--------------------------------------------------
   return(
    <PageContainer   title={"ثبت "+chapterName+"  جدید"} 
                                subTitle={"روستای "+props.profileState.objCity.name}
                               containerPadding={0} 
                               showBackIcon  
                               onBackIconClick={onBackClick}  
                               leftComponent={leftComponent()}
                               hasFlatList >
                     <View style={{}}>
                     <TabComponent arrTabs={arrTabs} />
                     </View>
                     <View  style={{flex:1 }}>
                                   <TabView  navigationState={{ index, routes }}
                                                 renderScene={renderScene}
                                                 onIndexChange={setIndex}
                                                 renderTabBar={()=>null} />    
                     </View>
           <MdlImagePicker ref={mdlGetImage} />
           <Fetch ref={fetchModal} />
           <Alert ref={alertModal} />
           <Confirm ref={confirmModal} />
       </PageContainer>
   )
}

const mapStateToProps= state =>({
   profileState : state.profileReducer
})
const mapDispatchToProps= (dispatch , props ) =>({
   setToastMessage : strMessage => dispatch(setToastMessage(strMessage)) , 
   addUpdateChapter : chapterInfo => dispatch(addUpdateChapter(chapterInfo))
})

export default connect(mapStateToProps , mapDispatchToProps)(AddUpdateChapter);
//=========================================================================================