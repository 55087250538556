//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
import {View} from "react-native"
import Fetch from '../../../globalComponents/fetch';
import {  PageContainer, Waiting , MyText, MyImage, MyButton, MyIcon } from '../../../globalComponents/components';
import { connect } from 'react-redux';
// import {useBackHandler} from "@react-native-community/hooks"
import { formatNumber } from '../../../globalComponents/rejax';
import Styles from '../../../styles';
import { myShopResetInfo } from '../../../redux/myBazaar';

//-------------------------------------------------------------------------------------------------------
const TransactionInfoComponent= props =>{
    const {title , value , valueBigFont=false} =props
    return(
        <View  style={{alignItems:"center" , flexDirection:"row" , marginTop:0 , justifyContent:"center" , borderBottomWidth:1 , borderColor:"#FFFFFF" , paddingVertical:2}}>
            <MyText  color="#90a4ae" >{title}</MyText>
            <View  className='displayFlexGrow' style={{borderTop:"1px dashed #90a4ae" , flex:1}} />
            <MyText  bigFont={valueBigFont} color="#90a4ae" >{value}</MyText>
        </View>
    )
}
const SuccessTransactionComponent = props =>{
    const {refId=0 , payDate="" , transactionId=0 , cardNumber="" , amount=0 , message="" } = props

    const [imageWidth , setImageWidth]=useState(0)

    const onLayout = e =>  setImageWidth(e.nativeEvent.layout.width/1.4)

    return (
        <View onLayout={onLayout}   style={Object.assign({} , {  backgroundColor:"#e0f2f1" , justifyContent:"center" , alignItems:"center" ,
                                                                                                     borderRadius:15 , padding:8 , margin:5} , Styles.l5 , Styles.m8 , Styles.s10)}>
                <View style={{paddingVertical : 20}} >
                {
                    imageWidth ? 
                            <MyImage source={require("../../../assets/images/logo/logoNameHoriz.png")} resizeMode={"contain"}  
                                            style={{width:imageWidth , height:imageWidth/2  }} /> : null
                }   
                </View>
                <View>
                    <MyIcon iconName="check_circle" color="#4db6ac" iconSize={40} style={{width:"100%" , height:"auto"}} />
                </View>
                <View>
                    <MyText isBold bigFont className="w3-text-teal ">تراکنش موفق</MyText>
                </View>
                <View>
                    <MyText  smallFont  className="w3-text-gray " style={{marginTop:7 , color:"gray"}}>{`شماره پیگیری : ${refId}`}</MyText>
                </View>

                <View  style={{width:"100%" , paddingHorizontal:16 , paddingVertical:8 , margin:8}}>
                    <View className='w3-blue-gray w3-padding-small w3-round-xlarge' />
                        <TransactionInfoComponent title="شماره صورتحساب : " value={transactionId} valueBigFont />
                        <TransactionInfoComponent title="تاریخ پرداخت : " value={payDate} />
                        <TransactionInfoComponent title="شماره کارت : " value={cardNumber} valueBigFont />
                        <TransactionInfoComponent title="مبلغ تراکنش ( تومان ) : " value={formatNumber(amount)} valueBigFont />
                </View>

                {
                    message ? <View>
                                        <MyText   smallFont color="#90a4ae">{message}</MyText>
                                    </View>  :null
                }

                <View className='w3-margin' style={{margin:16}}>
                        <MyButton smallFont title="بازگشت به برنامه" iconName="apps" onClick={props.onBackClick} buttonStyle={{backgroundColor:"#00bcd4"}} />
                </View>
        </View>
    )
}
const NoSuccessTransactionComponent = props =>{
    const [imageWidth , setImageWidth]=useState(0)
    const onLayout = e =>  setImageWidth(e.nativeEvent.layout.width/2)
    return (
            <View onLayout={onLayout}    style={Object.assign({} , { backgroundColor:"#e0f2f1" , justifyContent:"center" , alignItems:"center" , 
                                                                                                         borderRadius:15 , padding:8  , elevation:2 , margin:5} , Styles.l5 , Styles.m8 , Styles.s10)}>
                        <View style={{paddingVertical : 20}} >
                                <MyImage source={require("../../../assets/images/logo/logoNameHoriz.png")} resizeMode={"contain"}  
                                            style={{width:imageWidth , height:imageWidth/2 }} />
                        </View>
                        <View>
                            <MyIcon iconName="cancel" color="#e57373" iconSize={50} style={{backgroundColor:"#FFFFFF" , borderRadius:30 , padding:1 , border:"1px solid #e57373"}} />
                        </View>
                        <View className='w3-margin-top'>
                            <MyText isBold bigFont color="#e57373">تراکنش ناموفق</MyText>
                        </View>
                        <View  className='w3-margin-top'>
                            <MyText isBlock smallFont  className="w3-text-gray w3-center " style={{marginTop:7 , color:"gray" , textAlign:"center"}}>{"در صورت کسر هزینه از حساب شما , تا 48 ساعت آینده هزینه"+"\n"+
                                                                                                                                                            "به حساب شما برگشت داده خواهد شد."}</MyText>
                        </View>

                        
                        <View className='w3-margin' style={{marginTop:25 , marginBottom:5}}>
                                <MyButton title="بازگشت به برنامه" iconName="apps" onClick={props.onBackClick} buttonStyle={{backgroundColor:"#00bcd4"}} />
                        </View>
            </View>
    )
}
const ErrorInTransactionResultComponent= props =>{
    
    const [imageWidth , setImageWidth]=useState(0)

    const onLayout = e =>  setImageWidth(e.nativeEvent.layout.width/2)
    

    return (
        <View onLayout={onLayout}  
                  style={Object.assign({} , {backgroundColor:"#fffde7" , justifyContent:"center" , alignItems:"center" , 
                                                               borderRadius:15 , padding:8  , margin:5 , elevation:1} , Styles.l5 , Styles.m8 , Styles.s10)}>
            <View style={{paddingVertical : 20}} >
                    <MyImage source={require("../../../assets/images/logo/logoNameHoriz.png")} resizeMode={"contain"}  
                                   style={{width:imageWidth , height:imageWidth/2  }} />
            </View>
            <View>
                <MyIcon iconName="warning_amber" color="#e57373" iconSize={50}  />
            </View>
            <View >
                <MyText isBold bigFont color="#e57373">خطا در بررسی تراکنش</MyText>
            </View>
            <View  >
                <MyText isBlock smallFont  className="w3-text-gray w3-center " style={{marginTop:7 , color:"gray" , textAlign:"center"}}>{"در بررسی نتیجه تراکنش شما خطایی روی داده است."}</MyText>
            </View>
            <View className='w3-margin displayFlexRow' style={{ flexDirection:"row" , marginTop:25 , marginBottom:5 ,  justifyContent:"space-between" , width:"100%"}}>
                    <MyButton smallFont title="دوباره سعی کنید" iconName="refresh" buttonStyle={{backgroundColor:"#e57373"}} onClick={props.onTryAgain} />
                    <MyButton smallFont title="بازگشت به برنامه" iconName="apps" onClick={props.onBackClick} buttonStyle={{backgroundColor:"#00bcd4"}} />
            </View>
        </View>
    )
}
const WaitingForTransactionResultComponent= props =>{
    return (
        <View  style={{backgroundColor:"#f0f0f0" , justifyContent:"center" , alignItems:"center" , borderRadius:10 , padding:8}}>
            <View className='w3-margin-top w3-margin-bottom w3-center' style={{marginVertical:10 ,justifyContent:"center" , alignItems:"center"}}>
                <Waiting visible={true} title={"در انتظار دریافت نتیجه تراکنش"+"\n"+"لطفا منتظر بمانید"} />
            </View>
        </View>
    )
}
//----------------------------------------------------------------------------------------------------------
const CheckTransaction=(props)=>{
//----------------------------------------------------------------------------------------------------------GLOBAL VAR EREA
//----------------------------------------------------------------------------------------------------------REF EREA
const fetchModal=useRef()
//----------------------------------------------------------------------------------------------------------STATE EREA
const getTokenStatus=()=>{
    if(props.route.params){
            if( props.route.params.Authority && props.route.params.Status )
                    return { token:props.route.params.Authority , status : props.route.params.Status }
            else
                    return {token:'' , status:''}
    }
    else
        return {token:'' , status:''}
}

const [ fetchState , setFetchState ]=useState({ fetchFlag:true , errorFlag:false });
const [tokenStatusInfo , setTokenStatusInfo]=useState(getTokenStatus())
const [transactionInfo , setTransactionInfo]=useState({
                                                                                                status:null , 
                                                                                                transactionId :0  ,
                                                                                                payDate:'' , 
                                                                                                refId:'' , 
                                                                                                cardNumber:'' , 
                                                                                                amount:'' ,
                                                                                                message:""
                                                                                            })
//-----------------------------------USE_EFFECT EREA
useEffect(()=>{
    setTimeout(()=>checkTransactionVerify() , 1000) 
} , [])
//-----------------------------------FUNCTION EREA
// useBackHandler(()=>{
//     onBackClick()
//     return true
// })
const onBackClick=()=>{
    const routes= props.navigation.getState().routes; // Don't do this

    //آیا پرداخت قبض بوده است
    const findBillPage= routes.filter(route => route.name==="bill")
    if(findBillPage.length){
        props.navigation.replace("bill" , {mustUpdateInfo:transactionInfo.status ? true : false })
        return
    }
    //آیا پرداخت حق عضویت فروشگاه  بوده است
    const findMyShopPage = routes.filter(route => route.name === "bazaar")
    if (findMyShopPage.length) {
        props.myShopResetInfo()
        props.navigation.goBack()//("bazaar" , {mustUpdateInfo:transactionInfo.status ? true : false } )
        return
    }
    //آیا پرداخت حق عضویت فروشگاه  بوده است
    const findAnnoumcementPage = routes.filter(route => route.name === "addAnnouncement")
    if (findAnnoumcementPage.length) {
        props.navigation.replace("mainPage", { mustUpdateInfo: transactionInfo.status ? true : false })
        return
    }
    else
        props.navigation.goBack()
}
//-------------------------------------------------
const checkTransactionVerify=()=>{
    const objData={
        token : tokenStatusInfo.token, 
        status : tokenStatusInfo.status
    }
    fetchModal.current.fetchData("user/verifyTransaction" , objData ,
                                                       {
                                                            onStart : setFetchState({fetchFlag:true , errorFlag:false}) , 
                                                            onError:()=> setFetchState({fetchFlag:false , errorFlag:true}) , 
                                                            onSuccess:responseOfTransactionVerify , 
                                                       } , false , false , false)
}
const responseOfTransactionVerify= data =>{
    // alert(JSON.stringify(data))
    setFetchState({fetchFlag:false , errorFlag:false})
    setTransactionInfo(data )
}
//--------------------------------------------------
   return(
    <PageContainer   title={"بررسی تراکنش"} 
                                containerPadding={10}   
                                headerColor="#00ACC1" 
                                headerTextColor="#FFFFFF" 
                                backgroundColor="#FFFFFF"
                                showBackIcon={true}
                                onBackIconClick={onBackClick} hasFlatList >
           <View  style={{ alignItems:"center" , justifyContent:"center" , flex:1}}>
                {
                    fetchState.fetchFlag ?  <WaitingForTransactionResultComponent  /> : null
                }
                {
                    fetchState.errorFlag && !fetchState.fetchFlag ?  <ErrorInTransactionResultComponent onTryAgain={checkTransactionVerify} onBackClick={onBackClick} /> : null
                }
                {
                    transactionInfo.status !== null && 
                    !fetchState.fetchFlag &&   !fetchState.errorFlag  ? transactionInfo.status  ?  <SuccessTransactionComponent { ...transactionInfo } onBackClick={onBackClick} />
                                                                                                                                                 :  <NoSuccessTransactionComponent { ...transactionInfo } onBackClick={onBackClick} /> 
                                                                                                        : null
                }
           </View>
           
           <Fetch ref={fetchModal}/>
    </PageContainer>
   )
}

const mapStateToProps = state =>({
    profileState : state.profileReducer ,
    
})
const mapDispatchToProps =dispatch =>({
    myShopResetInfo : () => dispatch(myShopResetInfo())
})
export default connect(mapStateToProps , mapDispatchToProps )(CheckTransaction);
//=========================================================================================