//========================================================================================
import React,{ useState, useImperativeHandle, forwardRef}  from 'react'
import {View} from "react-native"
import { ModalContainer, MyButton, MyText } from '../../../../globalComponents/components';

const WarningComponent=props =>{
       const {title , subTitle} = props
       return(
              <View className='w3-border-bottom w3-border-light-gray' style={{marginBottom:5 , padding:"0 0 5px 0"}}>
                     <MyText isBlock smallFont  color="#e57373">{title}</MyText>
                     <MyText isBlock tinyFont color="#456e7a"  style={{marginRight:16}}>{subTitle}</MyText>
              </View>
       )
}
 
const MdlWarningOnSave=forwardRef((props ,ref)=>{
//-----------------------------------GLOBAL VAR EREA

//-----------------------------------REF EREA
//-----------------------------------STATE EREA
const [visible , setVisible]=useState(false)
const [arrWarning , setArrWarning]=useState([])
//-----------------------------------USE_EFFECT EREA
useImperativeHandle(ref , ()=>({
       show: _arrWarining => {
              setArrWarning(_arrWarining)
              setVisible(true)
       }
}))
//-----------------------------------FUNCTION EREA
const hide=()=>setVisible(false)
const onSaveClick=()=>{
       hide()
       props.addUpdateShopInfo()
}
//--------------------------------------------------
   return(
       <ModalContainer visible={visible} showTitle 
                                  showBackButton
                                  onBackClick={hide}
                                  title="ثبت و برزرسانی اطلاعات فروشگاه" 
                                  message={"اخطار های زیر شناسایی شد!!! ..."+"\n"+"آیا همچنان مایل به ادامه فرایند ثبت اطلاعات فروشگاه خود  هستید؟"}
                                  contentStyle={{width:"100%" , padding:10}}
                                  contentClassName=""
                                  closeWithClickBackButton
                                  containerClassName="w3-padding-small"
                                  containerStyle={{padding:10}}
                                  position="top" animation="slideInDown" >
              <View className='w3-margin'>
                     {
                            arrWarning.map((warning , index) => <WarningComponent key={index} {...warning} />)
                     }
              </View>
              <View   style={{flexDirection:"row-reverse", alignItems:"center" , marginBottom:5 , marginTop:20}}>
                     <MyButton smallFont isBold title="ادامه و ثبت اطلاعات فروشگاه" iconName="save" buttonStyle={{justifyContent:"center"}} onClick={onSaveClick} />
              </View>
       </ModalContainer >
   )
})

export default MdlWarningOnSave;
//=========================================================================================