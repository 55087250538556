//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
import {View , Linking , TouchableOpacity } from "react-native"
import {  PageContainer  , ImageModal, NoItem, Alert , MyButton, MySwitch  } from '../../../../globalComponents/components';
import { connect } from 'react-redux';
import ShopDescription from './ShopDescription';
import GoodsListComponent from '../GoodsListComponent';
// import {useBackHandler} from "@react-native-community/hooks"
import ShopInfoPlaceholder from '../components/shopInfoPlaceholder';
import Fetch from '../../../../globalComponents/fetch';
import ShopLink from './shopLink';
import ShopGoodsCategoryComponent from '../components/shopGoodsCategoryComponent';
import ExtendedActionButton from '../../../../commonComponents/extendedActionButton';
import SearchComponent from '../../../../commonComponents/searchComponent';
const ShopInfo=(props)=>{
//-----------------------------------GLOBAL VAR EREA
const shopId=props.route.params.shopId
//-----------------------------------REF EREA
const imageModal=useRef()
const fetchModal=useRef()
const alertModal=useRef()
/*       
       id:0 ,      
       name:'' , 
       banner:'',
       description:'',
       sellerName :'' , 
       sellerAvatar:'' , 
       shopAvatar:'' , 
       address:'' , 
       phone:'' , 
       arrImage:[] , 
       arrCategory:[] , 
       arrLink:[] , 
       selectedCategoryId:0,
       goodsInfo:{
              arrGoods:[],
              finished:false , 
              refresh:false ,
              tempStrSearch:'' , 
              strSearch:''
       }
*/
//-----------------------------------STATE EREA
const [strSearch  ,setStrSearch]=useState("")
const [showSearchBox , setShowSearchBox]=useState(false)
const [ fetchState , setFetchState ]=useState({ fetchFlag:true , errorFlag:false });
const [ goodsFetchState , setGoodsFetchState ]=useState({ fetchFlag:false , errorFlag:false });
const [isSelected , setIsSelected]=useState(false)
const [shopInfo , setShopInfo]=useState({
                                                                             id:0 ,      
                                                                             name:'' , 
                                                                             description:'',
                                                                             sellerName :'' , 
                                                                             shopAvatar:'' , 
                                                                             phone:'' , 
                                                                             arrCategory:[] , 
                                                                             arrLink:[] , 
                                                                             selectedCategoryId:0,
                                                                             shopCategoryId:0 , 
                                                                             goodsInfo:{
                                                                                    arrGoods:[],
                                                                                    finished:false , 
                                                                                    refresh:false ,
                                                                                    strSearch:'',
                                                                             }
                                                                      })
//-----------------------------------USE_EFFECT ERE
useEffect(()=>{
       setTimeout(()=>getShopInfo() , 100)
} , [])
useEffect(()=>{
       if(shopInfo.id)
              getGoodsList()
} , [shopInfo.id])
useEffect(()=>{
       if(shopInfo.goodsInfo.refresh)
              getGoodsList()
} , [shopInfo.goodsInfo.refresh])
//-----------------------------------FUNCTION EREA
// useBackHandler(()=>{
//        onBackClick()
//        return true
// })
const onBackClick=()=>props.navigation.goBack()
//------------------------------------------
const getShopInfo=()=>{
       const objData={
              shopId  ,
              jwt : props.profileState.jwt
       }
       fetchModal.current.fetchData("user/getShopInfo" , objData , 
                                                        {
                                                               onStart:()=>setFetchState({fetchFlag:true , errorFlag:false}) ,
                                                               onSuccess : responseOfGetSelectedShopInfo , 
                                                               onError:()=>setFetchState({fetchFlag:false , errorFlag:true})
                                                        } , false , false , false)
       
}
const responseOfGetSelectedShopInfo= data =>{
       setFetchState({fetchFlag:false , errorFlag:false})
       setShopInfo({...shopInfo , ...data})
}
//----------------------------------------
const getGoodsList=()=>{
       const objData={
              jwt : props.profileState.jwt ,
              shopId:shopInfo.id , 
              lastGoodsId : shopInfo.goodsInfo.arrGoods.length ? shopInfo.goodsInfo.arrGoods[shopInfo.goodsInfo.arrGoods.length-1].id : 0 , 
              categoryId : shopInfo.selectedCategoryId
       }
       fetchModal.current.fetchData("user/getGoodsList" , objData , 
                                                         {
                                                               onStart : ()=>setGoodsFetchState({fetchFlag : true , errorFlag : false}) ,
                                                               onError : ()=>setGoodsFetchState({fetchFlag : false , errorFlag : true}) , 
                                                               onSuccess : responseOfGetGoodsList
                                                         } , false , false , false
       )
}
const responseOfGetGoodsList=data =>{
       setGoodsFetchState({fetchFlag:false , errorFlag:false})
       setShopInfo({
                                   ...shopInfo , 
                                   goodsInfo :{
                                          ...shopInfo.goodsInfo , 
                                          arrGoods : [...shopInfo.goodsInfo.arrGoods , ...data.goodsInfo.arrGoods] , 
                                          finished : data.goodsInfo.finished , 
                                          refresh:false
                                   }
                            })
}
//--------------------------------------------------
const CallTo = () => {
       try{
              Linking.openURL(`tel:${shopInfo.phone}`)
        }
        catch{  }
}
//--------------------------------------------------
const onSelectCategory= categoryId =>{
       setShopInfo({
              ...shopInfo , 
              selectedCategoryId : shopInfo.selectedCategoryId===categoryId  ? 0  :   categoryId , 
              goodsInfo :{
                     ...shopInfo.goodsInfo , 
                     arrGoods:[],
                     finished:false , 
                     refresh : true , 
                     strSearch:""
              }
       })
       setStrSearch("")
}
//------------------------------------------------
const onChangeText=(txtId , newText)=>  setStrSearch(newText ? newText : "")
const onSearchButtonClick=()=>{
       const str= strSearch ?  strSearch.replace(/ +/g , " ") : ""
       setShopInfo({
              ...shopInfo , 
              selectedCategoryId:0 , 
              goodsInfo:{
                     ...shopInfo.goodsInfo , 
                     arrGoods:[] , 
                     finished:false , 
                     refresh:true , 
                     strSearch:str
              }
       })
       setStrSearch(str)
}
//-------------------------------------------------
const headerComponent= <View>
                                   <View style={Object.assign({} , {  margin:5  , borderRadius:10  , marginBottom:10   })}>
                                          <TouchableOpacity onPress={()=>setIsSelected(!isSelected)} activeOpacity={0.7}>
                                                 <View style={{flexDirection:"row" ,backgroundColor:"#eceff1" , alignItems:"center" , paddingHorizontal:5  , borderRadius:5   }}>
                                                        <View style={{flex:1}}>
                                                               <MyButton tinyFont disabled opacityOnDisabledOff
                                                                      title={"معرفی , مشخصات و آدرس فروشگاه"}
                                                                      //  iconName={iconName}
                                                                      buttonStyle={{ justifyContent:"flex-end" , paddingVertical:6  , borderWidth:0 , backgroundColor:"transparent"  }}
                                                                      buttonIconStyle={{fontSize:27 , color:"#e57373"}}
                                                                      buttonTextStyle={{color:isSelected ? "#29b6f6" :"#456e7a"}} />
                                                        </View>
                                                        <MyButton onClick={()=>{}} disabled opacityOnDisabledOff
                                                                      title='' 
                                                                      iconName={isSelected ? "keyboard_arrow_up" : "keyboard_arrow_down"} 
                                                                      buttonStyle={{backgroundColor:"transparent" , borderRadius:30  ,paddingHorizontal:0 , paddingVertical:0 }}
                                                                      buttonIconStyle={{fontSize:isSelected ? 25 : 25 , color:isSelected ? "#29b6f6" : "#90a4ae"}}
                                                                      buttonTExtStyle={{}} />
                                                 </View>
                                          </TouchableOpacity>
                                          {/* <View style={{}}>
                                          <MySwitch selected={isSelected} 
                                                            containerStyle={{ backgroundColor:"#F0F0F0" , borderRadius:6 , padding:5}}  
                                                           title="نمایش مشخصات فروشگاه" useImage 
                                                          useBackgroundColor useImageOpacity onClick={()=>setIsSelected(!isSelected)}  />
                                          </View> */}
                                          {
                                                 isSelected ?   <View style={{padding:5 , backgroundColor:"#eceff1"  , borderRadius:5 , marginTop:2  ,}}>
                                                                                    <View style={{marginTop:5  , borderRadius:10}}>
                                                                                           <ShopDescription name={shopInfo.name} sellerName={shopInfo.sellerName} description={shopInfo.description} 
                                                                                                                       shopAvatar={shopInfo.shopAvatar} categoryId={shopInfo.shopCategoryId} />
                                                                                           <ShopLink arrLink={shopInfo.arrLink} />
                                                                                    </View>     
                                                                      </View>  : null
                                          }
                                   </View>
                                   <View style={{paddingHorizontal:10}}>
                                   <SearchComponent onChangeText={onChangeText} value={strSearch} onSearchButtonClick={onSearchButtonClick} placeholder="جستجوی نام محصول در فروشگاه...."
                                                                                                                hideSearchButton={strSearch===shopInfo.goodsInfo.strSearch} />
                                   </View>
                                                                                    {
                                                                                           shopInfo.arrCategory.length>1 ? 
                                                                                           <ShopGoodsCategoryComponent arrCategory={shopInfo.arrCategory} isUserShopCategory={true}
                                                                                                                                            containerStyle={{ borderRadius:10 , backgroundColor:"#FFF"  ,  
                                                                                                                                                                               paddingHorizontal:10 , paddingVertical:4 , marginBottom:5}}
                                                                                                                                            buttonContainerStyle={{}} 
                                                                                                                                            showInHorizontalScrollView={true}
                                                                                                                                            buttonTextStyle={{color:"#456e7a"}}
                                                                                                                                            buttonTextColor="gray"
                                                                                                                                            onSelectCategory={onSelectCategory}
                                                                                                                                            selectedCategoryId={shopInfo.selectedCategoryId} /> : null
                                                                                                                                            }
</View>


const onRefresh=()=>{
       setShopInfo({
              ...shopInfo , 
              goodsInfo : {
                     ...shopInfo.goodsInfo , 
                     arrGoods:[] , 
                     finished:false , 
                     refresh:true , 
              }
       })
}
const gotoGoodsInfo=(selectedGoodsInfo)=>{
       props.navigation.navigate( "goodsInfo" , {
                                                                             goodsId:selectedGoodsInfo.id  , 
                                                                             shopId:shopInfo.id , 
                                                                             selectedGoodsInfo 
                                                               }) 
}
//--------------------------------------------------
   return(
       <PageContainer   title={shopInfo.name ? shopInfo.name : "نام فروشگاه آنلاین"}
                                   subTitle={"روستای "+props.profileState.objCity.name}
                                   onBackIconClick={onBackClick} 
                                   showBackIcon 
                                   containerPadding={0}
                                   showHeader={true}
                                   headerColor="#00acc1" 
                                   headerTextColor="#FFFFFF" 
                                   backgroundColor="#FFF"
                                   hasFlatList
                                   searchButtonStyle={{buttonStyle:{backgroundColor:"#FFFFFF"} , buttonTextStyle:{color:"#00ACC1"} , buttonIconStyle:{color:"#00ACC1"}}}>

                     {
                            fetchState.fetchFlag ?   <ShopInfoPlaceholder /> : null
                     }
                     {
                            fetchState.errorFlag ? <NoItem title="خطا در دریافت اطلاعات" noShadow
                                                                                message={"در دریافت اطلاعات فروشگاه خطایی روی داده است"+"\n"+
                                                                                                "این مشکل ممکن است در نتیجه کندی و یا قطعی اینترنت شما باشد"} visible 
                                                                             tryAgain={getShopInfo}
                                                                             showTryAgainButton />  :null
                     }
                     {
                            !fetchState.fetchFlag && !fetchState.errorFlag && shopInfo.id ? 
                            <GoodsListComponent  shopId={shopInfo.id} 
                                                               isMyGoods={false} 
                                                               fetchState={goodsFetchState}
                                                               headerComponent={headerComponent}
                                                               goodsInfo={shopInfo.goodsInfo} 
                                                               arrCategory={shopInfo.arrCategory} 
                                                               arrGoodsState={props.bazaarState.arrGoodsState}
                                                               selectedCategoryId={shopInfo.selectedCategoryId}
                                                               strSearch={shopInfo.goodsInfo.strSearch}
                                                               getGoods={getGoodsList}
                                                               onSelectGoods={gotoGoodsInfo}
                                                               onRefresh={onRefresh}  />  : null
                     }

                     <ExtendedActionButton iconName="phone" title="تماس با فروشگاه" onClick={CallTo} />

              <Fetch ref={fetchModal}/>
              <Alert ref={alertModal}/>
              <ImageModal ref={imageModal} />
              
       </PageContainer>
   )
}

const mapStateToProps= state =>({
       profileState : state.profileReducer ,
       bazaarState : state.bazaarReducer
})
const mapDispatchToProps = dispatch =>({
})
export default connect(mapStateToProps , mapDispatchToProps) (ShopInfo);
//=========================================================================================