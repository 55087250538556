//========================================================================================
import React,{ }  from 'react'
import {View  , Dimensions} from "react-native"
import { MySkeleton } from '../../../globalComponents/components';
 
const ChapterPlaceholder=(props)=>{
   const {chapterTypeName=""}=props
//-----------------------------------GLOBAL VAR EREA
//-----------------------------------FUNCTION EREA
const {width}=Dimensions.get("screen")
//--------------------------------------------------
   return(
         <View style={{margin:"5px 10px 0 10px " , padding:"8px 2px" , backgroundColor:"#f5f5f5" , marginBottom:6 }}>
            <MySkeleton>
                  <View style={{flexDirection:"row" , padding:10 , alignItems : "center"}}>
                        <View style={{width:60 , height:60 , borderRadius:60}} />
                        <View style={{flex:1 , alignItems:"flex-end" , marginRight:10 }}>
                              <View style={{width:"50%" , height:10 , marginBottom:5 , borderRadius:7}} />
                              <View style={{width:"80%" , height:10 , marginBottom:5 , borderRadius:7}} />
                              <View style={{width:"100%" , height:15 , marginBottom:5 , borderRadius:7}} />
                        </View>
                  </View>
            </MySkeleton>
         </View>
   )
}

export default ChapterPlaceholder;
//=========================================================================================