//=======================================================================================================================
import React, {useEffect, useRef, useState  } from 'react';
import { MyText , MyButton , MyImage , MySwitch, Pageing, MyIcon } from "../globalComponents/components"
import Swipeabledivs from 'react-swipeable-views';
//==============================================================================
const Dayitem=(props)=>{
    const { timestamp , dayName , isReservedDay    ,selected , onSelectDay }=props
    //--------------------------------------------------------------------
    const refDay=useRef()
    const [dayWidth , setDayWidth]=useState(0 )
    useEffect(()=>{
        if(refDay.current)
            setDayWidth(refDay.current.offsetWidth)
    } , [refDay.current])
    //---------------------------------------------------------------------
    const onSelect=()=> onSelectDay(timestamp) 
    const disabled=!timestamp || isReservedDay
    return(
    <div className='w3-border-' ref={refDay} style={Object.assign({} , {marginBottom:4 ,  opacity:!timestamp ? 0 
                                                                                                                                                                                  : isReservedDay ? 0.4 :  1   , padding:3} )}>
            <div className={  ` ${timestamp && !isReservedDay ? "w3-ripple" : ""}   w3-button`} 
                    style={{width:"100%" , height:dayWidth-5 , padding:0}} disabled={disabled} onClick={disabled ? ()=>{} : onSelect}  >
                    <div  className="w3-round-large displayFlexCol displayFlexCenter"   
                            style={{backgroundColor:!timestamp || isReservedDay ?"#e0e0e0" : "#81d4fa" , height:"100%" , width:"100%" , padding:0 , position:"relative"}} >
                                {dayName}
                            <div className='' style={{position:"absolute" , bottom:-8 , left:1}}>
                                {
                                    timestamp && !isReservedDay ? 
                                    <MyIcon iconName="check_circle" color={selected ? "#456e7a" : "#FFFFFF"}  /> : null
                                }
                           
                            </div>
                    </div>
            </div>
        </div>
    )
}
//=======================================================================================================================
const MonthItem=(props)=>{
    const {monthNumber , monthName="" ,arrWeekDay  , actOnAllDayFunc , canBack , actOnAllDayTitle , actOnAllDayIcon="" ,  canForward  ,backgroundColor ,   onSelectDay }=props
    const selectAllDay=()=>  actOnAllDayFunc(monthNumber)

    return(
        <div className='displayFlexCol displayFlexGrow' style={{ backgroundColor  }}  >
                <div className='displayFlexRow  ' style={{  alignItems:"center" , padding:"5px 0"}}>
                    <div>{canForward?<MyIcon iconName="arrow_back_ios" color="#456e7a"  />  : " " }</div>
                    <MyText isBold isBlock className="w3-center displayFlexGrow" >{monthName}</MyText>
                    <div>{canBack?<MyIcon iconName="arrow_forward_ios" color="#456e7a" /> : " " }</div>
                    
                </div>
                {
                    actOnAllDayTitle ? 
                    <MyButton  smallFont  title='انتخاب تمامی روزها'
                        iconName={actOnAllDayIcon}
                        onClick={selectAllDay}
                        buttonStyle={{marginVertical:10,marginHorizontal:15, backgroundColor:"transparent" , justifyContent:"flex-end"}}
                        buttonTextStyle={{color:"#29b6f6"}}
                        buttonIconStyle={{color:"#29b6f6"}} /> 
                    : 
                    null
                }
                <div className='displayFlexRowReverse  ' style={{flexWrap:"wrap"}}>
                    {
                       arrWeekDay.map( (weekDay , index)=>  <div key={index} className='displayFlexGrow displayFlexCol'>
                                                                                           <div key={index}     className=" w3-center w3-tiny" >{weekDay.dayName}</div>
                                                                                           {
                                                                                             weekDay.arrDay.map(day => <Dayitem key={day.timestamp} {...day} onSelectDay={onSelectDay} />)
                                                                                           }
                                                                                    </div>)
                    }
                </div>

        </div>
    )
}
const CalendarWorkComponent=(props)=>{

    const {arrMonth=[] ,
           showAnimation=false ,  
           dayIsCircle=false , 
           actOnAllDayTitle="" ,
           actOnAllDayIcon=""  ,
           actOnAllDayFunc=()=>{} ,
           onSelectDay=()=>{}, 
           dayWidth=10 , 
           panelWidth,
           backgroundColor="#f4f4f4",
           dayNameColor="#eeeeee"  }=props
    
        const [index , setIndex]=useState(0)
        const changeIndex= _index => setIndex(_index)

        const [imageWidth , setImageWidth]=useState(0)
        const onLayout=e => setImageWidth(e.nativeEvent.layout.width/7)
    
   return(
        <div className='' style={{}} onLayout={onLayout} >
            <div className='displayFlexGrow displayFlexCol'  style={{ overflow:"hidden"}}>
                <Swipeabledivs axis="x-reverse"  className='displayFlexGrow w3-round-large'  enableMouseEvents index={index} onChangeIndex={changeIndex} >
                    {
                    arrMonth.map( (month , index )  => 
                        <MonthItem  key={month.monthName} 
                                    monthNumber={month.monthNumber} 
                                    showAnimation={showAnimation}
                                    dayIsCircle={dayIsCircle}
                                    actOnAllDayTitle={actOnAllDayTitle}
                                    actOnAllDayIcon={actOnAllDayIcon}
                                    actOnAllDayFunc={actOnAllDayFunc}
                                    {...month}
                                    canBack={index>0}
                                    canForward={index<arrMonth.length-1}
                                    onSelectDay={onSelectDay}
                                    dayWidth={imageWidth-2}
                                    panelWidth={panelWidth} 
                                    backgroundColor={backgroundColor}
                                    dayNameColor={dayNameColor} /> )
                    }
                </Swipeabledivs>
            </div>
            
            <div style={{padding:10,paddingBottom:20 }}>
                    <Pageing activeIndex={index}  color="#b0bec5" activeColor="#607d8b"  numberOfDots={arrMonth.length} />
            </div> 
        </div>
   )
}
export default CalendarWorkComponent;
//=======================================================================================================================