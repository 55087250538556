
//================================================================================================================================
const SET_MY_SHOP_INFO="SET_MY_SHOP_INFO"
const RESET_MY_SHOP_INFO="RESET_MY_SHOP_INFO"
const UPDATE_MY_SHOP_INFO="UPDATE_MY_SHOP_INFO"    //شامل name , sellerName , phone , description , shopCategoryId , expireDate , shopAvatar , arrCity

const SET_MY_SHOP_GOODS="SET_MY_SHOP_GOODS"
const ADD_UPDATE_MY_SHOP_GOODS="ADD_UPDATE_MY_SHOP_GOODS"
const DELETE_MY_SHOP_GOODS=" DELETE_MY_SHOP_GOODS"
const REFRESH_MY_SHOP_GOODS="REFRESH_MY_SHOP_GOODS"
const SET_MY_SHOP_GOODS_SEARCH="SET_MY_SHOP_GOODS_SEARCH"


const ADD_UPDATE_MY_SHOP_CATEGORY="ADD_UPDATE_MY_SHOP_CATEGORY"
const DELETE_MY_SHOP_CATEGORY="DELETE_MY_SHOP_CATEGORY"
const SET_SELECTED_CATEGORY_IN_MY_GOODS="SET_SELECTED_CATEGORY_IN_MY_GOODS"

const ADD_UPDATE_MY_SHOP_LINK="ADD_UPDATE_MY_SHOP_LINK"
const DELETE_MY_SHOP_LINK="DELETE_MY_SHOP_LINK"
//================================================================================================================================
export const myShopSetInfo= shopInfo => ({type : SET_MY_SHOP_INFO  ,shopInfo })
export const myShopResetInfo= shopInfo => ({type : RESET_MY_SHOP_INFO  ,shopInfo })
export const myShopUpdateInfo = shopInfo => ({type : UPDATE_MY_SHOP_INFO , shopInfo})

export const myShopSetGoods=(goodsInfo)=>({type:SET_MY_SHOP_GOODS , goodsInfo})
export const myShopAddUpdateGoods= ( goodsInfo) =>({type:ADD_UPDATE_MY_SHOP_GOODS , goodsInfo   })
export const myShopDeleteGoods= ( goodsId) => ({type : DELETE_MY_SHOP_GOODS  , goodsId   })
export const myShopRefreshGoods= () =>({type:REFRESH_MY_SHOP_GOODS  })
export const myShopSetGoodsSearch= strSearch=>({type:SET_MY_SHOP_GOODS_SEARCH , strSearch  })

export const myShopAddUpdateCategory = categoryInfo =>({type : ADD_UPDATE_MY_SHOP_CATEGORY , categoryInfo})
export const myShopDeleteCategory= categoryId => ({type:DELETE_MY_SHOP_CATEGORY , categoryId})
export const myShopSetSetSelectedGoodsCategory= ( categoryId) => ({type:SET_SELECTED_CATEGORY_IN_MY_GOODS , shopInfo:{ categoryId}})

export const myShopAddUpdateLink = linkInfo =>({type : ADD_UPDATE_MY_SHOP_LINK , linkInfo})
export const myShopDeleteLink= linkId => ({type:DELETE_MY_SHOP_LINK , linkId})
//================================================================================================================================
const myBazaarInfoInit={
       shopInfo:{
                     id:0,
                     name:'' ,
                     sellerName:'' , 
                     shopAvatar:'' , 
                     description:'' , 
                     phone:'' , 
                     saveDate:"" , 
                     expireDate:'' , 
                     validate:'' ,
                     shopCategoryId:0 , 
                     arrLink:[] ,                         //{ socialNetworkLinkId , link   }
                     arrCategory : [] ,                //[{ id , name , avatar  , newAvatar }]
                     arrCity:[]  ,                        //[{id , name}]
       } , 
       goodsInfo:{
                     selectedCategoryId:0 , 
                     strSearch:'' , 
                     arrGoods:[] , ////[{id , title , price , goodsStateId , categoryId , saveDate , arrImageFile  , arrCity , }]
                     finished:false ,
                     refresh:false,
       }
}
export default (state=myBazaarInfoInit , action)=>{
       switch(action.type){
              case SET_MY_SHOP_INFO :
              case UPDATE_MY_SHOP_INFO : return {
                                                                      ...state , 
                                                                      shopInfo : {...state.shopInfo , ...action.shopInfo} ,
                                                               }
              case RESET_MY_SHOP_INFO : return myBazaarInfoInit
              
              case SET_MY_SHOP_GOODS : return  {
                                                                      ...state , 
                                                                      goodsInfo :   {
                                                                                                  ...state.goodsInfo ,
                                                                                                  arrGoods : [...state.goodsInfo.arrGoods , ...action.goodsInfo.arrGoods] , 
                                                                                                  finished : action.goodsInfo.finished , 
                                                                                                  refresh:false  
                                                                                           }
                                                        }
              case ADD_UPDATE_MY_SHOP_GOODS : return {
                                                                      ...state , 
                                                                      goodsInfo :  {
                                                                                                  ...state.goodsInfo ,
                                                                                                  arrGoods : state.goodsInfo.arrGoods.filter(goods => goods.id===action.goodsInfo.id).length ?  
                                                                                                                                                                        state.goodsInfo.arrGoods.map(goods => goods.id===action.goodsInfo.id ? action.shopInfo.goodsInfo : goods)  : 
                                                                                                                                                                        [action.goodsInfo , ...state.goodsInfo.arrGoods]
                                                                                           }
                                                        }
              case DELETE_MY_SHOP_GOODS : return  {
                                                               ...state , 
                                                               goodsInfo : {
                                                                                           ...state.goodsInfo ,
                                                                                           arrGoods : state.goodsInfo.arrGoods.filter(goods=> goods.id!==action.goodsId)
                                                                                           
                                                                                    }
                                                 }
              case REFRESH_MY_SHOP_GOODS : return {
                                                        ...state , 
                                                        goodsInfo : {
                                                                                    ...state.goodsInfo ,
                                                                                    arrGoods : [] , 
                                                                                    finished : false , 
                                                                                    refresh:true  
                                                                             }
                                                 }
              case SET_MY_SHOP_GOODS_SEARCH : return{
                                                        ...state , 
                                                        goodsInfo : {
                                                                                           ...state.goodsInfo , 
                                                                                           strSearch : action.strSearch , 
                                                                                           selectedCategoryId:0 , 
                                                                                           arrGoods:[] , 
                                                                                           finished:false , 
                                                                                           refresh:true
                                                                             }                     
                                                        }

              case ADD_UPDATE_MY_SHOP_CATEGORY : return {
                                                               ...state , 
                                                               shopInfo : {
                                                                                    ...state.shopInfo , 
                                                                                    arrCategory : state.shopInfo.arrCategory.filter(category => category.id===action.categoryInfo.id).length ? 
                                                                                                                state.shopInfo.arrCategory.map(category => category.id===action.categoryInfo.id ? action.categoryInfo : category) :
                                                                                                                [...state.shopInfo.arrCategory , action.categoryInfo  ]
                                                               }
                                                        }
              case DELETE_MY_SHOP_CATEGORY : return {
                                                               ...state , 
                                                               shopInfo : {
                                                                      ...state.shopInfo , 
                                                                      arrCategory:state.shopInfo.arrCategory.filter(category => category.id!==action.categoryId) , 
                                                               },
                                                               goodsInfo :   state.goodsInfo.selectedCategoryId===action.categoryId ?
                                                                                    {
                                                                                           selectedCategoryId:0 , 
                                                                                           arrGoods:[] ,                //[{id , title , price , goodsStateId , categoryId , saveDate , arrImageFile }] 
                                                                                           finished:false , 
                                                                                           refresh:true , 
                                                                                           strSearch : '' 
                                                                                    }  
                                                                                    : 
                                                                                    {
                                                                                           ...state.goodsInfo , 
                                                                                           arrGoods : state.goodsInfo.arrGoods.filter(goods => goods.categoryId!==action.categoryId)
                                                                                    }
                                                 }
               case SET_SELECTED_CATEGORY_IN_MY_GOODS : return{
                                                        ...state , 
                                                        goodsInfo :  {
                                                                                           strSearch : "" , 
                                                                                           selectedCategoryId:state.goodsInfo.selectedCategoryId===action.shopInfo.categoryId ? 0 : action.shopInfo.categoryId  ,
                                                                                           arrGoods:[] , 
                                                                                           finished:false , 
                                                                                           refresh:true
                                                                             }                         
                                                               }


                     case ADD_UPDATE_MY_SHOP_LINK : return {
                                                                      ...state , 
                                                                      shopInfo : {
                                                                                           ...state.shopInfo , 
                                                                                           arrLink : state.shopInfo.arrLink.filter(link => link.id===action.linkInfo.id).length ? 
                                                                                                                       state.shopInfo.arrLink.map(link => link.id===action.linkInfo.id ? action.linkInfo : link) :
                                                                                                                       [...state.shopInfo.arrLink , action.linkInfo  ]
                                                                      }
                                                               }
                     case DELETE_MY_SHOP_LINK : return {
                                                                      ...state , 
                                                                      shopInfo : {
                                                                             ...state.shopInfo , 
                                                                             arrLink:state.shopInfo.arrLink.filter(link => link.id!==action.linkId) , 
                                                                      },
                                                        }
              default : return state
       }
}

