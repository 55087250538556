//========================================================================================
import React,{useState, useImperativeHandle, forwardRef}  from 'react'
import { ModalContainer, MySwitch } from '../../../globalComponents/components'
 

const TopicStateComponent= props =>{
       const {keyItem="" , title="" , state=false , onClick}=props
       return(
                     <MySwitch containerStyle={{paddingVertical:10 , borderBottomWidth:1 , borderColor:"#eeeeee"}} 
                                      selectedIconColor="#e46648"
                                      useSwitch selected={state} title={title} onClick={()=>onClick(keyItem)} />
       )
}

const  MdlNotification=forwardRef((props , ref)=>{
//-----------------------------------GLOBAL VAR EREA
//-----------------------------------REF EREA

//-----------------------------------STATE EREA
const [visible , setVisible]=useState(false)
const [topicNotificationState, setTopicNotificationState ]=useState({
       BULLETIN:true , 
       NEWS:true ,
       ANNOUNCEMENT:true , 
       CONVERSATION:true,
       BILL:true
})
//-----------------------------------USE_EFFECT EREA
useImperativeHandle(ref , ()=>({
       show: ()=> setVisible(true)  
}))
//-----------------------------------FUNCTION EREA
const hide=()=>  setVisible(false)
const toggleTopicNotificationState=async (key)=> props.onToggleSubscribeTopic(key.toString())

const subscribeTopicState = props.subscribeTopicState ? props.subscribeTopicState : {
       BULLETIN:false , 
       NEWS:false , 
       CONVERSATION:false , 
       ANNOUNCEMENT:0
}
//--------------------------------------------------
   return(
                   <ModalContainer visible={visible}
                                          title='مدیریت اعلان های برنامه'
                                          showTitle
                                              message=''
                                              showBackButton={false}
                                              onBackClick={hide}
                                              containerStyle={{padding:0}}
                                              contentStyle={{}}
                                              closeWithClickBlankPlace
                                              closeWithClickBackButton
                                              position='bottom'
                                              animation='slideInUp' >
                     <TopicStateComponent keyItem="BULLETIN" title="اعلان سند جدید در تابلو اعلانات " state={subscribeTopicState.BULLETIN} onClick={toggleTopicNotificationState} />
                     <TopicStateComponent keyItem="NEWS" title="اعلان خبر جدید  " state={subscribeTopicState.NEWS} onClick={toggleTopicNotificationState} />
                     <TopicStateComponent keyItem="ANNOUNCEMENT" title="اعلان اطلاعیه جدید " state={subscribeTopicState.ANNOUNCEMENT} onClick={toggleTopicNotificationState} />
                     <TopicStateComponent keyItem="CONVERSATION" title="اعلان گفتگوی جدید " state={subscribeTopicState.CONVERSATION} onClick={toggleTopicNotificationState} />
                                                                  
                 </ModalContainer>
   )
})

export default MdlNotification ;
//=========================================================================================