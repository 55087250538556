//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
import {View , TouchableOpacity  , Share, Linking} from "react-native"
import {  Alert, Confirm , ImageModal, MyButton, PageContainer, Popup, AnimatableView, MyText ,MySkeleton, ProfileImage } from '../../../globalComponents/components'
import Fetch from './../../../globalComponents/fetch'
import { connect } from 'react-redux'
import {setToastMessage} from "./../../../redux/toastMessage"
import FooterNavbar from './../../../commonComponents/footer'
import { setInitialInfo } from './../../../redux/initialInfo'
import  { setProfileInfo, updateProfileInfo } from './../../../redux/profile'
import { SERVER_URL } from './../../../globalDef'
import Styles from './../../../styles'

import {} from "@react-navigation/native"
// import { useBackHandler } from '@react-native-community/hooks'
import { useNavigation } from '@react-navigation/native';
import { updateMainAdvertisingInfo } from './../../../redux/mainAdvertising'
// import RNExitApp from 'react-native-exit-app'

import AsyncStorage from '@react-native-async-storage/async-storage'
import MdlNotification from './mdlNotification'
// import { fcmGetToken, fcmSubscribeToTopic , fcmUnSubscribeToTopic } from './../../../commonComponents/firebase'


import { setSearchChapter as setChapterSearch } from '../../../redux/chapter'
import {  setSearchChapter as  setAnnouncementSearch , refreshChapter as refreshAnnouncement  } from '../../../redux/announcement'

import Chapter from '../chapter'
import Announcement from '../announcement'

import { SceneMap, TabView } from 'react-native-tab-view';
import MdlMessage from '../../../commonComponents/mdlMessage'
//----------------------------------------------------------------------------------------
const MainAdvertisingSkeleton=()=>{
       return(
              <View style={{padding:5 , borderBottomWidth:1 , borderColor:"#eeeeee"}}>
                     <MySkeleton borderRadius={4} >
                     <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <View style={{width: 60, height: 60, borderRadius: 50}} />
                            <View style={{marginRight: 10  , flex:1 ,alignItems:"flex-end" }}>
                                   <View style={{marginTop: 6, fontSize: 14, lineHeight: 18 ,width:"50%"}} />
                                   <View style={{marginTop: 6, fontSize: 14, lineHeight: 18 , width:"60%"}} />
                            </View>
                     </View>
                     </MySkeleton>
              </View>
       )
}
const MainAdvertisingInfoComponent= props =>{
       const { title="" , subTitle="" , avatar="" , link="" , onClickToShowAdvertising=()=>{} } = props
       // href={url ? url  : SITE_URL }
       const gotoUrl=()=>onClickToShowAdvertising(link)
       const imgAvatar = avatar ? {uri : avatar} : require("./../../../assets/images/act/imgDefManAvatar.jpg")
       return(
              <TouchableOpacity  onPress={gotoUrl}>
                     <View  style={{padding:8 , flexDirection:"row" , backgroundColor:"#f5f5f5" , alignItems:"center" }}>
                            <View style={{position:"absolute" , top:10 , right:10 , paddingHorizontal:5 , paddingVertical:1 , elevation:1 , borderRadius:16 , backgroundColor:"#FFFFFF"}}>
                                   <MyText style={{}}>تبلیغ</MyText>
                            </View>
                            <View >
                                   <ProfileImage  source={imgAvatar} width={60}   />
                            </View>
                            <View className='displayFlexCol displayFlexGrow ' style={{paddingRight:12 , flex:1 ,}}>
                                   <MyText  isBold color="#456e7a">{title}</MyText>
                                   <MyText   color="#9e9e9e">{subTitle}</MyText>
                            </View>
                     </View>
              </TouchableOpacity>
       )
}
const MenuView=(props)=>{
       const {onBackClick , arrMenuButton=[]} = props
       return(
              <TouchableOpacity style={{position:"absolute" , top:0 , right:0 , left:0 , bottom:0 , zIndex:4 }} onPress={onBackClick}>
                     <View style={{position:"absolute" , top:0 , right:0 , left:0 , bottom:0 , overflow:"hidden" }}>
                            <AnimatableView animation={"slideInDown"} duration={200} 
                                                style={{position:"absolute" , left:3 , top:3 , width:"50%" , elevation:20 , backgroundColor:"#FFFFFF" , borderRadius:10 }}>
                                   {
                                          arrMenuButton.map( menuButton => <MyButton key={menuButton.id} smallFont
                                                                                                                  title={menuButton.title} 
                                                                                                                  iconName={menuButton.icon} 
                                                                                                                  onClick={menuButton.onClick}
                                                                                                                  buttonStyle={{paddingVertical:10 , paddingHorizontal:7 ,justifyContent:"flex-end", backgroundColor:"transparent" ,
                                                                                                                                          borderBottomWidth:1 , borderColor:"#eeeeee"  }}
                                                                                                                  buttonIconStyle={{color:"#26a69a" , fontSize:23}}
                                                                                                                  buttonTextStyle={{color:"gray"}} />)
                                   }
                            </AnimatableView>
                     </View>
              </TouchableOpacity>
       )
}
 //----------------------------------------------------------------------------------------
const MainPage=(props)=>{
       const { showSearchIcon=true   } = props
       const navigation = useNavigation();
       //-----------------------------------------------------------------------------------------------------
       const alertModal=useRef()
       const fetchModal=useRef()
       const confirmModal=useRef()
       const popupModal=useRef()
       const imageModal=useRef()
       const mdlNotification=useRef()
       const mdlMessage=useRef()
       //-----------------------------------------------------------------------------------------------------------------
       const [strSearch , setStrSearch]=useState("")
       const [showSearchBox , setShowSearchBox]=useState(false)
       const [menuVisible , setMenuVisible]=useState(false)
       const [index , setIndex]=useState(0)

       const [currentToken , setCurrentToken]=useState("")
       const [subscribeTopicState , setSubscribeState]=useState(null)

       useEffect(()=>{
              getInitialInfo()
       } , [])

       // useEffect(()=>{
       //        toastTest()
       // } , [])

       // const toastTest=()=>{
       //        setInterval(()=>{
       //               props.setToastMessage("سلام به همه")
       //        } , 5000)
       // }
       //-----------------------------------------------------------------------------------------------------------------
       useEffect(()=>{
              if(props.route.params){
                     if(props.route.params?.mustUpdateInfo){
                            setIndex(0)
                            props.refreshAnnouncement()
                     }
              }
       } , [props.route.params ?.mustUpdateInfo])
       //-----------------------------------------------------------------------------------------------------------------
       useEffect(()=>{
              if(index===0){
                     if(props.chapterState.strSearch){
                            setStrSearch(props.chapterState.strSearch)
                            setShowSearchBox(true)
                     }
                     else{
                            if(strSearch)
                                   setStrSearch("")
                            setShowSearchBox(false)
                     }
              }
              if(index===1){
                     if(props.announcementState.strSearch){
                            setStrSearch(props.announcementState.strSearch)
                            setShowSearchBox(true)
                     }
                     else{
                            if(strSearch)
                                   setStrSearch("")
                            setShowSearchBox(false)
                     }
              }
       } , [index])
       //-----------------------------------------------------------------------------------------------------------------
       useEffect(()=>{
              getSubscribeTopicState()
       } , [])
       //-----------------------------------------------------------------------------------------------------------------
       useEffect(()=>{
              if(subscribeTopicState)
                     checkChangeInSubscribeTopic()
       }  ,[subscribeTopicState])
       //-----------------------------------------------------------------------------------------------------------------
       useEffect(()=>{
              if(currentToken){
                     checkFcmToken()
              }
       } , [currentToken])
       //-----------------------------------------------------------------------------------------------------------------
       // useBackHandler(()=>{
              // if(menuVisible)
              //        setMenuVisible(false)
              // else
              //        confirmModal.current.show("خروج از برنامه","آیا قصد دارید از ده وند خارج شوید؟","question",()=>RNExitApp.exitApp() , "تایید و خروج از برنامه" , true)
              // return true
       // } )
       //---------------------------------------------------------------------------------------
       const getInitialInfo=async ()=>{
              const objLastObjectReadId={
                                                 LAST_ANNOUNCEMENT_ID:0 , 
                                                 LAST_ALBUM_ID:0 ,
                                                 LAST_BILL_ID:0
              }
              let objInfo={}
              const lastInfoId=await AsyncStorage.getItem("LAST_OBJECT_INFO_ID")
              if(lastInfoId){
                     try{
                            objInfo=JSON.parse(lastInfoId)
                            objLastObjectReadId.LAST_ANNOUNCEMENT_ID = objInfo.LAST_ANNOUNCEMENT_ID ? objInfo.LAST_ANNOUNCEMENT_ID : 0 
                            objLastObjectReadId.LAST_ALBUM_ID = objInfo.LAST_ALBUM_ID ? objInfo.LAST_ALBUM_ID : 0 
                            objLastObjectReadId.LAST_BILL_ID = objInfo.LAST_BILL_ID ? objInfo.LAST_BILL_ID : 0 
                     }
                     catch{  await AsyncStorage.setItem("LAST_OBJECT_INFO_ID" , JSON.stringify(objLastObjectReadId))  }
              }
              else
                     await AsyncStorage.setItem("LAST_OBJECT_INFO_ID" , JSON.stringify(objLastObjectReadId))

              fetchModal.current.fetchData("user/getInitialInfo" , Object.assign({} , {jwt : props.profileState.jwt } , objLastObjectReadId  ) , 
                                                                 {
                                                                      // onStart : ()=>setFetchState({fetchFlag:true , errorFlag:false}),
                                                                      onError: ()=>props.updateMainAdvertisingInfo({fetchState:{fetchFlag:false , errorFlag:true}}),
                                                                      onSuccess: responseOfGetInitialInfo , 
                                                                      // onBackFromErrorConfirm: ()=> setFetchState({fetchFlag:false , errorFlag:false}),
                                                                 } , false , false , false   )
              
              
       }
       const responseOfGetInitialInfo=data => {
              /*
              profileInfo , objNewChapterCount : {NEWS , ANNOUNCEMENT , CONVERSATION ,ALBUM} , newBillCount , arrMessage :[{id , title , message , icon , objButton:{title , icon , url}}]
              */
              props.updateProfileInfo(data.profileInfo)
              props.setInitialInfo({
                                                        newAnnouncementCount:data.objNewChapterCount.ANNOUNCEMENT , 
                                                        newAlbumCount:data.objNewChapterCount.ALBUM , 
                                                        newBillCount:data.newBillCount , 
                                                        hasNewMessage:data.hasNewMessage , 
                                                        isPeopleInCity : data.isPeopleInCity , 
                                                        finished:true 
                                                 })
              if(data.advertisingInfo)
                     props.updateMainAdvertisingInfo( Object.assign({} ,{info : {...data.advertisingInfo}} , {fetchState:{fetchFlag:false , errorFlag:false}}) )
              else
                     props.updateMainAdvertisingInfo({fetchState:{fetchFlag:false , errorFlag:false}})
              if(data.arrMessage.length)
                     setTimeout(()=>mdlMessage.current.show(data.arrMessage) , 1000)
                     
              
              setTimeout(getFcmToken , 1000)    
       }
       //----------------------------------------------------------------------------------------
       const onClickToShowAdvertising=(url)=>{
              if(url){
                     if(url.indexOf(`${SERVER_URL}user/mainApp/bazaar/shopInfo/`)!==-1){
                            getSelectedShopInfo( url.substring(url.lastIndexOf("/")+1)   )
                     }
                     else{
                            try{
                                   Linking.openURL(url)
                                   }
                                   catch{ }
                     }
              }
       }
       const getSelectedShopInfo=(shopId)=>navigation.navigate("shopInfo" , {shopId})
       //--------------------------------------------------------------------------------------FCM MESSAGE
       const getFcmToken=async ()=>{
              const currentToken="" ;//await fcmGetToken();
              if(currentToken)
                        setCurrentToken(currentToken)
       }
       const checkFcmToken=async ()=>{
              if(props.profileState.token!==currentToken)
                     updateFcmToken(currentToken)
              else{
                     const jsonCheckSubscribedTopic= await AsyncStorage.getItem("FCM_SUBSCRIBE")
                     if(jsonCheckSubscribedTopic){
                            try{
                                   const checkSubscribedTopic =JSON.parse(jsonCheckSubscribedTopic)
                            }
                            catch{
                                   subscribeToAllTopic()
                            }
                     }
                     else
                            subscribeToAllTopic()
              }
       }
       const updateFcmToken= async(token) =>{
              const objData={
                     jwt:props.profileState.jwt , 
                     token
              }
              fetchModal.current.fetchData("user/updateFcmToken" ,
                                                                objData , {
                                                                      onSuccess : responseOfUpdateFcmToken
                                                               } , 
                                                               false , false , false)
       }
       const responseOfUpdateFcmToken= async (data)=>  setTimeout(()=>subscribeToAllTopic() , 1000) 
       const subscribeToAllTopic=()=>{
              const city=`CITY_${props.profileState.objCity.id}`
              const arrTopicname=[ city ,   city+"_BULLETIN" , city+"_NEWS" , city+"_ANNOUNCEMENT"  , city+"_CONVERSATION"  ]
              try{
                     // fcmSubscribeToTopic(arrTopicname)
                     responseOfSubscribeToAllTopic()
              }
              catch (error){  }
       }
       const responseOfSubscribeToAllTopic= data =>{
              const objSubscribe={
                     BULLETIN:true , 
                     NEWS:true ,
                     ANNOUNCEMENT:true , 
                     CONVERSATION:true,
              }
              setSubscribeState(Object.assign({} , objSubscribe ))
       }
       const toggleSubscribeTopic= topicName =>{
              // const arrTopicName=[`CITY_${props.profileState.objCity.id}_${topicName}`  ]
              // subscribeTopicState[key] ? fcmUnSubscribeToTopic(arrTopicName) : fcmSubscribeToTopic(arrTopicName)
              // responseOfToggleSubscribeTopic(arrTopicName)
       }
       const responseOfToggleSubscribeTopic= data=>{
              const topicName=data[0]
              const topicItem= topicName.substring(topicName.lastIndexOf("_")+1 )
              setSubscribeState({...subscribeTopicState , [topicItem] : !subscribeTopicState[topicItem] })
       }
       const getSubscribeTopicState=async ()=>{
              /**
                     {
                            BULLETIN : false , 
                            NEWS:false , 
                            CONVERSATION:false , 
                            ANNOUNCEMENT:false ,
                     }
               */
              const jsonCheckSubscribedTopic= await AsyncStorage.getItem("FCM_SUBSCRIBE")
              if(jsonCheckSubscribedTopic){
                     try{
                            const checkSubscribedTopic =JSON.parse(jsonCheckSubscribedTopic)
                            setSubscribeState(Object.assign({} , checkSubscribedTopic ))
                     }
                     catch{ }
              }
       }
       const checkChangeInSubscribeTopic=async ()=>{
              const jsonCheckSubscribedTopic= await AsyncStorage.getItem("FCM_SUBSCRIBE")
              if(jsonCheckSubscribedTopic){
                     try{
                            const checkSubscribedTopic =JSON.parse(jsonCheckSubscribedTopic)
                            if(subscribeTopicState.BULLETIN !== checkSubscribedTopic.BULLETIN ||
                                   subscribeTopicState.NEWS !== checkSubscribedTopic.NEWS || 
                                   subscribeTopicState.CONVERSATION !== checkSubscribedTopic.CONVERSATION || 
                                   subscribeTopicState.ANNOUNCEMENT !== checkSubscribedTopic.ANNOUNCEMENT )
                                   AsyncStorage.setItem("FCM_SUBSCRIBE" , JSON.stringify(subscribeTopicState))
                     }
                     catch{}
              }
       }
       //----------------------------------------------------------------------------------------
       const setChapterSearch=()=>{
              if(index===0){
                     if(props.chapterState.strSearch!==strSearch)
                            props.setChapterSearch(strSearch ? strSearch.trim().replace(/ +/g , " ") : "" )
              }
              if(index===1){
                     if(props.announcementState.strSearch!==strSearch)
                            props.setAnnouncementSearch(strSearch ? strSearch.trim().replace(/ +/g , " ") : "" )
              }
       }
       //----------------------------------------------------------------------------------------
       const changeStrSearch= (txtId , newText)=> setStrSearch( newText ? newText : "" )
       //----------------------------------------------------------------------------------------
       const toggleShowSearchBox=()=>{
              setMenuVisible(false)
              if(showSearchBox){
                     setStrSearch("")
                     if(index===0){
                            if(props.chapterState.strSearch)
                                   props.setChapterSearch("")
                     }
                     if(index===1){
                            if(props.announcementState.strSearch)
                                   props.setAnnouncementSearch("")
                     }
              }
              setShowSearchBox(!showSearchBox)
       }
       const onClickShowMessage=()=>{
              if(props.initialInfoState.isPeopleInCity)
                     navigation.navigate("shareFolderMessage")
              else
              alertModal.current.show("گزارش برنامه","شماره تماس شما به عنوان سرپرست خانوار در دهیاری روستا ثبت نشده است!!!"+"\n"+
                     "به همین دلیل امکان دسترسی به این بخش را ندارید."+"\n\n"+
                     "چنانچه سرپرست خانوار هستید , ابتدا از برنامه خارج و سپس با شماره تماس ثبت شده در دهیاری اقدام به ورود به برنامه نمایید"+"\n\n"+
                     "شما می توانید در صورت نیاز به دهیاری مراجعه  و شماره تماس فعلی خود را ثبت نمایید.","error")
       }
       const leftComponent=()=>{
              return(
                     <View  style={Object.assign({} , Styles._className.displayFlexRow)}>
                            
                            <View style={{flexDirection:"row-reverse"}}>
                                   <MyButton title="" 
                                                        iconName="more_vert" 
                                                        buttonStyle={{backgroundColor:"transparent" , marginRight:5 }}
                                                        buttonIconStyle={{color:"#FFF" , fontSize:27}} 
                                                        onClick={()=>setMenuVisible(!menuVisible)} />
                                   <View style={{}}>
                                          {
                                                 props.initialInfoState.hasNewMessage ? 
                                                        <View style={{ position:"absolute" , top:0 , right:0 ,  backgroundColor:"#ff7043" , width:12 , height:12 , borderRadius:60 , 
                                                                                    elevation:4 , marginLeft:20 , borderColor:"#e0e0e0" , zIndex:2 }} /> : null
                                          }
                                          <MyButton  title="" 
                                                               iconName="mail" 
                                                               buttonStyle={{backgroundColor:"transparent" }}
                                                               buttonIconStyle={{color:"#FFF" , fontSize:27}} 
                                                               onClick={onClickShowMessage} />
                                          </View>
                            </View>


                            {
                                   showSearchIcon ? 
                                   <MyButton title="" iconName="search" buttonStyle={{backgroundColor:"transparent" }}
                                                    buttonIconStyle={{color:"#FFF" , fontSize:27}} onClick={toggleShowSearchBox} /> : null
                            }
                     </View>
              )
       }
       //---------------------------------------------------------------------------------------
       const getChapterName=()=>{
              switch(index){
                     case 0 : return      "خبر و گفتگو های روستا"
                     case 1 : return      "اطلاعیه های روستا"
                     default : return    "سند"  
              }
       }
       //--------------------------------------------------------------------------------------
       const getAnnouncementPriceValidateCode=()=>{
              const objData={
                  jwt : props.profileState.jwt ,
              }
              fetchModal.current.fetchData("user/getAnnouncementPrice" , objData , 
                                                                 {
                                                                  onSuccess:responseOfGetAnnouncementPriceValidateCode 
                                                                 } , true , true , true)
       }
       const responseOfGetAnnouncementPriceValidateCode=(data)=>  props.navigation.navigate("addAnnouncement" , {announcementPrice:data }) 
       //--------------------------------------------------------------------------------------
       const arrFooterMenu=[
              {
                  id:1 ,
                  title:'خبر و گفتگو',
                  icon:'newspaper' , 
                  isSelectedWithIndex :0, 
                  badge:!props.initialInfoState.newNewsCount ? 0 
                                                                                            :  props.initialInfoState.newNewsCount >10 ? "10+" : props.initialInfoState.newNewsCount  , 
                  onClick:()=>setIndex(0)  //navigation.navigate("news")
              } , 
              {
                  id:2 ,
                  title:'اطلاعیه',
                  icon:'campaign',
                  isSelectedWithIndex:1 , 
                  badge:!props.initialInfoState.newAnnouncementCount ? 0 
                                                                                                          :  props.initialInfoState.newAnnouncementCount >10 ? "10+" : props.initialInfoState.newAnnouncementCount  , 
                  onClick:()=>setIndex(1)  //navigation.navigate("announcement") 
              } , 
              
              {
                  id:3 ,
                  title:'بازار',
                  icon:'storefront', 
                  onClick:()=>navigation.navigate("bazaar") 
              } ,
              {
                     id:4 ,
                     title:'قبض من',
                     icon:'receipt_long',
                     badge:!props.initialInfoState.newBillCount ? 0 
                                                                                               :  props.initialInfoState.newBillCount >10 ? "10+" : props.initialInfoState.newBillCount  ,  
                     onClick:()=>{
                                   if(props.initialInfoState.isPeopleInCity)
                                          navigation.navigate("bill") 
                                   else
                                          alertModal.current.show("گزارش برنامه","شماره تماس شما به عنوان سرپرست خانوار در دهیاری روستا ثبت نشده است!!!"+"\n"+
                                                                                                         "به همین دلیل امکان دسترسی به این بخش را ندارید."+"\n\n"+
                                                                                                         "چنانچه سرپرست خانوار هستید , ابتدا از برنامه خارج و سپس با شماره تماس ثبت شده در دهیاری اقدام به ورود به برنامه نمایید"+"\n\n"+
                                                                                                         "شما می توانید در صورت نیاز به دهیاری مراجعه  و شماره تماس فعلی خود را ثبت نمایید.","error")
                                                                                                         return
                     } 
              } , 
              {
                     id:5 ,
                     title:'حساب من',
                     icon:'', 
                     image:props.profileState.avatar ? {uri : props.profileState.avatar} : require("./../../../assets/images/act/imgDefManAvatar.jpg")  , 
                     onClick:()=>navigation.navigate("profile")
                 } , 
               
              
          ]
       const arrMenuButton=[
              {
                     id:1 ,
                     title:'ثبت تبلیغات',
                     icon:'verified' , 
                     onClick:()=>{ setMenuVisible(false)  ;  navigation.navigate( "advertisingDescription" )}
             } ,
       //         {
       //           id:2 ,
       //           title:'ارتباط با ما',
       //           icon:'support-agent' , 
       //           onClick:()=>{setMenuVisible(false)  ;navigation.navigate("contactUs") }
       //       } , //verified
             {
                     id:2 ,
                     title:'مدیریت اعلان ها',
                     icon:'notifications' , 
                     onClick:async ()=>{
                                                               setMenuVisible(false) ;
                                                               if(props.profileState.token  && subscribeTopicState )
                                                                             mdlNotification.current.show() ;
                                                                      else
                                                                             alertModal.current.show('خطا در برنامه','اعتبار سنجی ارسال اعلان برای شما انجام نشده است!!!'+"\n"+
                                                                                                                                            "برای حل مشکل , یک بار برنامه را بسته و مجددا باز نمایید.",'error');
                                                 }
              },
       //       {
       //        id:3 ,
       //        title:'حساب کاربری',
       //        icon:'person' , 
       //        onClick:()=>{setMenuVisible(false)  ;navigation.navigate("profile")}
       //    } , //verified
          {
              id:4 ,
              title:'معرفی ده وند به دوستان',
              icon:'share' , 
              onClick:()=>{
                     setMenuVisible(false)  ;
                     Share.share({title:'ده و ند اولین شبکه اجتماعی روستایی' , 
                            message:'ده و ند اولین شبکه اجتماعی روستایی'+"\n"+
                                          'جهت دانلود روی لینک زیر کلیک نمایید.'+"\n"+
                                           SERVER_URL+"application/dehvand.apk"})
              }
          } , //verified
       ]
       //-------------------------------------------------------------------------------------
       // const renderScene =SceneMap({  Chapter  , Announcement    });
       const renderScene=()=>{
              switch(index){
                     case 0 : return <Chapter />
                     case 1 : return <Announcement />
              }
       }
       //----------------------------------------------------------------------------------------------
       const [routes] = React.useState(
                                                               [ 
                                                                      { key: 'Chapter', title: 'Chapter' }  ,  
                                                                      { key: 'Announcement', title: 'Announcement' }  , 
                                                               ]);
       //----------------------------------------------------------------------------------------------
       return(
              <PageContainer   title={getChapterName()}  hasFlatList 
                                         subTitle={" روستای "+props.profileState.objCity.name}
                                          containerPadding={0} 
                                          leftComponent={leftComponent()}  
                                         searchBoxVisible={showSearchBox}
                                         onSearchClick={setChapterSearch}
                                         onChangeSearchText={changeStrSearch}
                                         searchValue={strSearch}
                                         onCloseSearchBoxClick={toggleShowSearchBox}  >
                                   <View style={{flex:1 , zIndex:1 }}>
                                          <View>
                                          {
                                                 props.mainAdvertisingInfoState.fetchState.fetchFlag  ? 
                                                 <MainAdvertisingSkeleton />:null
                                          }
                                          {
                                                 props.mainAdvertisingInfoState.info.title  ? 
                                                 <MainAdvertisingInfoComponent      {...props.mainAdvertisingInfoState.info} 
                                                                                                         onClickToShowAdvertising={onClickToShowAdvertising} />:null
                                          }
                                          </View>
                                          <View style={{flex:1}}>
                                                 {/* <TabView       lazy    
                                                                             swipeEnabled={false}
                                                                             navigationState={{ index, routes }}
                                                                             renderScene={renderScene}
                                                                             renderTabBar={()=>null}
                                                                             onIndexChange={setIndex}/>  */}
                                   {
                                   renderScene()
                                   }  
                                          </View>
                                   </View>
                                    
                                   <FooterNavbar arrFooterMenu={arrFooterMenu} 
                                                           currentIndex={index}   /> 
                                   {
                                          menuVisible ? <MenuView arrMenuButton={arrMenuButton} onBackClick={()=>setMenuVisible(false)} /> : null
                                   }
                                   <MdlMessage ref={mdlMessage} />
                                   <MdlNotification ref={mdlNotification} onToggleSubscribeTopic={toggleSubscribeTopic} subscribeTopicState={subscribeTopicState}/>
                                   <ImageModal ref={imageModal} />
                                   <Fetch ref={fetchModal} />
                                   <Alert ref={alertModal} />
                                   <Confirm ref={confirmModal} /> 
                                   <Popup ref={popupModal} />
              </PageContainer>
       )
}
const mapStateToProps= state => ({
       profileState : state.profileReducer ,  
       initialInfoState : state.initialInfoReducer ,
       mainAdvertisingInfoState : state.mainAdvertisingReducer ,
       scrollTopPagePositionState: state.scrollTopPagePositionReducer ,
       mainAdvertisingInfoState : state.mainAdvertisingReducer ,

       chapterState : state.chapterReducer , 
       announcementState : state.announcementReducer ,
})
const mapDispatchToProps= dispatch =>({
       setToastMessage : strMessage => dispatch(setToastMessage(strMessage)) ,
       setInitialInfo : initialInfo => dispatch(setInitialInfo(initialInfo)) ,
       setProfileInfo : profileInfo => dispatch(setProfileInfo(profileInfo)),
       updateProfileInfo : profileInfo => dispatch(updateProfileInfo(profileInfo)) , 
       updateMainAdvertisingInfo : advertisingInfo => dispatch(updateMainAdvertisingInfo(advertisingInfo)) , 

       setChapterSearch: ( strSearch) => dispatch(setChapterSearch( strSearch)) ,
       setAnnouncementSearch: ( strSearch) => dispatch(setAnnouncementSearch( strSearch)) ,  
       refreshAnnouncement :()=>dispatch(refreshAnnouncement())
})
export default connect(mapStateToProps , mapDispatchToProps)(MainPage);
//===========================================================================================