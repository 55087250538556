//------------------------------------------------------------------------------------------------------------native
import React, { forwardRef, useImperativeHandle, useState, useEffect ,useRef, useCallback } from "react"


import { Spinner } from "react-activity";
import "react-activity/dist/library.css";

import Styles  from "../../styles"
import { CircleIndicator} from 'react-indicators';

import { CircularProgressbar   } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import JDate from "jalali-date"
import uuid from "react-uuid"

import {Image, Modal, View , TouchableOpacity, FlatList, ActivityIndicator, Text} from "react-native-web"
import 'material-icons/iconfont/material-icons.css';

import Skeleton , {SkeletonTheme} from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import  "./index.css"
import { connect } from "react-redux";

import Webcam from "react-webcam";

import CircularSlider from 'react-circular-slider-svg';
import { getTimeFromTimestamp , isValidNumberString, isValidPersianName } from "../rejax";

import { isDesktop , isMobile, isTablet } from "react-device-detect";

import { slideInRight  , slideOutRight , 
              slideInLeft    , slideOutLeft , 
              slideInDown    , slideOutDown,
              slideInUp       , slideOutUp,
              zoomIn            , zoomOut , 
              fadeIn            , fadeOut } from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import SwipeableViews from "react-swipeable-views";

import Cropper from 'react-easy-crop'
import getCroppedImg  from "../../commonComponents/mdlImageCrop/makeCropedImage";
import ReactTextareaAutosize from "react-textarea-autosize";

import Lightbox, {  } from "yet-another-react-lightbox";
import { Zoom } from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/styles.css";
import axios from "axios";
import PinInput from "react-pin-input";

import imgLogo from "./../../assets/images/logo/logo.png"
import imgOk from "./../../assets/images/act/imgOk.png"
import imgError from "./../../assets/images/act/imgError.png"
import imgNoOk from "./../../assets/images/act/imgNo.png"
import imgWatch from "./../../assets/images/act/watch3.jpg"

import PullToRefresh from 'react-simple-pull-to-refresh';
//================================================================

const animateStyles = {
    slideInRight: {
        animation: 'x 0.35s',
        animationName: Radium.keyframes(slideInRight, 'slideInRight')
      },
      slideOutRight: {
        animation: 'x 0.35s',
        animationName: Radium.keyframes(slideOutRight, 'slideOutRight')
      },
      slideInLeft: {
        animation: 'x 0.35s',
        animationName: Radium.keyframes(slideInLeft, 'slideInLeft')
      },
      slideOutLeft: {
        animation: 'x 0.35s',
        animationName: Radium.keyframes(slideOutLeft, 'slideOutLeft')
      },
      slideInDown: {
        animation: 'x 0.35s',
        animationName: Radium.keyframes(slideInDown, 'slideInDown')
      },
      slideOutDown: {
        animation: 'x 0.35s',
        animationName: Radium.keyframes(slideOutDown, 'slideOutDown')
      },
      slideInUp: {
        animation: 'x 0.35s',
        animationName: Radium.keyframes(slideInUp, 'slideInUp')
      },
      slideOutUp: {
        animation: 'x 0.35s',
        animationName: Radium.keyframes(slideOutUp, 'slideOutUp')
      },
      zoomIn: {
        animation: 'x 0.35s',
        animationName: Radium.keyframes(zoomIn, 'zoomIn')
      },
      zoomOut: {
        animation: 'x 0.35s',
        animationName: Radium.keyframes(zoomOut, 'zoomOut')
      },
      fadeIn: {
        animation: 'x 0.9s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn')
      },
      fadeOut: {
        animation: 'x 0.9s',
        animationName: Radium.keyframes(fadeOut, 'fadeOut')
      },
  }
//===============================================================
const myStyle={
    fontSize:{
        tinyFont:12,
        smallFont:13,
        normalFont:14, 
        bigFont:15
    } ,
    button:{
        backgroundColor:"#03a9f4",
        textColor:"#FFFFFF",
        iconColor:"#FFFFFF"
    },
    text:{
        color:"#FFFFFF"
    },
    header:{
        backgroundColor:"#00acc1" , 
        textColor:'#FFFFFF',
        backgroundColor_searchMode:"#FFFFFF" , 
        textColor_searchMode :"#00ACC1" ,
    },
}
const fontSizeStyle={
    tinyFont: 11, 
    smallFont:12,
    normalFont:13,
    bigFont: 14

}
// .w3-animate-fading{animation:fading 10s infinite}@keyframes fading{0%{opacity:0}50%{opacity:1}100%{opacity:0}}
// .w3-animate-opacity{animation:opac 0.8s}@keyframes opac{from{opacity:0} to{opacity:1}}
// .w3-animate-top{position:relative;animation:animatetop 0.4s}@keyframes animatetop{from{top:-300px;opacity:0} to{top:0;opacity:1}}
// .w3-animate-left{position:relative;animation:animateleft 0.4s}@keyframes animateleft{from{left:-300px;opacity:0} to{left:0;opacity:1}}
// .w3-animate-right{position:relative;animation:animateright 0.4s}@keyframes animateright{from{right:-300px;opacity:0} to{right:0;opacity:1}}
// .w3-animate-bottom{position:relative;animation:animatebottom 0.4s}@keyframes animatebottom{from{bottom:-300px;opacity:0} to{bottom:0;opacity:1}}
// .w3-animate-zoom {animation:animatezoom 0.6s}@keyframes animatezoom{from{transform:scale(0)} to{transform:scale(1)}}
// .w3-animate-input{transition:width 0.4s ease-in-out}.w3-animate-input:focus{width:100%!important}
//============================================================================================================================ACTION BUTTON
export const  ActionButton =(props)=>{
    const {onClick=()=>{},actIcon="add",position="left",marginFromEadge=20,hide=false , marginFromBottom=20} =props;

    const  positionStyle= position=== "right" ? {right: marginFromEadge?marginFromEadge: 20} : {left:marginFromEadge?marginFromEadge: 20};
    const positionBottomStyle=hide?-300  : marginFromBottom;
    const backgroundColor=props.backgroundColor?props.backgroundColor : myStyle.button.backgroundColor
    const iconColor=props.iconColor?props.iconColor :"#ffffff"
    return(
        <button className="w3-ripple w3-btn w3-animate-zoom "    onClick={onClick} 
            style={Object.assign({} , {position: "fixed", bottom: positionBottomStyle , padding:1 ,
                        borderRadius:25,justifyContent:'center',display:"flex",
                        alignItems: 'center', height: 50, width: 50,backgroundColor},
                        positionStyle)}>
            <MyIcon iconName={actIcon}  style={{fontSize:30,color:iconColor }} />
        </button>
    )
} 
//============================================================================================================================TOASTMESSAGE
export const ToastMessage= forwardRef( (props,ref)=>{

    const timerRef = useRef(null);

    const [message,setMessage]=useState("")
    const [visible,setVisible]=useState(false)

    useEffect( ()=>{
        return  ()=>{
                        if(timerRef.current) {
                        clearTimeout(timerRef.current);
                    }
                }
    },[])

    useEffect(()=>{
        if(message)
            setTimeout(()=>{setVisible(true)},100)
        else
            setVisible(false)
    },[message])

    useImperativeHandle(ref , ()=>({
        show(matn , timout=3000){
            setMessage(matn)
            timerRef.current = setTimeout(() => {setMessage("")}, timout);
        }
    }))

    return(
        visible ? 
        <AnimatableView animation={"slideInDown"} duration={300} 
                                    style={{padding:"60px 10px" ,position:"absolute" , top:0 , left:0 , right:0 , alignItems:"center" , justifyContent:"center"  }}>
            
                <View style={{flexDirection:"row" , alignItems:"flex-start" , backgroundColor:"#FFF" , padding:10 ,paddingLeft:15 ,  
                                       borderRadius:8 , elevation:20 , margin:5 , borderWidth:1 ,borderRightWidth:6 , borderColor:"#26c6da"  ,boxShadow:" 0px 0px 10px 3px #9a9a9a"}}>
                    <View style={{borderRadius:20 , backgroundColor:"#ffffff",marginLeft:6 }}>
                    <Image source={require("../../assets/images/logo/logo.png")} style={{width:35 , height:35}} />
                    </View>

                    <MyText isBold smallFont color="#456e7a"  style={{ paddingRight:6  , flex:1}}>{message}</MyText>

                    
                </View>

        </AnimatableView> : null
    )
})
//============================================================================================================================ALERT
export const Alert= forwardRef( (props,ref)=>{
    const {onOpenModal=()=>{} , onCloseModal=()=>{} , contentStyle={} , containerStyle={} }=props
    const [message,setMessage]=useState({title:'',matn:'',icon:'' , animationType:""})
    const [visible,setVisible]=useState(false)

    useImperativeHandle(ref , ()=>({
        show(title , matn , icon="error" , animationType="slideInDown"){
            onOpenModal()
            setMessage({title,matn,icon  ,animationType})
        },
    }))

    useEffect(()=>{
        if(message.matn)
            setTimeout(()=>{setVisible(true)},100)
        else
            setVisible(false)
    },[message])

    const hide=()=> setMessage({title:'',matn:'',icon:'' , animationType:"zoom"})

    const horizontalPadding= isTablet ? Math.floor(window.innerWidth/8) : 20
    
    return(
        <ModalContainer icon={imgLogo} 
                        visible={visible} 
                        title={message.title}
                        showTitle={message.title ? true : false}
                        closeWithClickBlankPlace
                        closeWithClickBackButton
                        backIconName="close"
                        containerStyle={Object.assign({} , {padding:`20px ${horizontalPadding}px`  } , containerStyle)}
                        contentStyle={Object.assign({} , {padding:0 , borderRadius:10}  , contentStyle)}
                        contentClassName="w3-col l6 m8 s12"
                        titleContainerClassName="w3-light-gray w3-padding-small w3-small-"
                        messageContainerClassName="w3-padding-small w3-margin-bottom"
                         message={message.matn}
                         showBackButton={true} 
                         position="top"
                         animation={message.animationType}
                         animationDuration={600 }
                         modalAnimationType="none"
                         onBackClick={hide} />
    )
})
//============================================================================================================================CONFIRM
export const Confirm= forwardRef( (props,ref)=>{

    const {contentStyle={} , containerStyle={}} = props    
    const [message,setMessage]=useState({title:'',matn:'',icon:''})
    const [okButtonInfo,setOkButtonInfo]=useState({title:'',onClick:()=>{} } )
    const [visible,setVisible]=useState(false)
    const [showBackButton,setShowbackButton]=useState(true)
    const [closeWithClickBlankPlace , setCloseWithClickBlankPlace]=useState(false)
    const [children  ,setChildren]=useState(null)

    const [onBackButtonClick, setOnBackButtonClick]=useState({title:'' , onClick : ()=>{}})

    useImperativeHandle(ref , ()=>({
        show(title , matn , icon , onOkButtonClick=()=>{} , okButtonTitle="تایید",showBackButton=true ,closeWithClickBlankPlace=false , onBackClick=()=>{} , children=null  ){
                setMessage({title,matn,icon})
                setOkButtonInfo({ title:okButtonTitle , onClick: onOkButtonClick })
                setShowbackButton(showBackButton)
                setCloseWithClickBlankPlace(closeWithClickBlankPlace)
                setOnBackButtonClick({onClick: onBackClick})
                setChildren(children)
        }
    }))

    useEffect(()=>{
        if(message.matn)
            setTimeout(()=>{setVisible(true)},100)
        else
            setVisible(false)
    },[message])


    const okButtonClick=()=>{
        setMessage({title:'',matn:'',icon:''})
        setTimeout( ()=>{
            okButtonInfo.onClick()
        },200)  
    }

    const hide=()=>{
        // alert("g")
        onBackButtonClick.onClick()
        setMessage({title:'',matn:'',icon:''})
    }

    const horizontalPadding= isTablet ? Math.floor(window.innerWidth/8) : 10


    return(
        <ModalContainer icon={imgLogo}
                        visible={visible} 
                        title={message.title} 
                        showTitle={message.title ? true : false}
                        message={message.matn}

                        containerStyle={Object.assign({} , {padding:`10px ${horizontalPadding}px` } , containerStyle)}
                        contentStyle={Object.assign({} , {padding:10 ,  borderRadius:5}  , contentStyle)}
                        contentClassName="w3-col l4 m7 s12"

                        position="top"
                        animation="slideInDown"
                        animationDuration={1300}
                        modalAnimationType="none"
                        showBackButton={showBackButton} 
                        closeWithClickBlankPlace={showBackButton || closeWithClickBlankPlace  ? true : false}
                        closeWithClickBackButton={showBackButton || closeWithClickBlankPlace  ? true : false}
                        onBackClick={hide}>

                            {
                                children ? 
                                <div>
                                    {
                                        children
                                    }
                                </div> : null
                            }

                        <div style={{display:"flex" , flexDirection:'row-reverse',marginTop:10}}>
                            <MyButton  smallFont  title={okButtonInfo.title}
                                    buttonStyle={{borderRadius:5,elevation:1 , backgroundColor:"#eeeeee",justifyContent:"center" ,
                                                        padding:"2px 14px",marginHorizontal:10 , borderColor:"#e57373" , borderBottomWidth:3 }}
                                    buttonTextStyle={{color:"#456e7a"}}
                                    buttonIconStyle={{fontSize:24,color:"#00BCD4",marginLeft:2}}
                                    onClick={okButtonClick} iconName="check_circle" />
                        </div>

        </ModalContainer>
    )
})
//============================================================================================================================POPUP
export const Popup= forwardRef( (props,ref)=>{

    const {contentStyle={} , containerStyle={}} = props 

    const [visible , setVisible]=useState(false)
    const [enterText , setEnterText]=useState("")
    const [messageInfo , setMessageInfo]=useState({
        extraData:'',
        title:'',
        matn:'',
        icon:'question',
        onOkButtonClick:()=>{},
        onTextChange:()=>{},
        okButtonTitle:"تایید",
        placeholder:"", 
        maxLength:65535,
        keyboardType:"default",
        multiline:false,
        showBackButton:true , 
        extraTextStyle:{}
    })

    

    useImperativeHandle(ref , ()=>({
        show(extraData="" , 
            title="" , 
            matn="" , 
            icon="" , 
            onOkButtonClick=()=>{} , 
            onTextChange=()=>{},
            okButtonTitle="تایید",
            defText="",
            placeholder="", 
            maxLength=65535,
            keyboardType="default",
            multiline=false,
            showBackButton=true , 
            extraTextStyle={}){
                    setMessageInfo({
                        extraData , 
                        title , 
                        matn , 
                        icon , 
                        onOkButtonClick , 
                        onTextChange,
                        okButtonTitle,
                        placeholder, 
                        maxLength,
                        keyboardType,
                        multiline,
                        showBackButton , 
                        extraTextStyle
                    })
                    setVisible(true)
                    setEnterText(defText && typeof(defText)==="string" ?defText:"")
                // } 
        }
    }))
    const okButtonClick=()=>{
        // Keyboard.dismiss()
        setVisible(false)
        messageInfo.onOkButtonClick(messageInfo.extraData , enterText) 
    }
    const textChange=(id , newText)=>{
        if(newText){
            if(newText.trim()==="")
                setEnterText("")
            else
                setEnterText(newText)
        }
        else
            setEnterText("")
    }

    const hide=()=>setVisible(false)

    const countNumberOfLine=()=>{
        if(!enterText.length)
            return 2
        else{
            const arrLine= enterText.split("\n")
            if(arrLine.length<2)
                return 2
            else if(arrLine.length<4)
                return arrLine.length
            else
                return 4
        }    
    }

    const horizontalPadding= isTablet ? Math.floor(window.innerWidth/8) : 20

    return(
        <ModalContainer visible={visible} 
                        icon={imgLogo}
                        title={messageInfo.title} 
                        showTitle={messageInfo.title ? true : false}
                        message={messageInfo.matn}
                        messageStyle={{color:"#0288D1"}}
                        closeWithClickBlankPlace={false}
                        closeWithClickBackButton={messageInfo.showBackButton  ? true : false}
                        showBackButton={messageInfo.showBackButton} 

                        containerStyle={Object.assign({} , {padding:`10px ${horizontalPadding}px` } , containerStyle)}
                        contentStyle={Object.assign({} , {padding:10 ,  borderRadius:10}  , contentStyle)}
                        contentClassName="w3-col l4 m6 s12"

                        position="top"
                        animation="slideInDown"
                        onBackClick={()=>setVisible(false)}>
            <div style={{ display : "flex" ,  flex:1 , flexDirection:"row",marginVertical:8 , 
                                        alignItems:"flex-start",borderBottomWidth:1 , 
                                        borderColor:"#26A69A" , padding:"10px 2px" , marginHorizontal : 5  }  }>
                             
                <MyInput    inputStyle={Object.assign({} ,{textAlign:"right"  , color:"#4DB6AC" , padding:2}, messageInfo.extraTextStyle )} noShadow
                                containerStyle={{backgroundColor:"transparent",padding:"3px 3px"  , flex:1}}
                                keyboardType={messageInfo.keyboardType} 
                                placeholder={messageInfo.placeholder} 
                                placeholderTextColor="#bdbdbd"
                                underlineColorAndroid="#B0BEC5"
                                smallFont
                                bigFont={messageInfo.keyboardType==="numeric" && enterText.length ? true : false}
                                id="enterText"
                                onChangeText={textChange} 
                                multiline={messageInfo.multiline}
                                maxLength={messageInfo.maxLength}
                                numberOfLines={messageInfo.multiline ? countNumberOfLine() : 1}
                                value={enterText? enterText:''} />
            </div>

            <div style={{alignItems:"flex-start" , display:"flex" , flexDirection:"row-reverse" , justifyContent:"space-between"}}>
                <MyButton  smallFont  title={messageInfo.okButtonTitle} 
                            buttonStyle={{borderRadius:5,elevation:1 , backgroundColor:"#eeeeee",justifyContent:"center" ,
                                             padding:"2px 10px" , marginHorizontal:10 , borderColor:"#e57373" , borderBottomWidth:3 }}
                            buttonTextStyle={{color:"#456e7a"}}
                            buttonIconStyle={{fontSize:22,color:"#00BCD4"}}
                            onClick={okButtonClick} iconName="check_circle" />
            </div>

        </ModalContainer>
    )
})
//============================================================================================================================HEADER GLOBAL
export const  HeaderGlobal =(props)=> {

    const navigation=props.navigation

    const {showBackIcon=false,
           showMenuIcon=false,
           onBackClick,onMenuClick , 
           title="" , 
           subTitle="" , 
           headerTextColor ,
           showLoading=false , 
           loadingColor= myStyle.text.color ,
           backgroundColor="#FFFFFF" , 
           showHeaderBorder,
           leftIcon,
           onLeftIconClick=()=>{} , 
           leftComponent , 
           searchBoxVisible=false , 
           searchValue="" ,
           onChangeSearchText=()=>{} ,
           onSearchClick=()=>{} , 
           onCloseSearchBoxClick=()=>{} , 
           searchButtonStyle }=props

    const onChangeSearchValue=(txtId , txtValue)=> onChangeSearchText(txtId , txtValue)
    const searchClick=()=> onSearchClick(searchValue)

    const backToPage = onBackClick!=null ? ()=>onBackClick() : () => navigation.goBack();
    const showHeaderBorderStyle = showHeaderBorder?{elevation:3,marginBottom:0}:{elevation:0}
    return (
        searchBoxVisible ? 
                    <div className="w3-animate-right displayFlexRow"  style={{alignItems:"center" , padding:"7px 10px 6px 10px"}}>
                            <MyButton onClick={onCloseSearchBoxClick}
                                             title='' 
                                             iconName='arrow_forward_ios' 
                                             buttonStyle={{backgroundColor:"transparent"}}
                                             buttonIconStyle={Object.assign({} , {fontSize:29} , {color:myStyle.header.textColor_searchMode} ,   
                                                                          searchButtonStyle.buttonTextStyle? searchButtonStyle.buttonIconStyle : {}  )}
                                             buttonTExtStyle={{}} />
                            <MyInput id ='search'         
                                            placeholder="جستجو...." 
                                            value={searchValue}          
                                            disabled={false}  
                                            onChangeText={onChangeSearchValue}       
                                            password={false}  
                                            maxLength={200}         
                                            textAlign='right' 
                                            inputStyle={{textAlign:"right" , color:"gray" , padding:3 }}   
                                            containerStyle={{flex:1 , backgroundColor:"#FFFFFF" , borderRadius:10 , border:`1px solid ${myStyle.header.textColor_searchMode}`}}  />
                        <MyButton iconName="search" title="" smallFont onClick={searchClick} 
                                         buttonStyle={Object.assign({} , {paddingVertical:2 , paddingHorizontal:7 , backgroundColor:"#eeeeee" , marginRight:10 , borderRadius:30} , searchButtonStyle.buttonStyle? searchButtonStyle.buttonStyle : {}    )} 
                                        buttonTextStyle={Object.assign({} , {color:backgroundColor} ,   searchButtonStyle.buttonTextStyle? searchButtonStyle.buttonTextStyle : {}  )}
                                        buttonIconStyle={Object.assign({} , {color:myStyle.header.textColor_searchMode} ,   searchButtonStyle.buttonTextStyle? searchButtonStyle.buttonIconStyle : {}  )} />
                    </div>    : 
            <div className="displayFlexRow"  style={Object.assign({} ,{justifyContent:'flex-start',alignItems:"center",backgroundColor , padding:5} , showHeaderBorderStyle  )}>
                <div style={{display:"flex"  ,flex:1 ,  flexDirection:"row",alignItems:'center'}}>
                    {
                        showMenuIcon?<MyButton iconName={"menu"}
                                            buttonIconStyle= {Object.assign({} , Styles._elements._header.icon, { color:headerTextColor , fontSize:35})}
                                            buttonStyle={{backgroundColor:"transparent"}} onClick={onMenuClick} />:null
                    }
                    {
                        showBackIcon?<MyButton iconName={"arrow_forward"   }
                                            buttonIconStyle= {Object.assign({} , Styles._elements._header.icon, { color:headerTextColor})}
                                            buttonStyle={{backgroundColor:"transparent"}} onClick={backToPage} />:null
                    }
                    <div className="displayFlexCol displayFlexGrow">
                        <MyText isBlock    style={ { textAlign: "right", marginRight: (showBackIcon || showMenuIcon) ? 10 :5 , color:headerTextColor }}>{title}</MyText> 
                        {
                            subTitle ?
                            <MyText isBlock className="w3-margin-right"  tinyFont style={{ textAlign: "right", marginRight: (showBackIcon || showMenuIcon) ? 20 :5 , 
                                        color:headerTextColor}}>{subTitle}</MyText> :null
                        } 
                    </div>
                </div>
                <div className="displayFlexCol" >
                    <div style={{display : showLoading ? "flex" : "none"}}>
                        <Spinner color={loadingColor} size="large" style={{color:loadingColor}} />
                    </div>
                    {
                            leftIcon && !showLoading ?  <MyButton iconName={leftIcon} onClick={onLeftIconClick}
                                                                                          buttonStyle={{backgroundColor:"transparent"}} 
                                                                                          buttonIconStyle={{color:headerTextColor}}/>:null
                    } 
                    {/* <MyButton iconName={leftIcon} onClick={onLeftIconClick} />  */}
                    {
                            leftComponent && !showLoading ? leftComponent : null
                    }
                </div> 
                {
                    props.showHeaderIcon?
                                        <div style= {Object.assign({},{display:"flex" , flexDirection:"column"} ,Styles._components._headerIcon.icon,{left:10})}>
                                            <MyImage   src={imgLogo} />
                                        </div> : null
                }
            </div>
    )
}
//============================================================================================================================PAGE CONTAINER
const mapPageContainerStateToProps=(state)=>({
    pageAnimateDirState:state.pageAnimateDirReducer
})
//============================================================================================================================
const mapPageContainerDispatchToProps=(dispatch)=>({

})
//============================================================================================================================
export const PageContainer=connect(mapPageContainerStateToProps , mapPageContainerDispatchToProps)( props => {
    // const confirmModal=useRef()
    const { title="" , 
            headerIsFloat=false,
            subTitle="",
            showBackIcon=false , 
            showMenuIcon=false , 
            onBackIconClick=()=>{} , 
            onMenuIconClick=()=>{},
            hasHeaderBorder=false, 
            canHasHeaderBorder=true,
            containerPadding=5,
            leftIcon=null,
            onLeftIconClick=()=>{},
            leftIconText="",
            showLoading=false,
            loadingColor ,
            leftComponent=null,
            showHeaderIcon=false , 
            backgroundColor="#FFFFFF" , 
            headerColor= myStyle.header.backgroundColor,
            headerTextColor=myStyle.header.textColor ,
            hasFlatList=false , 
            showHeader=true ,
            toastComponent=null,
            dirAnimation="left" ,
            searchBoxVisible=false , 
            searchValue="" ,
            onChangeSearchText=()=>{} ,
            onSearchClick=()=>{} , 
            onCloseSearchBoxClick=()=>{} ,
            searchButtonStyle={buttonStyle:{} , buttonTextStyle:{} , buttonIconStyle:{} } }=props

    //============================================================================

    const [showBottomBorderOfHeader,setShowBottomBorderOfHeader]=useState( hasHeaderBorder? true : false )
    const changeBorderBottomOfHeader=(event)=>{
        if(hasHeaderBorder){
            if(showBottomBorderOfHeader===false)
                setShowBottomBorderOfHeader(true)
        }
        else{
            if(canHasHeaderBorder){
                const y=event.nativeEvent.contentOffset.y
                if(y < 1 )
                        setShowBottomBorderOfHeader(false)
                else 
                    setShowBottomBorderOfHeader(true)
            }
        }  
    }

    const backIconClicked=()=>onBackIconClick()


    return(
            <StyleRoot className="displayFlexCol displayFlexGrow" style={{ width:"100%" , overflow:"hidden"  }}>
                <div className={`${ isDesktop ? " w3-card " : ""}   displayFlexGrow   displayFlexCol `} 
                    style={Object.assign({} , { overflow:"hidden" } ,  isDesktop  ? animateStyles.slideInRight : animateStyles.slideInRight )}>
                    {
                        headerIsFloat?
                        <ContentContainer contentClassName="displayFlexGrow"  containertyle={{overflow:"auto"}}>
                            {
                                showHeader?
                                <HeaderGlobal title={title} 
                                            subTitle={subTitle}
                                            showHeaderIcon={showHeaderIcon}
                                            showBackIcon={showBackIcon} 
                                            showMenuIcon={showMenuIcon} 
                                            leftIcon={leftIcon} onLeftIconClick={onLeftIconClick}
                                            leftIconText={leftIconText}
                                            leftComponent={leftComponent}
                                            showLoading={showLoading}
                                            loadingColor={loadingColor ? loadingColor : headerTextColor}
                                            onMenuClick={onMenuIconClick}
                                            showHeaderBorder={hasHeaderBorder ? true : showBottomBorderOfHeader}
                                            onBackClick={backIconClicked} 
                                            backgroundColor={headerColor} 
                                            headerTextColor={headerTextColor}
                                            
                                            searchBoxVisible={searchBoxVisible} 
                                            searchValue={searchValue}
                                            onChangeSearchText={onChangeSearchText}
                                            onSearchClick={onSearchClick} 
                                            onCloseSearchBoxClick={onCloseSearchBoxClick} 
                                            searchButtonStyle={searchButtonStyle}/>:null
                            }
                            <div style={{ display:"flex" , flexDirection:"column", padding:containerPadding , position:"relative"}} >
                                {
                                    props.children
                                }
                            </div>
                        </ContentContainer>
                        :
                        <div className="displayFlexCol displayFlexGrow " style={{ overflow:"hidden"  , borderRadius: isDesktop ? 0 :  0 , backgroundColor }}>
                            {
                                showHeader?
                                <div className="displayFlexCol" style={{}} >
                                    <HeaderGlobal title={title} 
                                            subTitle={subTitle}
                                            showHeaderIcon={showHeaderIcon}
                                            showBackIcon={showBackIcon} 
                                            showMenuIcon={showMenuIcon} 
                                            leftIcon={leftIcon} onLeftIconClick={onLeftIconClick}
                                            leftIconText={leftIconText}
                                            leftComponent={leftComponent}
                                            showLoading={showLoading}
                                            onMenuClick={onMenuIconClick}
                                            showHeaderBorder={hasHeaderBorder ? true : showBottomBorderOfHeader}
                                            onBackClick={backIconClicked} 
                                            backgroundColor={headerColor} 
                                            headerTextColor={headerTextColor}
                                            
                                            searchBoxVisible={searchBoxVisible} 
                                            searchValue={searchValue}
                                            onChangeSearchText={onChangeSearchText}
                                            onSearchClick={onSearchClick} 
                                            onCloseSearchBoxClick={onCloseSearchBoxClick} 
                                            searchButtonStyle={searchButtonStyle}/>
                                </div>:null
                            }
                            <div className={`displayFlexGrow ${isDesktop ? "displayFlexCol" :"displayFlexCol" }`} 
                                    style={{ backgroundColor , padding:containerPadding   ,  overflow: "hidden" , position:"relative"}}>

                                {
                                    props.children
                                }
                                
                            </div>
                        </div>
                    }
                    {
                        toastComponent ? toastComponent : null
                    }
                </div> 
            </StyleRoot>
    ) 
})
export const  ContentContainer=(props)=>{
    const {containerStyle={} , containerClassName="" , contentStyle={} , contentClassName=""  , hasPadding=false}=props
    return (
        <div className= {`mainContainer  ${containerClassName} displayFlexCenter  `}   
                style={Object.assign({} ,containerStyle , {overflow:"hidden" } , {direction:"rtl" }   )} >
                <div  className={`mainContainer  w3-col  ${hasPadding===true ? "l10" : "l12"} m12 s12    ${contentClassName}  `}
                        style={Object.assign({},{ flex:1 ,display:"flex" , overflow:"auto" } ,contentStyle )} >
                {
                        props.children
                }
                </div>
        </div>
    )
}
//============================================================================================================================HR
export const Hr=(props)=>{
    const {color}=props
    return( <div style={{padding:1,marginHorizontal:10,marginVertical:10,backgroundColor:color}} /> )
}
//============================================================================================================================MODAL CONTAINER
export const ModalContainer=forwardRef((props , ref)=>{
    const {
                    visible=false , 
                    title="" , 
                    message="" ,
                    showTitle=true, 
                    isMessageBold=true,
                    onBackClick=()=>{} , 
                    showBackButton=false ,
                    backIconName="cancel",
                    hasFlatList=false , 
                    closeWithClickBlankPlace=false , 
                    closeWithClickBackButton=false,
                    containerStyle={padding:100},
                    contentStyle={},
                    containerClassName="" , 
                    contentClassName="",
                    position="center",
                    animation="zoomIn",
                    titleContainerClassName="",
                    titleContainerStyle={} , 
                    titleTextClassName="",
                    titleTextStyle={} ,
                    messageContainerClassName="" , 
                    messageContainerStyle={} , 
                    messageTextClassName="" , 
                    messageTextStyle={}
            }=props

    const [backPressed ,setBackPressed]=useState(false)

    useEffect(()=>{
        if(visible)
            setBackPressed(false)
        else
            setBackPressed(false)
    } , [visible])

    useEffect(()=>{
        if(backPressed){
            setTimeout(()=> onBackClick() , 300)
        }
    } , [backPressed])

    
    let enterAnimateStyle=animateStyles.zoomIn
    let exitAnimateStyle=animateStyles.zoomOut

    switch(animation){
        case "zoomIn":           enterAnimateStyle=animateStyles.zoomIn ; 
                                           exitAnimateStyle=animateStyles.zoomOut  ; break
        case "slideInUp" :    enterAnimateStyle=animateStyles.slideInUp ; 
                                           exitAnimateStyle=animateStyles.slideOutDown  ; break
        case "slideInDown" :  enterAnimateStyle=animateStyles.slideInDown ; 
                                           exitAnimateStyle=animateStyles.slideOutUp  ; break
        case "slideInRight" : enterAnimateStyle=animateStyles.slideInRight ; 
                                           exitAnimateStyle=animateStyles.slideOutRight  ; break
        case "slideInLeft" :  enterAnimateStyle=animateStyles.slideInLeft ; 
                                           exitAnimateStyle=animateStyles.slideOutLeft  ; break
        case "fadeIn" :          enterAnimateStyle=animateStyles.fadeIn ; 
                                           exitAnimateStyle=animateStyles.fadeOut  ; break
        case "none" : break
        default : break
    }
    
    let justifyContent="center"
    if(position==="top")
        justifyContent="flex-start"
    if(position==="bottom")
    justifyContent="flex-end"

    const widthStyle={width:"100%"}

    const onBackButtonClick=()=> setBackPressed(true)

 return(   
        <Modal visible={visible} transparent={true} animationType={"none"}>
            <StyleRoot style={{display:"flex" ,position:"fixed" , top:0 , bottom:0 , right:0 , left:0 , zIndex:10 , }}>
            <div className={`${containerClassName}`}
                    style={Object.assign({} ,
                        { flex:1 ,  zIndex:10 },
                        {backgroundColor:'rgba(0,0,0,0.7)'}  ,
                        {display:"flex", flexDirection:"column" , justifyContent , alignItems:"center"} , containerStyle  )}
                    onClick={closeWithClickBlankPlace ? onBackButtonClick : ()=>{}} >

                        <div className={`${contentClassName}`}
                                style={Object.assign({} ,   {backgroundColor:"white" , borderRadius:5,padding:5, overflow:"auto" } , 
                                                            //widthStyle , 
                                                            backPressed ? exitAnimateStyle : enterAnimateStyle , 
                                                            contentStyle )} >
                                {
                                    showTitle?
                                    <MyTitle title={title} backIconName={backIconName}
                                                   containerClassName={titleContainerClassName} 
                                                   containerStyle={titleContainerStyle}
                                                   textStyle={titleTextStyle}
                                                   textClassName={titleTextClassName} 
                                                   onBackButtonClick={onBackButtonClick}  showBackButton={showBackButton} />:null
                                }

                                {
                                    !showTitle && showBackButton ? 
                                    <div style={{ display:"flex"  , justifyContent:"flex-start" , flexDirection:"row" , marginBottom:5 }}>
                                            <MyButton onClick={onBackButtonClick} 
                                                    title="" iconName={backIconName}
                                                    buttonStyle={{backgroundColor:"transparent" ,padding:"0 2px"}}
                                                    buttonIconStyle={{fontSize:25 , color:"#e57373"}} />  
                                    </div>:null
                                }

                                {
                                    message?<div className={`${messageContainerClassName}`} style={ Object.assign( {} , messageContainerStyle ) }>
                                                            <MyText isBlock smallFont isBold={isMessageBold} 
                                                                        style={{marginTop: !showTitle && showBackButton ? 5 :  5 , marginHorizontal:5 , color:"#546e7a"}}>{message}</MyText>
                                                  </div>:null
                                }
                                

                                {
                                    !hasFlatList ?
                                        props.children
                                    :
                                    <div style={{flex:1}}>
                                        {
                                        props.children
                                        }
                                    </div>
                                } 
                            </div>
                           
        </div>
        </StyleRoot>
        </Modal> 
 )
})
//============================================================================================================================MY TITLE
export const MyTitle=(props)=>{
    const {title="",onBackButtonClick=()=>{} , showBackButton=false , backgroundColor="transparent" , backIconName="cancel" , 
                containerStyle={} , containerClassName="" ,
                 textStyle={} , textClassName="" }=props
    return(
        <div className={`${containerClassName}`} style={Object.assign({} , {borderBottomColor:"#26c6da",display:"flex" ,
                                                                                                                                    borderBottomWidth:2,
                                                                                                                                    padding:0,
                                                                                                                                    marginBottom:0,
                                                                                                                                    flexDirection:'row-reverse',
                                                                                                                                    justifyContent:"space-between",
                                                                                                                                    alignItems:'center',backgroundColor} , containerStyle )}>
            <MyText isBlock isBold className={`${textClassName}`} color='#e57575' style={Object.assign({} , {flex:1,padding:`0 10px`} , textStyle )} >{title}</MyText>
            {
                showBackButton? 
                <MyButton title="" 
                      iconName={backIconName} 
                      buttonStyle={{padding:0 , backgroundColor:"transparent"}}
                      buttonIconStyle={{color:"#e57373",fontSize:25}} 
                      onClick={onBackButtonClick} />
                :null

            }

        </div>
    )
}
//============================================================================================================================MY TEXT
export const MyText=(props)=>{
    const { 
                tinyFont=false , 
                smallFont=false ,
                normalFont=true , 
                bigFont=false,
                color="#456e7a" , 
                isBlock=false , 
                isBold=false , 
                className="" , 
                style={},
            }=props

    let findFontSize="normalFont"
    if(tinyFont) findFontSize="tinyFont"
    if(smallFont) findFontSize="smallFont"
    if(bigFont) findFontSize="bigFont"

    let myText=""
    let arrText=[]
    if(props.children){
        if(typeof(props.children)==="string")
            arrText=props.children.split("\n")
        else {
            if(Array.isArray(props.children)){
                try{
                    const arrTargetText= props.children.filter(text => text!=="  ")
                    arrText=arrTargetText.toString().split("\n")
                }
                catch{  }
            }  
        }
        // arrText.forEach(text => {
        //     myText+=text+<br />
        // });
    }


    return(
        <Text style={Object.assign({} , {fontSize:fontSizeStyle[findFontSize] , color , fontWeight : isBold ? "bold" : "normal"} , style )}>{props.children}</Text>
        // isBlock ? 
        // <div className={`${className}  w3-show-block`} 
        //         style={Object.assign({} ,     
        //                    {wordWrap: "break-word" , whiteSpace:"wrap" , fontSize:fontSizeStyle[findFontSize] , color , fontWeight : isBold ? "bold" : "normal"} , 
        //                    style )} >{arrText.map((text , index) => <div key={index}>
        //                                                                                                 {text}<br />
        //                                                                                         </div>)}
        // </div>
        // :<div className={`${className} w3-show-block`} 
        //             style={Object.assign({} , {wordWrap: "break-word" ,  whiteSpace:"wrap"  , fontSize:fontSizeStyle[findFontSize]  , color , fontWeight : isBold ? "bold" : "normal"} , 
        //             style )}>{arrText.map((text , index) =><span key={index}>
        //                                                                                 {text}<br />
        //                                                                             </span>)}
        // </div>
    )
}
//============================================================================================================================MY BUTTON
export const MyButton =(props)=>{

    const { buttonStyle={} , buttonTextStyle={} , buttonIconStyle={} ,buttonImageStyle={},
                buttonHoverStyle={}, buttonTextHoverStyle={}, buttonIconHoverStyle={},buttonImageHoverStyle={},
                onClick=()=>{} , 
                imageSource="",
                onMouseEnter=()=>{} , 
                onMouseLeave=()=>{} , 
                disabled=false ,
                disabledOnLoading=true , 
                showLoading=false , loadingSize=17 , loadingColor="#FFFFFF",
                loadingStyle={marginLeft:5},
                tinyFont=false , smallFont=false , bigFont=false , isBold=false , opacityOnDisabledOff=false }=props

    const opacityStyle=disabled && !opacityOnDisabledOff ?  {opacity : 0.6} : {}


    const [mouseEntered , setMouseEnterd]=useState(false)

    const _onMouseEnter=()=>{
        setMouseEnterd(true);
        onMouseEnter()
    }

    const _onMouseLeave=()=>{
        setMouseEnterd(false);
        onMouseLeave()
    }
    
    return(
            <div  className={` w3-button   ${disabled ? "" : "w3-ripple"} `}
                            style={Object.assign({} ,{ display:"flex",boxShadow:0 ,  
                                                                        flexDirection:'row',
                                                                        justifyContent:'center',
                                                                        alignItems:'center',
                                                                        borderRadius:5,
                                                                        // opacity : disabled && !opacityOnDisabledOff ? 0.6:1,
                                                                        padding:"5px 8px" , 
                                                                        backgroundColor: myStyle.button.backgroundColor 
                                                                    } , opacityStyle , buttonStyle , mouseEntered ? buttonHoverStyle  : {})}
                                onClick={  disabled ? ()=>{}  : onClick  }
                                disabled={disabled || (disabledOnLoading && showLoading)} 
                                onMouseEnter={_onMouseEnter}
                                onMouseLeave={_onMouseLeave}  >
                    {
                        props.title?
                            <MyText isBlock isBold={isBold} 
                                tinyFont={tinyFont} smallFont={smallFont} bigFont={bigFont}
                                style={Object.assign({} ,{color:myStyle.button.textColor },  buttonTextStyle , mouseEntered ? buttonTextHoverStyle  : {} )}>{props.title}
                            </MyText>
                        :null
                    }
                    {
                        showLoading ? 
                        <Spinner color={loadingColor} size={loadingSize} style={loadingStyle}   />
                        :
                        <div>
                            { 
                                props.iconName ?
                                <div style={{display:"flex" , flexDirection:"column"}}>
                                    <MyIcon iconName={props.iconName?props.iconName:"call"}
                                        style={Object.assign({} , {marginLeft:props.title ? 5 : 0,fontSize:22}, 
                                                {color:myStyle.button.iconColor} , buttonIconStyle , mouseEntered ? buttonIconHoverStyle  : {})} />
                                </div>
                                :null
                            }
                        </div>
                    }
                    {
                        imageSource ? <MyImage src={imageSource} style={Object.assign({} , {marginLeft:props.title ? 5 : 0,fontSize:25} , buttonImageStyle )}  /> : null
                    }
             </div>
    )
}
//===========================================================================================================================MY ICON
export const MyIcon=(props)=>{
    const {iconName="" , iconSize=21 , color="#FFFFFF" , backgroundColor="transparent" ,style={} , iconMode="" }=props

    const modeClass= iconMode ? `material-icons-${iconMode}` :  "material-icons"

    return(
        <span className={modeClass} style={Object.assign({} , {fontSize:iconSize , color , backgroundColor } , style)}>{iconName}</span>
    )
}
//============================================================================================================================MyIMAGE
export const MyImage=(props)=>{
    const { style={} , className=""  , resizeMode="cover" }=props
    // const imageSrc = props.source.uri ? props.source.uri : props.source
    return(
        <View  className={`${className}`}  style={Object.assign({} , {position:"relative" }   , style)}>
                <Image source={props.source} className={`${className}`} {...props}  resizeMode={resizeMode}
                        style={Object.assign({} , {width:"100%",   }  ,style  )} 
                         />
                         {/* <img src={imageSrc} style={Object.assign({} , {width:"100%" , objectFit:resizeMode} , style )} /> */}
        </View>
    )  
}
//============================================================================================================================MY RADIO
const RadioElem=(props)=>{
    const { width=24 , color="#546e7a" , selected , selectedColor }=props;
    return(
            <div style={{ display:"flex" ,  width,height:width,borderRadius:Math.floor(width/2),borderWidth:1,
                          borderColor: selected ? selectedColor :color , 
                          alignItems:"center" , justifyContent:"center"}}>
                    {
                        selected ? 
                        <div style={{display:"flex" ,  backgroundColor:selectedColor,width:width-8,height:width-8,borderRadius:Math.floor((width-8)/2)}}>

                        </div>:null
                    }
            </div>
    )
    
}
//============================================================================================================================MY RADIO
export const MyRadio=(props)=>{
    const {title="",
           selected=false,
           color="gray",
           selectedColor="teal",
           useImage=false,
           onClick=()=>{} , 
           disabled=false,
           isBold=true , 
           smallFont=false , 
           bigFont=false  ,
           tinyFont=false ,
           width=24,
           extraContainerStyle={}}=props;

    return(
            <button onPress={onClick} activeOpacity={0.7} underlayColor="transparent" disabled={disabled}>
            <div style={Object.assign({} ,{ display:"flex" ,  flexDirection:"row" , alignItems:"center"} , extraContainerStyle)}>
                {
                    useImage ? <div style={{ display:"flex" , flexDirection:"row-reverse", backgroundColor:"white" , width:30 , height:30 , borderRadius:20,
                                             justifyContent:"center" , alignItems:"center"}}>
                                    <MyImage src={imgOk} 
                                      style={{width:25,height:25 , opacity : selected ? 1 : 0}} />
                                </div> :
                               <RadioElem color={color} selected={selected} width={width}  selectedColor={selectedColor}  />
                }
                
                <MyText isBlock 
                        // smallFont={selected? false : true} 
                        tinyFont={tinyFont} 
                        smallFont={smallFont}
                        bigFont={bigFont}
                        isBold={isBold}
                        style={{marginRight:5,color:selected==true?selectedColor:color}}>{title}</MyText>
            </div>
            </button>
    )
}
//============================================================================================================================PROFLEIMAGE
export const  ProfileImage =(props) =>{
    const {className="" , style={}} = props
    const refImage=useRef()
    const [imageWidth , setImageWidth]=useState(0)
    useEffect(()=>{
        setImageWidth(refImage.current.offsetWidth)
    } , [])
    const {width=150}=props;
    const borderRadius=Math.floor(imageWidth/2)
    const borderRadiusContainer=borderRadius+10
    return(
        <div ref={refImage} className="" style= {Object.assign({},{display:"flex"} ,Styles._elements.viewCenterContainer,{borderRadius:borderRadiusContainer} )}>
            <div className={`w3-circle ${className}`}  style= {Object.assign({} , style,{display:"flex"} ,Styles._components._profileImageComponent.profileImageContainer)}>
                <MyImage  
                       className="w3-circle"
                       style= {Object.assign({} ,Styles._components._profileImageComponent.profileImage,{width,height:imageWidth,borderRadius})}
                       source={props.source} />
            </div>
        </div>
    )
}
//============================================================================================================================NO ITEM
export const  NoItem =(props)=>{
    const {title="خطا در دریافت اطلاعات",
        message="" , 
        showTryAgainButton=false,
        tryAgain=()=>{} , 
        visible ,
        showBorder=false , 
        messageStyle={} ,
        titleStylew={},
        containerStyle={},
        tryAgainButtonText="تلاش مجدد",
        tryAgainButtonIcon="refresh",
        icon=require("../../assets/images/act/imgError.png")}=props 
 return(
     <View  duration={500} style={{display:visible===true ?"flex":"none",marginVertical:0}}>
            <View style={Object.assign({} , {
                        // elevation:7,
                        backgroundColor:'white',
                        borderRadius:10,
                        paddingHorizontal:10,
                        paddingVertical: 5,
                        marginTop:10,
                        elevation: showBorder ? 3 : 0 , 
                        marginHorizontal:20} , containerStyle)} >
                <View style={{flexDirection:'row',alignItems:'center',borderBottomColor:'#4dd0e1', borderBottomWidth:2,paddingBottom:5}}>
                        <Image source={icon} style={{width:35 , height:35}} resizeMode="contain" />
                        <MyText  isBold tinyFont   style={Object.assign({} , {color:'#03a9f4',textAlign:'right',flex:1,marginRight:7} , titleStylew)}>{title}</MyText>
                </View>
                <MyText isBold tinyFont style={Object.assign({} , {color:"#546E7A",marginTop:5} , messageStyle)}>
                    {message}
                </MyText>

                <View style={{justifyContent:'center' , alignItems:'center' , marginTop:10}}>
                {
                    showTryAgainButton?<MyButton  tinyFont  title={tryAgainButtonText}
                                                buttonStyle={{borderRadius:4,elevation:2,backgroundColor:"#EFEBE9",margin:3,paddingVertical:2}}
                                                buttonTextStyle={{color:"#546e7a"}}
                                                buttonIconStyle={{color:"#546e7a",fontSize:22}}
                                                iconName={tryAgainButtonIcon}
                                                onClick={()=>tryAgain()} />:null
                }
                </View>   
         </View>
     </View>       
 )
}
//============================================================================================================================MY SWITCH
export const MySwitch =(props)=>{
    const {title="",
                subTitle="",
                iconName="done",
                containerClassName="" ,
                containerStyle={},
                selected=false,
                noSelectedColor="#bdbdbd",
                isBold=true , 
                smallFont=false , 
                bigFont=false  ,
                imageWidth=22,
                tinyFont=false ,
                selectedColor="#03a9f4",
                selectedIconColor="teal",
                onClick=()=>{} , 
                useImageOpacity=false,
                useImageBackgroundColor=false,
                imageBackgroundColor="#ffffff",
                disabled=false,
                useImage=false ,
                useIcon=false,
                useIconOpacity=false,
                useSwitch=false,
                iconSize=25
           }=props;

    const imgSwitch = selected===true || useImageOpacity ? imgOk :imgNoOk
    return(
            <TouchableOpacity className={`w3-transparent w3-border-0 w3-ripple ${containerClassName}`}
                         style={Object.assign({} ,{display:"flex" , flexDirection:"row-reverse",alignItems:'flex-start' ,borderBottomColor:"#e0e0e0"} , containerStyle)}
              disabled={disabled}  onPress={onClick}>

                {/* {
                    useSwitch ? <Switch color={color} value={selected} selectedColor={selectedColor} onValueChange={()=>onClick()}  /> :  null
                } */}
                {
                    useImage ?  <div style={{dispaly:"flex" ,flexDirection:"row-reverse" ,justifyContent:"center" , alignItems:"center" ,
                                                            border:useImageBackgroundColor ?"1px solid #e0e0e0":0,borderColor:"#e0e0e0" ,
                                                            backgroundColor:useImageBackgroundColor?imageBackgroundColor:"transparent",
                                                            elevation:useImageBackgroundColor && selected?1 : 0,
                                                            borderRadius:imageWidth , width:imageWidth,height:imageWidth,padding:2}}>
                                    <MyImage source={imgSwitch}  
                                      style={{width:"100%",height:"100%" ,
                                            opacity:useImageOpacity && !selected ? 0.2 : 1}} />
                                </div>
                                 :null
                }
                {
                    useIcon ? <MyIcon iconName={iconName} 
                                      iconSize={iconSize}
                                      color={useIconOpacity || selected ? selectedIconColor : noSelectedColor } 
                                      style={{opacity:useIconOpacity && !selected ? 0.1 : 1 }} /> : null
                }
                {
                    title || subTitle?
                    <div style={{flex:1 , display:"flex" , flexDirection:"column"}}>
                        {
                            title ?
                            <MyText isBlock tinyFont={tinyFont} color={selected?selectedColor:noSelectedColor} 
                                    smallFont={smallFont}
                                    bigFont={bigFont}
                                    isBold={isBold} 
                                    style={{marginRight:10,  textAlign:"right"}}>{title}</MyText> :null
                        }   
                        {
                            subTitle ?
                            <MyText isBlock tinyFont color={selected?selectedColor:noSelectedColor}
                                    // isBold={isBold} 
                                    style={{marginRight:10,}}>{subTitle}</MyText> :null
                        }
                    </div>
                    :null
                }
            </TouchableOpacity>
    )
}
//============================================================================================================================MY INPUT
export const  MyInput =(props)=>{

    const { containerStyle={},
             containerClassName="",
            id="",
            title="" ,
            titleClassName="" ,
            titleStyle={} ,  
            value="",
            inputStyle={},
            inputClassName="" , 
            placeholder="",
            placeholderColor="rgba(84,110,121,0.7)",
            underlineColorAndroid="transparent",
            isBold=true,
            keyboardType="default",
            maxLength=500,
            isPassword=false,
            multiline=false,
            numberOfLines=0,
            minNumberOfLines=1 , 
            maxNumberOfLines=10 ,
            disabled=false,
            _ref=null,
            onChangeText=()=>{},
            onGetFocus=()=>{},
            onLeaveFocus=()=>{},
            smallFont=false,
            bigFont=false,
            tinyFont=false,
            autoFocus=false,
            returnKeyType="done",
            onActionButtonClick=()=>{} ,
            iconName="",
            iconSize=22,
            iconColor="#456e7a",
            iconContainerStyle={},
            noShadow=true,
            opacityOnDisabledOff=false
        }=props

        let fontStyle={}
        if(smallFont)
            fontStyle=fontSizeStyle.smallFont;
        if(bigFont)
            fontStyle=fontSizeStyle.bigFont;
        if(tinyFont)
        fontStyle=fontSizeStyle.tinyFont;

    const _onChangeText=(txt)=>{
        const text=txt ? txt.replace(/۰/g , "0")
                            .replace(/۱/g , "1")
                            .replace(/۲/g , "2")
                            .replace(/۳/g , "3")
                            .replace(/۴/g , "4")
                            .replace(/۵/g , "5")
                            .replace(/۶/g , "6")
                            .replace(/۷/g , "7")
                            .replace(/۸/g , "8")
                            .replace(/۹/g , "9") : ""
        onChangeText( id , text )
    }
    const onFocus=()=>onGetFocus()
    const onBlur=()=>onLeaveFocus()


    const countNumberOfLine=()=>{
        if(!value.length)
            return minNumberOfLines
        else{
            const arrLine= value.split("\n")
            if(arrLine.length<minNumberOfLines)
                return minNumberOfLines
            else if(arrLine.length<maxNumberOfLines)
                return arrLine.length
            else
                return maxNumberOfLines
        }    
    }

    const   direction=isValidNumberString(value) || !isValidPersianName(value , 1 )  ? "LTR" :   ""

    return(
        <div className={`displayFlexRow ${noShadow ? "" : "w3-card"} w3-round   ${containerClassName} ${disabled && !opacityOnDisabledOff ? "w3-opacity"  :"" } `} 
                style= {Object.assign({},{backgroundColor:"#FFFFFF" , padding:5 , border:"1px solid #e0e0e0"}  , {alignItems:"center"  } , containerStyle)}>
            {
                iconName || title ? <div className="displayFlexRow" style={{alignItems:"center"}}>
                                                    {
                                                        iconName ? 
                                                        <div className=" displayFlexRow" style={Object.assign({} , {justifyContent:"center" , alignItems:"center"} , iconContainerStyle)}> 
                                                                    <MyIcon iconName={iconName} color={iconColor} iconSize={iconSize} /> 
                                                        </div>:null
                                                    }
                                                    {
                                                        title ? 
                                                        <MyText smallFont className={`${titleClassName}`} style={Object.assign({} , {color:"#a0a0a0" , marginRight:5} , titleStyle)}>{title}</MyText>:null
                                                    }
                                                </div>: null
             }
             <div className="displayFlexGrow ">
                {
                    multiline ? 
                    <ReactTextareaAutosize  maxLength={maxLength}
                                    value={value}
                                    disabled={disabled} 
                                    placeholder={placeholder} 
                                    onFocus={onFocus}
                                    onBlur={onLeaveFocus}
                                    minRows={minNumberOfLines}
                                    maxRows={maxNumberOfLines}
                                    onChange={e => _onChangeText( e.target.value)}
                                    style={Object.assign({},{width:"100%" , resize:"none" } , inputStyle , fontStyle)}
                                    className={`  w3-border-0 w3-transparent   ${inputClassName}`} /> : 
                    <input type={keyboardType}  ref={_ref}
                            maxLength={maxLength}
                            value={value} 
                            disabled={disabled}
                            placeholder={placeholder} 
                            onFocus={onFocus}
                            onBlur={onLeaveFocus}
                            onChange={e => _onChangeText( e.target.value)}
                            style={Object.assign({},{width:"100%"} , inputStyle , fontStyle , direction ? {direction} :{} )}
                            className={`  w3-border-0 w3-transparent   ${inputClassName}`} />

                }
                
             </div>
            
        </div>
    )
}
export const MyBorderInput= props =>{
    const {containerClassName="" , 
               containerStyle={} , 
               value="" , 
               placeholder="" , 
               title="" , 
               multiline=false , 
               id , 
               hasCharCounter=true , 
               maxLength , 
               onChangeText ,
                footer="" ,
               iconName , 
               minNumberOfLines=1 , 
               maxNumberOfLines=10 ,
               inputContainerClassName="" , 
                inputContainerStyle={} , 
               
               inputStyle={} ,
               inputClassName="w3-right-align"  } =props
    return(
        <div className='w3-border  w3-round-xlarge' style={Object.assign({} , {position:"relative" , padding:"15px 4px 1px 4px" } , containerStyle)}>
            <MyText isBold tinyFont className="w3-border- w3-white  w3-round-xxlarge w3-text-blue" 
                        style={{position:"absolute" , top:-13 , right:15 , padding:"1px 10px"}}>{title}</MyText>
            {
                hasCharCounter ? 
                <MyText smallFont className="w3-border- w3-white  w3-round-xxlarge" 
                                style={{position:"absolute" , top:-13 , left:15 , padding:"1px 10px"}}>{maxLength+" / "+value.length}</MyText> : null
            }
            <MyInput id ={id}    noShadow        smallFont iconName={iconName} iconColor="#e57373"
                        placeholder={placeholder}   
                        value={value}          
                        disabled={false}  
                        onChangeText={onChangeText}       
                        password={false}  
                        maxLength={maxLength} 
                        multiline={multiline}      
                        minNumberOfLines={minNumberOfLines}  
                        maxNumberOfLines={maxNumberOfLines}
                        textAlign='right' 
                        inputStyle={Object.assign({} , {width:"100%" } , inputStyle )}        
                        inputClassName={` ${inputClassName}`}  
                        containerStyle={Object.assign({} , {} , inputContainerStyle)}        
                        containerClassName={`w3-border-0 w3-transparent  ${inputContainerClassName}`} />  
        {
            footer ? <MyText tinyFont isBlock color="#9e9e9e" style={{ borderTop:"1px solid red"}}>{footer}</MyText> : ""
        } 
    </div>
    )
}
//===========================================================================================================================MY INPUT SELECT
export const  MyInputDropdown =(props)=>{

    const { containerStyle={},
             containerClassName="",
            id="",
            value="",
            inputStyle={},
            inputClassName="" , 
            placeholder="",
            placeholderColor="rgba(84,110,121,0.7)",
            underlineColorAndroid="transparent",
            isBold=true,
            keyboardType="default",
            maxLength=500,
            isPassword=false,
            multiline=false,
            numberOfLines=1,
            disabled=false,
            _ref=null,
            onChangeText=()=>{},
            onGetFocus=()=>{},
            onLeaveFocus=()=>{},
            smallFont=false,
            bigFont=false,
            tinyFont=false,
            autoFocus=false,
            returnKeyType="done",
            onActionButtonClick=()=>{} ,
            iconName="",
            iconSize=25,
            iconColor="#456e7a",
            iconContainerStyle={},
            containerDropdownStyle={},
            containerDropdownClassName="",
            dropdownItemClassName="",
            dropdownItemStyle={},
            dropdownType="click" ,   //click--hover
            arrItem=[]
        }=props

        let fontStyle={}
        if(smallFont)
            fontStyle=fontSizeStyle.smallFont;
        if(bigFont)
            fontStyle=fontSizeStyle.bigFont;
        if(tinyFont)
        fontStyle=fontSizeStyle.tinyFont;

    const _onChangeText=(txt)=>{
        const text=txt ? txt.replace(/۰/g , "0")
                            .replace(/۱/g , "1")
                            .replace(/۲/g , "2")
                            .replace(/۳/g , "3")
                            .replace(/۴/g , "4")
                            .replace(/۵/g , "5")
                            .replace(/۶/g , "6")
                            .replace(/۷/g , "7")
                            .replace(/۸/g , "8")
                            .replace(/۹/g , "9") : ""
        onChangeText( id , text )
    }
    const onFocus=()=>onGetFocus(id)
    const onBlur=()=>onLeaveFocus(id)

    return(
        <div className={`displayFlexRow w3-card w3-round w3-small-   ${containerClassName} `} 
                style= {Object.assign({},{backgroundColor:"#FFFFFF" , padding:5 , border:"1px solid #e0e0e0"} , containerStyle , {alignItems:"center"  })}>
            {
                iconName ? <div className=" displayFlexRow" style={Object.assign({} , {justifyContent:"center" , alignItems:"center"} , iconContainerStyle)}> 
                                            <MyIcon iconName={iconName} color={iconColor} iconSize={iconSize} /> 
                                 </div>: null
             }
             <div className="displayFlexGrow ">
                <input type={keyboardType}  ref={_ref}
                            maxLength={maxLength}
                            value={value} 
                            placeholder={placeholder} 
                            // onChangeText={(newText)=>_onChangeText( newText )} 
                            onChange={e => _onChangeText( e.target.value)}
                            style={Object.assign({},{width:"100%"} , inputStyle)}
                            className={`  w3-border-0 w3-transparent w3-right=align ${inputClassName}`} />
             </div>
            
        </div>
    )
}
//===========================================================================================================================
// export const  MyNewInput =(props)=>{

//     const { extraContainerStyle={},
//             id="",
//             value="",
//             extraInputStyle={},
//             placeholder="",
//             placeholderColor="rgba(84,110,121,0.7)",
//             underlineColorAndroid="transparent",
//             isBold=true,
//             keyboardType="default",
//             maxLength=500,
//             isPassword=false,
//             multiline=false,
//             numberOfLines=1,
//             disabled=false,
//             _ref=null,
//             onChangeText=()=>{},
//             smallFont=false,
//             bigFont=false,
//             tinyFont=false,
//             autoFocus=false,
//             returnKeyType="done",
//             onActionButtonClick=()=>{} ,
//         }=props

//         let fontStyle={}
//         if(smallFont)
//             fontStyle=Styles.smallFontSize;
//         if(bigFont)
//             fontStyle=Styles.bigFontSize;
//         if(tinyFont)
//         fontStyle=Styles.tinyFontSize;

//     const _onChangeText=(txt)=>{
//         const text=txt ? txt.replace(/۰/g , "0")
//                             .replace(/۱/g , "1")
//                             .replace(/۲/g , "2")
//                             .replace(/۳/g , "3")
//                             .replace(/۴/g , "4")
//                             .replace(/۵/g , "5")
//                             .replace(/۶/g , "6")
//                             .replace(/۷/g , "7")
//                             .replace(/۸/g , "8")
//                             .replace(/۹/g , "9") : ""
//         onChangeText( id , text )
//     }

//     return(
//         <TextInput style= {Object.assign({}  ,extraInputStyle , fontStyle , {opacity : !disabled ? 1 : 0.6} )} 
//                 placeholder={placeholder} 
//                 multiline={multiline}
//                 numberOfLines={numberOfLines}
//                 keyboardType={keyboardType}
//                 value={value}
//                 ref={_ref}
//                 editable={!disabled}
//                 secureTextEntry={isPassword}
//                 maxLength={maxLength}
//                 underlineColorAndroid={underlineColorAndroid}
//                 placeholderTextColor={placeholderColor}
//                 autoFocus={autoFocus} 
//                 returnKeyType={returnKeyType}
//                 onSubmitEditing={onActionButtonClick}
//                 onChangeText={(newText)=>_onChangeText( newText )} />
//     )
// }
//===========================================================================================================================MY FLATLIST
// export const MyFlatlist=(props)=>{
//     return(
//         <ScrollContainer  >
//             <FlatList showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} {...props}  />
//         </ScrollContainer>
        
//     )
// }
//===========================================================================================================================MY ITEM CONTAINER
export const MyItemContainer=(props)=>{
    const {contentStyle={}}=props
    return(
        <div style={{ display:"flex" ,  padding:"5px 10px", margin:5  , borderRadius:10 , backgroundColor:"#f0f0f0"}} > 
       <MyText isBlock isBold smallFont color="#456e7a" style={{borderBottomWidth:2 , borderColor:"#ef9a9a" , marginBottom:10 }}>{props.title}</MyText>
       {
           props.children
       }
        </div>
    )
}
//===========================================================================================================================IMAGE LOADER INDICATOR
export const ImageLoaderIndicator =(props)=> {
    return ( <CircleIndicator color = "#80CBC4"  style={{display:props.visible===true?"flex":"none"}} />)
}
//===========================================================================================================================LAZY IMAGE
export const  LazyImage=(props)=>{
    const {containerStyle={} , imageStyle={} }=props
    const [visible , setVisible]=useState(false)
    return(
        <div style={Object.assign({} ,{display:"flex" , justifyContent:'center',alignItems:'center' },containerStyle)}>
            <div style={{display:"flex" , position:"absolute" , left:0 , right:0 , bottom:0 , top:0}}>
                <MyImage src={props.source} onLoad={()=>{setVisible(true)}}
                        style={Object.assign({} ,{width:"100%" , height:"100%"} , imageStyle )} 
                         />
            </div>
            <ImageLoaderIndicator visible={!visible} />
        </div>
    )
}
//===========================================================================================================================IMAGE MODAL
// export const ImageModal=forwardRef((props  ,ref)=>{
//     const [visible , setVisible]=useState(false)
//     const [arrImage , setArrImage]=useState([]) //[{url , width , height}]
//     const [imageIndex, setImageIndex]=useState(0)

//     useImperativeHandle(ref , ()=>({
//         show:(_imageInfo=[] , _imageIndex=0)=>{
//             const  width=window.innerWidth
//             const imageWidth=width-10
//             const _arrImage=[]
//             _imageInfo.forEach(image =>{
//                                 const widthPerHeight=_imageInfo.width / _imageInfo.height
//                                 const imageHeight=Math.floor(imageWidth / widthPerHeight)
//                                 _arrImage.push({
//                                     url:image.url,
//                                     width:imageWidth,
//                                     height:imageHeight
//                                 })
//                             })
//             setArrImage(_imageInfo /*_arrImage*/)
//             setImageIndex(_imageIndex)
//             setVisible(true)
//         }
//     }))
//     const hide=()=>{
//         setVisible(false)
//         setArrImage([])
//     }
//     return(
//         <ModalContainer visible={visible}
//                animationType="fade"
//                onBackClick={hide}>

           

//                     <div className="foo">
//                         {arrImage.map((item, index) => (
//                             <Image source={item.url} alt="" />
//                         ))}
//                     </div>


//         </ModalContainer>
//     )
// })
//===========================================================================================================================LAZY CACHE IMAGE
export const  LazyCacheImage=(props)=>{
    const {containerStyle={} , imageStyle={} , }=props
    return(
        <div style={Object.assign({} ,{display:"flex" , flexDirection:'row-reverse',justifyContent:'center',alignItems:'center'},containerStyle)}>
            <MyImage src={props.source} 
                         style={imageStyle} 
                         Spinner={<ImageLoaderIndicator />}  
                         />
        </div>
    )
}
//===========================================================================================================================WAITING
export const Waiting=(props)=>{
    const {title="لطفا منتظر بمانید" , 
           color="#4DB6AC" , 
           flexDirection="column" , 
           marginTop=10,
           marginTopText=10,
           visible=false}=props
    return(
        <div style={{display:"flex" , flexDirection , justifyContent:'center',alignItems:'center' , display:visible?"flex":"none",marginTop}}>
            <Spinner color={color} size="large" />
            {/* <SkypeIndicator size={37} color="teal" />  */}
            <MyText isBlock  smallFont isBold  style={{color,marginRight:flexDirection==="column"?0:10,marginTop:marginTopText}}>{title}</MyText>
        </div>
    )
}
//===========================================================================================================================WAIT MODAL
export const  WaitModal=(props)=>{
    return(
            <div  className={`${props.visible ? "w3-modal" : "w3-hide" }`}
                   style={{display:"flex" ,flexDirection:'row-reverse',justifyContent:'center',alignItems:'center',backgroundColor:'rgba(0,0,0,0.6)',flex:1}} >
                <div style={{ display:"flex" ,  flexDirection:"column" , alignItems:"center" ,  backgroundColor:'white' ,
                                       padding:20,borderRadius:15,borderWidth:1,elevation:6}}>
                    <Spinner size="large" color="#546e7a" />
                    <MyText isBlock  smallFont isBold  style={{color:"#546e7a",marginTop:10}}>لطفا منتظر بمانید</MyText>
                </div>    
            </div>
    )
}
//===========================================================================================================================PAGE MODAL
export const PageModal=forwardRef((props , ref)=>{

    useImperativeHandle(ref , ()=>({
        show:()=>setVisible(true) ,
    }))

    

    const [visible , setVisible]=useState(false)
    const [animation , setAnimation]=useState("slideInRight")

    useEffect(()=>{
        if(animation==="slideOutRight")
           setTimeout(()=>{setVisible(false)} , 800) 
    },[animation])

    const hide=()=>setAnimation("slideOutRight")

    return(
        <ModalContainer visible={visible} 
                        title=""  
                        message="" 
                        showTitle={false}
                        isMessageBold={true}
                        onBackClick={hide  }
                        showBackButton={false} 
                        hasFlatList={false}  
                        closeWithClickBlankPlace={false}  
                        closeWithClickBackButton={true}
                        containerStyle={{padding:0}}
                        contentStyle={{padding:10 , height:"100%" , width:"100%"}}
                        position="center"
                        animation="sideInRight"  
                        animationDuration={700}
                        modalAnimationType="fade" >
            <div style={{display:"flex" , flex:1,elevation:3,borderWidth:1}}>
                    {props.children}
            </div>
        </ModalContainer>
    )
})
//===========================================================================================================================ITEM KEYINPUT
export const  ItemKeyInput =(props)=>{

    const {_key ,keyWidth , keyStyle={} ,  containerStyle={}, isKeyBold=true ,
           keySmallFont=false , keyBigFont=false  ,keyTinyFont=false ,  } = props;
    return(
        <div style={Object.assign({} ,{display:"flex" , flexDirection:'row',alignItems:'flex-start'} , containerStyle)}>
            <MyText isBlock smallFont={keySmallFont} bigFont={keyBigFont} tinyFont={keyTinyFont} 
                    style= {Object.assign({} ,  isKeyBold ? Styles._elements._txtBold:Styles._elements._txt,{}, keyStyle )}>{_key}</MyText>
            <div style={{flex:1 , display:"flex"}}>
            {
                props.children
            }
            </div>
        </div>
    )
}
//===========================================================================================================================ITEM KEYVALUE
export const  ItemKeyValue = (props)=>{

    const {_key,_value ,keyWidth , isKeyBold=true , isValueBold=true , 
          keySmallFont=false , keyBigFont=false  ,keyTinyFont=false , 
          valueSmallFont=false , valueBigFont=false , valueTinyFont=false , 
           keyStyle={} , valueStyle={} , containerStyle={} } = props;


    return(
        <div style={Object.assign({} ,{display:"flex" , flexDirection:'row-reverse',alignItems:'flex-start',flex:1} , containerStyle,)}>
            <MyText isBlock smallFont={keySmallFont} bigFont={keyBigFont} tinyFont={keyTinyFont} 
                    style= {Object.assign({} ,  isKeyBold ? Styles._elements._txtBold:Styles._elements._txt,{}, keyStyle )}>{_key}</MyText>
            <MyText isBlock smallFont={valueSmallFont} bigFont={valueBigFont} tinyFont={valueTinyFont}
                    style= {Object.assign({} ,  isValueBold ? Styles._elements._txtBold:Styles._elements._txt,{flex:1}, valueStyle)}>{_value}</MyText>
        </div>
    )
}
//===========================================================================================================================UPLOAD PROGRES MODAL
// export const UploadProgressModal =(props)=> {
//     const {percentValue} = props;
//     return(

//             <div  className={`${props.visible ? "w3-modal" : "w3-hide"}`}
//                     style= {Object.assign({} , Styles._components._modal.container,{ display:"flex"   ,  backgroundColor:"#rgba(0,0,0,0.7)" , 
//                                                                 justifyContent:"center" , alignItems:"center" })} >
//                 <div style={{display:"flex", flexDirection:"column" , padding:20,borderRadius:10,backgroundColor:"#ffffff" , width:150 }}>
//                     <CircularProgressbar   strokeWidth={8} value={props.percentValue} text={props.percentValue+"%"} />
//                     <MyText isBlock smallFont isBold style={{textAlign:"center",marginTop:20}}>{percentValue!==100  ? "لطفا منتظر بمانید" : 
//                                                                                                                    "لطفا منتظر بمانید"}</MyText>
//                 </div>     
//             </div>
//     )
// }
export const UploadProgressModal =(props)=> {
    const {percentValue , visible} = props;
    return(

            <ModalContainer visible={visible}
                                       title=""
                                       message=""
                                       showBackButton={false}
                                       containerStyle={{}}
                                       containerClassName=""
                                       contentStyle={{}}
                                       contentClassName="" >
                <div style={{display:"flex", flexDirection:"column" , padding:20,borderRadius:10,backgroundColor:"#ffffff" , width:150 }}>
                    <CircularProgressbar   strokeWidth={8} value={props.percentValue} text={props.percentValue+"%"} />
                    <MyText isBlock smallFont isBold style={{textAlign:"center",marginTop:20}}>{percentValue!==100  ? "لطفا منتظر بمانید" : 
                                                                                                                   "لطفا منتظر بمانید"}</MyText>
                </div>     
            </ModalContainer>
    )
}
//===========================================================================================================================
export const PersianCalendar=forwardRef((props , ref)=>{
    const { title=""  ,  message="" }=props
    const [initCompelted , setInitCompeleted]=useState(false)

    const arrMonth=[" فروردین","اردیبهشت","  خرداد","     تیر","   مرداد","  شهریور","     مهر","    آبان","     آذر","      دی","    بهمن","   اسفند"]

    const [visible , setVisible]=useState(false)
    const [arrYear , setArrYear] =useState([])
    const [yearIndex , steYearIndex]=useState(10)
    const [monthIndex , setMonthIndex]=useState(0)
    const [arrDay , setArrDay]=useState([])

    // const dayPanelWidth=Math.floor((width-20-28-32)/7)


    //آماده سازی سالهای موجود
    useEffect(()=>{
        const date=new Date();
        const jdate=new JDate(date)
        const currentYear= parseInt(jdate.getFullYear())
        setMonthIndex(parseInt(jdate.getMonth()-1) )
        const _arrYear=[]
        for(let i= currentYear-10 ; i<currentYear+10 ; i++ )
            _arrYear.push(i)
        setArrYear([..._arrYear])
    } , [])
    //اعلام کامل شدن لود سالها
    useEffect(()=>{
        if(arrYear.length)
            setInitCompeleted(true)
    } , [arrYear])

    useEffect(()=>{
        if(initCompelted){
            const _arrDay=[]
            const jdate=new JDate(arrYear[yearIndex] , monthIndex+1 , 1 )
            const firstDayOfMonth=jdate.getDay()
            if(firstDayOfMonth<6){
                for(let i=0;i<firstDayOfMonth+1;i++)
                    _arrDay.push(" ")
            }
            if(firstDayOfMonth===7)
                _arrDay.push(" ")
            if(monthIndex<6){
                for(let i=1;i<=31;i++)
                    _arrDay.push(i)
            }
            else if(monthIndex<11){
                for(let i=1;i<31;i++)
                    _arrDay.push(i)
            }
            else{
                if(new JDate.isLeapYear(arrYear[yearIndex]))
                    for(let i=1;i<31;i++)
                        _arrDay.push(i)
                else
                    for(let i=1;i<30;i++)
                        _arrDay.push(i)
            }

            for(let i=0;i<42-_arrDay.length ;i++)
                _arrDay.push(" ")

            setArrDay([..._arrDay])
            //آیا ماه 29 روزه است یا 30 روزه
        }
    } , [initCompelted , yearIndex , monthIndex])

    const arrDayName=["ش","ی","د","س","چ","پ","ج"]
    useImperativeHandle(ref , ()=>({
        show : ()=> setVisible(true)  
    }))
    //سال افزایش یافت
    const incYear=()=>{
        if(yearIndex<19)
            steYearIndex(yearIndex+1)
    }
    //سال کاهش یافت
    const decYear=()=>{
        if(yearIndex>0)
            steYearIndex(yearIndex-1)
    }
    //ماه افزایش یافت
    const incMonth=()=>{
        if(monthIndex<11)
            setMonthIndex(monthIndex+1)
    }
    //ماه کاهش یافت
    const decMonth=()=>{
        if(monthIndex>0)
            setMonthIndex(monthIndex-1)
    }
    const hide=()=>setVisible(false)
    const onSelectDate=(day)=>{
        const jdate=new JDate(arrYear[yearIndex] , monthIndex+1 , day )
        const targetDate = JDate.toGregorian(arrYear[yearIndex] , monthIndex+1, day)

        
        const strLongDate=jdate.format('dddd DD MMMM YYYY') // دوشنبه 6 امرداد 1393
        const strDate=jdate.format("YYY-MM-DD")             // 1399-04-11

        const year=targetDate.getFullYear();
        const month=targetDate.getMonth();
        const date=targetDate.getDate();
        const timestamp=new Date(year,month,date,12,0,0,0).getTime()/1000;// UNIX_TIMESTAMP //ساعت 12 ظهر

        props.onSelectDate({strLongDate , strDate ,timestamp})
        hide()
    }
    
    const panelWidth=isDesktop ? 380 :   Math.floor(window.innerWidth) -20
    const dayWidth=Math.floor(panelWidth/7)
    const paddingStyle= isTablet ?  {padding:"10px 0 0 10 "} : {padding:"10px 10px 10px 10px "}

    return(
        <ModalContainer visible={visible}
                    title={title}  
                    message={message}
                    showTitle={title?true : false }
                    isMessageBold={true}
                    onBackClick={hide}  
                    showBackButton={true} 
                    hasFlatList={false}  
                    closeWithClickBlankPlace={false  }
                    closeWithClickBackButton={true}
                    containerStyle={Object.assign({} , { borderWidth:0  } , paddingStyle)}
                    contentStyle={{margin:0,padding:0 , borderWidth:0 , width:panelWidth}}
                    position={isTablet ? "bottom" : "center"}
                    animation={ isTablet  ? "slideInUp" : "zoomIn"}>
            <div style={{display:"flex" , flexDirection:"column"}}>
            <div style={{display:"flex" , flexDirection:"row-reverse" , padding:5 }}>
                <MyButton title="" iconName="cancel" tinyFont
                        buttonStyle={{backgroundColor:"transparent" , borderRadius:5 , padding:1}} 
                        buttonIconStyle={{color:"#e57373" , fontSize:30}} 
                        buttonTextStyle={{color:"#456e7a"}}
                        onClick={hide} />
            </div>
                {/* سال */}
                <div style={{display:"flex" , flexDirection:"row-reverse" , alignItems:"center" ,margin:10  , backgroundColor:"#eeeeee" , padding:0 , borderRadius:10}}>
                
                    <MyButton iconName="arrow_back_ios" 
                                title={yearIndex>0 ? "  "+ arrYear[yearIndex-1]+"  " : "        "} smallFont
                                buttonStyle={{backgroundColor:"#e0e0e0" , padding:"1px 10px",flexDirection:"row" , paddingRight:20 }}
                                buttonIconStyle={{color:yearIndex ? "#456e7a" : "#e0e0e0" ,padding:0 , marginLeft:0 , marginRight:-10}}
                                buttonTextStyle={{color:"#9E9E9E"}}
                                disabled={!yearIndex}
                                onClick={decYear} />

                    <div style={{display:"flex" , flex:1}}>
                        <div style={{display:"flex"}}>
                            <div style={{display:"flex" , flexDirection:"row-reverse"}}>
                                <MyText isBlock isBold color="#00897B" bigFont style={{flex:1 , textAlign:"center"}} >{arrYear[yearIndex]}</MyText>
                            </div>
                        </div>
                    </div>

                    <MyButton iconName="arrow_forward_ios" 
                                title={yearIndex < 19 ? "  "+arrYear[yearIndex+1]+"  " : "        "} smallFont
                                buttonStyle={{backgroundColor:"#e0e0e0" , padding:"1px 10px" ,flexDirection:"row-reverse" , paddingLeft:20 }}
                                buttonIconStyle={{color:yearIndex < 19 ?"#456e7a": "#e0e0e0"   , padding:0 , marginLeft:0 , marginRight:-10}}
                                buttonTextStyle={{color:"#9E9E9E"}}
                                disabled={yearIndex===19}
                                onClick={incYear} />
                </div>
                {/* ماه  */}
                <div style={{display:"flex" , flexDirection:"row-reverse" , alignItems:"center" , padding:2 , marginHorizontal:10 ,  backgroundColor:"#eeeeee" , borderRadius:10}}>

                    <MyButton iconName="arrow_back_ios" 
                                title={monthIndex>0 ? arrMonth[monthIndex-1] : "    "} smallFont
                                buttonStyle={{backgroundColor:"#e0e0e0" , padding:"1px 10px" ,flexDirection:"row" , paddingRight:20 }}
                                buttonIconStyle={{color:monthIndex ? "#456e7a" : "#e0e0e0" ,padding:0 , marginLeft:0 , marginRight:-10}}
                                buttonTextStyle={{color:"#9E9E9E"}}
                                disabled={!monthIndex}
                                onClick={decMonth} />

                    <div style={{flex:1 , display:"flex"}}>
                        <div style={{display:"flex"}} >
                            <div style={{flexDirection:"row-reverse" , display:"flex"}}>
                                <MyText isBlock isBold color="#00897B" style={{flex:1 , textAlign:"center"}}  >{arrMonth[monthIndex]}</MyText>
                            </div>
                        </div>
                    </div>

                    <MyButton iconName="arrow_forward_ios" 
                                title={monthIndex < 11 ? arrMonth[monthIndex+1] : "    "} smallFont
                                buttonStyle={{backgroundColor:"#e0e0e0" , padding:"1px 10px",flexDirection:"row-reverse" , paddingLeft:20 }}
                                buttonIconStyle={{color:monthIndex < 11 ?"#456e7a": "#e0e0e0"   , padding:0 , marginLeft:0 , marginRight:-10}}
                                buttonTextStyle={{color:"#9E9E9E"}}
                                disabled={monthIndex===11}
                                onClick={incMonth} />
                </div>
                {/* روزهای هته */}
                <div className="" style={{}}>
                    <div style={{display:"flex" , flexDirection:"row"   , justifyContent:"space-between" , alignItems:"flex-start" , marginBottom:10}}>
                    {
                        arrDayName.map((day , index) => <div key={index} style={{display:"flex" , width:dayWidth  , padding:2 }}>
                                                                                <MyText isBlock style={{flex:1 , textAlign:"center" , fontWeight:"bolder" , marginTop:5 }}  >{day}</MyText>
                                                                            </div>)
                    }
                    </div>
                    <div style={{display:"flex" , flexDirection:"row", flexWrap:"wrap"  ,justifyContent:"space-between" , alignItems:"flex-start" }}>
                    {
                        arrDay.map((day , index) =><div key={index} style={{display:"flex" , width:dayWidth-2 , height:dayWidth , padding:"4px 3px", marginVertical:3 }}> 
                                                        <MyButton key={index} 
                                                            onClick={()=>onSelectDate(day)} 
                                                            disabled={day===" "} 
                                                            title={day}
                                                            buttonTextStyle={{color:"#456e7a"}}
                                                            buttonStyle={{justifyContent:"center" , height:dayWidth-2 , width:"100%" ,borderRadius:dayWidth , borderWidth:0 , 
                                                                             
                                                                            backgroundColor:day===" " ? "transparent" : index%7===6 ? "#ffcdd2" : "#B2DFDB"}} />
                                                    </div>
                                                )
                    }
                    </div>
                    
                </div>

                
            </div>
            
        </ModalContainer>
    )
})
export const PersianDatePicker=forwardRef((props , ref)=>{
    const [initCompelted , setInitCompeleted]=useState(false)

    const arrMonth=[" فروردین","اردیبهشت","  خرداد","     تیر","   مرداد","  شهریور","     مهر","    آبان","     آذر","      دی","    بهمن","   اسفند"]

    const [visible , setVisible]=useState(true)
    const [arrYear , setArrYear] =useState([])
    const [yearIndex , steYearIndex]=useState(10)
    const [monthIndex , setMonthIndex]=useState(0)
    const [arrDay , setArrDay]=useState([])

    // const dayPanelWidth=Math.floor((width-20-28-32)/7)


    //آماده سازی سالهای موجود
    useEffect(()=>{
        const date=new Date();
        const jdate=new JDate(date)
        const currentYear= parseInt(jdate.getFullYear())
        setMonthIndex(parseInt(jdate.getMonth()-1) )
        const _arrYear=[]
        for(let i= currentYear-10 ; i<currentYear+10 ; i++ )
            _arrYear.push(i)
        setArrYear([..._arrYear])
    } , [])
    //اعلام کامل شدن لود سالها
    useEffect(()=>{
        if(arrYear.length)
            setInitCompeleted(true)
    } , [arrYear])

    useEffect(()=>{
        if(initCompelted){
            const _arrDay=[]
            const jdate=new JDate(arrYear[yearIndex] , monthIndex+1 , 1 )
            const firstDayOfMonth=jdate.getDay()
            if(firstDayOfMonth<6){
                for(let i=0;i<firstDayOfMonth+1;i++)
                    _arrDay.push(" ")
            }
            if(firstDayOfMonth===7)
                _arrDay.push(" ")
            if(monthIndex<6){
                for(let i=1;i<=31;i++)
                    _arrDay.push(i)
            }
            else if(monthIndex<11){
                for(let i=1;i<31;i++)
                    _arrDay.push(i)
            }
            else{
                if(new JDate.isLeapYear(arrYear[yearIndex]))
                    for(let i=1;i<31;i++)
                        _arrDay.push(i)
                else
                    for(let i=1;i<30;i++)
                        _arrDay.push(i)
            }

            for(let i=0;i<42-_arrDay.length ;i++)
                _arrDay.push(" ")

            setArrDay([..._arrDay])
            //آیا ماه 29 روزه است یا 30 روزه
        }
    } , [initCompelted , yearIndex , monthIndex])

    const arrDayName=["ش","ی","د","س","چ","پ","ج"]
    useImperativeHandle(ref , ()=>({
        show : ()=> setVisible(true)  
    }))
    //سال افزایش یافت
    const incYear=()=>{
        if(yearIndex<19)
            steYearIndex(yearIndex+1)
    }
    //سال کاهش یافت
    const decYear=()=>{
        if(yearIndex>0)
            steYearIndex(yearIndex-1)
    }
    //ماه افزایش یافت
    const incMonth=()=>{
        if(monthIndex<11)
            setMonthIndex(monthIndex+1)
    }
    //ماه کاهش یافت
    const decMonth=()=>{
        if(monthIndex>0)
            setMonthIndex(monthIndex-1)
    }
    const hide=()=>setVisible(false)

    const onSelectDate=(day)=>{
        const jdate=new JDate(arrYear[yearIndex] , monthIndex+1 , day )
        const targetDate = JDate.toGregorian(arrYear[yearIndex] , monthIndex+1, day)

        
        const strLongDate=jdate.format('dddd DD MMMM YYYY') // دوشنبه 6 امرداد 1393
        const strDate=jdate.format("YYY-MM-DD")             // 1399-04-11

        const year=targetDate.getFullYear();
        const month=targetDate.getMonth();
        const date=targetDate.getDate();
        const timestamp=new Date(year,month,date,12,0,0,0).getTime()/1000;// UNIX_TIMESTAMP //ساعت 12 ظهر

        props.onSelectDate({strLongDate , strDate ,timestamp})
        hide()
    }


    const panelWidth=isDesktop ? 70 :   Math.floor(window.innerWidth) -20
    const dayWidth=Math.floor(panelWidth/7)

    return(
            <div className="w3-white w3-card-4 w3-round-large w3-show-inline-block" style={{backgroundColor:"#FFFFFF"}}>
                {/* سال */}
                <div style={{display:"flex" , flexDirection:"row" , alignItems:"center" ,margin:10  , backgroundColor:"#FFFFFF" , padding:0 , borderRadius:10}}>
                
                    <MyButton iconName="arrow_back_ios" 
                                title={yearIndex>0 ? "  "+ arrYear[yearIndex-1]+"  " : "        "} smallFont
                                buttonStyle={{backgroundColor:"#FFFFFF" , padding:"1px 10px" ,flexDirection:"row-reverse" , paddingRight:20 }}
                                buttonIconStyle={{color:yearIndex ? "#00bcd4" : "#e0e0e0" ,padding:0 , marginLeft:0 , marginRight:10 , fontSize:20}}
                                buttonTextStyle={{color:"#9E9E9E"}}
                                disabled={!yearIndex}
                                onClick={decYear} />

                    <div style={{display:"flex" , flex:1}}>
                        <div style={{display:"flex"}}>
                            {/* <div>{yearIndex}</div> */}
                            <div style={{display:"flex"}}>
                                {
                                    arrYear.length && yearIndex!==null ? 
                                    <div isBold color="#00897B"  style={{flex:1 , textAlign:"center"}} >{arrYear[yearIndex]}</div>  
                                    : null
                                }
                            </div>
                        </div>
                    </div>

                    <MyButton iconName="arrow_forward_ios" 
                                title={yearIndex < 19 ? "  "+arrYear[yearIndex+1]+"  " : "        "} smallFont
                                buttonStyle={{backgroundColor:"#FFFFFF" , padding:"1px 10px" ,flexDirection:"row" , paddingLeft:20 }}
                                buttonIconStyle={{color:yearIndex < 19 ?"#00bcd4": "#e0e0e0"   , padding:0 , marginLeft:0 , marginLeft:10,fontSize:20 }}
                                buttonTextStyle={{color:"#9E9E9E"}}
                                disabled={yearIndex===19}
                                onClick={incYear} />
                </div>
                {/* ماه  */}
                <div style={{display:"flex" , flexDirection:"row" , alignItems:"center" , padding:2 , marginHorizontal:10 ,  backgroundColor:"#FFFFFF" , borderRadius:10}}>
                    <MyButton iconName="arrow_back_ios" 
                                title={monthIndex>0 ? arrMonth[monthIndex-1] : "    "} smallFont
                                buttonStyle={{backgroundColor:"#FFFFFF" , padding:"1px 10px",flexDirection:"row-reverse" , paddingRight:20 }}
                                buttonIconStyle={{color:monthIndex ? "#00bcd4" : "#e0e0e0" ,padding:0 , marginLeft:0 , marginRight:10 , fontSize:20}}
                                buttonTextStyle={{color:"#9E9E9E"}}
                                disabled={!monthIndex}
                                onClick={decMonth} />

                    <div style={{display:"flex" , flex:1}}>
                                <MyText isBlock isBold  style={{flex:1 , textAlign:"center"}}  >{arrMonth[monthIndex]}</MyText>
                    </div>

                    <MyButton iconName="arrow_forward_ios" 
                                title={monthIndex < 11 ? arrMonth[monthIndex+1] : "    "} smallFont
                                buttonStyle={{backgroundColor:"#FFFFFF" , padding:"1px 10px" ,flexDirection:"row" , paddingLeft:20 }}
                                buttonIconStyle={{color:monthIndex < 11 ?"#00bcd4": "#e0e0e0"   , padding:0 , marginLeft:0 , marginLeft:10 , fontSize:20}}
                                buttonTextStyle={{color:"#9E9E9E"}}
                                disabled={monthIndex===11}
                                onClick={incMonth} />
                </div>
                {/* روزهای هته */}
                <div className="" style={{}}>
                    <div style={{display:"flex" , flexDirection:"row-reverse"   , justifyContent:"space-between" , alignItems:"flex-start" , marginBottom:10}}>
                    {
                        arrDayName.map((day , index) => <div key={index} style={{display:"flex" , width:dayWidth  , padding:2  , flex:1 }}>
                                                                                <MyText isBlock isBold style={{flex:1 , textAlign:"center" , marginTop:5  }}  >{day}</MyText>
                                                                            </div>)
                    }
                    </div>
                    <div style={{flexDirection:"row", flexWrap:"wrap"  ,justifyContent:"space-between" , alignItems:"flex-start" }}>
                    {
                        arrDay.map((day , index) =><div key={index} style={{display:"flex" , width:dayWidth-2 , height:dayWidth ,padding:"4px 3px" , marginVertical:3 }}> 
                                                                        <span style={{justifyContent:"center" , height:dayWidth-2 , width:"100%" ,borderRadius:dayWidth , borderWidth:0 , 
                                                                                            
                                                                                            backgroundColor:day===" " ? "transparent" : index%7===6 ? "#ffcdd2" : "#B2DFDB"}}>
                                                                        {day}
                                                                        </span >
                                                                        {/* <MyButton key={index} 
                                                                            onClick={()=>onSelectDate(day)} 
                                                                            disabled={day===" "} 
                                                                            title={day}
                                                                            buttonTextStyle={{color:"#456e7a"}}
                                                                            buttonStyle={{justifyContent:"center" , height:dayWidth-2 , width:"100%" ,borderRadius:dayWidth , borderWidth:0 , 
                                                                                            
                                                                                            backgroundColor:day===" " ? "transparent" : index%7===6 ? "#ffcdd2" : "#B2DFDB"}} /> */}
                                                                    </div>
                                                )
                    }
                    </div>
                    
                </div>

                
            </div>
    )
})
//===========================================================================================================================
export const MySkeleton=(props)=>{
    const {style={} , circle=false , inline=false , width=10 , height=10 , baseColor="#cfd8dc" ,  highlightColor="#f4f4f4"  }=props
    return(
        <SkeletonTheme  baseColor={baseColor} highlightColor={highlightColor}>
             <Skeleton className="" style={Object.assign({} , style)} circle={circle} inline={inline} width={width} height={height}  />
        </SkeletonTheme>
    )
}
//===========================================================================================================================
export const MyTimePicker=(props)=>{
    const {visible}= props

    // const [timeOptions , setTimeOption]=useState({hour:[] , min:[]})
    // const [selectedTimeOptions , setSelectedTimeOptions]=useState({hour:'12' , min:"00"})

    // useEffect(()=>{
    //     const arrHour=[]
    //     const arrMin=[]
    //     for(let i=0;i<24 ;i++)
    //         arrHour.push(i<10 ? "0"+i : i )
    //     for(let i=0;i<60 ;i++)
    //         arrMin.push(i<10 ? "0"+i : i )
    //     setTimeOption({hour:[...arrHour]  , min:[...arrMin]})
    // },[])

    // const handleChange = (name, value) => {
    //     setSelectedTimeOptions({
    //         ...selectedTimeOptions,
    //         [name] : value
    //     })
    // };
    
    return (
        <ModalContainer visible={false}>
            {/* <Picker
                    optionGroups={timeOptions}
                    valueGroups={selectedTimeOptions}
                    onChange={handleChange} /> */}
        </ModalContainer>
        
      
    
     
    )
}
//===========================================================================================================================
export const MyTimeRangePicker=forwardRef((props , ref)=>{

    const {width = window.innerWidth-40}=props

    const [visible , setVisible]=useState(false)
    const [selectedRangeTime , setSelectedRangeTime]=useState({startTime :'' , endTime:''})
    const [realSelectedRangTime , setRealSelectedRangTime] = useState({startTime:'' , endTime:'' })

    const hide=()=>setVisible(false)

    useImperativeHandle(ref , ()=>({
        show:(startTime=0 , endTime=7200)=>{
            setVisible(true)
            setRealSelectedRangTime({startTime , endTime})
            convertRealTimeToTime(startTime , endTime)
        }
    }))

    const converTimeToRealTime=(time , targetTime)=> setRealSelectedRangTime({...realSelectedRangTime , [targetTime]: time*900  })
    const convertRealTimeToTime=(startTime , endTime)=>{
        setSelectedRangeTime({startTime : startTime/900 , endTime : endTime/900 })
    }

    const onChangeStartTime=(time)=>{
        setSelectedRangeTime({...selectedRangeTime , startTime:time})
        converTimeToRealTime(time , "startTime")
    }
    const onChangeEndTime=(time)=>{
         setSelectedRangeTime({...selectedRangeTime , endTime:time})
         converTimeToRealTime(time , "endTime")
    }
    
    const panelWidth=width

    const onSelectTime=()=>{
        hide()
        props.onSelectTime(realSelectedRangTime)
    }


    return(
        visible ? 

        <ModalContainer visible={visible}
                        showTitle={false}
                        showBackButton={true}
                        onBackClick={hide}
                        containerStyle={{padding:0 , justifyContent:"center" , alignItems:"center" }}
                        contentStyle={{padding:0, width    , borderWidth:2 , borderColor:"blue",
                                       borderRadius:panelWidth , borderTopLeftRadius:0}}
                        animation="zoomIn" >
        <div style={{display:"flex" , width:panelWidth , height:panelWidth  , padding:45 , justifyContent:"center" , alignItems:"center"}}>
            <div style={{display:"flex" , position:"absolute" , top:0 , right:0 , left:0 , bottom:0 , zIndex:3  ,justifyContent:"center" , alignItems:"center"}}>
                <CircularSlider
                                size={width}
                                handle1={{  value: selectedRangeTime.startTime , onChange: onChangeStartTime }}
                                handle2={{  value: selectedRangeTime.endTime   , onChange : onChangeEndTime }}
                                arcColor="#00bcd4"
                                minValue={0}
                                arcBackgroundColor="#e0e0e0"
                                maxValue={96}
                                coerceToInt
                                startAngle={0}
                                endAngle={360}
                                handleSize={10}  />
            </div>
            <div style={{zIndex:4,display:"flex"}}>
                <ItemKeyValue _key="ساعت شروع :  " _value={ getTimeFromTimestamp(realSelectedRangTime.startTime , true ) }
                            keySmallFont
                            valueSmallFont
                            keyIsBold
                            valueIsBold 
                            containerStyle={{}}
                            keyStyle={{color:"#456e7a"}}
                            valueStyle={{color:"#e57373"}} />
                <ItemKeyValue _key="ساعت پایان :  " _value={ getTimeFromTimestamp(realSelectedRangTime.endTime , true ) }
                            keySmallFont
                            valueSmallFont
                            keyIsBold
                            valueIsBold 
                            containerStyle={{}}
                            keyStyle={{color:"#456e7a"}}
                            valueStyle={{color:"#e57373"}} />
                <MyButton onClick={onSelectTime}
                          smallFont
                          buttonStyle={{backgroundColor:"#e0e0e0" , justifyContent:"center" , marginTop:10 , borderWidth:1 , borderColor:"#a0a0a0"}}
                  buttonTextStyle={{color:"#456e7a"}}
                  buttonIconStyle={{color:"#e57373"}}

                title="ثبت زمان" />
               
            </div>
            <div style={{display:"flex" , position:"absolute" , top:40 , right:40 , left:40 , bottom:40 , zIndex:0}} >
                <MyImage source={imgWatch} style={{width:"100%" , height:"100%"}} />
            </div>
        </div>
        {/* <MyButton onClick={onSelectTime}
                  iconName="check"
                  title="ثبت زمان انتخاب شده"
                  buttonStyle={{backgroundColor:"#e0e0e0" , justifyContent:"center"}}
                  buttonTextStyle={{color:"#456e7a"}}
                  buttonIconStyle={{color:"#e57373"}} /> */}
        {/* </View> */}
        
        
        {/* </ImageBackground> */}
        
        </ModalContainer> : null
    )
})
//===========================================================================================================================
export const MyCamera=forwardRef((props , ref)=>{
    const {onGetImage=()=>{} , onErrorImGetImage=()=>{} ,onBackClick=()=>{}  }=props

    const [image ,setImage] =useState("")

    useEffect(()=>{
        if(image){
            returnImageFile()
        }
    } , [image])

    const returnImageFile=()=>{
        setVisible(false)
            if(mustReturnFile)
            { 
                fetch(image).then(res => res.blob())
                            .then(blob =>{
                                const newFile= new File([blob] , "file_"+new Date().getTime().toString()+".jpeg" , {type : "image/jpg"})
                                                    // console.log(newFile)
                                                    onGetImage(newFile)
                            } )
            }
            else{
                onGetImage(image)
            }
    }

    const webcamRef=useRef()
    const [visible , setVisible]=useState(false)
    const [cameraImageDiamention , setCameraImageDiamention]=useState({width:500 , height : 500})
    const [screenShotDiamention , setScreenShotDiamention]=useState({width:0 , height : 0})
    const [mustReturnFile , setMustReturnFile]=useState(false)
    const [faceMode , setFaceMode]=useState("user")

    useImperativeHandle(ref , ()=>({
        show:(_width=0 , _height=0 , _mustReturnFile=false , _screenShotWidth=600 , _screenShotHeight=600)=>{
            const width=window.innerWidth
            const height=window.innerHeight
            // console.log(width+"---"+height)
            setImage("")
            if(!_width){
                const target= (width < height ? width : height)//*80/100
                // console.log(target)
                setCameraImageDiamention({width:target , height:target})
            }
            else
                setCameraImageDiamention({width:_width , height:_height})
            
            if(_screenShotWidth)
                setScreenShotDiamention({width:_screenShotWidth , height:_screenShotHeight})


            setMustReturnFile(_mustReturnFile)
            setVisible(true)
            setFaceMode("environment")
        }
    }))

    // const capture = React.useCallback( 
    //     async () => {
    //         alert(JSON.stringify(screenShotDiamention))
    //         const imageSrc = await  webcamRef.current.getScreenshot(screenShotDiamention);
    //         setImage(imageSrc)
    //     },
    //     [webcamRef]
    // );

    const capture=async () => {
                // alert(JSON.stringify(screenShotDiamention))
                const imageSrc = screenShotDiamention.width ?  await  webcamRef.current.getScreenshot(screenShotDiamention) : await  webcamRef.current.getScreenshot()
                setImage(imageSrc)
            }

    const funcErrorInGetCamera=(err)=>onErrorImGetImage(err)
    const toggleFaceModa=()=>setFaceMode(faceMode==="user" ? { exact: "environment" } : "user")
    const hide=()=>{
        onBackClick()
        setVisible(false)
    }

    const videoConstraints = {
        // width: { min: 480  },
        // height: { min: 720 },
        // width:cameraImageDiamention.width,
        // height:cameraImageDiamention.height,
        facingMode:  faceMode,
    };

    return (
        visible ? 
        <ModalContainer visible={visible} 
                                   containerStyle={{}}
                                   containerClassName="displayFlexRow "
                        contentStyle={{width:"100%"}}
                        contentClassName="displayFlexRow displayFlexCenter displayFlexGrow w3-transparent"
                        showTitle={false}
                        position="center"
                        animation="fadeIn"  >


            <div className=" displayFlexCenter displayFlexRow w3-col l6 m8 s10 w3-transparent w3-round-xlarge" style={{padding:5 , overflow:"hidden"}}>
                <Webcam ref={webcamRef }
                        audio={false}
                        imageSmoothing={true}
                        videoConstraints={videoConstraints}
                        screenshotFormat="image/jpg"
                        onUserMediaError={funcErrorInGetCamera}
                        // width={cameraImageDiamention.width}
                        // height={cameraImageDiamention.height}
                        width="100%"
                        height="100%"
                        className=" displayFlexRow displayFlexCenter "
                        style={{alignItems:"center" , justifyContent:"center", backgroundColor:"#e57373"}}  />
            </div>



            <ActionButton marginFromEadge={20} marginFromBottom={20} backgroundColor="#f5f5f5" 
                    position="right" iconColor="#e57373" actIcon="close" onClick={hide} /> 
            <ActionButton marginFromEadge={80} marginFromBottom={20} backgroundColor="#f5f5f5" 
                    position="right" iconColor="teal" actIcon="flip_camera_ios" onClick={toggleFaceModa} /> 
            <ActionButton marginFromEadge={20} marginFromBottom={20} backgroundColor="#f5f5f5" 
                    position="left" iconColor="#e57373" actIcon="camera" onClick={capture} />

          

        </ModalContainer>  : null
    )
    // return   <Camera onTakePhoto = { (dataUri) => onGetImage(dataUrl) } />
})
//===========================================================================================================================
export const MyFabs=(props)=>{
    const {arrButton=[/* [{title , icon , onClick }] */] , position="right" , iconName="" , color="#456e7a" , iconColor="#FFFFFF" ,disabled=false } = props
    const [visible , setVisible]=useState(false)

    const hide=()=>setVisible(false)
    return(
        <div style={Object.assign({} , {display:"flex" , position:"absolute" , flexDirection:"column-reverse" , bottom:20 } , position==="right" ? {right:20} : {left:20} )}>
            <div style={{flexDirection:"row" , display:"flex"}}>
            <MyButton iconName={iconName} 
                      disabled={disabled}
                      buttonStyle={{padding:10, borderRadius:40 , backgroundColor:color }}
                      buttonIconStyle={{color:iconColor}}
                      onClick={()=>setVisible(!visible)} />
            </div>
            
            {
                visible ?
                <div className="w3-animate-opacity" style={{marginBottom:10}}>
                    {
                        arrButton.map((btn , index)=> <div key={index} style={{display:"flex",flexDirection:"column" ,marginBottom:10,marginRight:10 }}>
                                                                                <button activeOpacity={0.6} onPress={()=>{hide() ; btn.onClick()}}>
                                                                                    <div style={{display:"flex" , flexDirection:"row" , alignitems:"center"}}>
                                                                                        <MyButton iconName={btn.icon} 
                                                                                                buttonStyle={{borderRadius:30 , marginLeft:3 , padding:"4px 3px", backgroundColor:"#00bcd4"}}
                                                                                                buttonIconStyle={{fontSize:25}} />
                                                                                        <div style={{display:"flex" , justifyContent:"center"}}>
                                                                                            <MyText isBlock isBold tinyFont style={{borderRadius:20 , verticalAlign:"center" , color:"#FFFFFF" , backgroundColor:"#37474f" , 
                                                                                                                                padding:"4px 10px"}} >{btn.title}</MyText>
                                                                                        </div>
                                                                                        
                                                                                    </div>
                                                                                </button>
                                                                            </div>)
                    }
                </div> 
                 : null
            }
            
        </div>
    ) 
}
//===========================================================================================================================
export const Pageing=(props)=>{
    const {  activeIndex=0 , color="#eeeeee" , activeColor="#e0e0e0" , numberOfDots=0 }= props
    

    const initDots=()=>{
        const arrDot=[]
        for(let i=0;i<numberOfDots ; i++)
            arrDot.push(i)
        return arrDot
    }
    return(
        <div className="displayFlexRow displayFlexCenter" style={{ padding:"10px 0"}}>
            {
                initDots().map( (dot , index)=>   <div key={index} style={{ width:10 , height:10 , borderRadius:10 , marginRight:4  , backgroundColor:index !== activeIndex ? color : activeColor }} />  )
            }
        </div>
    )
}
//===========================================================================================================================
export const ImageModal= forwardRef((props , ref) =>{

    const zoomRef = React.useRef(null);
    
    const [arrImage , setArrImage]=useState([])

    const [finite, setFinite] = React.useState(false);
    const [index, setIndex] = React.useState(0);
    // const [count, setCount] = React.useState(arrImage.length);
    const [preload, setPreload] = React.useState(2);
    const [fade, setFade] = React.useState(350);
    const [swipe, setSwipe] = React.useState(500);
    const [navigation  , setNavagation]=useState(300)
    const [imageFit, setImageFit] = React.useState('contain' );
    const [renderPrev, setRenderPrev] = React.useState(false);
    const [renderNext, setRenderNext] = React.useState(false);
    const [closeOnPullUp, setCloseOnPullUp] = React.useState(false);
    const [closeOnPullDown, setCloseOnPullDown] = React.useState(true);
    const [closeOnBackdropClick, setCloseOnBackdropClick] = React.useState(false);
    const [padding, setPadding] = React.useState('16px');
    const [spacing, setSpacing] = React.useState('10%');
    const [backgroundColor , setBackgroundColor]=useState("#000")

    const [visible  ,setVisible]=useState(false)

    useImperativeHandle(ref , ()=>({
        show: (arrImage , imageIndex , backgroundColor="rgba(0  , 0 , 0 , 0.8)")=>{
            setArrImage(arrImage)
            setIndex(imageIndex)
            setVisible(true)
            setBackgroundColor(backgroundColor)
        }
    }))

    return(
        visible ? 
        <Lightbox        open={visible}
                                close={() => setVisible(false)}
                                index={index}
                                slides={arrImage.map(image => ({src:image  }))}
                                carousel={{
                                finite,
                                preload,
                                imageFit,
                                padding,
                                spacing,
                                }}
                                toolbar={{
                                    buttons:["close"]
                                }}
                                controller={{ closeOnPullDown , closeOnPullUp:true, closeOnBackdropClick }}
                                animation={{ fade, swipe  }}
                                on={{
                                // view: (index) => console.log('View', index),
                                // entering: () => console.log('Entering'),
                                // entered: () => console.log('Entered'),
                                // exiting: () => console.log('Exiting'),
                                // exited: () => console.log('Exited'),
                                }}
                                className="w3-white"
                                styles={{
                                    container:{backgroundColor}
                                }}
                                render={{
                                    buttonPrev: renderPrev ? undefined : () => null,
                                    buttonNext: renderNext ? undefined : () => null,
                                }}
                                plugins={[Zoom , ]}
                                zoom={{ ref: zoomRef , maxZoomPixelRatio:5 }}
                                // inline={{
                                //     style: { width: "100%" ,  maxWidth: "2000px", aspectRatio: "3 / 2" },
                                //   }}

                            /> : null
    )
})
//===========================================================================================================================
export const ImagePaging=(props)=>{
    const {numberOfPages=0 , currentPageIndex=0 }=props
    const arrDots=[]

    const initDots=()=>{
        const arrDots=[]
        for( let i=0 ; i < numberOfPages ; i++ )
            arrDots.push(i)
        return arrDots
    }

    return(
        <div className="displayFlexRow w3-padding-small " style={{justifyContent:"center"}}>
            {
                initDots().map((dot , index) => <div key={dot} 
                                                     className="" 
                                                     style={{width:10 , height:10 , borderRadius:10 , backgroundColor: currentPageIndex==index ?"#757575" :  "#bdbdbd"  , margin:"0 3px" }} />)
            }
        </div>
    )
}
//============================================================================================================================
export const MyTable=(props)=>{
    const { arrHeader=[], arrData=[] , thStyle={} , thClassName="" , trStyle={} , trClassName="" , style={} , className="" }=props
    return(
            <table className={` ${className}`} style={Object.assign({} ,{} , style)} >
                <thead>
                    <tr className="">
                        {
                            arrHeader.map( (header , index) => <th key={index} className="  w3-right-align">{header}</th> )
                        }
                    </tr>
                </thead>
                <tbody>
                        <tr>
                            {
                                
                            }
                        </tr>
                </tbody>
            </table>
    )
}
//=============================================================================================================================
// export const TimePicker=(props)=>{
//     const {id="" , onSelectDate=()=>{} , onCancel=()=>{} , visible=false}=props
//     const onSelect=(dateTime)=>{
//         onSelectDate(id , dateTime)
//     }
//     const onEnd=()=>{
//         onCancel(id)
//     }
//     return(  
//         <DateTimePickerModal
//                 isVisible={visible}
//                 mode="time"
//                 onConfirm={onSelect}
//                 onCancel={onEnd}
//             />
//     )
// }
//==========================================================================================================================
export const MyImageCropper=forwardRef((props , ref)=>{
    const refPanelWidth=useRef()

    const [panelWidth , setPanelWidth]=useState(0)
    useEffect(()=>{
        if(refPanelWidth.current)
            setPanelWidth(refPanelWidth.current.offsetWidth)
    } , [refPanelWidth.current])

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);

    const [image , setImage]=useState(null)
    const [visible , setVisible]=useState(false)

    const [cropSize , setCropSize]=useState({width:500 , height:500 , isCircle:true})

    useImperativeHandle(ref , ()=>({
            show:(_image , width=400 , height=400 , isCircle=true)=>{
                setImage(_image)
                setCropSize({width , height , isCircle})
                setVisible(true)
            }
    }))
    const hide=()=>{
        setVisible(false)
        setImage(null)
        setCroppedImage(null)
    } 
    //--------------------------------------------------------------------------------
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {  setCroppedAreaPixels(croppedAreaPixels); }, []);

    const corpedImageAndShow = useCallback(async () => {
        try {
          const croppedImage = await getCroppedImg(
            image,
            croppedAreaPixels,
            rotation
          )
        hide()
          setCroppedImage(croppedImage)


          const config = { responseType: 'blob' };
        axios.get(croppedImage, config)
        .then(response => {
           props.onSelectCroppedImage(response.data)
        })
        .catch(error => {
            alert("در دریافت فایل تصویر خطایی روی داده است .!!!")
        })



        //   props.onSelectCroppedImage(croppedImage)
        } catch (e) {
          console.error(e)
        }
      }, [croppedAreaPixels, rotation])
    
    return (
        <ModalContainer visible={visible} 
                                   containerStyle={{}}  
                                   showTitle={false} 
                                   animation="fadeIn"
                                   contentStyle={{width:"100%" , height:"100%" , backgroundColor:"transparent"}}
                                   contentClassName="displayFlexCol">
            <div className="displayFlexGrow w3-col l4 m6 s12" ref={refPanelWidth} >
                        <Cropper      image={image}
                                            crop={crop}
                                            zoomSpeed={0.04}
                                            minZoom={0.3}
                                            rotation={rotation}
                                            zoom={zoom}
                                            aspect={4 / 4}
                                            cropSize={{
                                                                width:Math.floor(panelWidth*90/100), //cropSize.width ,
                                                                    height:Math.floor(panelWidth*90/100)//cropSize.height
                                                            }}
                                            onCropChange={setCrop}
                                            cropShape={cropSize.isCircle ? "round" : ""}
                                            onRotationChange={setRotation}
                                            onCropComplete={onCropComplete}
                                            onZoomChange={setZoom}
                                            objectFit="horizontal-cover"  />
            </div>
           <div className="displayFlexRowReverse">
                <div className="w3-round-large w3-white w3-padding displayFlexRow" style={{   bottom:10 , left:10 ,zIndex:2 }}>
                        <MyButton iconName="close" onClick={hide}
                                        buttonStyle={{backgroundColor:"#e57373" , borderRadius:20 ,}} />
                        <MyButton iconName="check"  onClick={corpedImageAndShow} title=""
                                        buttonStyle={{backgroundColor:"#00bcd4" , borderRadius:20 , marginRight:15}} />
                    </div>
           </div>
                        

            
        </ModalContainer>
    )
})
//===============================================================================================================================
export const MySlideShow=(props)=>{
    const { arrImage = [ /* url  , overlayComponent , onClickImage=()=>{}  , buttonTitle="" */ , ] , 
                width="100%", height="100%" , className="" , style={} , autoSlide=false  , zoomAble=true }=props

    const mdlSlideShow=useRef()

    const [imageIndex , setImageIndex] =useState(0)

    let refTimeout=null
    useEffect(()=>{
        if(autoSlide){
            refTimeout=null
            refTimeout = setTimeout(()=>{
                    setImageIndex(  imageIndex<arrImage.length-1 ? imageIndex+1 : 0 )
            } , 5000)
        }
                  
        return ()=>refTimeout ? refTimeout=null : null
    } , [imageIndex ])

    const changeIndexOfImage=(index)=>{
        if(refTimeout)
            clearTimeout(refTimeout)
            setImageIndex(index)
    }

    const showOverlaySlider=(index)=>   mdlSlideShow.current.show(arrImage.filter(image => image.url.indexOf(".mp4")===-1).map(image=>image.url) , index)


    return(
        <div className={` ${className} `} style={Object.assign({} , { width , height } , style ,{position:"relative"})}>

                {
                    arrImage.length>1 && isDesktop ? 
                    <div>
                        <div className=" w3-padding-small w3-display-right" style={{zIndex:4 }}>
                            <MyButton  disabled={!imageIndex} onClick={()=>setImageIndex(imageIndex-1)}
                                            title='' 
                                            iconName='arrow_forward_ios' 
                                            buttonStyle={{backgroundColor:"rgba(255,255,255,0.5)" ,}}
                                            buttonIconStyle={{color:"#909090" }}
                                            buttonTExtStyle={{}} />
                        </div>
                        <div className=" w3-padding-small w3-display-left" style={{zIndex:4 }}>
                        <MyButton   disabled={imageIndex===arrImage.length-1} onClick={()=>setImageIndex(imageIndex+1)}
                                            title='' 
                                            iconName='arrow_back_ios' 
                                            buttonStyle={{backgroundColor:"rgba(255,255,255,0.5)" , }}
                                            buttonIconStyle={{color:"#909090"}}
                                            buttonTExtStyle={{}} />
                        </div>
                    </div> : null
                }

                <SwipeableViews enableMouseEvents axis="x-reverse" index={imageIndex}  onChangeIndex={changeIndexOfImage} style={{width , height}}  containerStyle={{width , height}}  >
                {
                    arrImage.map( (imageInfo , index)=>imageInfo.url.indexOf(".mp4")!==-1 ? 
                                                                                <div style={{width:"100%" , height:"100%" , overflow:"hidden" , margin:0 , padding:0 }} >
                                                                                    <video src={imageInfo.url} style={{width:"100%" , height:"100%" , overflow:"hidden" , margin:0 , padding:0 }} controls  />
                                                                                </div> : 
                                                                                <div key={index} className={`w3-round   w3-display-container ${zoomAble ? " w3-ripple " : "" } `}
                                                                                       onClick={()=>zoomAble ? showOverlaySlider(index) : {}  }
                                                                                       style={{ width:"100%" , height:"100%" 
                                                                                       , overflow:"hidden" , position:"relative" ,  backgroundImage:`url(${imageInfo.url})` ,
                                                                                                                                                                                                                                backgroundPosition:"top",
                                                                                                                                                                                                                                backgroundSize: 'cover',//"100% 100%"
                                                                                                                                                                                                                               objectFit:"cover" , 
                                                                                                                                                                                                                                backgroundRepeat: 'no-repeat'}}>
                                                                                    {
                                                                                        imageInfo.buttonTitle?
                                                                                        <div className="w3-display-bottomright w3-padding">
                                                                                            <MyButton onClick={imageInfo.onClickImage}  
                                                                                            iconName="arrow_back_ios"
                                                                                            title={imageInfo.buttonTitle}
                                                                                                             buttonStyle={{backgroundColor:"#ec407a" , borderRadius:30 , padding:"2px 10px" , boxShadow:" 0 0 10px 3px #FFFFFF"}} />
                                                                                        </div>:null
                                                                                    }
                                                                                    {
                                                                                        imageInfo.title ? 
                                                                                        <div className="w3-display-middle  w3-white w3-padding w3-round-large w3-small- w3-card" 
                                                                                                style={{opacity:0.6 , fontWeight:"bold"}}>{imageInfo.title}</div>:null
                                                                                    }
                                                                                </div>)
                }
                </SwipeableViews>
                {
                    arrImage.length>1 ? 
                    <div className="displayFlexRow displayFlexCenter " style={{position:"absolute"  , bottom:0 , left:0 , right:0}}>
                        <div className="w3-white w3-card" style={{borderRadius:"10px 10px 0 0 "}}>
                            <ImagePaging  numberOfPages={arrImage.length} currentPageIndex={imageIndex}/>
                         </div>
                    </div> : null
                }
                 {/* <PhotoSlider       images={arrImage.map((item , index) => ({ src: item.url, key: index }))}
                                            visible={showSlider}
                                            onClose={() => setShowSlider(false)}
                                            index={imageIndex}
                                            onIndexChange={setImageIndex}
                                        /> */}
                <ImageModal ref={mdlSlideShow} />
        </div>
    )
}
export const SlideshowManager=(props)=>{
    const {typeId=1 , width , showTitle=true , showButton=true , 
                height ,
                ratioTitle="",
                rationTitleColor="#e57373" , 
                arrImageFile /* id , type , filename , title , buttonTitle , targetUrl */
              , onDelete = ()=>{} , 
              onAddImage  = ()=>{}  , 
              onChangeTitle= ()=>{}  , 
              onChangeButtonTitle = ()=>{} , 
              onSelectTargetCategoryProduct = ()=>{} , 
              removeTargetUrl= ()=>{} , 
              acceptFileType="image/png , image/jpeg , image/jpg , image/webp" } = props

    const refFileImage=useRef()
    const [selectedImageIndex , setSelectedImageIndex]=useState(0)
    //-----------------------------------------------------
    const mdlSlideShow=useRef()
    //-------------------------------------------------------
    const handleImageUpload = async (e) => { 
             if(e.target.files && e.target.files.length > 0){
                 const arrFile=[]
                 for(let file of e.target.files)
                      arrFile.push({ id:uuid() , type : typeId , filename : file , title:'' , buttonTitle:'' , targetUrl:''  })
                 onAddImage(arrFile)
             }
    };
    //-----------------------------------------------------
    const decreaseSelectedImageIndex=()=>setSelectedImageIndex(selectedImageIndex-1)
    const deleteImage=()=>{
        onDelete(arrImageFile.filter((filename , index) => index===selectedImageIndex )[0].id)
        if(selectedImageIndex)
            decreaseSelectedImageIndex()
    }
    //----------------------------------------------------
    const changeIndexOfImage=(index)=>setSelectedImageIndex(index)
    //-----------------------------------------------------
    const showOverlaySlider=(index)=>   mdlSlideShow.current.show(arrImageFile.map(image=>typeof(image.id)==="number" ?  image.filename  :URL.createObjectURL(image.filename) ) , index)
    //-----------------------------------------------------
    const onChangeTitleInfo=(txtId , newText)=>onChangeTitle(isNaN(txtId) ? txtId : parseInt(txtId) , newText)
    const onChangeButtonTitleInfo=(txtId , newText)=>onChangeButtonTitle(isNaN(txtId) ? txtId : parseInt(txtId) , newText)
    
    return(
        <div className='w3-green  w3-round-xlarge  displayFlexRow w3-light-gray' style={{width  ,height  , position:"relative"}} >
                <div dir="ltr" className="w3-padding-small w3-small" 
                        style={{position:"absolute" , left:0 , bottom:0 ,backgroundColor:"rgba(255,255,255,0.4)" , color:rationTitleColor , zIndex:3  , fontWeight:"bold"}}>
                {
                    ratioTitle
                }
                </div>
                <div className='displayFlexRowReverse w3-white w3-card w3-border ' 
                        style={{position:"absolute" , top:0 , right:0 , zIndex:10 , borderRadius:"0 15px 0 20px" , padding:"3px 10px"}}>
            
                    {
                        arrImageFile.length ? 
                        <MyButton iconName="fullscreen" 
                                        onClick={()=>showOverlaySlider(selectedImageIndex)  }
                                        buttonStyle={{backgroundColor:"#FFFFFF" , padding:1 , borderRadius:20}}
                                        buttonIconStyle={{color:"#4fc3f7" , fontSize:20 , marginRight:30}} /> : null
                    }

                    <MyButton iconName="add" disabled={arrImageFile.length>10} onClick={()=>refFileImage.current.click()}
                                    buttonStyle={{backgroundColor:"#4fc3f7" , padding:1 , borderRadius:20}}
                                    buttonIconStyle={{color:"#FFFFFF" , fontSize:20}} />
                    <MyButton iconName="close" onClick={deleteImage} disabled={!arrImageFile.length}
                                    buttonStyle={{backgroundColor:"#e57373" , padding:1 , borderRadius:20 , marginLeft:10}}
                                    buttonIconStyle={{color:"#FFFFFF" ,  fontSize:20}} />
                    
                </div>

                {
                    arrImageFile.length ? 
                    <div className='displayFlexGrow  w3-round-xlarge w3-display-container'>

                        {
                            arrImageFile.length>1 ? 
                            <div>
                                <div className=" w3-padding-small w3-display-left" style={{zIndex:4 }}>
                                    <MyButton  disabled={!selectedImageIndex} onClick={()=>setSelectedImageIndex(selectedImageIndex-1)}
                                                    title='' 
                                                    iconName='arrow_back_ios' 
                                                    buttonStyle={{backgroundColor:"rgba(255,255,255,0.5)" ,}}
                                                    buttonIconStyle={{color:"#909090"}}
                                                    buttonTExtStyle={{}} />
                                </div>
                                <div className=" w3-padding-small w3-display-right" style={{zIndex:4 }}>
                                <MyButton   disabled={selectedImageIndex===arrImageFile.length-1} onClick={()=>setSelectedImageIndex(selectedImageIndex+1)}
                                                    title='' 
                                                    iconName='arrow_forward_ios' 
                                                    buttonStyle={{backgroundColor:"rgba(255,255,255,0.5)" , }}
                                                    buttonIconStyle={{color:"#909090"}}
                                                    buttonTExtStyle={{}} />
                                </div>
                            </div> : null
                        }

                            <SwipeableViews   index={selectedImageIndex} 
                                                         enableMouseEvents axis="x-reverse"  
                                                         onChangeIndex={changeIndexOfImage} style={{width:"100%" , height:"100%"}}  
                                                        containerStyle={{width:"100%" , height:"100%"}}  >
                            {
                                arrImageFile.map( (imageInfo , index)=>typeof( imageInfo.id)==="string" ? //فایل جدید                                              
                                                                                                <div key={index} style={{ width:"100%" , height:"100%" , overflow:"hidden"  }}>
                                                                                                     {
                                                                                                          imageInfo.filename.type==="video/mp4" ? 
                                                                                                          //یک فیلم جدید
                                                                                                          <div style={{width:"100%" , height:"100%" , overflow:"hidden" , margin:0 , padding:0 }} >
                                                                                                              <video src={ URL.createObjectURL(imageInfo.filename)}  
                                                                                                                         style={{width:"100%" , height:"100%" , overflow:"hidden" , margin:0 , padding:0 }} controls  />
                                                                                                          </div> :
                                                                                                          //یک عکس دریافت شده 
                                                                                                          <div key={index} className="w3-round-xlarge w3-display-container"   
                                                                                                                style={{ width:"100%" , height:"100%" , overflow:"hidden" , position:"relative" }}>
                                                                                                                    <img src={URL.createObjectURL(imageInfo.filename)} 
                                                                                                                            style={{width:"100%" , height:"100%" , objectFit:"cover"}} className="w3-round-xlarge" />
                                                                                                                    {
                                                                                                                        showTitle ?
                                                                                                                        <div className='w3-display-middle w3-col l5  '>
                                                                                                                            <MyInput id ={imageInfo.id}   
                                                                                                                                    containerStyle={{backgroundColor:"rgba(255,255,255,0.4)"}}   smallFont isBold
                                                                                                                                    containerClassName="w3-col 12 w3-border-0"
                                                                                                                                    inputStyle={{textAlign:"center" , padding:"2px 5px"}}  
                                                                                                                                    placeholder={ "...." +"عنوان تصویر"}   
                                                                                                                                    value={imageInfo.title}          
                                                                                                                                    disabled={false}  
                                                                                                                                    onChangeText={onChangeTitleInfo}       
                                                                                                                                    password={false}  
                                                                                                                                    maxLength={50}         
                                                                                                                                    textAlign='right' 
                                                                                                                                    style={{}}        
                                                                                                                                    className='' />   
                                                                                                                        </div> : null
                                                                                                                    }
                                                                                                                    {
                                                                                                                        showButton ? 
                                                                                                                        <div className='w3-display-bottomright   w3-padding  ' style={{width:200 , marginBottom:5}}>
                                                                                                                                <div className='displayFlexRowReverse'>
                                                                                                                                        <MyInput id ={imageInfo.id}   
                                                                                                                                                containerStyle={{}}   smallFont isBold
                                                                                                                                                containerClassName=""
                                                                                                                                                inputStyle={{textAlign:"right" , padding:"0px 5px"}}  
                                                                                                                                                placeholder={ "...." +"عنوان دکمه "}   
                                                                                                                                                value={imageInfo.buttonTitle}          
                                                                                                                                                disabled={false}  
                                                                                                                                                onChangeText={onChangeButtonTitleInfo}       
                                                                                                                                                password={false}  
                                                                                                                                                maxLength={50}          
                                                                                                                                                textAlign='right' 
                                                                                                                                                style={{}}        
                                                                                                                                                className='' />   
                                                                                                                                                <MyButton iconName="category"   onClick={()=>onSelectTargetCategoryProduct(imageInfo.id)}
                                                                                                                                                                buttonStyle={{backgroundColor:"#FFFFFF" , borderRadius:6 , padding:"0px 4px 0px 1px " , marginRight:2}} 
                                                                                                                                                                buttonIconStyle={{color:"#456e7a"}}    />
                                                                                                                                </div>
                                                                                                                            {
                                                                                                                                imageInfo.targetUrl ? 
                                                                                                                                <div className='w3-white w3-card w3-round-large w3-center displayFlexRow displayFlexCenter' style={{marginTop:4}}>
                                                                                                                                    <MyButton iconName="close" buttonStyle={{backgroundColor:"transparent"}} buttonIconStyle={{color:"#e57373"}} onClick={()=>removeTargetUrl(imageInfo.id)} />
                                                                                                                                    <div className='displayFlexGrow'>{imageInfo.targetUrl}</div>
                                                                                                                                </div>:null
                                                                                                                            }
                                                                                                                        </div>  :null
                                                                                                                    }
                                                                                                        </div>
                                                                                                    }
                                                                                                </div> : 
                                                                                                <div key={index} style={{ width:"100%" , height:"100%" , overflow:"hidden"  }}>
                                                                                                    {
                                                                                                          imageInfo.filename.indexOf(".mp4")!==-1 ? 
                                                                                                          //یک فیلم ذخیره شده
                                                                                                          <div style={{width:"100%" , height:"100%" , overflow:"hidden" , margin:0 , padding:0 }} >
                                                                                                              <video src={ imageInfo.filename }  
                                                                                                                         style={{width:"100%" , height:"100%" , overflow:"hidden" , margin:0 , padding:0 }} controls  />
                                                                                                          </div> :
                                                                                                          //یک عکس ذخیره شده 
                                                                                                          <div key={index} className="w3-round-xlarge w3-display-container"   
                                                                                                                style={{ width:"100%" , height:"100%" , overflow:"hidden" , position:"relative" }}>
                                                                                                                    <img src={ imageInfo.filename} 
                                                                                                                            style={{width:"100%" , height:"100%" , objectFit:"cover"}} className="w3-round-xlarge" />
                                                                                                                    {
                                                                                                                        showTitle ?
                                                                                                                        <div className='w3-display-middle w3-col l5  '>
                                                                                                                            <MyInput id ={imageInfo.id}   
                                                                                                                                    containerStyle={{backgroundColor:"rgba(255,255,255,0.4)"}}   smallFont isBold
                                                                                                                                    containerClassName="w3-col 12 w3-border-0"
                                                                                                                                    inputStyle={{textAlign:"center" , padding:"2px 5px"}}  
                                                                                                                                    placeholder={ "...." +"عنوان تصویر"}   
                                                                                                                                    value={imageInfo.title}          
                                                                                                                                    disabled={false}  
                                                                                                                                    onChangeText={onChangeTitleInfo}       
                                                                                                                                    password={false}  
                                                                                                                                    maxLength={50}         
                                                                                                                                    textAlign='right' 
                                                                                                                                    style={{}}        
                                                                                                                                    className='' />   
                                                                                                                        </div> : null
                                                                                                                    }
                                                                                                                    {
                                                                                                                        showButton ? 
                                                                                                                        <div className='w3-display-bottomright   w3-padding  ' style={{width:200 , marginBottom:5}}>
                                                                                                                                <div className='displayFlexRowReverse'>
                                                                                                                                        <MyInput id ={imageInfo.id}   
                                                                                                                                                containerStyle={{}}   smallFont isBold
                                                                                                                                                containerClassName=""
                                                                                                                                                inputStyle={{textAlign:"right" , padding:"0px 5px"}}  
                                                                                                                                                placeholder={ "...." +"عنوان دکمه "}   
                                                                                                                                                value={imageInfo.buttonTitle}          
                                                                                                                                                disabled={false}  
                                                                                                                                                onChangeText={onChangeButtonTitleInfo}       
                                                                                                                                                password={false}  
                                                                                                                                                maxLength={50}          
                                                                                                                                                textAlign='right' 
                                                                                                                                                style={{}}        
                                                                                                                                                className='' />   
                                                                                                                                                <MyButton iconName="category"   onClick={()=>onSelectTargetCategoryProduct(imageInfo.id)}
                                                                                                                                                                buttonStyle={{backgroundColor:"#FFFFFF" , borderRadius:6 , padding:"0px 4px 0px 1px " , marginRight:2}} 
                                                                                                                                                                buttonIconStyle={{color:"#456e7a"}}    />
                                                                                                                                </div>
                                                                                                                            {
                                                                                                                                imageInfo.targetUrl ? 
                                                                                                                                <div className='w3-white w3-card w3-round-large w3-center displayFlexRow displayFlexCenter' style={{marginTop:4}}>
                                                                                                                                    <MyButton iconName="close" buttonStyle={{backgroundColor:"transparent"}} buttonIconStyle={{color:"#e57373"}} onClick={()=>removeTargetUrl(imageInfo.id)} />
                                                                                                                                    <div className='displayFlexGrow'>{imageInfo.targetUrl}</div>
                                                                                                                                </div>:null
                                                                                                                            }
                                                                                                                        </div>  :null
                                                                                                                    }
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                            )
                            }
                            </SwipeableViews>
                            {
                    arrImageFile.length>1 ? 
                    <div className="displayFlexRow displayFlexCenter " style={{position:"absolute"  , bottom:0 , left:0 , right:0}}>
                        <div className="w3-white w3-card" style={{borderRadius:"10px 10px 0 0 "}}>
                            <ImagePaging  numberOfPages={arrImageFile.length} currentPageIndex={selectedImageIndex}/>
                         </div>
                    </div> : null
                }
                    </div> : null
                }
                 <ImageModal ref={mdlSlideShow} />
                <input type={"file"} accept={acceptFileType} ref={refFileImage} multiple onChange={handleImageUpload} style={{display:"none"}} />
        </div>
    )
}
//===============================================================================================================================
export const  ActivationCodeInput = props =>{
    const {numberOfDigit=4 , validateCode="" , onChangeValidateCode=()=>{}} = props

    return(
        <PinInput length={numberOfDigit} 
                        initialValue={validateCode}
                        secret={false} 
                        onChange={onChangeValidateCode} 
                        type="numeric" 
                        inputMode="number"
                        style={{padding: '10px' , direction:"ltr"}}  
                        inputStyle={{backgroundColor:"#FFFFFF" , borderRadius:7 , boxShadow:"0 0 10px 5px  #eeeeee" , marginLeft:5 , borderColor:"#eeeeee" , padding:4  , fontSize:20}}
                        inputFocusStyle={{backgroundColor:"#FFFFFF" , borderRadius:7 , boxShadow:"0 0 10px 5px  #eeeeee" , marginLeft:5 , borderColor:"#d0d0d0"}}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}   />
    )
}
//================================================================================================================================
export const MySelect  = props =>{
    const { 
                placeholder="" ,  
                containerStyle={} , 
                selectButtonStyle={ buttonStyle:{} , buttonIconStyle:{} , buttonTextStyle:{} } , //buttonStyle , buttonTextStyle , buttonIconStyle
                modalStyle={} , 
                itemStyle={} , 
                selectedItemStyle={} , 
                smallFont=false , 
                bigFont=false , 
                tinyFont=false ,
                selectedItemId=0 , 
                onSelectItem=()=>{} , 
                disabled=false , 
                arrItem =[] , //[{id , name , icon , avatar  , disabled}]
              } =props

    const [isOpen , setIsOpen]=useState(false)

    const itemClick=( itemId)=>{
        setIsOpen(false)
        onSelectItem( itemId)
    }

    return (
        <View style={containerStyle}> 
            <MyButton title={arrItem.filter(item => item.id===selectedItemId).length ? arrItem.filter(item => item.id===selectedItemId)[0].name  : placeholder} 
                            iconName={!isOpen ? "expand_more" : "expand_less"} smallFont={smallFont} tinyFont={tinyFont} bigFont={bigFont}
                            disabled={disabled}
                            buttonStyle={Object.assign({} , {backgroundColor:"#eeeeee" ,justifyContent:"flex-end" ,elevation:1} , selectButtonStyle.buttonStyle ? selectButtonStyle.buttonStyle  :{})}
                            buttonIconStyle={Object.assign({} , {color:"#456e7a"} , selectButtonStyle.buttonIconStyle ? selectButtonStyle.buttonIconStyle  :{})}
                            buttonTextStyle={Object.assign({} , {color:"#456e7a"} , selectButtonStyle.buttonTextStyle ? selectButtonStyle.buttonTextStyle  :{})}
                            onClick={()=>setIsOpen(true)}   />
            <Modal visible={isOpen}  animationType="none"
                       transparent={true}  
                        onRequestClose={()=>setIsOpen(false)} >
                            <View animation="zoomIn" style={{flexDirection:"column" , justifyContent:"center" , alignItems:"center" , flex:1 , backgroundColor:"rgba(0, 0,0 , 0.8)"}}>
                                <div className="w3-animate-zoom"  animation="zoomIn" duration={400} style={Object.assign({} , {backgroundColor:"#FFFFFF" , borderRadius:10 , padding:10 , width:"80%"} , modalStyle)} >
                                    {
                                        arrItem.map((item  , index)=><View key={index} style={{flexDirection:"row" , alignItems:"center" , padding:5}}>
                                                                                            {
                                                                                                item.avatar ? <View style={{marginLeft:7 , borderRadius:20 , elevation:1 , borderWidth:1 , borderColor:"#eeeeee" , padding:2}}>
                                                                                                    <ProfileImage source={{uri:item.avatar}} width={25} />
                                                                                                </View> : null
                                                                                            }
                                                                                            <View style={{flex:1}}>
                                                                                                <MyButton key={index} onClick={()=>itemClick( item.id)} title={item.name} iconName={item.icon}
                                                                                                            buttonStyle={{backgroundColor:"transparent",justifyContent:"flex-end",borderBottomWidth:1 , borderColor:"#eeeeee" }}
                                                                                                            buttonIconStyle={{color:"#456e7a" , }}
                                                                                                            buttonTextStyle={{color:"#456e7a"}} />
                                                                                            </View>

                                                                                        </View>)
                                    }
                                </div >
                            </View>
            </Modal>
        </View>
    )
}
//================================================================================================================================
export const AnimatableView=props =>{
    const {animation="" , duration=500 , style={}} =props

    const objAnimation={
        slideInRight  , 
        slideOutRight , 
        slideInLeft    , 
        slideOutLeft , 
        slideInDown    , 
        slideOutDown,
        slideInUp       , 
        slideOutUp,
        zoomIn            ,
        zoomOut , 
        fadeIn            , 
        fadeOut
    }

    return (
        <StyleRoot style={{ }}>
            <div {...props} style={Object.assign({} , style  , !animation ? {} :  animateStyles[animation]  ) } />
        </StyleRoot>
    )
}
export const Section = props =>{
    const {containerStyle={} , containerClassName="" , contentStyle={} , contentClassName=""} = props
    return(
           <div className={` displayFlexCenter displayFlexCol ${containerClassName}`} style={Object.assign({} , containerStyle)}>
                  <div className={`w3-col l9 m11 s12  ${contentClassName}`} style={Object.assign({} , { alignItems:"center" , padding:"10px 0"} , contentStyle)}>
                  {
                         props.children
                  }
                  </div>
           </div>
    )
}
//================================================================================================================================
export const MyFlatList= props =>{

    const {onRefresh=()=>{}} = props

    return(
        <PullToRefresh onRefresh={async ()=>await onRefresh()}
                                  pullingContent={null}
                                  refreshingContent={<View style={{margin:10}}><ActivityIndicator size={"large"} style={{margin:10}} /></View>}>
                <FlatList {...props} />
        </PullToRefresh>
        
    )
}
//================================================================================================================================