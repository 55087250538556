//========================================================================================
import React,{useRef , useEffect  , useState, useImperativeHandle, forwardRef}  from 'react'
import {View , TouchableOpacity} from "react-native"
import { ModalContainer, MyButton, MyIcon, MySwitch, MyText } from '../../../../globalComponents/components';
import { formatNumber } from '../../../../globalComponents/rejax';

const MonthPriceComponent=props =>{
       const {id , title , price , selected=false , onSelect}=props
       return(
              <TouchableOpacity style={{ width:"100%"  , backgroundColor:selected ? "#e0f7fa" : "#FFFFFF"}} onPress={onSelect}>
                     <View  style={{alignItems:"center" , flexDirection:"row" , paddingVertical:10 ,borderBottomWidth:1 , borderColor:"#f5f5f5" }}>
                            <View style={{}}>
                                   <MyIcon iconName="task_alt" iconSize={25} color={selected ? "#00897B" : "#E0F2F1"  } />
                            </View>
                            <MyText className='displayFlexGrow w3-center' isBold={selected} bigFont={selected}
                                         style={{flex:1,color:selected ? "#00ACC1" : "gray"}}> {title}</MyText>
                            <MyText className='displayFlexGrow w3-center' isBold={selected} bigFont={selected}
                                         style={{flex:1,color:selected ? "#00ACC1" : "gray"}}>{`${props ? formatNumber(price)+" تومان" : "-----"}`}</MyText>
                     </View>
              </TouchableOpacity>
       )
}
 
const MdlMembershipPriceSelect=forwardRef((props , ref)=>{
//-----------------------------------GLOBAL VAR EREA
//-----------------------------------REF EREA
//-----------------------------------STATE EREA
const [visible , setVisible]=useState(false)
const [arrMonthPrice , setArrMonthPrice]=useState([])
const [selectedMonthPriceId , setSelectedMonthPriceId]=useState(0)
//-----------------------------------USE_EFFECT EREA
useImperativeHandle(ref , ()=>({
       show: _arrMonthPrice =>{
              setVisible(true)
              setArrMonthPrice(_arrMonthPrice)
       } ,
       hide:()=>{
              setVisible(false)
              setArrMonthPrice([])
              setSelectedMonthPriceId(0)
       }
}))
//-----------------------------------FUNCTION EREA
const hide=()=> {
       setVisible(false)
        setArrMonthPrice([])
        setSelectedMonthPriceId(0)
}
const onSelectMembershipPrice=()=>  props.onSelectMembershipPrice(selectedMonthPriceId)
//--------------------------------------------------
   return(
       visible ? 
       <ModalContainer visible={visible}
                                  showBackButton={true}
                                  onBackClick={hide}
                                   closeWithClickBackButton

                                   title="میزان اعتبار مورد نظر خود را انتخاب نمایید"
                                   messageTextStyle={{color:"#e57373"}}
                                   containerStyle={{padding:0}}
                                   containerClassName=""
                                   contentStyle={{width:"100%"   }}
                                   contentClassName="" 
                                   position="bottom"
                                   animation="slideInUp" >
              {
                     arrMonthPrice.map((monthPrice , index) => <MonthPriceComponent key={index} 
                                                                                                                               {...monthPrice} 
                                                                                                                               selected={selectedMonthPriceId===monthPrice.id}
                                                                                                                               onSelect={()=>setSelectedMonthPriceId(monthPrice.id)} />)
              }
              <View  style={{flexDirection:"row-reverse" , justifyContent:"center" , alignItems:"center" , marginVertical:16 , paddingHorizontal:16 , paddingVertical:8 ,}}>
                     <MyButton title="تایید و پرداخت هزینه  تمدید اعتبار" 
                                      iconName="sell" 
                                      smallFont
                                      disabled={!selectedMonthPriceId} 
                                      onClick={onSelectMembershipPrice} />
              </View>
       </ModalContainer>
       :null
   )
})

export default MdlMembershipPriceSelect;
//=========================================================================================