//=================================================================================================
const SET_TOAST_MESSAGE="SET_TOAST_MESSAGE"
const RESET_TOAST_MESSAGE="RESET_TOAST_MESSAGE"
//=================================================================================================
export const setToastMessage=(strMessage)=>({type:SET_TOAST_MESSAGE , strMessage})
export const resetToastMessage=()=>({type:RESET_TOAST_MESSAGE})
//=================================================================================================
const toastMessageInit=""

export default (state=toastMessageInit , action)=>{
    switch(action.type){
        case SET_TOAST_MESSAGE :  return action.strMessage
        case RESET_TOAST_MESSAGE : return ""
        default : return state
    }
}