//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
import {  Linking, View  , FlatList, ImageBackground} from "react-native"
import { Alert, MyButton, MyInput, MySlideShow, MyText, NoItem, PageContainer, ProfileImage, Waiting , AnimatableView } from '../../../../globalComponents/components';
import Fetch from '../../../../globalComponents/fetch';
import { connect } from 'react-redux';
import { formatNumber } from '../../../../globalComponents/rejax';
import { setToastMessage } from '../../../../redux/toastMessage';
// import {useBackHandler } from "@react-native-community/hooks"
import ExtendedActionButton from '../../../../commonComponents/extendedActionButton';
import ChapterGoodsInfoPlaceholder from '../../../../commonComponents/chapterGoodsInfoPlaceholder';
//-----------------------------------------------------------------------------------------------
const CommentAnswerComponent=(props) =>{
       const {id , answer  , sellerAvatar} =props
       return(
              <View style={{flexDirection:"row" ,  borddrTopWidth:1 , borderColor:"#e0e0e0" , paddingTop:10}}>
                     <ProfileImage source={sellerAvatar } width={35} />
                     <View style={{flex:1 , paddingRight:5}}>
                            <MyText smallFont color="gray">{answer}</MyText>
                     </View>
              </View>
       )
}
const CommentComponent= props =>{
       const {id , writerId , date , shortDate ,writerName , writerAvatar ,  comment , time , writerAboutMe , isMyComment , hasAnimation=false , answer ,sellerAvatar=""} = props
       const avatar= writerAvatar ? {uri : writerAvatar} : require("../../../../assets/images/act/imgDefManAvatar.jpg")

       const commentInfo = <View  style={{flexDirection: "row" , borderBottomWidth:0.5 , borderColor:"#e0e0e0" , 
                                                               paddingHorizontal:16 , paddingVertical:8  , alignItems:"flex-start" , marginBottom:10}}>
                                                 <ProfileImage source={avatar} width={40}  style={{borderWidth:1 , borderColor:"#eeeeee" , padding:3}} />                            
                                                 <View style={{flex:1 , borderBottomRightRadius:!isMyComment ? 10:0 ,  borderBottomLeftRadius:!isMyComment ? 0:15  , 
                                                                        padding:8 , paddingBottom:1 }} >
                                                               <View style={{flexDirection:"row"}}>
                                                                      <MyText isBlock tinyFont  color="brow-reversen" style={{flex:1}} >{writerName}</MyText>
                                                                      <MyText isBlock tinyFont  color="gray" >{shortDate+"  "+time}</MyText>
                                                               </View>
                                                               {
                                                                      writerAboutMe  ?
                                                                      <View  className="w3-text-gray w3-tiny" style={{ fontWeight:"bold"}}>
                                                                             <MyText tinyFont color='#9a9a9a'>{writerAboutMe}</MyText>
                                                                      </View> : null
                                                               }
                                                               <MyText isBlock smallFont >{comment}</MyText>
                                                               {/* <MyText smallFont  dir="ltr" style={{textAlign:"left" }}>{time}</MyText> */}
                                                               {
                                                                      answer ? 
                                                                      <View style={{}}>
                                                                             <CommentAnswerComponent answer={answer} sellerAvatar={sellerAvatar} />
                                                                      </View> : null
                                                               }
                                                 </View> 

                                          </View>
       if(hasAnimation) 
              return(
                     <AnimatableView animation={"zoomIn"} duration={800}>
                            {
                                   commentInfo
                            }
                     </AnimatableView>
              )
       else

              return(
                     <View>
                            {
                                   commentInfo
                            }
                     </View>
              )
}
const DateCommentCommponent= props =>{
       const {date  ,shortDate , arrComment , myId ,sellerAvatar} = props
       return (
              <View title={date} style={{padding:10 }}>
                     {
                            arrComment.map(comment => <CommentComponent key={comment.id} {...comment} shortDate={shortDate} isMyComment={myId===comment.writerId} sellerAvatar={sellerAvatar} />)
                     }
              </View>
       )
}
//-----------------------------------------------------------------------------------------------
const GoodsInfo=(props)=>{
//-----------------------------------GLOBAL VAR EREA

const  shopId = props.route.params.shopId
const  goodsId = props.route.params.goodsId  
const  selectedGoods = props.route.params.selectedGoodsInfo
//-----------------------------------REF EREA
const fetchModal=useRef()
const alertModal=useRef()
//-----------------------------------STATE EREA
const [wantToSendComment , setWantToSendComment]=useState(false)
const [scrollViewY , setScrollViewY]=useState(0)
const [newComment  ,setNewComment]=useState("")
const [ fetchState , setFetchState ]=useState({ fetchFlag:false , errorFlag:false });
const [ commentFetchState , setCommentFetchState ]=useState({ fetchFlag:false , errorFlag:false });
 /*
           id:null ,
              sellerName:'' , 
              sellerAvatar:'' , 
              title:'' , 
              price , 
              phone , 
              goodsState , 
              description:'', 
              categoryName:0
              arrImageFile:[]  //[   {    id , filename ,   }   ] ,
              commentable , 
               likeCount,
              dislikeCount , 
              isLiked
   */
const [selectedGoodsInfo , setSelectedGoodsInfo] =useState({
                                                                                                                id:0 ,
                                                                                                                sellerName:selectedGoods.sellerName ,
                                                                                                                sellerAvatar :selectedGoods.sellerAvatar ,
                                                                                                                title : selectedGoods.title,
                                                                                                                price:selectedGoods.price , 
                                                                                                                phone : '' ,
                                                                                                                goodsState : selectedGoods.goodsState ,  
                                                                                                                saveDate:"" ,  
                                                                                                                description:'',
                                                                                                                categoryName:'' ,  
                                                                                                                arrImageFile:[] , //[   {    id , filename    }   ],
                                                                                                                commentable:0,
                                                                                                                likeCount:0,
                                                                                                                dislikeCount:0 , 
                                                                                                                isLiked:false
                                                                                                  })
const [commentInfo , setCommentInfo]=useState({ arrDateComment:[] , finished:false  }) //[ { date , arrComment : [{id , writerName ,writerAboutMe, writerAvatar , comment   }] } ]
//-----------------------------------USE_EFFECT EREA
useEffect(()=>{
        getGoodsInfo()  
} , [])
//-----------------------------------FUNCTION EREA
const getGoodsInfo=()=>{
       const objData={
              jwt : props.profileState.jwt ,
              goodsId , 
              shopId
       }
       fetchModal.current.fetchData("user/getGoodsInfo" , objData , 
                                                          {
                                                               onStart : ()=>setFetchState({fetchFlag:true , errorFlag:false}),
                                                               onError: ()=>setFetchState({fetchFlag:false , errorFlag:true}),
                                                               onSuccess : ResponseOfGetGoodsInfo
                                                          } , false , false , false)
}
const ResponseOfGetGoodsInfo = data =>{
       setFetchState({fetchFlag:false , errorFlag:false})
       setSelectedGoodsInfo({...selectedGoodsInfo ,...data})
       if(data.commentable)
              setTimeout(()=> getCommentList() , 200)
}
//--------------------------------------------------
// useBackHandler(()=>{
//        onBackClick()
//        return true
// })
const onBackClick=()=>props.navigation.goBack()
//-------------------------------------------------
const likeGoods=(likeState)=>{
       const objData={
              jwt:props.profileState.jwt,
              goodsId ,
              likeState
       }
       fetchModal.current.fetchData("user/likeGoods" , objData , 
                                                         {
                                                               onSuccess : responseOfLikeGoods , 
                                                         } , false , false , false)
}
const responseOfLikeGoods= data => setSelectedGoodsInfo({...selectedGoodsInfo , isLiked:true , likeCount:data.likeCount , dislikeCount :data.dislikeCount })
//---------------------------------------------------
const getCommentList=()=>{
       let lastCommentId=0
       if(commentInfo.arrDateComment.length){
              const targetDateComment= commentInfo.arrDateComment[commentInfo.arrDateComment.length-1]
              lastCommentId =targetDateComment.arrComment[targetDateComment.arrComment.length-1].id
       }
       const objData={
              jwt : props.profileState.jwt ,
              goodsId , 
              lastCommentId 
       }
       fetchModal.current.fetchData("user/getGoodsCommentList" , objData , 
                                                          {
                                                               onStart : ()=>setCommentFetchState({fetchFlag:true , errorFlag:false}) , 
                                                               onError : ()=>setCommentFetchState({fetchFlag:false , errorFlag:true}) , 
                                                               onSuccess:responseOfGetCommentList,
                                                               onBackFromErrorConfirm:()=>setCommentFetchState({fetchFlag:false , errorFlag:false}) , 
                                                          } , false , true , true)
}
const responseOfGetCommentList=  data   =>{
       setCommentFetchState({fetchFlag:false , errorFlag:false})
       const _arrDateComment = JSON.parse(JSON.stringify(commentInfo.arrDateComment))
       let findDate=false
       for(let dataDateComment of data.arrDateComment){
              for(let dateComment of _arrDateComment){
                     if(dataDateComment.date===dateComment.date){
                            dateComment.arrComment.push(...dataDateComment.arrComment)
                            findDate=true
                            break
                     }
              }
              if(!findDate)
                     _arrDateComment.push(dataDateComment)
       }
       setCommentInfo({ arrDateComment : _arrDateComment , finished : data.finished})
}
//--------------------------------------------------
const setMyComment=()=>{
    const objData={
       jwt: props.profileState.jwt,
       comment : newComment.trim().replace( / +/g , " ").replace(/\n+/g , "\n") ,
       goodsId
    }
    fetchModal.current.fetchData("user/setGoodsComment" , objData , 
                                                      {
                                                        onSuccess : responseOfSetMyComment
                                                      } , true , true , true)
}
const responseOfSetMyComment= data =>{
       setNewComment("")
       // if(commentInfo.arrDateComment.filter(dateComment => dateComment.date===data.date).length)
       //        setCommentInfo({...commentInfo , arrDateComment : commentInfo.arrDateComment.map(dateComment => dateComment.date===data.date ? {...dateComment , arrComment : [data.commentInfo , ...dateComment.arrComment]} : dateComment)})
       // else
       //        setCommentInfo({...commentInfo , arrDateComment : [{date:data.date , arrComment:[data.commentInfo]} , ...commentInfo.arrDateComment] })
       // props.setToastMessage("ثبت دیدگاه شما با موفقیت انجام شد")
       props.setToastMessage("ثبت دیدگاه شما با موفقیت انجام شد و پس از تایید توسط فروشگاه به نمایش در خواهد آمد")
}
//--------------------------------------------------
const changeNewComment=(txtId , newText)=>setNewComment(newText ? newText :"")
//--------------------------------------------------
const avatar =  selectedGoodsInfo.sellerAvatar ?    {uri : selectedGoodsInfo.sellerAvatar} :  require("../../../../assets/images/act/imgDefManAvatar.jpg")
const goodsWriter= selectedGoodsInfo.goodsTypeName==="BULLETIN" ? "دهیاری روستا" : selectedGoodsInfo.sellerName  
//-----------------------------------------------------------------------------------------------
const CallTo = strPhone =>Linking.openURL("tel:"+strPhone)

const [imageWidth , setImageWidth]=useState(0)
const onLayout=e => setImageWidth(e.nativeEvent.layout.width)
const onScrollFlatList = event =>  setScrollViewY(Math.floor(event.nativeEvent.contentOffset.y))
const goodsInfoComponent = <View  style={{ }}>
                                                        {
                                                               selectedGoodsInfo.arrImageFile.length ? 
                                                               <View onLayout={onLayout} className='' style={{   height:imageWidth/1.4   }}>
                                                                      <MySlideShow arrImage={selectedGoodsInfo.arrImageFile.map(imageFile => ({url : imageFile.filename}) )} 
                                                                                            style={{ elevation:2 }}
                                                                                           width="100%" height={"100%"}  />
                                                               </View> : null
                                                        }
                                                        <View  style={{flexDirection:"row" , padding:16}}>
                                                               <ProfileImage source={avatar} width={45} style={{elevation:1}} />
                                                               <View style={{marginRight:10 , flex:1}}>
                                                                      <MyText isBlock smallFont isBold color="#26a69a" style={{}} >{goodsWriter}</MyText>
                                                                      <MyText isBlock tinyFont  color="#a9a9a9">{selectedGoodsInfo.sellerAboutMe ?selectedGoodsInfo.sellerAboutMe : "--------------"}</MyText>
                                                               </View>
                                                        </View>
                                                        

                                                        <View  style={{ paddingHorizontal:16 , paddingVertical:8}} >
                                                               <MyText isBlock color="#456e7a" isBold >{selectedGoodsInfo.title}</MyText>
                                                               <View className='w3-padding-small w3-small-' style={{ padding:8}}>
                                                                      <MyText  isBold   color="black">{`قیمت : ${selectedGoodsInfo.price ? `${formatNumber(selectedGoodsInfo.price)} تومان`   : "تماس بگیرید"}`}</MyText>
                                                                      <MyText  isBold   color="black">{`وضعیت کالا : ${selectedGoodsInfo.goodsState ? selectedGoodsInfo.goodsState: "--------"}`}</MyText>
                                                               </View>
                                                               
                                                               <View  style={{marginTop:16}}>
                                                                      {/* <MyText  isBold color="gray">توضیحات</MyText> */}
                                                                      <MyText  style={{marginRight:10 , color:"gray"}}  >{selectedGoodsInfo.description}</MyText>
                                                               </View>
                                                        </View>
                                                        {
                                                               shopId ?    <MyText tinyFont color="#e57373" isBold style={{ paddingVertical:0 , paddingHorizontal:16 , marginTop:16}}>سوال و نظرات کاربران : </MyText> : null
                                                        }
                                          </View>
const emptyComment=()=>{
       if(!commentFetchState.fetchFlag && !commentFetchState.fetchFlag && !commentInfo.arrDateComment.length && commentInfo.finished)
              return   <MyText smallFont color="gray" style={{textAlign:"center"}}>
                                                                هیچ نظر و دیدگاهی ثبت نشده است.
                            </MyText>
       if(!commentFetchState.fetchFlag && commentFetchState.fetchFlag && !commentInfo.arrDateComment.length)
       return   <MyText smallFont color="gray" style={{textAlign:"center"}}>
                                                               هیچ نظر و دیدگاهی ثبت نشده است.
                     </MyText>
}
const leftComponent=()=>{
       if(selectedGoodsInfo.phone)
              return   <AnimatableView  duration={700} animation="pulse" easing="ease-out" iterationCount="infinite" >
                                   <MyButton  onClick={()=>CallTo("tel:"+selectedGoodsInfo.phone)} 
                                          title='' 
                                          iconName='phone' 
                                          buttonStyle={{backgroundColor:"#FFFFFF" , borderRadius:40 , paddingHorizontal:5 , paddingVertical:5}}
                                          buttonIconStyle={{color:"#e57373"}}
                                          buttonTExtStyle={{}} />
                            </AnimatableView>
       else
              return null
}
//-----------------------------------------------------------------------------------------------
   return(
       <PageContainer title="معرفی کالا" leftComponent={leftComponent()} showHeader={false}
                                 subTitle={"روستای "+props.profileState.objCity.name}  hasFlatList
                                 containerPadding={0} showBackIcon onBackIconClick={onBackClick}>

              <View style={{flexDirection:"row"  ,position:"absolute" , top:0 , right:0 , left:0 , zIndex:2 , alignItems:"center" , 
                                      padding:10 , backgroundColor:`rgba(255 , 255 , 255 , ${scrollViewY/200} )` ,borderBottomWidth:1 , borderColor :`rgba(230 , 230 , 230 , ${scrollViewY/200} )`  }}>
                     <MyButton onClick={onBackClick}
                                      title='' 
                                      iconName='arrow_forward' 
                                      buttonStyle={{backgroundColor:"transparent"}}
                                      buttonIconStyle={{color:"#909090"}}
                                      buttonTExtStyle={{}} />
                     <MyText isBold color={`rgba( 120 , 120 , 120 , ${scrollViewY/200} )`} style={{paddingRight:5 }} >
                                   {selectedGoodsInfo.title.length<40 ? selectedGoodsInfo.title : selectedGoodsInfo.title.substring(0 , 40 )+"..."}
                     </MyText>
              </View>
              <View  style={{flex:1  }} >
                     {
                            // نمایش لودینگ برنامه
                            fetchState.fetchFlag ? 
                            <View className='displayFlexCenter displayFlexCol displayFlexGrow'>
                                   <ChapterGoodsInfoPlaceholder />
                           </View>:null
                     }
                     {
                            // نمایش خطا در دریافت اطلاعات
                            fetchState.errorFlag ? 
                            <View className='displayFlexCenter displayFlexCol displayFlexGrow'>
                                   <NoItem visible  message={"در دریافت اطلاعات خطایی روی داده است!!!"+"\n"+
                                                                             "این مشکل ممکن است در نتیجه قطعی و یا کندی شبکه اینترنت شما باشد."} 
                                                               title="خطا در دریافت اطلاعات" contentStyle={{boxShadow:" 0 0 0 "}}
                                                               showTryAgainButton tryAgain={getGoodsInfo} />
                           </View>:null
                     }
                     {
                            // نمایش اطلاعات سند و نظرسنجی در صورت وجود
                            selectedGoodsInfo.id   ? 
                            <FlatList  data={ commentInfo.arrDateComment}  onScroll={onScrollFlatList}
                                          style={{flex:1 }} contentContainerStyle={{paddingBottom:70}}
                                          keyExtractor={(item , index)=>index.toString()}
                                          onEndReachedThreshold={0.15}
                                          onEndReached={ !commentInfo.finished &&  commentInfo.arrDateComment.length  ? getCommentList :  ()=>{}   }
                                          ListHeaderComponent={ goodsInfoComponent }
                                          ListFooterComponent={commentFetchState.fetchFlag ? <Waiting visible /> : null }
                                          ListEmptyComponent={shopId ? emptyComment() : null}                
                                          renderItem={({item})=> <DateCommentCommponent key={item.date} {...item} myId={props.profileState.id} sellerAvatar={avatar} />}  />
                            : null
                     } 
                     <ExtendedActionButton iconName="phone" title="تماس با " />
              </View>
              {
                     //بخش مدیریت ارسال نظر و دیدگاه
                     !fetchState.errorFlag && !fetchState.fetchFlag && shopId   ?
                     <View style={{alignItems:"flex-end" ,elevation:1 , elevation:1 , backgroundColor:"#FFFFFF" , padding:8 , flexDirection:"row" ,  }}>
                            <View style={{flex:1}}>
                                   <MyInput id ='newComment'   
                                                 underlineColorAndroid="transparent"        
                                                 placeholder={"سوال , نظر و دیدگاه شما...."}
                                                 onFocus={()=>setWantToSendComment(true)}   
                                                 onLeaveFocus={()=>setTimeout(()=>setWantToSendComment(false) , 500)} 
                                                 value={newComment}          
                                                 onChangeText={changeNewComment}       
                                                 password={false}  
                                                 maxLength={2000}         
                                                 maxNumberOfLines={7}    
                                                 style={{}}  
                                                 inputStyle={{textAlign:"right" , padding:0  ,backgroundColor:"#FFFFFF" , padding:5 , paddingHorizontal:10 , borderWidth:1 ,borderColor:"#9a9a9a"}}
                                                 containerStyle={{ borderRadius:15 }} /> 
                            </View>
                            
                     
                     {
                            newComment ? 
                            <AnimatableView  animation="zoomIn" duration={100} style={{marginRight:10 }}>
                                          <MyButton   onClick={setMyComment}
                                                                                    title='' 
                                                                                    iconName='send' 
                                                                                    buttonStyle={{backgroundColor:"#26c6da" , borderRadius:30   , paddingHorizontal:7 , paddingVertical:7 , transform: [{ rotate: '180deg' }] }}
                                                                                    buttonIconStyle={{color:"#FFFFFF", fontSize:25 , borderRadius:40}}
                                                                                    buttonTExtStyle={{}} />
                            </AnimatableView> : 
                            <View>
                                   {
                                          shopId ? 
                                          <View  style={{flexDirection:"row-reverse"}}>
                                                {/* <AnimatableView animation={"zoomIn"} duration={200}  style={{position:"relative" , marginLeft:15}}>
                                                        <MyButton iconName="thumb-up" buttonStyle={{backgroundColor:"#f0f0f0" , borderRadius:40  }} disabled={selectedGoodsInfo.isLiked} 
                                                                      opacityOnDisabledOff onClick={()=>likeGoods(true)}
                                                                      buttonIconStyle={{fontSize:25 , marginLeft:0 , color:selectedGoodsInfo.isLiked ? "#26c6da" :"#c0c0c0"}} />
                                                       <MyText isBlock  style={{backgroundColor:"#e57373" , color:"#FFFFFF" , position:"absolute" , top:-6 , left:-5 , paddingHorizontal:4 , borderRadius:30 }}>
                                                               {selectedGoodsInfo.likeCount ? formatNumber(selectedGoodsInfo.likeCount) : "0" }
                                                        </MyText>
                                                 </AnimatableView> */}
                                                 {/* <AnimatableView animation={"zoomIn"}  style={{position:"relative" , marginLeft:15 , marginRight:7}}>
                                                        <MyButton iconName="thumb-down" buttonStyle={{backgroundColor:"#c0c0c0" , borderRadius:40  }} disabled={selectedGoodsInfo.isLiked} 
                                                                      opacityOnDisabledOff onClick={()=>likeGoods(false)}
                                                                      buttonIconStyle={{fontSize:25 , marginLeft:0}} />
                                                        <MyText isBlock  style={{backgroundColor:"#e57373" , color:"#FFFFFF" , position:"absolute" , top:-6 , left:-5 ,paddingHorizontal:4 , borderRadius:30 }}>
                                                               {selectedGoodsInfo.dislikeCount ? formatNumber(selectedGoodsInfo.dislikeCount) : "0" }
                                                        </MyText>
                                                 </AnimatableView> */}
                                          </View> : null
                                   }
                            </View>
                     }  
                     </View> : null
              }
           
           <Fetch ref={fetchModal}/>
           <Alert ref={alertModal} />
       </PageContainer>
   )
}

const mapStateToProps= state =>({
       shopId : state.bazaarReducer.shopInfo.selectedShopId , 
       arrGoods  : state.bazaarReducer.goodsInfo.arrGoods ,
       profileState : state.profileReducer
})
const mapDispatchToProps= dispatch =>({
       setToastMessage : strMessage => dispatch(setToastMessage(strMessage))
})

export default connect(mapStateToProps , mapDispatchToProps)(GoodsInfo);
//=========================================================================================