//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
import {View , TouchableOpacity} from "react-native"
import { PageContainer  , Alert, ProfileImage  , MyButton , MyText, MyIcon, Popup, Confirm , ImageModal} from '../../../globalComponents/components';
import Fetch from "../../../globalComponents/fetch"
import { setToastMessage } from '../../../redux/toastMessage';
import { setProfileInfo, updateProfileInfo } from '../../../redux/profile';
import { connect } from 'react-redux';
// import {useBackHandler} from "@react-native-community/hooks"
import MdlImageCrop from '../../../commonComponents/mdlImageCrop';
import AsyncStorage from "@react-native-async-storage/async-storage"
import Styles from '../../../styles';

const Profile=(props)=>{
       //-----------------------------------GLOBAL VAR EREA
       //-----------------------------------REF EREA
       const mdlImageCroper=useRef()
       const imageModal=useRef()
       const fetchModal=useRef()
       const alertModal=useRef()
       const popupModal=useRef()
       const confirmModal=useRef()
       //-----------------------------------STATE EREA
       const [pageWasLoaded , setPageWasLoaded]=useState(false)
       //-----------------------------------USE_EFFECT EREA
       useEffect(()=>{
              setTimeout(()=>setPageWasLoaded(true) , 100)
       } , [])
       useEffect(()=>{
              if(pageWasLoaded)
                      AsyncStorage.setItem("PROFILE_INFO" , JSON.stringify(props.profileState))
       } , [props.profileState])
       //-----------------------------------FUNCTION EREA
       // useBackHandler(()=>{
       //        onBackClick()
       //        return true
       // })
       const onBackClick=()=>props.navigation.goBack()

       const setNewAvatar=(newImage)=>{
                     const objData={jwt : props.profileState.jwt}
                     const fileResult = newImage ? [ {name:'myAvatar' , filename:'myAvatar.esi' , type:newImage.mime , uri:newImage.path  } ]  : null
                     fetchModal.current.uploadData("user/setAvatar" ,
                                                                             objData ,
                                                                             fileResult ,
                                                                             {
                                                                                    onSuccess : responseOfSetNewAvatar ,
                                                                                    onStart:()=>{} ,
                                                                                    onError:()=>{}
                                                                             }  )  
       }
       const responseOfSetNewAvatar=(data)=>{
              if(typeof(data)==="string"){
                     if(data=="ERROR_IN_SEND_IMAGE_DATA"){
                     alertModal.current.show("خطا در بارگزاری تصویر پروفایل",
                                                 "در ارسال تصویر پروفایل خطایی روی داده است و لازم است تصویر مجددا انتخاب و ارسال گردد.",
                                                 "error")
                     return
                     }
                     if(data=="DATA_IS_NOT_VALIDATE"){
                     alertModal.current.show("خطا در بارگزاری تصویر پروفایل",
                                                 "اطلاعات ارسال شده معتبر نمی باشد",
                                                 "error")
                     return
                     }
                     if(data=="NO_VALID_IMAGE_FORMAT"){
                     alertModal.current.show("خطا در بارگزاری تصویر پروفایل",
                                                 "تنها تصاویری با فرمتهای "+"\n"+
                                                 "jpg"+"\n"+
                                                 "jpeg"+"\n"+
                                                 "png"+"\n"+
                                                 "برای تصویر پروفایل قابل پذیرش می باشد.",
                                                 "error")
                     return
                     }
                     if(data=="ERROR_IN_SAVE_DATA_LASTLY"){
                     alertModal.current.show("خطا در بارگزاری تصویر پروفایل",
                                                 "در ثبت نهایی تصویر خطایی روی داده است و لازم است تصویر مجددا انتخاب و ارسال گردد.",
                                                 "error")
                     return
                     }
                     if(data=="ERROR_IN_CONNECT_DATABASE"){
                     alertModal.current.show("خطا در بارگزاری تصویر پروفایل",
                                                 "در ثبت اطلاعات خطایی رود داده است"+"\n"+
                                                 "لطفا پس از لحظاتی مجددا تلاش نمایید.",
                                                 "error")
                     return
                     }
              }
              props.updateProfileInfo( data  )
              props.setToastMessage("بروزرسانی اطلاعات فردی شما با موفقیت انجام شد.")
       }
       //------------------------------------------------
       const onCangeProfileInfo=(nameOrAboutMe , defText)=>{ // NAME OR ABOUT_ME
              popupModal.current.show(nameOrAboutMe , 
                                                 nameOrAboutMe==="NAME" ? "نام ونام خانوادگی" : "درباره من" ,
                                                        nameOrAboutMe==="NAME" ? "نام و نام خانوادگی خود را وارد نمایید": "متن مورد نظر خود را وارد نمایید" , 
                                                        "information" , 
                                                        changeProfileInfo, 
                                                        ()=>{} , 
                                                        "تایید و ادامه" ,
                                                        defText ? defText :
                                                        nameOrAboutMe==="NAME" ?  props.profileState.name: props.profileState.aboutMe , 
                                                        nameOrAboutMe==="NAME" ? "نام ونام خانوادگی ....": "درباره من ....." , 
                                                        nameOrAboutMe ==="NAME" ? 50 : 255 )
       }
       const changeProfileInfo=(nameOrAboutMe , newText="")=>{
              newText = newText.replace(/ +/g , " ").trim()
              if(newText.length<4){
                     confirmModal.current.show("خطا در ورود اطلاعات" , ` ${nameOrAboutMe==="NAME" ? "نام و نام خانوادگی " : "درباره من "}  را با حداقل 4 حرف  وارد نمایید .` ,"error" , 
                                                               ()=>onCangeProfileInfo(nameOrAboutMe , newText ) , "بررسی مجدد" )
                     return
              }
              const objData={
                     jwt  : props.profileState.jwt , 
                     nameOrAboutMe , 
                     newText
              }
              fetchModal.current.fetchData("user/addUpdateProfile" , objData , 
                                                               {
                                                                      onSuccess : responseOfChangeProfileInfo
                                                               } , true , true , true)
       }
       const responseOfChangeProfileInfo = data =>{
                     props.updateProfileInfo(data)
                     props.setToastMessage("بروزرسانی اطلاعات حساب کاربری با موفقیت انجام شد")
       }
       //------------------------------------------------
       const profileAvatar=props.profileState.avatar ? {uri : props.profileState.avatar }  
                                                                                      : props.profileState.sex===1 ? require("../../../assets/images/act/imgDefManAvatar.jpg")
                                                                                                                                       : require("../../../assets/images/act/imgDefWomanAvatar.jpg")
       //--------------------------------------------------
       const [panelWidth , setPanelWidth]=useState(0)
       const onLayout=e => setPanelWidth(e.nativeEvent.layout.width)

       return(
              <PageContainer   title="حساب کاربری" 
                                         containerPadding={0}   
                                          showBackIcon={true}  
                                          onBackIconClick={onBackClick}
                                          hasFlatList  >
              
                            {/* دریافت عکس کاربر */}
                            <View  style={{alignItems:"center" , marginTop:25  }}>
                                   <View onLayout={onLayout}  style={Object.assign({}  , Styles.l1 , Styles.m4 ,Styles.s6)}>
                                          {
                                                 props.profileState.avatar ?
                                                 <View style={{borderWidth:1 , borderColor:"#e0e0e0" , borderRadius:panelWidth}}>
                                                        <TouchableOpacity onPress={()=>imageModal.current.show([{url : props.profileState.avatar}] , 0)}   >
                                                               <ProfileImage source={profileAvatar} width={panelWidth}   />
                                                        </TouchableOpacity>   
                                                 </View> 
                                                 :
                                                 <View style={{borderWidth:1 , borderColor:"#e0e0e0" , borderRadius:panelWidth}}>
                                                        <ProfileImage source={profileAvatar} width={panelWidth} style={{}} />
                                                 </View>
                                          }
                                          <View style={{position:"absolute" , bottom:0 , right:10 }}>
                                                 <MyButton onClick={()=>mdlImageCroper.current.showModal()}
                                                        iconName="camera" 
                                                        buttonStyle={{borderRadius:20,padding:6,marginTop:-30, backgroundColor:"#00ACC1" , elevation:2}}
                                                        buttonIconStyle={{color:"#FFFFFF",fontSize:30  }} />
                                          </View>
                                   </View>
                            </View>
                            {/* دریافت نام کاربر */}
                            <View  style={{marginTop:30 , alignItems:"flex-start" , flexDirection:"row" , paddingHorizontal:16 , paddingVertical:8 }}>
                                   <MyIcon iconName="person" color="#a0a0a0" iconSize={30} />
                                   <View className='displayFlexGrow displayFlexRow w3-border-bottom w3-border-light-gray' 
                                             style={{marginRight:15 , marginLeft:15 , alignItems:"flex-start" , borderBottomWidth:1 , borderColor:"#eeeeee" , flex:1 , flexDirection:"row"}}>
                                          <View className='displayFlexGrow' style={{paddingBottom:10 , flex:1}}>
                                                 <MyText isBlock tinyFont  isBold >نام و نام خانوادگی : </MyText>
                                                 <MyText    style={{paddingHorizontal:16 , paddingVertical:8}}>{props.profileState.name}</MyText> 
                                                 <MyText   tinyFont className="w3-text-gray" style={{padding:"0 0 10px 0"}}>از این نام جهت نمایش پیام های شما استفاده خواهد شد</MyText>
                                          </View>
                                          <MyButton title="" iconName="edit" buttonStyle={{backgroundColor:"transparent" , padding:0 }}
                                                        buttonIconStyle={{color:"#00ACC1"}} onClick={()=>onCangeProfileInfo("NAME" , "")} />
                                   </View>
                            </View> 
                            {/*درباره شما  */}
                            <View  style={{marginTop:10 , alignItems:"flex-start" , flexDirection:"row" , paddingHorizontal:16 , paddingVertical:8}}>
                                   <MyIcon iconName="error_outline" color="#a0a0a0" iconSize={30} />
                                   <View  style={{marginRight:15 , marginLeft:15 , alignItems:"flex-start" , flex:1 , flexDirection:"row" , borderBottomWidth:1 , borderColor:"#eeeeee"}}>
                                          <View className='displayFlexGrow' style={{paddingBottom:10 , flex:1}}>
                                                 <MyText   tinyFont  isBold className="w3-text-gray">درباره من : </MyText>
                                                 <MyText   className=" w3-padding" style={{paddingHorizontal:16}}>{props.profileState.aboutMe ? props.profileState.aboutMe :"---------------"}</MyText> 
                                                 {/* <MyText isBlock tinyFont className="w3-text-gray" style={{padding:"0 0 10px 0"}}>از این نام جهت نمایش پیامه ای شما استفاده خواهد شد</MyText> */}
                                          </View>
                                          <MyButton title="" iconName="edit" buttonStyle={{backgroundColor:"transparent" , padding:0}}
                                                        buttonIconStyle={{color:"#00ACC1"}} onClick={()=>onCangeProfileInfo("ABOUT_ME" , "" )} />
                                   </View>
                            </View> 


              
                     {/* <input type={"file"} style={{display:"none"}} accept="image/png, image/jpeg" onChange={getNewAvatar} ref={refFile} /> */}
                     <MdlImageCrop ref={mdlImageCroper} onSelectImage={setNewAvatar}  />
                     <ImageModal ref={imageModal}  />
                     <Fetch ref={fetchModal} />
                     <Popup ref={popupModal} />
                     <Confirm ref={confirmModal} />
                     <Alert ref={alertModal} />
              </PageContainer>
       )
}

const mapStateToProps=(state)=>({
       profileState : state.profileReducer,
       navbarState:state.navbarReducer
   })
   const mapDispatchToProps=(dispatch)=>({
       setToastMessage : strMessage => dispatch(setToastMessage(strMessage)),
       setProfileInfo : profileInfo => dispatch(setProfileInfo(profileInfo)) ,
       updateProfileInfo : profileInfo => dispatch(updateProfileInfo(profileInfo))
   })
   
   export default connect(mapStateToProps , mapDispatchToProps) (Profile);
//=========================================================================================