//========================================================================================
import React,{useRef , useEffect  , useState, forwardRef, useImperativeHandle}  from 'react'
import { ModalContainer  , MyText, ProfileImage } from './../../../globalComponents/components'
import { View } from 'react-native'
import { formatNumber } from '../../../globalComponents/rejax'
 
const MdlShowPrice=forwardRef((props , ref)=>{
//-----------------------------------GLOBAL VAR EREA
//-----------------------------------REF EREA
//-----------------------------------STATE EREA
const [visible , setVisible]=useState(false)
const [price , setPrice]=useState(0)
//-----------------------------------USE_EFFECT EREA
useImperativeHandle(ref , ()=>({
       show : (_price)=>{
              setPrice(_price)
              setVisible(true)
       }
}))
//-----------------------------------FUNCTION EREA
const hide=()=>setVisible(false)
//--------------------------------------------------
   return(
                   <ModalContainer visible={visible}
                                              title=''
                                              message=''
                                              showTitle={false}
                                              showBackButton={true}
                                              onBackClick={hide}
                                              containerStyle={{padding:0}}
                                              containerClassName=''
                                              contentStyle={{borderRadius:0 , padding:6}}
                                              contentClassName=''
                                              closeWithClickBlankPlace
                                              cloaseWithClickBackButton 
                                              position='bottom'
                                              animation='slideInUp' >
                            <View style={{flexDirection:"row" ,alignItems:"flex-start" }}>
                                   <ProfileImage source={require("./../../../assets/images/logo/logo.png")} width={70} style={{elevation:2 , borderWidth:1 , borderColor:"#eee"}} />
                                   <View style={{flex:1}}>
                                          <MyText isBold  smallFont color="#000"  style={{padding:10 , borderRadius:10 }} >
                                                 {"کاربر گرامی"+"\n\n"+
                                                 " هزینه ثبت آگهی  در روستای شما مبلغ "+"\n"+
                                                               formatNumber(price)+" تومان "+"\n"+
                                                 "می باشد که پیش از ثبت نهایی آگهی , جهت پرداخت به درگاه بانک هدایت  خواهید شد."}  
                                          </MyText >
                                   </View>
                            </View>              
                 </ModalContainer>
   )
})

export default MdlShowPrice;
//=========================================================================================