//========================================================================================
import React, { useState , useRef , useImperativeHandle , forwardRef  } from 'react'
import { TouchableOpacity, View } from 'react-native';
import { ModalContainer, MyButton, MyInput, MySwitch, MyText , MyImage } from '../../../../../../globalComponents/components';
import { arrSocialNetwork } from '../../../../../../globalDef';

//-------------------------------------------------------------------------------------
const SocialNetworkComponent=(props)=>{
    const {id , link , icon , selected=false , onClick} = props

    const [imageWidth , setImageWidth]=useState(0)
    const onLayout= event=>{
        const {width} =event.nativeEvent.layout
        setImageWidth(width)
    }

    return(
        <View  onLayout={onLayout} style={{padding:5 , width:"25%" , height:imageWidth }}>
            <TouchableOpacity onPress={onClick}>
                <View className='w3-button w3-ripple w3-light-gray w3-round-xlarge ' style={{padding:6 , width:"100%" , backgroundColor:"#eeeeee" , borderRadius:20 }} onClick={onClick}>
                    <MyImage source={icon} style={{width:"100%" , height:"100%"}} />
                    {
                        selected ? 
                        <View className='' style={{position:"absolute" , top:-5 , left:-5 , zIndex:3  , padding:0 }}>
                                <MySwitch selected={true} useImage useImageBackgroundColor useImageOpacity />
                        </View> : null
                    }
                </View>
            </TouchableOpacity>
        </View>
    )
}
//---------------------------------------------------------------------------------------

const MdlAddUpdateLink=forwardRef((props , ref)=>{
    const mdlImageCroper=useRef()


    const [visible , setVisible]=useState(false)
    const [linkInfo , setLinkInfo]=useState({id:0 ,   // 0 : add--------- !0 : update
                                                                         socialNetworkId : 0 , 
                                                                         link:''
                                                                    })

    useImperativeHandle(ref , ()=>({
        show:(_linkInfo)=>{
            setLinkInfo(_linkInfo)
            setVisible(true)
        },
        hide:()=>setVisible(false)
    }))
    const hide=()=>setVisible(false)
    const addUpdateLink=()=>  props.onAddUpdateLink(linkInfo)
    
    //----------------------------------------------------------------------------------------------------------
    const changeName=(txtId , newText)=> setLinkInfo({...linkInfo  , [txtId] : newText ? newText.trim() : ""})
    //---------------------------------------------------------------------------------------------------------
   return(
       <ModalContainer visible={visible}
                                   showTitle={true}
                                   position="top"
                                   animation="slideInDown"
                                   title={linkInfo.id ? "برزورسانی اطلاعات دسته بندی":"ثبت دسته بندی جدید"}
                                   showBackButton
                                   onBackClick={hide}
                                   containerClassName=""
                                   containerStyle={{padding:10}}
                                   contentClassName="w3-col s12"
                                   contentStyle={{ }}>
            <View  style={{padding:6 , marginTop:16}}>
                     <View  style={{ flexDirection:"column"}}>
                            <MyText isBlock isBold smallFont color="#456e7a"  style={{marginBottom:5 , width:"100%"}} className="">شبکه اجتماعی مورد نظر خود را انتخاب نمایید</MyText>
                            <View className=' w3-row-reverse w3-padding  '   style={{padding:10 , flexDirection:"row-reverse" , flexWrap:"wrap"}}>
                                {/* <View>{JSON.stringify(props.socialNetworkState)}</View> */}
                                   {
                                    arrSocialNetwork.map(socilaNetwork => <SocialNetworkComponent key={socilaNetwork.id} 
                                                                                                                                             {...socilaNetwork} 
                                                                                                                                             selected={socilaNetwork.id===linkInfo.socialNetworkId}
                                                                                                                                             onClick={()=>setLinkInfo({...linkInfo , socialNetworkId:socilaNetwork.id})} />)
                                   }
                            </View>
                            <View className='displayFlexGrow w3-small- w3-col s12 w3-margin-top'style={{padding:5 , marginTop:17 }}>
                                <MyText isBlock isBold smallFont color="#456e7a"  style={{marginBottom:5}}>آدرس صفحه فروشگاه  : </MyText>
                                   <MyInput id ='link'  noShadow           
                                            placeholder="https://...."
                                            value={linkInfo.link}
                                            disabled={false}  
                                            onChangeText={changeName}       
                                            password={false}  
                                            maxLength ={255}        
                                            textAlign='right' 
                                            inputStyle={{textAlign:"left"}}          
                                            containerStyle={{borderWidth:1 , borderColor:"#e0e0e0" , borderRadius:10 }}  />   
                            </View>
                     </View>
            </View>
            <View className='displayFlexRowReverse   w3-padding-small' style={{marginVertical:16 , alignItems:"flex-start"}}>
                     <MyButton smallFont  onClick={addUpdateLink}
                                   title="ثبت و بروزرسانی اطلاعات" />
              </View>
           
       </ModalContainer>
   )
})
export default  MdlAddUpdateLink
//=========================================================================================