//========================================================================================
import React,{  useEffect  , useState, useImperativeHandle, forwardRef, useRef }  from 'react'
import { ModalContainer , Alert , Popup, MySwitch, NoItem, Waiting, ProfileImage, MyText , MyButton, Confirm, AnimatableView } from './../../../../../globalComponents/components'
import Fetch from "./../../../../../globalComponents/fetch"
import { View } from 'react-native'
import { FlatList } from 'react-native'
import { connect } from 'react-redux'
import { setToastMessage } from '../../../../../redux/toastMessage'

//--------------------------------------------------------------------
const CommentAnswerComponent=(props) =>{
       const { answer  , sellerAvatar} =props
       return(
              <View style={{flexDirection:"row" ,  borddrTopWidth:1 , borderColor:"#e0e0e0" , paddingTop:10}}>
                     <ProfileImage source={sellerAvatar} width={35} />
                     <View style={{flex:1 , paddingRight:5}}>
                            <MyText smallFont color="gray">{answer}</MyText>
                     </View>
              </View>
       )
}
const CommentComponent= props =>{
       const {id , date  ,writerName , writerAvatar ,  comment , time , writerAboutMe  , hasAnimation=false , answer ,sellerAvatar="" , validate , toggleCommentValidate , addUpdateCommentAnswer} = props
       const avatar= writerAvatar ? {uri : writerAvatar} : require("../../../../../assets/images/act/imgDefManAvatar.jpg")

       const toggleValidate=()=>  toggleCommentValidate(id , validate)
       const addUpdateAnswer=()=>addUpdateCommentAnswer(id , answer)

       const commentInfo = <View  style={{flexDirection: "row" , borderBottomWidth:1 , borderColor:"#e0e0e0" , 
                                                               padding:8 , alignItems:"flex-start" , marginBottom:10}}>
                                                 <ProfileImage source={avatar} width={40}  style={{borderWidth:1 , borderColor:"#eeeeee" , padding:3}} />                            
                                                 <View style={{flex:1 ,   padding:8 , paddingBottom:1 }} >
                                                               <View style={{flexDirection:"row"}}>
                                                                      <MyText isBlock tinyFont  color="brow-reversen" style={{flex:1}} >{writerName}</MyText>
                                                                      <View style={{flexDirection:"row-reverse"}}>
                                                                        <MyText isBlock tinyFont  color="gray" >{date}</MyText>
                                                                        <MyText isBlock tinyFont  color="gray" style={{marginLeft:10}} >{time}</MyText>
                                                                      </View>
                                                               </View>
                                                               {
                                                                      writerAboutMe  ?
                                                                      <View  className="w3-text-gray w3-tiny" style={{ fontWeight:"bold"}}>
                                                                             <MyText tinyFont color='#9a9a9a'>{writerAboutMe}</MyText>
                                                                      </View> : null
                                                               }
                                                               <MyText isBlock smallFont >{comment}</MyText>
                                                               {/* <MyText smallFont  dir="ltr" style={{textAlign:"left" }}>{time}</MyText> */}
                                                               {
                                                                      answer ? 
                                                                      <View style={{}}>
                                                                             <CommentAnswerComponent  answer={answer} sellerAvatar={sellerAvatar} />
                                                                      </View> : null
                                                               }
                                                                <View style={{flexDirection:"row",  marginTop:15}}>
                                                                    <View style={{flex:1 }}>
                                                                        <MySwitch tinyFont title="پیام نمایش داده شود" selected={validate} useImage useBackgroundImage onClick={toggleValidate} />
                                                                    </View>
                                                                    <MyButton  tinyFont onClick={addUpdateAnswer}
                                                                                         title={"پاسخ به پیام"} 
                                                                                         iconName='comment' 
                                                                                         buttonStyle={{paddingVertical:2 , paddingHorizontal:5}}
                                                                                         buttonIconStyle={{fontSize:20}}
                                                                                         buttonTExtStyle={{}} />
                                                                </View> 
                                                 </View> 

                                          </View>
       if(hasAnimation) 
              return(
                     <AnimatableView animation={"zoomIn"} duration={800}>
                            {
                                   commentInfo
                            }
                     </AnimatableView>
              )
       else

              return(
                     <View>
                            {
                                   commentInfo
                            }
                     </View>
              )
}
//--------------------------------------------------------------------

const MdlGoodsComment=forwardRef((props , ref)=>{
//-----------------------------------GLOBAL VAR EREA
//-----------------------------------REF EREA
const fetchModal=useRef()
const alertModal=useRef()
const popupModal=useRef()
const confirmModal=useRef()
//-----------------------------------STATE EREA
const [visible , setVisible]=useState(false)
const [goodsInfo , setGoodsInfo]=useState({
                                                                            id:0 , 
                                                                            title:'' , 
                                                                            price:'' , 
                                                                            saveDate:''
                                                                        })
const [mustFilter , setMustFilter]=useState(true)
const [pageWasLoaded ,setPageWasLoaded]=useState(false)
const [ fetchState , setFetchState ]=useState({ fetchFlag:false , errorFlag:false });
const [commentInfo , setCommentInfo]=useState({
                                                                                arrComment:[] , 
                                                                                finished:false
                                                                            })
//-----------------------------------USE_EFFECT EREA
useImperativeHandle(ref , ()=>({
    show : _goodsInfo =>{
                                            setMustFilter(true)
                                            setGoodsInfo(_goodsInfo)
                                            setCommentInfo({arrComment :[] , finished:false})
                                            setVisible(true)
                                    }
}))
useEffect(()=>{
    if(visible)
        setTimeout(()=> setPageWasLoaded(true) , 200)
    else
        setPageWasLoaded(false)
} , [visible])

useEffect(()=>{
        if(pageWasLoaded){
            getCommentList()
        }
} , [mustFilter , pageWasLoaded])
//-----------------------------------FUNCTION EREA
const hide=()=>{
    setPageWasLoaded(false)
    setVisible(false)
}
//--------------------------------------
const getCommentList=()=>{
    const objData={
           jwt : props.profileState.jwt ,
           goodsId : goodsInfo.id , 
           lastCommentId : commentInfo.arrComment.length ? commentInfo.arrComment[commentInfo.arrComment.length-1].id : 0  ,
           mustFilter : mustFilter ? true : false
    }
    fetchModal.current.fetchData("user/getMyGoodsCommentList" , objData , 
                                                       {
                                                            onStart : ()=>setFetchState({fetchFlag:true , errorFlag:false}) , 
                                                            onError : ()=>setFetchState({fetchFlag:false , errorFlag:true}) , 
                                                            onSuccess:responseOfGetCommentList,
                                                            onBackFromErrorConfirm:()=>setFetchState({fetchFlag:false , errorFlag:false}) , 
                                                       } , false , true , true)
}
const responseOfGetCommentList=  data   =>{
    setFetchState({fetchFlag:false , errorFlag:false})
    setCommentInfo({ arrComment : [...commentInfo.arrComment , ...data.arrComment ] , finished : data.finished})
}
//----------------------------------------
const toggleCommentValidate= (commentId , currentValidate) =>{
    const objData={
        jwt:props.profileState.jwt , 
        commentId ,
        newValidateState : !currentValidate 
    }
    fetchModal.current.fetchData("user/toggleCommentValidate" , objData  , 
                                                      {
                                                            onSuccess : responseOfToggleCommentValidate
                                                      } , true , true , true
                                                     )
}
const responseOfToggleCommentValidate= data =>{
    setCommentInfo({
        ...commentInfo , 
        arrComment: commentInfo.arrComment.map(comment => comment.id===data.commentId ? Object.assign({} , comment , {validate : data.newValidateState})  : comment)
    })
}
//--------------------------------------
const addUpdateCommentAnswer= (commentId , currentAnswer) => popupModal.current.show(commentId , 
                                                                                                                                                "پاسخ به پیام " , 
                                                                                                                                                "پاسخ خود را در کادر زیر وازد نمایید ." ,
                                                                                                                                                "question" ,
                                                                                                                                                onAddUpdateCommentAnswer , 
                                                                                                                                                ()=>{} , 
                                                                                                                                                "ذخیره و بازگشت" , 
                                                                                                                                                currentAnswer ? currentAnswer : "" , 
                                                                                                                                                "پاسخ به پیام کاربر...." ,
                                                                                                                                                2000 , 
                                                                                                                                                "default",
                                                                                                                                                true , 
                                                                                                                                                true
                                                                                                                                            )
const onAddUpdateCommentAnswer= (commentId , answer)=>{
                const objData={
                    jwt:props.profileState.jwt , 
                    commentId ,
                    answer  : answer ? answer.trim().replace(/ +/g , " ").replace(/\n+/g , "\n") : ""
                }
                fetchModal.current.fetchData("user/addUpdateCommentAnswer" , objData  , 
                                                                    {
                                                                            onSuccess : responseOfAddUpdateCommentAnswer
                                                                    } , true , true , true
                                                                )
}
const responseOfAddUpdateCommentAnswer= data =>{
    setCommentInfo({
        ...commentInfo , 
        arrComment: commentInfo.arrComment.map(comment => comment.id===data.commentId ? Object.assign({} , comment , {answer : data.answer})  : comment)
    })
    props.setToastMessage("ثبت و بروزرسانی پاسخ به پیام با موفقیت انجام شد.")
}
//--------------------------------------
const loadMore=()=>{
    if(visible && pageWasLoaded && commentInfo.arrComment.length && !commentInfo.finished)
        getCommentList()
    return
}
const toggleMustFilter=()=>setMustFilter(!mustFilter)
//--------------------------------------------------
const avatar =  props.profileState.avatar ?    {uri : props.profileState.avatar} :  require("../../../../../assets/images/act/imgDefManAvatar.jpg")
//--------------------------------------------------
   return(
                   <ModalContainer visible={visible}
                                              title='مدیریت پیام های مربوط به کالا'
                                              message=''
                                              showBackButton={true}
                                              showTitle={false}
                                              onBackClick={hide}
                                              containerStyle={{padding:0 }}
                                              contentStyle={{ height:"80%" }}
                                              position='bottom'
                                              animation='slideInUp'
                                              hasFlatList >
                            <FlatList  data={ commentInfo.arrComment} 
                                                                      style={{flex:1 }} 
                                                                      contentContainerStyle={{paddingBottom:70}}
                                                                      keyExtractor={(item , index)=>index.toString()}
                                                                      onEndReachedThreshold={0.15}
                                                                      onEndReached={ loadMore  }
                                                                      ListHeaderComponent={ <MySwitch smallFont title="تنها پیام های نیازمند به پاسخ را نمایش بده" 
                                                                                                                            containerStyle={{padding:5 , borderBottomWidth:1 , borderBotomColor:"#e0e0e0" , backgroundColor:"#EEE" , margin:5 , borderRadius:5 }} 
                                                                                                                            selected={mustFilter} onClick={toggleMustFilter} useImage useImageOpacity useImageBackgroundColor  /> }
                                                                      ListFooterComponent={fetchState.fetchFlag ?   <Waiting visible />  : null }
                                                                      ListEmptyComponent={<NoItem title="دیدگاه های ثبت شده برای محصول" message="هیچ نظر و دیدگاهی برای این کالا ثبت نشده است" 
                                                                                                                      visible icon={require("./../../../../../assets/images/logo/logo.png")} />}                
                                                                      renderItem={({item})=> <CommentComponent      key={item.date} 
                                                                                                                                                {...item} myId={props.profileState.id} 
                                                                                                                                                toggleCommentValidate={toggleCommentValidate}
                                                                                                                                                addUpdateCommentAnswer={addUpdateCommentAnswer}
                                                                                                                                                sellerAvatar={avatar} />}  />
                        
                        <Fetch ref={fetchModal} />
                        <Popup ref={popupModal} />
                        <Confirm ref={confirmModal} />
                        <Alert ref={alertModal} />
                                                                  
                 </ModalContainer>
   )
})

const mapStateToProps = state =>({
    profileState : state.profileReducer
})
const mapDispatchToProps= dispatch =>({
    setToastMessage : strMessage => dispatch(setToastMessage(strMessage))
})
export default connect(mapStateToProps , mapDispatchToProps , null , {forwardRef:true})(MdlGoodsComment);
//=========================================================================================
