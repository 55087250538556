//========================================================================================
import React,{  }  from 'react'
import { Dimensions, TouchableOpacity, View } from 'react-native';
import {  MyText  , MyImage ,MyButton, MySelect } from './../../../../globalComponents/components';
import { connect } from 'react-redux';
//----------------------------------------------------------------------------
const InfoComponent= props=>{ 
      const {title , icon } = props
      return(
            <View style={{}}>
                  <MyButton tinyFont disabled opacityOnDisabledOff
                              title={title}
                              iconName={icon} 
                              buttonStyle={{backgroundColor:"transparent" , justifyContent:"flex-end" , alignItems:"flex-start"  }}
                              buttonIconStyle={{color:"#e57373" , fontSize:21}}
                              buttonTextStyle={{color:"#456e7a" , }} /> 
            </View>
      )
}
//----------------------------------------------------------------------------
const ShopProperties=(props)=>{
//-----------------------------------GLOBAL VAR EREA
const {name , sellerName , description , shopAvatar , categoryId} = props
//-----------------------------------FUNCTION EREA
//--------------------------------------------------
const avatarPanelWidth=Dimensions.get("window").width/5
   return(
                  <View  style={{  }}>
                        <View style={{flexDirection:"row-reverse" , alignItems:"center"}}  >
                              <View style={{width:avatarPanelWidth , height:avatarPanelWidth , alignItems:"center" , borderColor:"#26c6da" , 
                                                justifyContent:"center" , padding:3 , elevation:2 , borderWidth:3 , borderRadius:40 ,  }}>
                                    <MyImage  source={shopAvatar  ?  {uri : shopAvatar} : require("./../../../../assets/images/act/imgDefImage.png")}  resizeMode="cover"
                                                                        style={{width:"100%" , height:"100%",borderRadius:avatarPanelWidth }} />
                              </View>
                              <View style={{flex:1 ,padding:0 , marginLeft:10 }}>
                                    <InfoComponent     title={"دسته بندی : "+props.bazaarState.arrShopCategory.filter(category => category.id===categoryId)[0].name}   icon='apps'  />
                                    <InfoComponent     title={name}   icon='storefront'  />
                                    <InfoComponent    title={sellerName}   icon='person'  />
                              </View>
                        </View>
                        <View style={{}}>
                              <InfoComponent   title={description}   icon='description' />
                        </View>
                  </View>
   )
}


const mapStateToProps=state =>({
      bazaarState:state.bazaarReducer
})
export default connect(mapStateToProps)(ShopProperties);
//=========================================================================================