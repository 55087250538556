
import React, { forwardRef, useImperativeHandle, useState , useCallback , useRef } from "react"
import {TouchableOpacity , Image , View} from "react-native-web"
import { MyButton, ModalContainer, MyCamera , ActionButton ,MyText  ,MyImage } from "../../globalComponents/components"
import Cropper from 'react-easy-crop'
import getCroppedImg from "./makeCropedImage";
import axios from "axios";
import { isDesktop } from "react-device-detect";
//==============================================================================================================

const ButtonComponent =(props)=>{
        const {title , imageSource , onClick , style={} } = props
        const [imageWidth , setImageWidth]=useState(0)
        
        const onLayout=event =>{
            const { width, height } = event.nativeEvent.layout;
            setImageWidth(width)
        }

        return(
            <TouchableOpacity onPress={onClick} >
                <View style={Object.assign({} , {alignItems:"center"} , style)} onLayout={onLayout}>
                    <MyImage   source={imageSource} style={{width:"100%" , height:70 }} resizeMode={"contain"} />
                    <MyText style={{marginTop:10}}>{title}</MyText>
                </View>
            </TouchableOpacity>
        )
}


const MdlImageCrop = forwardRef( (props,ref)=>{

    const [visible,setVisible]=useState(false)
    const fileInputRef=useRef()
    const mdlCameraRef=useRef()

    const [selectedImageInfo , setSelectedImageInfo]=useState(null)
    const [selectedImageInProgress , setSelectImageInProgres]=useState(false)

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    
    useImperativeHandle(ref , ()=>({
        showModal(source){
            // fileInputRef.current.value=null
            setVisible(true)
            setSelectedImageInfo(null)
            setCrop({x:0,y:0})
            setZoom(1)
            setRotation(0)
            setCroppedAreaPixels(null)
            setCroppedImage(null)

            if(source==="GALLERY")
                selecteImageFromGallery()
            else if(source==="CAMERA")
                 selectImageFromCamera()
            
            else{

            }
        }
    }))
    //=================================================================================
    const hideModal=()=>setVisible(false)
    //=================================================================================
    // const handleTakePhoto=(dataUri)=> console.log(dataUri)
    const  cameraGetImageCompeleted = (dataUri) =>   setSelectedImageInfo(dataUri)
    const handleCameraError=()=>alert("در ردبافت اطلاعات تصویر خطایی روی داده است.")
    //=======================================================================================================================
    const resetSelectedImageInfo=()=> {
        setSelectImageInProgres(false)
        setSelectedImageInfo(null)
    }
    const selectImageFromCamera=()=>{
        setSelectImageInProgres(true)
        const width=isDesktop ? 400 : 400
        const height=isDesktop ? 400 /2: 400/2
        mdlCameraRef.current.show(width , height , false  , 1028 , 800)
    }
    const selecteImageFromGallery=()=>{
        setSelectImageInProgres(true)
        fileInputRef.current.click()
    }
    //======================================================================================================================
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {  setCroppedAreaPixels(croppedAreaPixels); }, []);
    //=====================================================================================================================
    const corpedImageAndShow= useCallback(async () => {
            try {
                    const croppedImage = await getCroppedImg(
                        selectedImageInfo,
                        croppedAreaPixels,
                        rotation
                    );
                    setCroppedImage(croppedImage);
            } catch (e) {
            //   console.error(e);
            }
    }, [croppedAreaPixels, rotation, selectedImageInfo]);
    //======================================================================================================================
    const returnCropedImage=()=>{
        hideModal()
        const config = { responseType: 'blob' };
        axios.get(croppedImage, config)
        .then(response => {
           props.onSelectImage(response.data)
        })
        .catch(error => {
            // console.log(error)
            alert("در دریافت فایل تصویر خطایی روی داده است .!!!")
        })
    }
    const handleImageUpload = async (e) => { 
        if(e.target.files && e.target.files[0]){
            setSelectedImageInfo(URL.createObjectURL(e.target.files[0]));
        }     
    };

    const panelWidth=window.innerWidth/5

    return(
        <ModalContainer visible={visible} 
                        title=""
                        showTitle={true}
                        showBackButton={!selectedImageInfo ? true : false}
                        animation="slideInUp"
                        onBackClick={ ()=>setVisible(false)}
                        animationDuration={500}
                        closeWithClickBlankPlace ={false}
                        closeWithClickBackButton
                        position={!selectedImageInfo ? "bottom" : "center" } 
                        containerStyle={{}}
                        contentStyle={{paddingHorizontal:0 , width:"100%"  }}
                        contentClassName={` ${!selectedImageInfo ?"w3-white" : "w3-transparent"} `} >
            
            {
                !selectedImageInfo ?
                    // <div className=" displayFlexRow" style={{flexDirection:"row-reverse" }}>
                    //     <div style={{flex:1 , paddingHorizontal:10}}>
                    //         <MyButton   smallFont  
                    //                     title="دریافت عکس از گالری" 
                    //                     onClick={selecteImageFromGallery} 
                    //                     buttonStyle={{marginVertical:10,backgroundColor:'#EFEBE9',borderRadius:5,elevation:1,justifyContent:"flex-end" ,margin:3 }}
                    //                     buttonTextStyle={{color:'#456e7a'}}
                    //                     buttonIconStyle={{color:'teal',fontSize:25}} 
                    //                     iconName='apps' />
                    //     </div>
                    //     <div style={{flex:1 , paddingHorizontal:10}}>
                    //         <MyButton   smallFont  
                    //                     title="دریافت عکس از دوربین" 
                    //                     onClick={selectImageFromCamera}  
                    //                     buttonStyle={{marginVertical:10,backgroundColor:'#EFEBE9',borderRadius:5,elevation:1,justifyContent:"flex-end",margin:3 }}
                    //                     buttonTextStyle={{color:'#456e7a'}}
                    //                     iconName="camera"
                    //                     buttonIconStyle={{color:'teal',fontSize:25}}   />
                    //     </div>
                    // </div>
                    <View style={{padding:5,paddingVertical:5 , flexDirection:"row"}}>
                                  <View style={{flex:1 , padding:10}}>
                                        <ButtonComponent title="انتخاب عکس از گالری" imageSource={require("./../../assets/images/act/imgGallery.png")} 
                                                                      onClick={selecteImageFromGallery}   />
                                  </View>
                                  <View style={{flex:1 , padding:10}}>
                                        <ButtonComponent title="دریافت عکس از دوربین" imageSource={require("./../../assets/images/act/imgCamera.png")} 
                                                                     onClick={selectImageFromCamera}      />
                                  </View>
                            </View>
                :null
            }

            {
                selectedImageInfo && !croppedImage ?
                <div style={{width:"100%" , height:panelWidth , alignSelf:"center" , overflow:"hidden"}}>
                    <Cropper      image={selectedImageInfo}
                                        crop={crop}
                                        rotation={rotation}
                                        zoom={zoom}
                                        zoomSpeed={4}
                                        maxZoom={3}
                                        zoomWithScroll={true}
                                        showGrid={true}
                                        aspect={4 / 4}
                                        cropShape="round"
                                        style={{mediaStyle:{backgroundColor:"transparent"} , containerStyle:{backgroundColor:"transparent"}}}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onZoomChange={setZoom}
                                        onRotationChange={setRotation}  />
                        <ActionButton marginFromEadge={10} marginFromBottom={10} backgroundColor="#f5f5f5" 
                                    iconColor="teal" actIcon="check" onClick={corpedImageAndShow} />
                        <ActionButton marginFromEadge={10} marginFromBottom={10} backgroundColor="#f5f5f5" 
                                    position="right" iconColor="#e57373" actIcon="close" onClick={resetSelectedImageInfo} />
                </div>
                :null
            }
            {
                croppedImage ?
                <div className="displayFlexRow displayFlexCenter">
                    <div className=" w3-transparent w3-col s10    displayFlexCenter displayFlexRow" style={{}}>
                        <img className="cropped-image" src={croppedImage } alt="cropped" style={{width:"100%" , height:"100%" , borderRadius:300}} />
                        <ActionButton marginFromEadge={10} marginFromBottom={10} backgroundColor="#f5f5f5" 
                                        iconColor="teal" actIcon="check" onClick={returnCropedImage} />
                        <ActionButton marginFromEadge={10} marginFromBottom={10} backgroundColor="#f5f5f5" 
                                        position="right" iconColor="#e57373" actIcon="close" onClick={()=>setCroppedImage(null)} />
                    </div>
                </div>
                :null 

            }
            <input  type="file"
                    name="cover"
                    ref={fileInputRef}
                    onChange={handleImageUpload}
                    accept="image/png, image/jpeg"
                    style={{ display: "none" }} />
            <MyCamera ref={mdlCameraRef} onGetImage={cameraGetImageCompeleted} onBackClick={resetSelectedImageInfo} />
        </ModalContainer>
    )
})

export default MdlImageCrop;