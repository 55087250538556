//=====================================================================================================================================================
const ADD_ANNOUNCEMENT="ADD_ANNOUNCEMENT"
const DELETE_ANNOUNCEMENT="DELETE_ANNOUNCEMENT"
const ADD_UPDATE_ANNOUNCEMENT="ADD_UPDATE_ANNOUNCEMENT"
const SET_SEARCH_ANNOUNCEMENT="SET_SEARCH_ANNOUNCEMENT"
const REFRESH_ANNOUNCEMENT="REFRESH_ANNOUNCEMENT"
//=====================================================================================================================================================
export const addChapter= chapterInfo => ({type:ADD_ANNOUNCEMENT , chapterInfo})
export const deleteChapter= chapterId => ({type:DELETE_ANNOUNCEMENT , chapterId})
export const addUpdateChapter= chapterInfo => ({type:ADD_UPDATE_ANNOUNCEMENT , chapterInfo})
export const setSearchChapter=(strSearch)=>({type : SET_SEARCH_ANNOUNCEMENT ,  strSearch})
export const refreshChapter=( )=>({type : REFRESH_ANNOUNCEMENT })
//=====================================================================================================================================================
const chapterInit={
       arrChapter:[] ,  /* [{  id , 
                                            writerName , 
                                            writerAvatar ,
                                            writerAboutMe, 
                                            title , 
                                            matn , 
                                            chapterTypeName ,
                                             targetCategoryId , 
                                             saveDate  , 
                                             chapterAvatar, 
                                             validate , 
                                             commentable , 
                                             managerDescription , 
                                             likeCount , 
                                             dislikeCount , 
                                             isMyChapter    }] */
       finished:false,
       refresh:false , 
       strSearch:""
}

export default (state=chapterInit , action)=>{
       switch(action.type){
              case ADD_ANNOUNCEMENT : return { 
                     ...state , 
                     arrChapter : [...state.arrChapter , ...action.chapterInfo.arrChapter ] , 
                     finished  : action.chapterInfo.finished,
                     refresh:false
              }
              case ADD_UPDATE_ANNOUNCEMENT : return {
                     ...state ,
                     arrChapter :  state.arrChapter.filter(chapter => chapter.id===action.chapterInfo.id).length  ? 
                                                                        state.arrChapter.map(chapter => chapter.id===action.chapterInfo.id ? action.chapterInfo : chapter )
                                                                       :[action.chapterInfo ,   ...state.arrChapter   ]
              }
              case SET_SEARCH_ANNOUNCEMENT : return{
                     arrChapter : [] , 
                     finished:false , 
                     refresh:false , 
                     strSearch : action.strSearch
              }
              case REFRESH_ANNOUNCEMENT : return{
                     ...state , 
                     arrChapter : [] , 
                     finished:false,
                     refresh:true,   
              }
              case DELETE_ANNOUNCEMENT : return {
                     ...state ,
                     arrChapter : state.arrChapter.filter(chapter => chapter.id!==action.chapterId)
              }                                                                   
              default:return state
       }
}