//========================================================================================
import React,{useRef   , useState}  from 'react'
import {RefreshControl, View , FlatList, TouchableOpacity} from "react-native"
import { Alert, Confirm , Waiting , NoItem , MyText , MyImage, MyIcon, AnimatableView, MyButton } from '../../../globalComponents/components';
import Fetch from "../../../globalComponents/fetch"

import { connect } from 'react-redux';
import defImage from "../../../assets/images/act/imgDefImage.png"
import { formatNumber } from '../../../globalComponents/rejax';
import logoImage from "../../../assets/images/logo/logo.png"
import { setToastMessage } from '../../../redux/toastMessage';
import GoodsShopPlaceholder from '../../../commonComponents/goodsShopPlaceholder';
import {useNavigation} from "@react-navigation/native"
import Styles from '../../../styles';
//-----------------------------------------------------------------------------------------------
const GoodsBazaarComponent=props =>{
    const {id , arrImageFile , goodsState , categoryName  , title   ,onSelectGoods , saveDate , price  } =props

    const [imageWidth , setImageWidth ]=useState(0)
    const onLayout=e => setImageWidth(e.nativeEvent.layout.width)

    return (
            <TouchableOpacity   onPress={onSelectGoods} >
                <View  style={{borderBottomWidth:1 , borderColor:"#eeeeee" ,  padding:2 , paddingHorizontal:5, paddingBottom:6 , flexDirection:"row-reverse" }} >
                        <View onLayout={onLayout}   style={Object.assign({} , {height:imageWidth} , Styles.l1 , Styles.m2 , Styles.s3)} >
                            <MyImage  source={arrImageFile.length ? {uri:arrImageFile[0].filename} : defImage }  style={{width:imageWidth,height:imageWidth , borderRadius:8}} />
                        </View>
                        
                        <View  style={{padding:5 ,minHeight:imageWidth ,flexDirection:"column" ,  justifyContent:"space-between" , flex:1 }}>
                            <View  style={{justifyContent:"space-between" , flex:1}}>
                                    <MyText  isBold  color="#000" style={{flex:1}} >{title}</MyText>
                                    <View>
                                        <MyText  tinyFont   style={{}}>{"دسته بندی : "+categoryName}</MyText>
                                        <MyText isBold smallFont color="#e57373" style={{}}>{`قیمت :  ${price ? formatNumber(price)+" تومان" : "تماس بگیرید"}`}</MyText> 
                                        <View style={{flexDirection:"row"}}>
                                            {
                                                goodsState ? <MyText isBlock tinyFont   color="gray" style={{flex:1}} >{goodsState }</MyText> : null
                                            }
                                            <MyText isBlock tinyFont   color="gray" style={{marginLeft:8}} >{saveDate}</MyText>
                                        </View>
                                    </View>
                            </View>
                            
                        </View>
                </View>
            </TouchableOpacity>
    )
}
const GoodsMyBazaarComponent=props =>{
    const {id  , arrImageFile    , title  , price , onSelectGoods , onLongSelectGoods , showCommentWithoutAnswer , hasCommentWithoutAnswer  } =props

    const [imageWidth , setImageWidth ]=useState(0)
    const onLayout=e => setImageWidth(e.nativeEvent.layout.width)

    const onLongPress=()=>onLongSelectGoods()
    const onSelect=()=>onSelectGoods()
    return (
            <TouchableOpacity style={{padding:5,marginTop:4  , width:"50%"  }}  onPress={onSelect} onLongPress={onLongPress}>
                <View   style={{padding:2 , flex:1  , backgroundColor:"#f0f0f0" , borderRadius:10  ,justifyContent:"space-between"  , elevation:1 }}  >
                    <View  style={{ padding:2 , paddingBottom:6 }} >
                            <View onLayout={onLayout}    >
                                <MyImage source={arrImageFile.length ? {uri : arrImageFile[0].filename} : defImage }    
                                               style={{width:imageWidth, height:imageWidth  , borderRadius:8}} />
                            </View>
                            <View  style={{padding:5 , justifyContent:"space-between" ,flex:1 }}>
                                <MyText isBlock isBold smallFont color="#9e9e9e" style={{flex:1}} >{title}</MyText>
                                <MyText isBlock tinyFont   style={{}}>{`قیمت :  ${price ? formatNumber(price)+" تومان" : "-----------"}`}</MyText> 
                            </View>
                    </View>
                </View>
                {
                    showCommentWithoutAnswer && hasCommentWithoutAnswer ? 
                    <View style={{position:"absolute" , top:0 , right:0 , padding:2 , backgroundColor:"#e57474" , borderRadius:6 , elevation:1  }}>
                        <MyIcon iconName="live_help"  color="#FFF" />
                    </View> : null
                }
            </TouchableOpacity>
    )
}
const GoodsListComponent=(props)=>{
        //-----------------------------------------------------------------------------------------------GLOBAL VAR EREA

        const {
                            isMyGoods=false ,
                            shopId=0 ,  
                            fetchState={fetchFlag:false , errorFlag:false} , 
                            goodsInfo={goodsInfo} ,
                            arrCategory=[],
                            arrGoodsState=[] , 
                            getGoods=()=>{} , 
                            onSelectGoods=()=>{} , 
                            onRefresh=()=>{} , 
                            strSearch="" , 
                            onLongSelectGoods=()=>{} ,
                            onAddCategory=()=>{} , 
                            headerComponent=null ,
                    }=props
        //----------------------------------------------------------------------------------------------REF EREA
        const alertModal=useRef()
        const confirmModal=useRef()
        const fetchModal=useRef()  
        //---------------------------------------------------------------------------------------------
        const initDataToShow=()=>{
            if(strSearch)
                        return goodsInfo.arrGoods.filter(goods=>goods.title.indexOf(strSearch)!==-1 )      
            else
                return goodsInfo.arrGoods
        }
        //--------------------------------------------
        const loadMore=()=>{
            if(!goodsInfo.finished && goodsInfo.arrGoods.length  && !fetchState.fetchFlag && !fetchState.errorFlag && !goodsInfo.refresh){
                getGoods()
            }
        }
        //-----------------------------------------------------------------------------------------------
        const loadingComponent=()=>{
              if(fetchState.fetchFlag && !fetchState.errorFlag){
                     if(!goodsInfo.arrGoods.length )
                            return <View style={{padding:5}}>
                                                 <GoodsShopPlaceholder isMyGoods={isMyGoods}  />
                                                 <GoodsShopPlaceholder isMyGoods={isMyGoods} />
                                                 <GoodsShopPlaceholder  isMyGoods={isMyGoods} />
                                          </View>
                     else
                            return <Waiting  visible={fetchState.fetchFlag} 
                                                                      flexDirection="column" 
                                                                      backgroundColor="#ffffff" 
                                                                      marginTop={30}
                                                                      color="#546e7a" />
              }
       }
       const errorEmptyComponent=()=>{
        if(!fetchState.fetchFlag){
                if(fetchState.errorFlag){ //ارور اتفاق افتاده است
                    if(!goodsInfo.arrGoods.length && !goodsInfo.finished )  //در همان ابتدا خطا داشته ایم
                            return <View className='displayFlexGrow displayFlexRow displayFlexCenter'>
                                                    <NoItem visible={true} title="خطا در دریافت اطلاعات" 
                                                                message={"در دریافت اطلاعات خطایی روی داده است!!!!"+"\n"+
                                                                                "این مشکل ممکن است در نتیجه قطعی و یا کندی شبکه اینترنت شما باشد"}
                                                                showTryAgainButton={true} tryAgain={getGoods} />
                                        </View> 
                }
                else{   
                         if(!goodsInfo.arrGoods.length && goodsInfo.finished){ //هیچ کالایی ثبت نشده است
                            if(goodsInfo.strSearch) //آیا جستجو انجام شده است
                                return <View className='displayFlexGrow displayFlexRow displayFlexCenter'>
                                                    <NoItem visible={true} title="گزارش برنامه" contentStyle={{boxShadow:"0 0 0 0 #FFFFFF"}}
                                                            icon={logoImage}
                                                                    message={ "جستجو نتیجه ای نداشت!!!" } 
                                                                    //showTryAgainButton={true} tryAgain={getGoods} 
                                                                    />
                                            </View>
                            else{
                                    if(shopId){ //یک فروشگاه است
                                        if(isMyGoods)  //فروشگاه من است
                                            return <View className='displayFlexGrow displayFlexRow displayFlexCenter'>
                                                                <NoItem visible={true} title="گزارش برنامه" contentStyle={{boxShadow:"0 0 0 0 #FFFFFF"}}
                                                                        icon={logoImage}
                                                                        message={   "هیچ کالایی در فروشگاه شما  برای فروش ثبت نشده است!!!!"} 
                                                                                // showTryAgainButton={true} tryAgain={getGoods} 
                                                                                />
                                                        </View>
                                        else//فروشگاه متعلق به فرد دیگری است
                                                return <View className='displayFlexGrow displayFlexRow displayFlexCenter'>
                                                                <NoItem visible={true} title="گزارش برنامه" contentStyle={{boxShadow:"0 0 0 0 #FFFFFF"}}
                                                                        icon={logoImage}
                                                                        message={   "هیچ کالایی در فروشگاه  برای فروش ثبت نشده است!!!!"} 
                                                                        //showTryAgainButton={true} tryAgain={getGoods} 
                                                                        />
                                                        </View> 
                                    }
                                    else{
                                        if(isMyGoods)//کالا های من
                                            return <View className='displayFlexGrow displayFlexRow displayFlexCenter'>
                                                                <NoItem visible={true} title="گزارش برنامه" contentStyle={{boxShadow:"0 0 0 0 #FFFFFF"}}
                                                                        icon={logoImage}
                                                                                message={   " هیچ کالایی توسط شما در بازار روستا جهت فروش ثبت نشده است!!!" } 
                                                                                //showTryAgainButton={true} tryAgain={getGoods} 
                                                                                />
                                                        </View>
                                        else
                                                return <View className='displayFlexGrow displayFlexRow displayFlexCenter'>
                                                                <NoItem visible={true} title="گزارش برنامه" contentStyle={{boxShadow:"0 0 0 0 #FFFFFF"}}
                                                                        icon={logoImage}
                                                                                message={   "هیچ کالایی  برای فروش ثبت نشده است!!!!"} 
                                                                                //showTryAgainButton={true} tryAgain={getGoods} 
                                                                                />
                                                        </View> 
                                    }
                            }
                        }         
                        else 
                                return <NoItem visible={true} title="گزارش برنامه" contentStyle={{boxShadow:"0 0 0 0 #FFFFFF"}}
                                                            icon={logoImage}
                                                            message={ "جستجو نتیجه ای نداشت!!!" }  />
                }
        }
        else 
               return null
        }
        //----------------------------------------------------------------------------------------------
        return(
            <View  style={{flex:1 }}  >
                    <FlatList data={ initDataToShow()} keyboardShouldPersistTaps={'handled'}
                                    style={{flex:1   }} 
                                    numColumns={isMyGoods || shopId ? 3 : 1} 
                                    contentContainerStyle={{paddingBottom:100 }}
                                    keyExtractor={(item , index)=>index.toString()}
                                    refreshing={goodsInfo.refresh && fetchState.fetchFlag && goodsInfo.arrGoods.length }
                                    onEndReachedThreshold={0.15}
                                    onEndReached={ loadMore }
                                    ListHeaderComponent={headerComponent}
                                    ListFooterComponent={loadingComponent()}
                                    ListEmptyComponent={errorEmptyComponent()}     
                                    refreshControl={ <RefreshControl refreshing={false}     onRefresh={onRefresh} />}             
                                    renderItem={({item})=>isMyGoods || shopId ?  <GoodsMyBazaarComponent     {...item}   showCommentWithoutAnswer={isMyGoods && shopId }
                                                                                                                                            onLongSelectGoods={()=>onLongSelectGoods(item)}
                                                                                                                                            onSelectGoods={ ()=>onSelectGoods(item) }
                                                                                                                                            categoryName = {arrCategory.filter(category => category.id===item.categoryId).length ?  
                                                                                                                                                                                    arrCategory.filter(category => category.id===item.categoryId)[0].name : "-------------"}
                                                                                                                                            goodsState ={item.goodsStateId ? arrGoodsState.filter(goodsState => goodsState.id===item.goodsStateId)[0].name  :  "--------" }     /> 
                                                                                            :  
                                                                                            <GoodsBazaarComponent   {...item}  
                                                                                                                                     onSelectGoods={ ()=>onSelectGoods(item) }
                                                                                                                                    categoryName = {arrCategory.filter(category => category.id===item.categoryId).length ?  
                                                                                                                                                                                                                arrCategory.filter(category => category.id===item.categoryId)[0].name : "-------------"}
                                                                                                                                    goodsState ={item.goodsStateId ? arrGoodsState.filter(goodsState => goodsState.id===item.goodsStateId)[0].name :  "--------" }   />}    />
                    <Fetch ref={fetchModal}/>
                    <Alert ref={alertModal}/>
                    <Confirm ref={confirmModal}/>
            </View>
        )
}
const mapStateToProps= state => ({
    profileState : state.profileReducer ,
    bazaarState : state.bazaarReducer ,
})
const mapDispatchToProps= (dispatch  ) =>({
    setToastMessage:strMessage => dispatch(setToastMessage(strMessage)), 
})
export default connect(mapStateToProps , mapDispatchToProps)(GoodsListComponent);
//=========================================================================================