//========================================================================================
import React,{useEffect, useRef   , useState}  from 'react'
import {View} from "react-native"
import {  Alert, Confirm, MyButton , PageContainer } from '../../../../globalComponents/components';
import { connect } from 'react-redux';
import {setToastMessage} from "../../../../redux/toastMessage"
import GoodsListComponent from '../GoodsListComponent';
import MdlAddUpdateGoods from '../mdlAddUpdateGoods';
import { isValidPhoneNumber } from '../../../../globalComponents/rejax';
import Fetch from '../../../../globalComponents/fetch';
import { deleteGoodsCategory } from '../../../../redux/bazaar';
// import {useBackHandler} from "@react-native-community/hooks"
import SearchComponent from '../../../../commonComponents/searchComponent';
import MdlActOnGoods from '../mdlActOnGoods';
 //--------------------------------------------
const MyGoods=(props)=>{
       //----------------------------------------------------------------------------------------------------------------------GLOBAL VAR EREA
       //----------------------------------------------------------------------------------------------------------------------
       const mdlAddUpdateGoods=useRef()
       const fetchModal=useRef()
       const alertModal=useRef()
       const confirmModal=useRef()
       const mdlActOnGoods=useRef()
       //----------------------------------------------------------------------------------------------------------------------STATE EREA
       const [goodsInfo , setGoodsInfo] = useState({
                                                                                    strSearch:'' , 
                                                                                    arrGoods:[] , ////[{id , title , price , goodsStateId , categoryId , saveDate , arrImageFile  , arrCity , }]
                                                                                    finished:false ,
                                                                                    refresh:false,
                                                                             })
       const [ fetchState , setFetchState ]=useState({ fetchFlag:false , errorFlag:false });
       //-----------------------------------USE_EFFECT ERE
       useEffect(()=>{
              if((!goodsInfo.arrGoods.length && !goodsInfo.finished) || goodsInfo.refresh) 
                      getGoodsList() 
       } , [goodsInfo.refresh])

       // useBackHandler(()=>{
       //        onBackClick()
       //        return true
       // } )
       const onBackClick=()=>props.navigation.goBack()
       //---------------------------------------------------
       const getGoodsList=async ()=>{
              const objData={
                     jwt : props.profileState.jwt,
                     lastGoodsId: goodsInfo.arrGoods.length ? goodsInfo.arrGoods[goodsInfo.arrGoods.length-1].id : 0 ,
                     categoryId :0 ,
                     strSearch : "" , 
                     shopId:0  ,
                     showMyGoods : true
                 }
                 await fetchModal.current.fetchData("user/getGoodsList" ,objData , 
                                                                 {
                                                                         onStart : ()=>setFetchState({fetchFlag : true , errorFlag : false}) ,
                                                                         onError : ()=>setFetchState({fetchFlag : false , errorFlag : true}) ,
                                                                         onSuccess : responseOfGetGoods,
                                                                         onBackFromConfirmError : ()=>setFetchState({fetchFlag : false , errorFlag : false}) ,
                                                                 } , 
                                                                 false ,
                                                                 goodsInfo.arrGoods.length ? true : false ,
                                                                 true
                                                                     )
       }
       const responseOfGetGoods=data =>{
              setFetchState({fetchFlag:false , errorFlag:false})
              setGoodsInfo({
                     ...goodsInfo , 
                     arrGoods:[...goodsInfo.arrGoods , ...data.goodsInfo.arrGoods] ,
                     finished:data.goodsInfo.finished ,
                     refresh:false
              })
       }
       const onUpdate=(selectedGoodsInfo)=>mdlAddUpdateGoods.current.show(goodsInfo.arrGoods.filter(goods=> goods.id===selectedGoodsInfo.id)[0] )

       const onAddUpdate=(goodsInfo)=>{
              if(goodsInfo.title.length<5){
                     alertModal.current.show("خطا در انجام عملیات","عنوان آگهی را با حداقل 5 حرف وارد نمایید.","error")
                     return
              }
              if(goodsInfo.description.length<5){
                     alertModal.current.show("خطا در انجام عملیات","متن و توضیحات آگهی را با حداقل 5 حرف وارد نمایید.","error")
                     return
              }
              if(!goodsInfo.categoryId){
              alertModal.current.show("خطا در انجام عملیات","دسته بندی و نوع کالا را انتخاب نمایید.","error")
              return
       }
              if(!isValidPhoneNumber(goodsInfo.phone)){
                     alertModal.current.show("خطا در انجام عملیات","شماره تماس به درستی وارد نشده است!!!.","error")
              return
              }
       
              const arrNewImage = goodsInfo.arrImageFile.filter(file => typeof(file.id)==="string" )
                                                                                    .map( (image , index)=>({
                                                                                                                                     name:"imageForGoods"+index  , 
                                                                                                                                     filename:'imageForGoods.esi'+"_"+image.id ,
                                                                                                                                     type:image.type , 
                                                                                                                                     uri : image.uri
                                                                                                                              }) )

       const objData={
                     goodsInfo : {
                            id:goodsInfo.id ,
                            title:goodsInfo.title,
                            description : goodsInfo.description,
                            categoryId: goodsInfo.categoryId , 
                            goodsStateId : goodsInfo.goodsStateId , 
                            price : goodsInfo.price , 
                            arrImageFile : goodsInfo.arrImageFile.filter(imageFile => imageFile.filename),
                            phone : goodsInfo.phone ,
                            arrCity : [props.profileState.objCity] ,
                            goodsUUID: goodsInfo.goodsUUID
                     },
                     jwt:props.profileState.jwt
       }

       fetchModal.current.uploadData( "user/addUpdateGoods" ,
                                                               objData ,
                                                               arrNewImage,
                                                               {
                                                               onSuccess : responseOfaddUpdateGoods ,
                                                               onStart:()=>{},
                                                               onError:()=>{},
                                                               } , 
                                                               true , )  
       }
       const responseOfaddUpdateGoods = data => {
              if(typeof(data)==="string"){
                     if(data=="ERROR_IN_SEND_IMAGE_DATA"){
                            alertModal.current.show("خطا در بارگزاری تصویر ",
                                                 "در ارسال تصویر مقاله خطایی روی داده است و لازم است تصویر مجددا انتخاب و ارسال گردد.",
                                                 "error")
                            return
                     }
                     if(data=="DATA_IS_NOT_VALIDATE"){
                            alertModal.current.show("خطا در بارگزاری تصویر ",
                                                 "اطلاعات ارسال شده معتبر نمی باشد",
                                                 "error")
                            return
                     }
                     if(data=="NO_VALID_IMAGE_FORMAT"){
                            alertModal.current.show("خطا در بارگزاری تصویر ",
                                                 "تنها تصاویری با فرمتهای "+"\n"+
                                                 "jpg"+"\n"+
                                                 "jpeg"+"\n"+
                                                 "png"+"\n"+
                                                 "برای تصویر پروفایل قابل پذیرش می باشد.",
                                                 "error")
                            return
                     }
                     if(data=="ERROR_IN_SAVE_DATA_LASTLY"){
                            alertModal.current.show("خطا در بارگزاری تصویر ",
                                                 "در ثبت نهایی تصویر خطایی روی داده است و لازم است تصویر مجددا انتخاب و ارسال گردد.",
                                                 "error")
                            return
                     }
              
                     if(data.indexOf("GOODS_LAST_DELETED_")!==-1){
                     setGoodsInfo({
                                                 ...goodsInfo , 
                                                 arrGoods : goodsInfo.arrGoods.filter(goods => goods.id !== parseInt(data.substring(  data.indexOf("GOODS_LAST_DELETED_") + 19   ))   ) 
                                          })
                            props.setToastMessage("این آگهی قبلا حذف شده است!!!!")
                     }
                     if(data.indexOf("CATEGORY_LAST_DELETED_")!==-1){   //CATEGORY_LAST_DELETED_شماره دسته بندی
                     setGoodsInfo({
                            ...goodsInfo , 
                            arrGoods : goodsInfo.arrGoods.filter(goods => goods.categoryId !==  parseInt( data.substring(    data.indexOf("CATEGORY_LAST_DELETED_") + 22   ))   ) 
                     })
                            props.deleteGoodsCategory(  parseInt( data.substring(    data.indexOf("CATEGORY_LAST_DELETED_") + 22   ))   )
                            props.setToastMessage("دسته بندی انتخاب شده برای کالا قبلا حذف شده است!!!."+"\n"+"لطفا دسته بندی دیگری را انتخاب نمایید")
                     }
              }
              else
              {
                     props.setToastMessage("ثبت و بروزرسانی اطلاعات آگهی  با موفقیت انجام شد.")
                     setGoodsInfo({
                            ...goodsInfo , 
                            arrGoods : goodsInfo.arrGoods.filter(goods => goods.id===data.id).length ? goodsInfo.arrGoods.map(goods => goods.id===data.id ? data : goods )    
                                                                                                                                                          :  [  data , ...goodsInfo.arrGoods]
                     })
                     props.setToastMessage("آگهی فروش شما با موفقیت در بازار روستا ثبت گردید.")

                     mdlAddUpdateGoods.current.hide()
              }
       }
       const onRefresh=()=>setGoodsInfo({
                                                                      ...goodsInfo , 
                                                                      arrGoods:[] ,
                                                                      finished:false , 
                                                                      refresh:true
                                                               })
       //----------------------------------------------------
       const onDelete= selectedGoodsInfo =>{

              confirmModal.current.show(    "حذف کالا از بازار",
                                                               "آیا از حذف این کالا از بازار روستا اطمینان دارید ؟ ","qustion",
                                                               ()=>startToDeleteGoods(selectedGoodsInfo.id) , 
                                                               "تایید و ادامه")
      }
      const startToDeleteGoods=  goodsId =>{
       const objData={
              jwt : props.profileState.jwt ,
              goodsId
      }
      fetchModal.current.fetchData("user/deleteGoods" , objData , 
                                                          {
                                                              onSuccess : responseOfDeleteGoods
                                                          } , true , true , true)
      }
      const responseOfDeleteGoods= data =>{
       //    deleteGoods(data)
       setGoodsInfo({
                                   ...goodsInfo ,
                                   arrGoods : goodsInfo.arrGoods.filter(goods => goods.id!==data)
                            })
          props.setToastMessage("حذف کالا از بازار با موفقیت انجام شد.")
      }
       const onSelectGoods= goodsInfo =>mdlActOnGoods.current.show(goodsInfo)
       //----------------------------------------------------
       const onChangeText=(txtId , newText)=>  setGoodsInfo({...goodsInfo , strSearch : newText ? newText : ""})
       //------------------------------------------------------
       const actButton=[
              {
                     id:1 , 
                     title:'بروزرسانی اطلاعات کالا' , 
                     iconName:'refresh' , 
                     onClick: goodsInfo=>onUpdate(goodsInfo)             
              } , 
              {
                     id:2 , 
                     title:'حذف کالا از بازار روستا' , 
                     iconName:'close' , 
                     onClick: goodsInfo=>onDelete(goodsInfo)              
              } , 
       ]
       //-----------------------------------------------------
       return(
              <View style={{flex:1}}>
                     <View style={{paddingHorizontal:10}}>
                        <SearchComponent onChangeText={onChangeText} value={goodsInfo.strSearch} hideSearchButton  />
                </View>
                     <View style={{flex:1 }}>
                            <GoodsListComponent  isMyGoods
                                                               fetchState={fetchState}
                                                               goodsInfo={goodsInfo} 
                                                               headerComponent={null}
                                                               arrCategory={props.bazaarState.arrCategory} 
                                                               arrGoodsState={props.bazaarState.arrGoodsState}
                                                               getGoods={getGoodsList}
                                                               strSearch={goodsInfo.strSearch ? goodsInfo.strSearch.replace( / +/g , " ") : ""}
                                                               onRefresh={onRefresh}
                                                               onSelectGoods={onSelectGoods} /> 
                     </View>
                     <MdlActOnGoods ref={mdlActOnGoods} arrActButton={actButton} />
                     <MdlAddUpdateGoods ref={mdlAddUpdateGoods} 
                                                        arrCategory ={props.bazaarState.arrCategory} 
                                                        arrGoodsState={props.bazaarState.arrGoodsState}
                                                        onAddUpdate={onAddUpdate}/>

                     <Fetch ref={fetchModal}/>
                     <Confirm ref={confirmModal} />
                     <Alert ref={alertModal} />
              </View>
       )
}

const mapStateToProps= state => ({
       profileState : state.profileReducer ,
       goodsInfo : state.myBazaarReducer.goodsInfo ,
       bazaarState : state.bazaarReducer ,
       myBazaarState : state.myBazaarReducer
})
const mapDispatchToProps= dispatch =>({
       setToastMessage : strMessage => dispatch(setToastMessage(strMessage)),
       deleteGoodsCategory : categoryId => dispatch(deleteGoodsCategory(categoryId)) 
})

export default connect(mapStateToProps ,mapDispatchToProps)(MyGoods)
//=========================================================================================