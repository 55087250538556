//========================================================================================
import React,{useRef , useEffect  , useState, useId}  from 'react'
import {View , ScrollView, TouchableOpacity} from "react-native"
 import { PageContainer, MyButton, MyImage , MyText , Alert, Confirm , MyBorderInput } from '../../../globalComponents/components';
import Divider from '../../../commonComponents/divider';
import CalendarWorkComponent from '../../../commonComponents/calendarWorkComponent';
import {  formatNumber, isValidURL } from '../../../globalComponents/rejax';
import { connect } from 'react-redux';
import MdlFactor from '../../../commonComponents/mdlFactor';
import Fetch from "../../../globalComponents/fetch"
import uuid from 'react-uuid';
// import {useBackHandler ,useKeyboard} from "@react-native-community/hooks"
import MdlImageCrop from '../../../commonComponents/mdlImageCrop';
//=============================================================
const AddAdvertising=(props)=>{
//-----------------------------------GLOBAL VAR EREA
const {dayAdvertisingPrice}=props.route.params
// const keyboard=useKeyboard()
//-----------------------------------REF EREA
const fileInputRef=useRef()
const alertModal=useRef()
const mdlImageCropper=useRef()
const confirmModal=useRef()
const mdlFactor=useRef()
const fetchModal=useRef()
//-----------------------------------STATE EREA
const [advertisingInfo , setAdvertisingInfo]=useState({
                                                                                                    title:'',
                                                                                                    subTitle:'' , 
                                                                                                    link:'' , 
                                                                                                    avatar:null
                                                                                            })
const [animateEnabled , setAnimateEnabled]=useState(false)
const [panelId , setPanelId]=useState(1)
const [cityAdvertisingDayInfo , setCityAdvertisingDayInfo]=useState(props.route.params.cityAdvertisingDayInfo)
const [UUID , setUUID]=useState(uuid())
//-----------------------------------USE_EFFECT EREA
//-----------------------------------FUNCTION EREA
// useBackHandler(()=>{
//     onBackClick()
//     return true
// })

const onBackClick=()=> props.navigation.goBack()

const onChangeText=( txtId  , newText )=> setAdvertisingInfo({...advertisingInfo , [txtId]  : newText ? newText : ""   })
const handleImageUpload = async (e) => { 
    if(e.target.files && e.target.files.length > 0){
            //  setAdvertisingInfo({...advertisingInfo , avatar : e.target.files[0]})
            mdlImageCropper.current.show(URL.createObjectURL(e.target.files[0]))
    }
};
//----------------------------------------
const onSelectDay= timestamp =>{
    const _arrMonth=JSON.parse(JSON.stringify(cityAdvertisingDayInfo))
    for(let month of _arrMonth){
        for(let weekDay of month.arrWeekDay){
            for(let day of weekDay.arrDay){
                if(day.timestamp===timestamp){
                    day.selected=!day.selected
                    break
                }
            }
        }
    }
    setCityAdvertisingDayInfo(_arrMonth)
}
const countNumberOfDaySelected=()=>{
    let numberOfDay=0
    for(let month of cityAdvertisingDayInfo ){
            for(let weekDay of month.arrWeekDay)
                numberOfDay+= weekDay.arrDay.filter(day => day.selected).length
    }
    return numberOfDay
}
const gotoGetDayForAdvertising=()=>{
    if(!advertisingInfo.avatar){
        alertModal.current.show('خطا در ورود اطلاعات','تصویر تبلیغ را انتخاب و بارگزاری نمایید.','error');
        return
    }
    if(advertisingInfo.title.trim().replace(/ +/g , " ").length<3){
        alertModal.current.show('خطا در ورود اطلاعات','عنوان تبلیغ را با حداقل 3 حرف وارد نمایید.','error');
        return
    }
    if(advertisingInfo.subTitle.trim().replace(/ +/g , " ").length<3){
        alertModal.current.show('خطا در ورود اطلاعات','متن تبلیغ را با حداقل 3 حرف وارد نمایید.','error');
        return
    }
    if(advertisingInfo.link){
        if(!isValidURL(advertisingInfo.link)){
            alertModal.current.show('خطا در ورود اطلاعات','لینک تبلیغ به درستی وارد نشده است.','error');
            return
        }
    }
    else{
        alertModal.current.show('خطا در ورود اطلاعات','لینک تبلیغ وارد نشده است!!!.','error');
        return
    }
    setAnimateEnabled(true)
    setPanelId(2)
}
//-------------------------------------------------
const gotoPayAndSetAdvertising=()=>{
        if(!countNumberOfDaySelected()){
            alertModal.current.show('خطا در عملیات','روزهای مورد نظر خود را جهت انتشار تبلیغ انتخاب نمایید.','error');
            return
        }

        const  arrSelectedDayTimestamp=[]
        for(let month of cityAdvertisingDayInfo ){
                for(let weekDay of month.arrWeekDay)
                    arrSelectedDayTimestamp.push(...weekDay.arrDay.filter(day => day.selected).map(day => day.timestamp) )
        }

        const objData={
            jwt : props.profileState.jwt ,
            title:advertisingInfo.title ? advertisingInfo.title.trim().replace(/ +/g , " ") : "",
            subTitle : advertisingInfo.subTitle ? advertisingInfo.subTitle.trim().replace(/ +/g , " ")  :"" ,
            link:advertisingInfo.link,
            arrSelectedDayTimestamp ,
            advertisingUUID:UUID
        }

        const newAvatarFile=advertisingInfo.avatar
        // const fileResult = newAvatarFile ? new File([newAvatarFile] , "myAdvertisingAvatar.esi" , {type:"image/jpg"}) : null
        fetchModal.current.uploadData("user/setAdvertising" ,
                                                                objData ,
                                                                // [{ name:'myAdvertisingAvatar' , file : fileResult }] ,
                                                                newAvatarFile ? [{name : 'myAdvertisingAvatar', 
                                                                                              filename : 'myAdvertisingAvatar.esi',
                                                                                               type:newAvatarFile.mime, 
                                                                                               uri : newAvatarFile.path }] : [] ,
                                                                {
                                                                       onSuccess : responseOfGotoPayAndSetAdvertising ,
                                                                       onStart:()=>{} ,
                                                                       onError:()=>{}
                                                                } , 
                                                                true )  
}
const responseOfGotoPayAndSetAdvertising= data =>{
    // alert(JSON.stringify(data))
    if(typeof(data)==="string"){
        if(data==="SUCCESS"){
            confirmModal.current.show("گزارش برنامه",
                                                          "کاربر گرامی"+"\n"+
                                                         "تبلیغ شما ثبت و در روزهای  انتخاب شده , نمایش داده خواهد شد"+""+
                                                        "از اعتماد شما به دهوند سپاس گذاریم" , 
                                                        "information" ,()=>props.navigation.navigate("bulletin") , "تایید و بازگشت"  , false )
        }
    }
    else{
        if(data.message){
            if(data.message==="SOME_DAY_RESERVED"){
                setCityAdvertisingDayInfo(data.data)
                alertModal.current.show("خطا در انجام عملیات ثبت",
                                                         "کاربر گرامی"+"\n"+
                                                         "در مدت زمانی که شما درحال ثبت مشخصات تبلیغ بودید , بعضی از روز های مورد نظر شما توسط کاربری دیگری رزرو گردید."+"\n"+
                                                         "به همین دلیل روزهای قابل انتخاب شده مجددا بروزرسانی شده و لازم است مجددا انتخاب روزها را تکرار نمایید",
                                                         "error")
            }
            if(data.message==="PAY_LINK_CREATED"){
                mdlFactor.current.show("فاکتور ثبت تبلیغ",
                                                        "فاکتور ثبت تبلیغ با موفقیت برای شما ایجاد گردیده و آماده ی پرداخت می باشد.",
                                                        data.data.link , 
                                                        data.data.amount,
                                                        data.data.factorId)
            }
        }
    }
}
//---------------------------------------------------
const onSelectAdvertisingAvatar = image => setAdvertisingInfo({...advertisingInfo , avatar:image})
//--------------------------------------------------
   return(
    <PageContainer title="پنل تبلیغات دهوند "
                            subTitle={" روستای "+props.profileState.objCity.name} 
                            onBackIconClick={onBackClick} 
                            showBackIcon 
                            containerPadding={0} hasFlatList >

                 {
                        panelId===1 ? 
                        <View className='displayFlexGrow displayFlexCol ' style={{flex:1}}>
                            <ScrollView className={`${animateEnabled ? "w3-animate-right" : ""} displayFlexGrow`} style={{flex:1}}>
                                    <Divider title="پیش نمایش تبلیغ شما " containerStyle={{marginTop:15}} contentStyle={{paddingTop:15}}>
                                        <View  style={{paddingHorizontal:16 , paddingVertical:8 }}>
                                            <View style={{borderWidth:2 , borderStyle:"dashed" , borderColor:"#d0d0d0"  , alignItems:"center" , flexDirection:"row" , padding:5 , borderRadius:15}}>
                                                <TouchableOpacity onPress={()=>mdlImageCropper.current.showModal()}>
                                                    <View className='w3-ripple w3-button w3-round-large ' style={{width:90 , height:90 , padding:0 , borderRadius:10}} >
                                                    {
                                                        advertisingInfo.avatar ? 
                                                        <MyImage source={ {uri : advertisingInfo.avatar.path}} style={{width:"100%" , height:"100%" , borderRadius:10}} />
                                                        :
                                                        <View style={{width:"100%" , height:"100%" , padding:15 , backgroundColor:"#eeeeee" , borderRadius:10 }}  >
                                                                <MyText     style={{ height:"100%" ,verticalAlign:"middle" , textAlign:"center" , borderRadius:10  }} className="w3-center" >{"بارگزاری"+"\n"+"تصویر"+"\n"+"تبلیغ"}</MyText>
                                                        </View>
                                                    }
                                                    </View>
                                                </TouchableOpacity>
                                                <View  style={{paddingRight:10 , flex:1 }}>
                                                    <MyText smallFont isBold>{advertisingInfo.title ? advertisingInfo.title : "عنوان تبلیغ "}</MyText>
                                                    <MyText smallFont color="#757575">{advertisingInfo.subTitle ? advertisingInfo.subTitle : "متن تبلیغ "}</MyText>
                                                </View>
                                            </View>
                                        </View>
                                    </Divider>
                                     {/* ورود مشخصات تبیلغ */}
                                    <Divider title="ورود مشخصات تبلیغ" containerClassName="w3-margin-top " containerStyle={{marginTop:20}}>
                                                <View className='' style={{paddingVertical:30 , paddingHorizontal : 10}}>
                                                    {/* ثبت عنوان تبیغ */}
                                                    <MyBorderInput title="عنوان تبلیغ" //iconName="title" 
                                                                              isBold={false}
                                                                                value={advertisingInfo.title} placeholder="عنوان تبلیغ را وارد نمایید...." 
                                                                                id="title" 
                                                                                maxLength={35} 
                                                                                hasCharCounter={true} 
                                                                                onChangeText={onChangeText}
                                                                                inputStyle={{textAlign:"right" , padding:8 , paddingTop:14}}
                                                                                containerStyle={{}} />
                                                    {/* ثبت متن تبیلغ */}
                                                    <MyBorderInput title="متن تبلیغ" //iconName="description" 
                                                                                                containerStyle={{marginTop:35}}
                                                                                                value={advertisingInfo.subTitle} 
                                                                                                placeholder="متن تبلیغ را وارد نمایید...." 
                                                                                                id="subTitle" 
                                                                                                maxLength={50} 
                                                                                                hasCharCounter={true} 
                                                                                                onChangeText={onChangeText}
                                                                                                inputStyle={{textAlign:"right" , padding:8 , paddingTop:14}}
                                                                                                contanerStyle={{paddingTop:14}} />
                                                    {/*لینک  تبیلغ */}
                                                    <MyBorderInput title="لینک تبلیغ" //iconName="link"
                                                                                                value={advertisingInfo.link} placeholder="لینک تبلیغ را وارد کنید...." containerStyle={{marginTop:35}}
                                                                                                id="link" maxLength={255} hasCharCounter={false} onChangeText={onChangeText}
                                                                                                inputStyle={{textAlign:advertisingInfo.link ? "left" : "right" , padding:8 , paddingTop:14}}
                                                                                                contanerStyle={{paddingTop:14}} 
                                                                                                footer="  آدرس فروشگاه , صفحه در شبکه اجتماعی و یا سایت مورد نظر خود را  وارد نمایید"
                                                                                                footerTextStyle={{backgroundColor:"#eeeeee"}} />        
                                                </View>
                                    </Divider>
                            </ScrollView>
                            {/* {
                                !keyboard.keyboardShown ?  */}
                                <View  style={{padding:16}}>
                                    <MyButton title="تایید و ادامه" iconName="check_circle" onClick={gotoGetDayForAdvertising} buttonStyle={{backgroundColor:"#00bcd4"}}  />
                                </View> 
                                {/* : null */}
                            {/* } */}
                        </View>
                        // {/* ورد زمان تبلیغ */}
                        :
                        <View className='w3-animate-left displayFlexGrow displayFlexCol ' style={{flex:1}}>
                            <ScrollView className='displayFlexGrow' style={{flex:1 }}>
                                <Divider title="انتخاب روز های نمایش تبلیغ"  containerStyle={{marginTop:16, flexDirection:"column"}}>
                                        <View  style={{padding:8}}>
                                                <CalendarWorkComponent arrMonth={cityAdvertisingDayInfo}  onSelectDay={onSelectDay} />
                                        </View>
                                </Divider>
                                <Divider title="پیش فاکتور هزینه تبلیغات">
                                        <View className=''>
                                            <View  style={{paddingHorizontal:16 , paddingVertical:8}}>
                                                <View  style={{flexDirection:"row"}}>
                                                    <MyText isBold  smallFont color="#4a4a4a">{"هزینه تبلیغ در هر روز : "}</MyText>
                                                    <MyText isBold  smallFont color={dayAdvertisingPrice ? "#03a9f4" : "#e57373" }
                                                                 style={{marginRight:5}}>{`${!dayAdvertisingPrice? " رایگان " : formatNumber(dayAdvertisingPrice)+" تومان"}`}</MyText>
                                                </View>
                                                <View  style={{marginTop:4 , flexDirection:"row"}}>
                                                    <MyText isBold isBlock smallFont color="#4a4a4a">{"تعداد روزهای انتخاب شده : "}</MyText>
                                                    <MyText isBold isBlock smallFont color="#4a4a4a" style={{marginRight:5}}>{countNumberOfDaySelected() ? formatNumber(countNumberOfDaySelected())+" روز" 
                                                                                                                                                                                        : ""}</MyText>
                                                </View>
                                            </View>
                                            <View  style={{paddingHorizontal:16 , paddingVertical:8 , backgroundColor:"#eeeeee" , flexDirection:"row"}}>
                                                <MyText isBold  color="#4a4a4a">{"مبلغ قبل پرداخت : "}</MyText>
                                                <MyText isBold  color={dayAdvertisingPrice ? "#03a9f4" : "#e57373" }
                                                                style={{marginRight:5}}>{`${!dayAdvertisingPrice? " رایگان " :formatNumber(countNumberOfDaySelected()*dayAdvertisingPrice)+" تومان"}`}</MyText>
                                            </View>
                                        </View>
                                </Divider>
                            </ScrollView>
                            <View className='displayFlexRowReverse' style={{justifyContent:"space-between" , padding:16 , flexDirection:"row-reverse"}}>
                                    <MyButton title="ادامه و پرداخت" iconName="save" buttonStyle={{}}  onClick={gotoPayAndSetAdvertising}  />
                                    <MyButton title="بازگشت " iconName="arrow_forward" buttonStyle={{backgroundColor:"#e57373"}}  onClick={ ()=>setPanelId(1)}  />
                            </View>
                        </View>
                }

           {/* <input  type="file"  ref={fileInputRef} onChange={handleImageUpload} accept="image/png, image/jpeg" style={{ display: "none" }} /> */}
           {/* <MyImageCropper ref={mdlImageCropper} onSelectCroppedImage={image => setAdvertisingInfo({...advertisingInfo , avatar:image})} /> */}
           <MdlImageCrop ref={mdlImageCropper} onSelectImage={onSelectAdvertisingAvatar} />
           <MdlFactor ref={mdlFactor} onLinkClick={onBackClick} />
           <Fetch ref={fetchModal} />
           <Alert ref={alertModal} />
           <Confirm ref={confirmModal} />
       </PageContainer>
   )
}
const mapStateToProps=state=>({
    profileState : state.profileReducer
})
export default connect(mapStateToProps)(AddAdvertising);
//=========================================================================================