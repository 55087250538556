//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
import {View , TouchableOpacity, RefreshControl, Linking, Dimensions  } from "react-native"
import {  Alert, Confirm , ImageModal, MyButton, MyImage, MyText, NoItem, ProfileImage, Waiting  } from '../../../globalComponents/components'
import Fetch from '../../../globalComponents/fetch'
import { connect } from 'react-redux'
import {setToastMessage} from "../../../redux/toastMessage"
import AnnouncementPlaceholder from './announcementPlaceholder'
import { setInitialInfo, updateInitialInfo } from '../../../redux/initialInfo'
import  { setProfileInfo, updateProfileInfo } from '../../../redux/profile'
import { FlatList } from 'react-native'

import {} from "@react-navigation/native"
import { useNavigation } from '@react-navigation/native';
import { updateMainAdvertisingInfo } from '../../../redux/mainAdvertising'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { addChapter as addAnnouncement, refreshChapter as   refreshAnnouncement } from '../../../redux/announcement';
import ExtendedActionButton from '../../../commonComponents/extendedActionButton'
 //----------------------------------------------------------------------------------------
 const AnnouncementComponent=props =>{
       const { chapterAvatar ,writerAvatar , writerName , writerAboutMe , title  , showImageInModal , saveDate  } =props
       const [imageWidth , setImageWidth]=useState(Math.floor((Dimensions.get("screen").width-32-40)*90/100)-14)

       const onLayout = e => {
              // const {x, y, height, width} = e.nativeEvent.layout;
              // setImageWidth(width)
       }

       const defImage= require("./../../../assets/images/act/imgDefImage.png")
       return (
              <View  style={{ paddingHorizontal:16 , flexDirection:"row" ,alignItems:"flex-start" , marginVertical:10 , flex:1 }}>
                     <ProfileImage source={writerAvatar ? {uri : writerAvatar}  : require("./../../../assets/images/act/imgDefManAvatar.jpg")} width={40} style={{elevation:1}}  />
                     <View style={{flex:1  , alignItems:"flex-start"}}>
                            <View  style={Object.assign({} , {  flexDirection:"column" , marginRight:10 , width:"90%" , padding:7 , backgroundColor:"#eceff1" , borderRadius:10 , borderTopRightRadius:0 , elevation:5  }  )}>
                                   <TouchableOpacity onPress={()=>showImageInModal(chapterAvatar)}>
                                          <View style={{ backgroundColor:"#eeeeee" , flex:1  }} onLayout={onLayout}>
                                                 <MyImage  source={chapterAvatar ? {uri : chapterAvatar} : defImage }  
                                                        style={{width:imageWidth , height : imageWidth , borderRadius:10  }} />
                                          </View>
                                   </TouchableOpacity>
                                   <View style={{flexDirection:"row-reverse"}}>
                                          <View  style={{paddingTop:5  , borderTopRadius:10 , borderBottomRadius:0 , flexDirection:"row-reverse" , flex:1}}>
                                                 <View style={{marginRight:10}}>
                                                        <MyText isBold tinyFont color="#26a69a">{writerName}</MyText>
                                                        <MyText tinyFont  color="#a9a9a9">{writerAboutMe ? writerAboutMe : "-----------"}</MyText>
                                                 </View>
                                          </View>
                                          <View>
                                                 <MyText tinyFont color="#a9a9a9" style={{}}>{saveDate}</MyText>
                                          </View>
                                   </View>

                                   {title ? <View  style={{borderTopEndRadius:0 , borderBottomRadius:8 , backgroundColor:"#eeeeee" , paddingHorizontal:8 , paddingVertical:0 ,marginTop:5}} >
                                                        <MyText smallFont tinyFont color="#456e7a">{title}</MyText>
                                                 </View>  : null}
                            </View>
                    </View>
              </View>
       )
 }
 //----------------------------------------------------------------------------------------
const Announcement=(props)=>{
       const { announcementState   , addAnnouncement , refreshAnnouncement ,  mustUpdateInfo=false  } = props
       const navigation = useNavigation();
       //-----------------------------------------------------------------------------------------------------
       const [ fetchState , setFetchState ]=useState({ fetchFlag:!announcementState.arrChapter.length && !announcementState.finished ? true : false , 
                                                                                      errorFlag:false })
       const [pageWasLoaded , setPageWasLoaded]=useState(false)

       useEffect(()=>{
              setTimeout(()=>setPageWasLoaded(true) , 100)
       } , [])
       //------------------------------------------------------------------------------------------------------------------
       useEffect(()=>{
              if(pageWasLoaded){
                     if(!announcementState.arrChapter.length && !announcementState.finished )
                            getAnnouncementList()
              }           
       } , [pageWasLoaded ])

       useEffect(()=>{
              if(pageWasLoaded)
                     getAnnouncementList()
       } , [announcementState.strSearch])

       useEffect(()=>{
              if(pageWasLoaded && announcementState.refresh)
                     getAnnouncementList()
       } , [announcementState.refresh])
       useEffect(()=>{
              if(mustUpdateInfo)
                     props.refreshAnnouncement()
       } , [mustUpdateInfo])
       //-----------------------------------------------------------------------------------------------------------------
       const alertModal=useRef()
       const fetchModal=useRef()
       const confirmModal=useRef()
       const imageModal=useRef()
       //-----------------------------------------------------------------------------------------------------------------
       const getAnnouncementList=async ()=>{
              if(!pageWasLoaded)
                     return
              const objData={
                     jwt : props.profileState.jwt , 
                     chapterTypeName:"ANNOUNCEMENT" , 
                     strSearch : announcementState.strSearch , 
                     lastChapterId : announcementState.arrChapter.length ?  announcementState.arrChapter[announcementState.arrChapter.length-1].id : 0 
              }
              await fetchModal.current.fetchData("user/getChapterList" , 
                                                                objData , 
                                                                {
                                                                      onStart :  ()=>setFetchState({fetchFlag:true , errorFlag:false}),
                                                                      onError :  ()=>setFetchState({fetchFlag:false , errorFlag:true}),
                                                                      onBackFromConfirmError:()=>setFetchState({fetchFlag:false , errorFlag:false}),
                                                                      onSuccess: responseOfGetAnnouncement
                                                                } , announcementState.arrChapter.length  ? true : false , 
                                                                     announcementState.arrChapter.length  ? true : false , true)
       }
       const responseOfGetAnnouncement=async data => {
              setFetchState({fetchFlag:false , errorFlag:false})
              addAnnouncement(data)
              props.updateInitialInfo({newBillCount:0})

              if(data.arrChapter.length){
                     const lastInfoId=await AsyncStorage.getItem("LAST_OBJECT_INFO_ID")
                     if(lastInfoId){
                            try{
                                   const objInfo=JSON.parse(lastInfoId)
                                   if(objInfo["LAST_ANNOUNCEMENT_ID"] < data.arrChapter[0].id ){
                                          objInfo["LAST_ANNOUNCEMENT_ID"]= data.arrChapter[0].id
                                          await AsyncStorage.setItem("LAST_OBJECT_INFO_ID" , JSON.stringify(objInfo))
                                   }
                            }
                            catch{
                                   await AsyncStorage.setItem("LAST_OBJECT_INFO_ID" , JSON.stringify({  LAST_ANNOUNCEMENT_ID:0 ,    LAST_ALBUM_ID:0 ,  LAST_BILL_ID:0   }))
                            }
                     }
                     else
                            await AsyncStorage.setItem("LAST_OBJECT_INFO_ID" , JSON.stringify({   LAST_ANNOUNCEMENT_ID:0 ,    LAST_ALBUM_ID:0 ,  LAST_BILL_ID:0   }))
              }
       }
       //----------------------------------------------------------------------------------------
       const initDataToShow=()=>{
              // if(strSearch){
              //        if(isNaN(strSearch))
              //               return announcementState.arrChapter.filter(announcement=>announcement.title.indexOf( strSearch.trim().replace(/ +/g  , " ") )!==-1 )
              //        else
              //               return announcementState.arrChapter.filter(announcement=>announcement.id===parseInt(strSearch) )
              // }      
              // else
                     return announcementState.arrChapter
       }
       //---------------------------------------------------------------------------------------
       const loadingComponent=()=>{
              if(fetchState.fetchFlag && !fetchState.errorFlag){
                     if(!announcementState.arrChapter.length )
                            return <View style={{padding:5}}>
                                                 <AnnouncementPlaceholder  />
                                                 <AnnouncementPlaceholder />
                                                 <AnnouncementPlaceholder />
                                          </View>
                     else
                            return <Waiting  visible={fetchState.fetchFlag} 
                                                                      flexDirection="column" 
                                                                      backgroundColor="#ffffff" 
                                                                      marginTop={30}
                                                                      color="#546e7a" />
              }
       }
       const errorEmptyComponent=()=>{
              if(!fetchState.fetchFlag){
                     if( fetchState.errorFlag && !announcementState.arrChapter.length && !announcementState.finished )
                            return <View  style={{flex:1 , flexDirection:"row-reverse" , alignItems:"center" , justifyContent:"center"}} >
                                                 <NoItem visible={true} title="خطا در دریافت اطلاعات" 
                                                               message={"در دریافت اطلاعات خطایی روی داده است!!!!"+"\n"+
                                                                             "این مشکل ممکن است در نتیجه قطعی و یا کندی شبکه اینترنت شما باشد"}
                                                               showTryAgainButton={true} tryAgain={getAnnouncementList} />
                                          </View> 
                     if(!fetchState.fetchFlag && !fetchState.errorFlag && !announcementState.arrChapter.length && announcementState.finished)
                            return <View style={{flex:1 , flexDirection:"row-reverse" , alignItems:"center" , justifyContent:"center"}}>
                                                 <NoItem visible={true} title="گزارش برنامه" 
                                                        icon={require("./../../../assets/images/logo/logo.png")}
                                                               message={announcementState.strSearch ? "جستجوی نتیجه ای نداشت!!!" : "هیچ سندی جهت نمایش بافت نشد!!!!"} />
                                          </View>
                     if(!fetchState.fetchFlag && pageWasLoaded)
                            return <NoItem visible={true} title="گزارش برنامه" contentStyle={{boxShadow:"0 0 0 0 #FFFFFF"}}
                                                        icon={require("./../../../assets/images/logo/logo.png")}
                                                        message={ "جستجوی نتیجه ای نداشت!!!" }  />
              }
              else 
                     return null
       }
       const showImageInModal=(imageUrl)=> imageModal.current.show([{url : imageUrl}] , 0)
       //---------------------------------------------------------------------------------------
       const getAnnouncementPriceValidateCode=()=>{
            const objData={
                jwt : props.profileState.jwt ,
            }
            fetchModal.current.fetchData("user/getAnnouncementPrice" , objData , 
                                                            {
                                                                onSuccess:responseOfGetAnnouncementPriceValidateCode 
                                                            } , true , true , true)
        }
        const responseOfGetAnnouncementPriceValidateCode=(data)=> navigation.navigate("addAnnouncement" , {announcementPrice:data }) 
       //---------------------------------------------------------------------------------------
       return(
              <View style={{flex:1}}>
                                   <FlatList     data={ initDataToShow()} 
                                                        style={{flex:1 }}
                                                        keyExtractor={(item , index)=>index.toString()}
                                                        refreshing={announcementState.refresh  }
                                                        onEndReachedThreshold={0.15}
                                                        onEndReached={ !announcementState.finished &&  announcementState.arrChapter.length &&   pageWasLoaded   ? getAnnouncementList :  ()=>{}   }
                                                        ListFooterComponent={loadingComponent()}
                                                        ListEmptyComponent={errorEmptyComponent()}     
                                                        refreshControl={ <RefreshControl refreshing={announcementState.refresh}     
                                                        onRefresh={refreshAnnouncement} />}             
                                                        renderItem={({item})=><AnnouncementComponent      {...item} 

                                                                                                                                     onClick={()=>navigation.navigate("announcementInfo" , { selectedAnnouncement : item  })}
                                                                                                                                     showImageInModal={showImageInModal}  />}    />
                                   <ExtendedActionButton title={"ثبت اطلاعیه جدید"} iconName='add_photo_alternate' onClick={getAnnouncementPriceValidateCode}  />

                                   <ImageModal ref={imageModal} />
                                   <Fetch ref={fetchModal} />
                                   <Alert ref={alertModal} />
                                   <Confirm ref={confirmModal} /> 
              </View>
       )
}
const mapStateToProps= state => ({
       profileState : state.profileReducer ,  
       initialInfoState : state.initialInfoReducer ,
       mainAdvertisingInfoState : state.mainAdvertisingReducer ,
       scrollTopPagePositionState: state.scrollTopPagePositionReducer ,
       announcementState : state.announcementReducer
})
const mapDispatchToProps= dispatch =>({
       setToastMessage : strMessage => dispatch(setToastMessage(strMessage)) ,
       setInitialInfo : initialInfo => dispatch(setInitialInfo(initialInfo)) ,
       updateInitialInfo : initialInfo =>dispatch(updateInitialInfo(initialInfo)),
       setProfileInfo : profileInfo => dispatch(setProfileInfo(profileInfo)),
       updateProfileInfo : profileInfo => dispatch(updateProfileInfo(profileInfo)) , 
       updateMainAdvertisingInfo : advertisingInfo => dispatch(updateMainAdvertisingInfo(advertisingInfo)),

       addAnnouncement : announcementInfo => dispatch(addAnnouncement(announcementInfo)) ,
       // addUpdateAnnouncement : announcementInfo => dispatch(addUpdateAnnouncement(announcementInfo)) , 
       // deleteAnnouncement : announcementId => dispatch(deleteAnnouncement(announcementId)) , 
       refreshAnnouncement : () =>dispatch(refreshAnnouncement()) ,
})
export default connect(mapStateToProps , mapDispatchToProps)(Announcement);
//===========================================================================================
