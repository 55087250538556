//regax function

export const  isValidMobilNumber=(strMobileNumber) => {
    var regPhone=/^09[0-9]{9}$/;
    return regPhone.test(strMobileNumber);
}
export const isValidPhoneNumber=(strPhoneNumber) => {
    var regPhone=/^0[0-9]{10}$/;
    return regPhone.test(strPhoneNumber);
}
///-------------------------------------
export const isValidPersianString=(str)=>{
    var regName = /^[\u0686\u067E\u0621-\u0628\u062A-\u063A\u0641-\u064A\u0698\u06A9\u06AF\u06C1\u06CC\u06f6 .]{1,}$/;
    return regName.test(str)
}
//--------------------------------------------
export const isValidPersianNumberString = (strName) => {
    var regName = /^[\u0686\u067E\u0621-\u0628\u062A-\u063A\u0641-\u064A\u0698\u06A9\u06AF\u06C1\u06CC\u06f6 .0-9]{1,}$/;
    return regName.test(strName)
}
//------------------------------------------
export const isValidPersianEnglishString = (str) => {
    var regName = /^[\u0686\u067E\u0621-\u0628\u062A-\u063A\u0641-\u064A\u0698\u06A9\u06AF\u06C1\u06CC\u06f6 a-zA-Z.]{1,}$/;
    return regName.test(str);
}
//------------------------------------------
export const isValidPersianEnglishNumberString = (str) => {
    var regName = /^[\u0686\u067E\u0621-\u0628\u062A-\u063A\u0641-\u064A\u0698\u06A9\u06AF\u06C1\u06CC\u06f6 0-9a-zA-Z.]{1,}$/;
    return regName.test(str);
}
//-------------------------------------------
export const isValidEnglishNumberString = (strName, LeastCharNmuber) => {
    var regPhone = /^[0-9a-zA-Z]{1,}$/;
    return regPhone.test(strName);
}
//===========================================
export const isValidEnglishName=(strName, LeastCharNmuber) => {
    var regPhone=/^[a-zA-Z]{8,}$/;
    return regPhone.test(strName);
}
export const   isValidmail=(strEmail)=> {
    var regEmail=/[]+@(gmail|yahoo|hotmail).com/;
    return regEmail.test(strEmail);
}
//=============================================
///-------------------------------------
export const isValidNumberString=(strName)=>{
    var regName =new RegExp("^[0-9]+$");
    return regName.test(strName);
}
//----------------------------------------
export const isValidNumber=(strName,minChar=1,maxChar="")=>{
    var regName =new RegExp("^[0-9]{"+minChar+","+maxChar+"}$");
    return regName.test(strName);
}
//---------------------------------------
export const isValidPersianName=(strName,minChar=3,maxChar="")=> {

    var regName =new RegExp("^[\u0686\u067E\u0621-\u0628\u062A-\u063A\u0641-\u064A\u0698\u06A9\u06AF\u06C1\u06CC\u06f6 .]{"+minChar+","+maxChar+"}$");
    return regName.test(strName)
}
//--------------------------------------------
export const isValidPersianNumberName=(strName,minChar=3,maxChar="")=> {
    var regName =new RegExp("^[\u0686\u067E\u0621-\u0628\u062A-\u063A\u0641-\u064A\u0698\u06A9\u06AF\u06C1\u06CC\u06f6 .0-9]{"+minChar+","+maxChar+"}$");
    return regName.test(strName)
}
//-----------------------------------------
export const isValidPersianEnglishName=(strName,minChar=3,maxChar="")=> {
    var regName =new RegExp("^[\u0686\u067E\u0621-\u0628\u062A-\u063A\u0641-\u064A\u0698\u06A9\u06AF\u06C1\u06CC\u06f6 a-zA-Z.]{"+minChar+","+maxChar+"}$");
    return regName.test(strName);
}
//------------------------------------------
export const isValidPersianEnglishNumberName=(strName,minChar=3,maxChar="")=> {
    var regName =new RegExp("^[\u0686\u067E\u0621-\u0628\u062A-\u063A\u0641-\u064A\u0698\u06A9\u06AF\u06C1\u06CC\u06f6 0-9a-zA-Z.]{"+minChar+","+maxChar+"}$");
    return regName.test(strName);
}
//-------------------------------------------
export const isValidEnglishNumberName=(strName,minChar=3,maxChar="")=> {
    var regName =new RegExp("^[0-9a-zA-Z]{"+minChar+","+maxChar+"}$");
    return regName.test(strName);
}
//-------------------------------------------
export const isValiEnglishName=(strName,minChar=3,maxChar="")=> {
    var regName =new RegExp("^[a-zA-Z]{"+minChar+","+maxChar+"}$");
    return regName.test(strName);
}
//-------------------------------------------
// export const  isValidEmail=(strEmail)=> {
//     var regEmail=/[]+@(gmail|yahoo|hotmail|info).com/;
//     return regEmail.test(strEmail);
// }
export const isValidEmail=(strEmail)=>{
    var regEmail=/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    return regEmail.test(strEmail);
}
//------------------------------------------
export const isValidMeliCode=(str)=>{
    var regNumber = /^[0-9]{10}$/;
    return regNumber.test(str);
}
//--------------------------------------
export const isValideBirthday=(str)=>{
    var regNumber = /^13[0-9]{2}\/[0-9]{2}\/[0-9]{2}$/;
    return regNumber.test(str);
}
//--------------------------------------
export const isValid24HourTime=(str)=>{
    var regTime = /^([0-1]{1}[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}$/;
    return regTime.test(str)
}
//--------------------------------------
export const isValid12HourTime=(str)=>{
    var regTime = /^(0[0-9]{1}|1[0-2]{1}):[0-5]{1}[0-9]{1}$/;
    return regTime.test(str)
}
//--------------------------------------
export const isValidURL= str =>{
    var regUrl=/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
    return regUrl.test(str)
}
//---------------------------------------
export const formatNumber=(number , charSlicer , cutNumber=3 , fromEnd=false)=>{
    let strPrice=number.toString();
    // strPrice = strPrice.replace(/\,/g, '');
    // var objRegex = new RegExp('(-?[0-9]+)([0-9]{3})');
 
    // while (objRegex.test(strPrice)) {
    //     strPrice = strPrice.replace(objRegex, '$1'+charSlicer+'$2');
    // }
 
    // return strPrice;
    const arrPrice=[];
    while(true){
        if(strPrice.length>cutNumber){
            if(fromEnd){
                arrPrice.push(strPrice.substr(0,cutNumber))
                strPrice=strPrice.substr(cutNumber)
            }
            else{
                arrPrice.push(strPrice.substr(strPrice.length-cutNumber,cutNumber))
                strPrice=strPrice.substr(0,strPrice.length-cutNumber)
            }
            
        }
        else if(strPrice.length>0 &&  strPrice.length<=cutNumber){
            arrPrice.push(strPrice)
            break
        }
        else
            break
    }
    
    if(!fromEnd)
        arrPrice.reverse();
     return arrPrice.join(charSlicer)

}
//---------------------------------------
export const formatPhone=(number , charSlicer , cutNumber=3)=>{
    let strPrice=number.toString();
    const arrPrice=[];
    while(true){
        if(strPrice.length>cutNumber){
            arrPrice.push(strPrice.substr(strPrice.length-cutNumber,cutNumber))
            strPrice=strPrice.substr(0,strPrice.length-cutNumber)
        }
        else if(strPrice.length>0 &&  strPrice.length<=cutNumber){
            arrPrice.push(strPrice)
            break
        }
        else
            break
    }
     arrPrice.reverse();
     return arrPrice.join(charSlicer)
}
//---------------------------------------
export const getTimeFromTimestamp=(intTime , reverseMinHour=false) =>{
    if(intTime>=86400)
    intTime-=86400
    const hour=Math.floor(intTime/3600)
    const min=Math.floor((intTime%3600)/60)
    return reverseMinHour ? `${hour<10 ? "0"+hour : hour} : ${min<10 ? "0"+min : min}`  
                          : `${min<10 ? "0"+min : min} : ${hour<10 ? "0"+hour : hour}`
}
//--------------------------------------