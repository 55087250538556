
//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
import {ScrollView, View , TouchableOpacity} from "react-native"
import {MyText, PageContainer  ,WaitModal , Confirm, MyButton, MyIcon, MyImage, MyBorderInput, Alert ,AnimatableView } from "../../../globalComponents/components"
import { connect } from 'react-redux';
import { formatNumber } from '../../../globalComponents/rejax';
import Fetch from "../../../globalComponents/fetch"
import { setToastMessage } from '../../../redux/toastMessage';
import { addUpdateChapter, refreshChapter } from '../../../redux/announcement';
import MdlFactor from "../../../commonComponents/mdlFactor"
import uuid from 'react-uuid';
// import {useBackHandler , useKeyboard} from "@react-native-community/hooks"
import MdlImagePicker from '../../../commonComponents/mdlImagePicker';
import MdlShowPrice from './mdlShowPrice';
//===================================================================================================================
const AddAnnouncement=(props)=>{
//-----------------------------------GLOBAL VAR EREA
const {announcementPrice }=props.route.params
// const keyboard=useKeyboard()
//-----------------------------------REF EREA
const fileInputRef=useRef()
const confirmModal=useRef(null)
const fetchModal=useRef()
const mdlFactor=useRef()
const alertModal=useRef()
const mdlShowPrice=useRef()
//--------------------------------------------------------------------------------------
const [announcementInfo , setAnnouncementInfo]=useState({
    matn:'' , 
    selectedImage:null
})
const [showWaitModal , setShowWaitModal]=useState(false)
const [announcementUUID , setAnnouncementUUID]=useState(uuid())
//-----------------------------------USE_EFFECT EREA
useEffect(()=>{
    if(showWaitModal)
        setTimeout(()=> {
                                        setShowWaitModal(false) 
                                        onBackClick()
                                    }, 3000)
} , [showWaitModal])

useEffect(()=>{
    if(announcementPrice)
            setTimeout(()=>mdlShowPrice.current.show(announcementPrice) , 1000)
} , [])
//-----------------------------------FUNCTION EREA
// useBackHandler(()=>{
//     onBackClick()
//     return true
// })
const onBackClick=()=>props.navigation.goBack()
const handleImageUpload = async (arrImage) => setAnnouncementInfo({...announcementInfo , selectedImage : arrImage[0] })
const addNewAnnouncement=()=>{      

    if(announcementInfo.matn.trim().replace(/ +/ , " ").replace(/\n+/ , "\n").length<5){
        alertModal.current.show('خطا در ورود اطلاعات','متن اطلاعیه را با حداقل 5 حرف وارد نمایید و یا آن را خالی بگذارید','error');
        return
    }

    if(!announcementInfo.selectedImage){
        alertModal.current.show('خطا در ورود اطلاعات','تصویر آگهی را انتخاب نمایید.','error');
        return
    }

    const objData={
            jwt:props.profileState.jwt , 
            announcementUUID ,
            matn:announcementInfo.matn.trim().replace(/ +/g , " ").replace(/\n+/ , "\n")
    }
    fetchModal.current.uploadData( "user/addAnnouncement" ,
                                                        objData ,
                                                        [{
                                                            name:"imageForAnnouncement" , 
                                                            filename:"imageForAnnouncement.esi" , 
                                                            type:announcementInfo.selectedImage.type , 
                                                            uri :announcementInfo.selectedImage.uri  }] , 
                                                        {
                                                            onSuccess : responseOfAddNewAnnouncement ,
                                                            onStart:()=>{},
                                                            onError:()=>{},
                                                        } , 
                                                        true , )  
}
const responseOfAddNewAnnouncement= data =>{
        setAnnouncementInfo({
            matn:'' , 
            selectedImage:null
        })
        if(typeof(data)==="string"){

        }
        else{
            if(data.message==="PAY_LINK_CREATED"){
                mdlFactor.current.show("فاکتور ثبت آگهی" , 
                                                        "کاربر گرامی"+"\n"+
                                                        "فاکتور ثبت اطلاعیه جدید با موفقیت برای شما ایجاد گردیده و آماده ی پرداخت می باشد." 
                                                        , data.data.link , 
                                                        data.data.amount,
                                                        data.data.factorId )
                setAnnouncementUUID(uuid())
            }
            else{
                props.addUpdateChapter(data.data)
                props.setToastMessage("ثبت آگهی با موفقیت انجام شد.")
                onBackClick()
            }
    }
}
///-------------------------------------------------
const backFromMdlFactor=()=>{
    props.refreshChapter()
    onBackClick()
}
const getNewImage=()=>{
    setAnnouncementInfo({ ...announcementInfo , selectedImage:null })
    fileInputRef.current.showModal(1 , 1280 , 1280 , handleImageUpload )
}
//---------------------------------------------------
const onChangeText=( txtId  , newText )=> setAnnouncementInfo({...announcementInfo , [txtId]  : newText ? newText : ""   })

const [imageWidth , setImageWidth]=useState(0)
const onLayout=event =>{
    const {width}= event.nativeEvent.layout
    setImageWidth(width)
}
const leftComponent=()=>{
    return(
           <View>
                  <MyButton 
                                   title='ثبت اطلاعیه' 
                                   iconName='save' 
                                   buttonStyle={{backgroundColor:"#FFF"}}
                                   buttonIconStyle={{color:"#e57373"}}
                                   buttonTextStyle={{color:"#456e7a"}}
                                //    disabled={!announcementInfo.selectedImage} 
                                   onClick={addNewAnnouncement}  />
           </View>
    )
}
//--------------------------------------------------
   return(
       <PageContainer   title={"ثبت اطلاعیه جدید"} 
                                         subTitle={" روستای "+props.profileState.objCity.name}
                                          containerPadding={0} 
                                          leftComponent={ leftComponent()  }  
                                         headerColor="#00ACC1" 
                                         headerTextColor="#FFFFFF" 
                                         backgroundColor="#FFFFFF"
                                        showBackIcon
                                        onBackIconClick={onBackClick} 
                                         >

                                <View  style={{paddingHorizontal:16 , paddingVertical:8}}>
                                    {
                                            announcementInfo.selectedImage ? 
                                            <View  style={{  paddingVertical:8}}>
                                                    <TouchableOpacity   style={{borderWidth:1, borderStyle:"dashed" , borderColor:"#e0e0e0" , borderRadius:10 ,   padding:5  }}
                                                            onPress={getNewImage}>
                                                           <View onLayout={onLayout} style={{}}>
                                                           <MyImage  source={{ uri : announcementInfo.selectedImage.uri}} resizeMode={"cover"}   style={{width:imageWidth , height:imageWidth , borderRadius:10}} />
                                                           </View>
                                                    </TouchableOpacity>
                                            </View> : 
                                            <View  style={{justifyContent:"center" , marginTop:16 , paddingHorizontal:16 , paddingVertical:8}}>
                                                    <TouchableOpacity   style={{borderWidth:2 , borderStyle:"dashed" , borderColor:"#eeeeee" , borderRadius:10 , 
                                                                                                  padding:8 , paddingTop:20 , justifyContent:"center" , alignItems:"center"}}
                                                            onPress={()=>fileInputRef.current.showModal(1 , 1280 , 1280 , handleImageUpload )}>
                                                            <View  style={{justifyContent:"center" , alignItems:"center"}}>
                                                            <MyIcon iconName="add_a_photo" iconSize={30} color="#00bcd4" />
                                                            <MyText smallFont  style={{marginVertical:16 , }} >انتخاب تصویر اطلاعیه</MyText>
                                                            <MyText  tinyFont color="#4fc3f7"  style={{ padding:10 , borderRadius:6   , marginBottom:20 , textAlign:"center" }} >لطفا اطلاعیه را با فرمت های jpg  و یا jpeg  بارگزاری نمایید</MyText>
                                                            </View>
                                                    </TouchableOpacity>
                                            </View>
                                    }
                                     <MyBorderInput title="متن اطلاعیه" smallFont
                                                             containerStyle={{marginTop:35 , paddingBottom:10 , marginBottom:15}}
                                                             subTitle="ورود عنوان الزامیست"
                                                             multiline
                                                             minNumberOfLines={5}
                                                            value={announcementInfo.matn} placeholder="متن اطلاعیه را وارد کنید..." 
                                                            id="matn" maxLength={1000} hasCharCounter={true} onChangeText={onChangeText}
                                                            inputStyle={{textAlign:"right" , marginTop:10 ,textAlignVertical :"top"}} />
                                    {/* <View style={{marginVertical:20 , paddingHorizontal:10 , flexDirection:"row-reverse"}}>
                                    <MyButton smallFont
                                                    title='ثبت اطلاعیه' 
                                                    iconName='save' 
                                                    buttonStyle={{paddingHorizontal:15}}
                                                    buttonIconStyle={{}}
                                                    buttonTextStyle={{}}
                                                    disabled={!announcementInfo.selectedImage} 
                                                    onClick={addNewAnnouncement}  />
                                    </View> */}
                                </View>
            <MdlImagePicker ref={fileInputRef}   />
            <MdlFactor  ref={mdlFactor} onLinkClick={backFromMdlFactor} />
            <WaitModal visible={showWaitModal}/>
            <MdlShowPrice ref={mdlShowPrice} />
            <Confirm ref={confirmModal} />
            <Fetch ref={fetchModal}/>
            <Alert ref={alertModal} />
       </PageContainer>
   )
}

const mapStateToProps=state =>({
       profileState:state.profileReducer
})
const mapDispatchToProps = dispatch =>({
    setToastMessage : strMessage => dispatch(setToastMessage(strMessage)),
    addUpdateChapter : chapterInfo => dispatch(addUpdateChapter(chapterInfo)) ,
    refreshChapter: ()=>dispatch(refreshChapter("ANNOUNCEMENT"))
})
export default connect(mapStateToProps , mapDispatchToProps)(AddAnnouncement);
//=========================================================================================