//========================================================================================
import React,{ useState}  from 'react'
import { Linking, ScrollView, TouchableOpacity, View } from 'react-native'
import {  MyImage, MyText} from './../../../../globalComponents/components'
import { arrSocialNetwork } from './../../../../globalDef'
 
const LinkComponent= props =>{
    const { socialNetworkIcon , onSelect , imageWidth} = props

    return(
            <View    style={{width:imageWidth , marginRight:10 }}>
                <TouchableOpacity style={{width:"100%" , flexDirection:"column"  , alignItems:"center"   }}   onPress={onSelect} >
                        <View  style={{padding:1  , borderRadius:10 }}>
                            <MyImage source={ socialNetworkIcon} style={{width:imageWidth-4 , height:imageWidth-4 ,    }}   />
                        </View>
                </TouchableOpacity>       
            </View>

    )
}
const ShopLink=(props)=>{
//-----------------------------------GLOBAL VAR EREA
const {arrLink}=props
//-----------------------------------REF EREA
//-----------------------------------STATE EREA
const [imageWidth ]=useState(40)
//-----------------------------------USE_EFFECT EREA
//-----------------------------------FUNCTION EREA
const onAddUpdateLink = linkInfo => Linking.openURL(linkInfo.link) 
//--------------------------------------------------
   return(
        <View  style={{ paddingHorizontal:6   }}>
            <ScrollView horizontal  contentContainerStyle={{flexDirection:"row-reverse" ,direction:"revert" }} >
                        {
                            arrLink.map(link =><LinkComponent key={link.id} {...link} imageWidth={imageWidth}
                                                                                                            socialNetworkIcon={arrSocialNetwork.filter(socialNetwork => socialNetwork.id===link.socialNetworkId)[0].icon} 
                                                                                                            onSelect={()=>onAddUpdateLink(link)} />)
                        }
            </ScrollView>
        </View>
       
   )
}
export default ShopLink;
//=========================================================================================