//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
 
const Section= props =>{
       const {containerStyle={} , containerClassName="" , contentStyle={} , contentClassName=""} = props
       return(
              <div className={` displayFlexCenter displayFlexCol ${containerClassName}`} style={Object.assign({} , containerStyle)}>
                     <div className={`w3-col l9 m11 s12  ${contentClassName}`} style={Object.assign({} , { alignItems:"center" , padding:"20px 0"} , contentStyle)}>
                     {
                            props.children
                     }
                     </div>
              </div>
       )
}

export default Section;
//=========================================================================================