//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
 
import { ModalContainer, MyButton } from '../../../../globalComponents/components'

const MdlMenu =(props)=>{
//-----------------------------------GLOBAL VAR EREA
const { visible , onCloseClick , onSelectItem , arrNavbarButton , onSelectAdvertising } =props
//-----------------------------------REF EREA
//-----------------------------------STATE EREA
//-----------------------------------USE_EFFECT EREA
//-----------------------------------FUNCTION EREA
//--------------------------------------------------
   return(
       <ModalContainer visible={visible}
                                  title=''
                                  message=''
                                  onBackClick={onCloseClick}
                                  closeWithClickBlankPlace={true}
                                  containerStyle={{ display:"flex" ,flexDirection:"row-reverse" ,alignItems:"stretch" ,  justifyContent:"flex-start"}}
                                  containerClassName=' '
                                  contentStyle={{width:"70%"}}
                                  contentClassName='w3-padding-small-' 
                                  position='left'
                                  animation='slideInRight' >
                     <div>
                            <img src={require("./../../../../assets/images/logo/logoNameHoriz.png")} style={{height:50 , width:"auto"}} />
                     </div>  
                     <div className='w3-padding w3-margin'>
                                   <MyButton title="سفارش تبلیغات" onClick={onSelectAdvertising} 
                                                 buttonStyle={{backgroundColor:"transparent" , border:"1px solid #26a69a" , borderRadius:10 , padding:"8px 25px" }}
                                                 buttonTextStyle={{color:"#26a69a"}} />
                     </div>
                     {/* <div className='w3-margin-top'>
                            {
                                   arrNavbarButton.map((navButton , index) =><MyButton key={index}  
                                                                                                                           title={navButton.title}  
                                                                                                                           iconName=""
                                                                                                                           buttonStyle={{padding:6 ,backgroundColor:"transparent" , borderBottom:"1px solid #eeeeee" , 
                                                                                                                                                   borderRadius:0 , justifyContent:"flex-end"}}
                                                                                                                           buttonTextStyle={{color:"#456e7a"}}   /> )
                            }
                     </div>                                      */}
       </ModalContainer>
   )
}

export default MdlMenu ;
//=========================================================================================