//========================================================================================
import React,{  }  from 'react'
import { Dimensions, TouchableOpacity, View } from 'react-native';
import {  MyText  , MyImage ,MyButton, MySelect } from '../../../../../../globalComponents/components';
import { connect } from 'react-redux';
//----------------------------------------------------------------------------
const InfoComponent= props=>{ 
    const {title , icon , keyItem , containerStyle={} , onUpdateDescriptionInfo} = props
    const onClickToUpdate=()=>onUpdateDescriptionInfo(keyItem)
    return(

        <View style={Object.assign({} , {flexDirection:"row-reverse"   , borderColor:"#f0f0f0" } , containerStyle)}>
            <View style={{flex:1}}>
                <MyButton tinyFont disabled opacityOnDisabledOff
                                title={title}
                                iconName={icon} 
                                buttonStyle={{backgroundColor:"transparent" , justifyContent:"flex-end"  , flexDirection:"row-reverse" }}
                                buttonIconStyle={{color:"#e57373" , fontSize:23}}
                                buttonTextStyle={{color:"#456e7a"}} /> 
            </View>
            {/* <View>
                    <MyButton onClick={onClickToUpdate}
                                     title='' 
                                     iconName='edit' 
                                     buttonStyle={{paddingHorizontal:3 , paddingVertical:3 , borderRadius:20 , backgroundColor:"#FFF"}}
                                     buttonIconStyle={{fontSize:20 , color:"#26a69a"}}
                                     buttonTExtStyle={{}} />
            </View> */}
        </View>
    )
}
//----------------------------------------------------------------------------
const ShopProperties=(props)=>{
//-----------------------------------GLOBAL VAR EREA
const shopInfo =props.myBazaarState.shopInfo
const {onUpdateDescriptionInfo , onShopAvatarClick , onUpdateDescriptionClick=()=>{} } = props
//-----------------------------------FUNCTION EREA
//--------------------------------------------------
const getShopAvatar=()=>onShopAvatarClick()
//--------------------------------------------------
const onSelectShopCategory= selectedShopCategoryId => onUpdateDescriptionInfo("shopCategoryId" , selectedShopCategoryId )
//--------------------------------------------------
const avatarPanelWidth=Dimensions.get("window").width/4
   return(
                <View  style={{marginVertical:20}}>
                    {/* <MySelect smallFont placeholder="انتخاب دسته بندی فروشگاه" selectButtonStyle={{buttonStyle:{paddingVertical:1 , elevation:0 , borderWidth:1 , borderColor:"#e0e0e0"}}}
                                     arrItem={props.bazaarState.arrShopCategory} onSelectItem={onSelectShopCategory} selectedItemId={shopInfo.shopCategoryId} /> */}
                    <View style={{flexDirection:"row-reverse" , marginTop:10 , alignItems:"center"}}  >
                        <View style={{width:avatarPanelWidth , height:avatarPanelWidth , alignItems:"center" , justifyContent:"center" , padding:5 , }}>
                            <TouchableOpacity onPress={getShopAvatar} style={{width:"100%" , height:"100%" ,  }}>
                                    {
                                    <View className='' style={{borderColor:"#90a4ae" , borderRadius:avatarPanelWidth , width:"100%" , height:"100%" , 
                                                                                  borderWidth:4 , padding:3 , alignItems:"center" , justifyContent:"center"}}>
                                            <MyImage  source={shopInfo.shopAvatar  ?  {uri : shopInfo.shopAvatar} : require("../../../../../../assets/images/act/imgDefImage.png")}  resizeMode="cover"
                                                        style={{width:"100%" , height:"100%",borderRadius:avatarPanelWidth}} />
                                            {
                                            !shopInfo.shopAvatar ?  <MyText tinyFont style={{ position:"absolute" }} >لوگو فروشگاه  </MyText> :null
                                            } 
                                    </View> 
                                    }
                            </TouchableOpacity>
                        </View>
                        <View style={{flex:1 ,padding:0 , marginLeft:10 }}>
                                <InfoComponent  keyItem="shopCategoryId"    title={"دسته بندی : "+props.bazaarState.arrShopCategory.filter(category=>category.id===shopInfo.shopCategoryId)[0].name}   
                                                          icon='apps'      onUpdateDescriptionInfo={onUpdateDescriptionInfo}  />
                                <InfoComponent  keyItem="name"    title={shopInfo.name}   icon='storefront'      onUpdateDescriptionInfo={onUpdateDescriptionInfo}  />
                                <InfoComponent   keyItem="sellerName"   title={shopInfo.sellerName}   icon='person'   onUpdateDescriptionInfo={onUpdateDescriptionInfo}/>
                                <InfoComponent   keyItem="phone"   title={shopInfo.phone}   icon='phone'             onUpdateDescriptionInfo={onUpdateDescriptionInfo}/>
                        </View>
                    </View>
                    <InfoComponent   keyItem="description" onClick={()=>{}}  title={shopInfo.description}   icon='description'  onUpdateDescriptionInfo={onUpdateDescriptionInfo}/>
                        <View style={{flexDirection:"row-reverse"}}>
                            <MyButton onClick={onUpdateDescriptionClick} 
                                             tinyFont
                                             title='بروزرسانی اطلاعات' 
                                             iconName='refresh' 
                                             buttonStyle={{}}
                                             buttonIconStyle={{}}
                                             buttonTExtStyle={{}} />
                        </View>
            </View>
   )
}

const mapSTateToProps= state => ({
       profileState : state.profileReducer ,
       myBazaarState:state.myBazaarReducer ,
       bazaarState : state.bazaarReducer
   })
   const mapDispatchToProps= dispatch =>({

   })
export default connect(mapSTateToProps , mapDispatchToProps)(ShopProperties);
//=========================================================================================