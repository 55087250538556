//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
import {View} from "react-native"
import { PageContainer , MyText , Alert , MyImage, Waiting, MyButton, ActivationCodeInput  } from '../../globalComponents/components';
import { isValidNumber } from '../../globalComponents/rejax';
import Fetch from '../../globalComponents/fetch';
import { connect } from 'react-redux';
import { setToastMessage } from '../../redux/toastMessage';
import { setProfileInfo } from '../../redux/profile';
import Styles from '../../styles';
import AsyncStorage from "@react-native-async-storage/async-storage"
import imgLogoNameHoriz from "./../../assets/images/logo/logoNameHoriz.png"

const GetActivationCode=(props)=>{
    const fetchModal=useRef()
    const alertModal=useRef()
    const [imageWidth , setImageWidth]=useState(10)

    const {phone  , cityId}=props.route.params

    const [validateCode , setValidateCode]=useState("")
    const [fetchState , setFetchState]=useState({ fetchFlag: false , errroFlag:false })

    const  onChangeValidateCode= value => setValidateCode(value)
    useEffect(()=>{
        if(validateCode.length===4)
            checkValidateCode()
    },[validateCode])
    //----------------------------------------------------------------------------------
    const [counter , setCounter]=useState(120)
    useEffect(()=>{
            setTimeout(()=>{
                if(counter>0)
                    setCounter(counter-1)
            } , 1000)
    } , [counter])
    const showRemainigTime=()=>{
        if(counter>0){
            const min=Math.floor(counter/60)
            const sec=counter%60
            return `${min>=10 ? min : "0"+min} : ${sec>=10 ? sec : "0"+sec} `
        }
        else
            return "00 : 00"
    }
    //----------------------------------------------------------------------------------
    useEffect(()=>{
        if(props.profileState.jwt)
            props.navigation.replace("initApp")
    } , [props.profileState.jwt])
    //-----------------------------------------------------------------------------------
    const checkValidateCode=()=>{
        if(!isValidNumber(validateCode , 4 , 4)){
            alertModal.current.show("خطا در ورود اطلاعات",
                                                     "کد تایید اعتبار را با 4  حرف وارد نمایید !!!",
                                                     "error")
            return 
        }
        const objData={
            phone,
            cityId , 
            validateCode:validateCode ,
            role:"USER"
        }
        fetchModal.current.fetchData("checkActivationCode",
                                                        objData , 
                                                        {
                                                            onStart:()=>setFetchState({fetchFlag:true , errroFlag:false}) ,
                                                            onError : ()=> setFetchState({fetchFlag:false , errroFlag:true}) , 
                                                            onSuccess :responseOfCheckValidateCode
                                                        } ,false , true , true)
    }
    const responseOfCheckValidateCode=(data)=>{
            setFetchState({fetchFlag:false , errroFlag:false})
            if( typeof(data)==="string" ){
                if(data==="VALIDATE_CODE_EXPIRED"){
                    props.setToastMessage("به دلیل طولانی شدن مراحل ورود لازم است مجددا شماره تماس خود را تایید نمایید.")
                        props.navigation.replace("getPhoneNumber" )
                }
                if(data==="NEED_ENTER_PASSWORD"){
                    props.navigation.replace("getPassword" , { phone ,cityId , validateCode } )
                }
                return
            }
            AsyncStorage.setItem("PROFILE_INFO" , JSON.stringify(data) )
            props.setProfileInfo(data)
    }
    //----------------------------------------------------------------------------------
    const checkPhoneAndGetActivationCode=()=>{
        const objData={
            cityId , 
            phone:phone ,
            role:"USER"
        }
        fetchModal.current.fetchData("getActivationCode" , 
                                                            objData ,
                                                             {
                                                                    onStart:()=>setFetchState({fetchFlag:true , errroFlag:false}),
                                                                    onError:()=>setFetchState({fetchFlag:false , errroFlag:true}),
                                                                    onSuccess:responseOfCheckPhone ,
                                                                    onBackFromConfirmError:()=>setFetchState({fetchFlag:false , errroFlag:false})
                                                            } , false , true , true )
    }
    const responseOfCheckPhone=(data)=>{
        setFetchState({fetchFlag:false , errroFlag:false})
        if(data==="ACTIVATION_CODE_SENDED"){
            setCounter(120)
            props.setToastMessage("کد فعال سازی مجددا برای شما ارسال شد.")
        }
    }
    //------------------------------------------------------------------------------------
    const onLayout=(event)=> {
        const {x, y, height, width} = event.nativeEvent.layout;
        setImageWidth(width)
      }
    //-----------------------------------------------------------------------------------
   return(
    <PageContainer title="ورود به برنامه" 
                            headerColor="#FFFFFF" 
                            headerTextColor="#456e7a" 
                            backgroundColor="#FFFFFF"
                            showHeader={false} hasFlatList>
        <View className='displayFlexGrow displayFlexCol' style={Styles._pages._getActivationCode.container}>
                <View style={Styles._pages._getActivationCode.containerUp}>
                            <View  onLayout={onLayout} style={Object.assign({} , Styles.l3 , Styles.m4  , Styles.s7  , {} )}>
                                <MyImage source={imgLogoNameHoriz}  style={{width:"100%"  , height:Math.floor(imageWidth/3.3)}} resizeMode="cover" />
                            </View> 
                        <MyText  smallFont   color="#505050" style={{marginTop:10 , marginBottom:10 }}>
                                    {
                                        "کد تایید اعتبار از طریق پیامک به گوشی شما ارسال شد"+"\n"+
                                        "لطفا کد دریافتی را در کادر زیر وارد نمایید"
                                    }
                        </MyText>
                        <View className='displayFlexRowReverse displayFlexCenter' style={{marginTop:10}}>
                                <ActivationCodeInput  numberOfDigit={4} validateCode={validateCode} onChangeValidateCode={onChangeValidateCode} />
                        </View>
                </View>
                <View  style={Styles._pages._getActivationCode.containerDown}>
                    {
                        !fetchState.fetchFlag && !fetchState.erroFlag ?
                        <View>
                            {
                                counter>0 ?
                                <MyText tinyFont isBold style={Styles._pages._getActivationCode.counterText}>{showRemainigTime()}</MyText>
                                :<MyButton smallFont  title="ارسال مجدد کد فعال سازی" iconName="qr_code" 
                                                                    buttonStyle={{backgroundColor:"#f4f4f4" ,}}
                                                                    buttonTextStyle={{color:"#456e7a"}}
                                                                    buttonIconStyle={{color:"#e57373"}} onClick={checkPhoneAndGetActivationCode} />
                            }
                        </View>:null
                    }
                        <Waiting visible={fetchState.fetchFlag && !fetchState.errroFlag} />
                </View>
            </View>
        <Fetch ref={fetchModal} />
        <Alert ref={alertModal} />
    </PageContainer>
   )
}

const mapStateToProps=(state)=>({
    profileState:state.profileReducer
})
const mapDispatchToProps=(dispatch)=>({
    setProfileInfo : (profileInfo)=>dispatch(setProfileInfo(profileInfo)),
    setToastMessage : strMessage => dispatch(setToastMessage(strMessage))
})
export default connect(mapStateToProps ,mapDispatchToProps) (GetActivationCode);
//=========================================================================================