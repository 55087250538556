//=====================================================================================================================================================
const ADD_CHAPTER="ADD_CHAPTER"
const DELETE_CHAPTER="DELETE_CHAPTER"
const ADD_UPDATE_CHAPTER="ADD_UPDATE_CHAPTER"
const SET_SEARCH_CHAPTER="SET_SEARCH_CHAPTER"
const REFRESH_CHAPTER="REFRESH_CHAPTER"
//=====================================================================================================================================================
export const addChapter= chapterInfo => ({type:ADD_CHAPTER , chapterInfo})
export const deleteChapter= chapterId => ({type:DELETE_CHAPTER , chapterId})
export const addUpdateChapter= chapterInfo => ({type:ADD_UPDATE_CHAPTER , chapterInfo})
export const setSearchChapter=( strSearch)=>({type : SET_SEARCH_CHAPTER ,  strSearch})
export const refreshChapter=( )=>({type : REFRESH_CHAPTER })
//=====================================================================================================================================================
const chapterInit={
       arrChapter:[] ,  /* [{  id , 
                                            writerName , 
                                            writerAvatar ,
                                            writerAboutMe, 
                                            title , 
                                            matn , 
                                            chapterTypeName ,
                                            targetCategoryId , 
                                            saveDate  , 
                                            chapterAvatar, 
                                            validate , 
                                            commentable , 
                                            managerDescription , 
                                            likeCount , 
                                            dislikeCount , 
                                            isMyChapter   }] */
       finished:false,
       refresh:false , 
       strSearch:""
}

export default (state=chapterInit , action)=>{
       switch(action.type){
              case ADD_CHAPTER : return { 
                     ...state , 
                     arrChapter : [...state.arrChapter , ...action.chapterInfo.arrChapter ] , 
                     finished  : action.chapterInfo.finished,
                     refresh:false
              }
              case ADD_UPDATE_CHAPTER : return {
                     ...state ,
                     arrChapter :  state.arrChapter.filter(chapter => chapter.id===action.chapterInfo.id).length  ? 
                                                                        state.arrChapter.map(chapter => chapter.id===action.chapterInfo.id ? action.chapterInfo : chapter )
                                                                       :[action.chapterInfo ,   ...state.arrChapter   ]
              }
              case SET_SEARCH_CHAPTER : return{
                     arrChapter : [] , 
                     finished:false , 
                     refresh:false , 
                     strSearch : action.strSearch
              }
              case REFRESH_CHAPTER : return{
                     ...state , 
                     arrChapter : [] , 
                     finished:false,
                     refresh:true,   
              }
              case DELETE_CHAPTER : return {
                     ...state ,
                     arrChapter : state.arrChapter.filter(chapter => chapter.id!==action.chapterId)
              }                                                                   
              default:return state
       }
}