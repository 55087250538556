//========================================================================================
import React,{useRef , useEffect  , useState, forwardRef, useImperativeHandle}  from 'react'
import Resizer from "react-image-file-resizer";
 
const MdlImagePicker=forwardRef((props , ref)=>{
//-----------------------------------GLOBAL VAR EREA
//-----------------------------------REF EREA
const refInput=useRef()
//-----------------------------------STATE EREA
const [inputFileType , setInputFileType]=useState({
                                                                                           multiple:false , 
                                                                                           maxWidth:1280 , 
                                                                                           maxHeight:1280 ,
                                                                                           onSelectImage:()=>{} 
                                                                                    })
//-----------------------------------USE_EFFECT EREA
useImperativeHandle(ref , ()=>({
       showModal : (multiple=false , maxWidth=1280 , maxHeight=1280 , onSelectImage=()=>{})=>{
              setInputFileType({
                     multiple , 
                     maxWidth , 
                     maxHeight ,
                     onSelectImage
              })
              refInput.current.click()
       }
}))
//-----------------------------------FUNCTION EREA
const resizeFile = (file) =>
       new Promise((resolve) => {
                     Resizer.imageFileResizer(
                            file,
                            inputFileType.maxWidth,
                            inputFileType.maxHeight,
                            "JPEG",
                            100,
                            0,
                            (uri) => {
                            resolve(uri);
                            },
                            "file"
                     );
       });
const onSelectImage=async e =>{

       try{
              if(e.target.files && e.target.files.length > 0){
                     const arrFile=[]
                     for(let file of e.target.files){
                            const image = await resizeFile(file)
                            arrFile.push(image)
                     }
                     inputFileType.onSelectImage(arrFile)
                     // setChapterInfo({...chapterInfo , arrImageFile:[...chapterInfo.arrImageFile , ...arrFile ]})
              }
       }
       catch(error){
              alert("در دریافت اطلاعات تصویر خطایی روی داده است")
       }
       
}
//--------------------------------------------------
   return(
       <input ref={refInput} className='w3-hide' type='file' multiple={inputFileType.multiple} 
                  accept={["image/png" ,"image/jpg" , "image/jpeg" ,  ]} onChange={onSelectImage}  />
   )
})

export default MdlImagePicker;
//=========================================================================================