
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import {SERVER_URL , SERVER_URL_WITH_WWW, SITE_URL} from "../../globalDef"
import {connect} from "react-redux"
import {Alert,Confirm , WaitModal , UploadProgressModal} from "../components"
import {  resetProfileInfo   } from '../../redux/profile'
import axios from "axios"
import AsyncStorage from '@react-native-async-storage/async-storage'

const Fetch =forwardRef((props , ref)=>{
    //==================================================
    const alertModal=useRef()
    const confirmModal=useRef()
    const axiosSource=useRef(null);

    //================================================
    const [fetchState , setFetchState]=useState({
                                        url: '',
                                        data: '',
                                        arrFiles:[],
                                        funcAction:{
                                                        onStart   : ()=>{},
                                                        onError   : ()=>{},
                                                        onSuccess : ()=>{},
                                                        onBackFromConfirmError : ()=>{}
                                                   },
                                        showWaitModal:false,
                                        showBackButton: false, 
                                        showUploadProgressModal:false,
                                        preShowWaitModal:false,
                                        customTimeout:15000,
                                        fetchType:"fetch",
                                        showTryAgainModalOnError:false,
                                        changeDetect:false
                                    })
    const [uploadProgressPrecentValue , setUploadProgressPrecentValue]=useState(0)
    const [status , setStatus]=useState({fetchFlag:false , errorFlag:false})
    let task="";
    //================================================
    useEffect(()=>{
        return ()=>{
            if(status.fetchFlag){
                try{ task.cancel((err) => {  }) }
                catch{}
            }   
        }
    },[])

    useEffect(()=>{
        if(fetchState.changeDetect===true)
            setFetchState({...fetchState , changeDetect:false})
        if(fetchState.url && !fetchState.changeDetect)
            doAction()
    },[fetchState.changeDetect])

    useImperativeHandle(ref , ()=>({
        fetchData: (url,data, funcAction={   onStart:()=>{} , onError:()=>{} , onSuccess:()=>{} , onBackFromConfirmError:()=>{}},  showWaitModal, showTryAgainModalOnError=true,  showBackButton=true,customTimeout=15000)=>
        {
            if(status.fetchFlag)
                return
            
            const onStart= funcAction.onStart ? funcAction.onStart :   ()=>{}
            const onError= funcAction.onError ? funcAction.onError :   ()=>{}
            const onSuccess= funcAction.onSuccess ? funcAction.onSuccess : ()=>{}
            const onBackFromConfirmError = funcAction.onBackFromConfirmError ? funcAction.onBackFromConfirmError : ()=>{}

            setFetchState({
                        fetchType:"fetch",
                        url,
                        data,
                        funcAction:{ onStart , onError , onSuccess , onBackFromConfirmError },
                        showBackButton ,
                        preShowWaitModal: showWaitModal,
                        customTimeout,
                        showTryAgainModalOnError ,
                        showUploadProgressModal:false,

                        arrFiles:[],
                        showWaitModal:false,
                        changeDetect:true
                    })
        } ,
        uploadData:(url,data , arrFiles ,funcAction={onStart:()=>{} , onError:()=>{} , onSuccess:()=>{} , onBackFromConfirmError:()=>{}} , showTryAgainModalOnError=true , showBackButton=true,customTimeout=15000)=>{
            if(status.fetchFlag)
                return
                const onStart= funcAction.onStart ? funcAction.onStart :   ()=>{}
                const onError= funcAction.onError ? funcAction.onError :   ()=>{}
                const onSuccess= funcAction.onSuccess ? funcAction.onSuccess : ()=>{}
                const onBackFromConfirmError = funcAction.onBackFromConfirmError ? funcAction.onBackFromConfirmError : ()=>{}
            setFetchState({
                        fetchType: "upload",
                        url,
                        data,
                        arrFiles,
                        funcAction:{ onStart , onError , onSuccess , onBackFromConfirmError },
                        showBackButton,
                        showTryAgainModalOnError ,
                        showWaitModal:false,
                        customTimeout,
                        showUploadProgressModal:false,
                        preShowWaitModal:true,
                        changeDetect:true
                    })
                    setUploadProgressPrecentValue(0)
        }
    }))
    const doAction=async ()=>{
        fetchState.funcAction.onStart()
        setStatus({ fetchFlag:true , errorFlag:false })

        let newData={}
        const {data}=fetchState
        for(let key in data){
            if(typeof(data[key])==="string"){
                const strData= data[key].replace(/۰/g , "0")
                                        .replace(/۱/g , "1")
                                        .replace(/۲/g , "2")
                                        .replace(/۳/g , "3")
                                        .replace(/۴/g , "4")
                                        .replace(/۵/g , "5")
                                        .replace(/۶/g , "6")
                                        .replace(/۷/g , "7")
                                        .replace(/۸/g , "8")
                                        .replace(/۹/g , "9")

                newData=Object.assign({} ,newData ,  {[key] : strData })
            }
            else
                newData=Object.assign({} ,newData ,  {[key] : data[key] })
        }
        const strData=JSON.stringify(newData)

        if(!fetchState.arrFiles.length)
            _fetchData( strData );  
        else
            _uploadData( strData )  
    }
    //================================================
    const  _fetchData=async (strData)=>{
        setFetchState({...fetchState , showWaitModal: fetchState.preShowWaitModal});
        const data = new FormData() 
        data.append("objData" , strData )
        task= axios({
                                     url:`${window.location.href.indexOf("www")!==-1 || window.location.href.indexOf("WWW")!==-1 ?  SERVER_URL_WITH_WWW : SERVER_URL}${fetchState.url}` , 
                                    method:"POST" , 
                                    data , 
                                    timeout:30000 , 
                                    headers:{
                                        'accept': 'application/json',
                                        'Content-Type': `multipart/form-data;`,
                                    }
                            })
                            .then(response=>{
                                setStatus({fetchFlag:false , errorFlag:false})
                                setFetchState({ ...fetchState , showUploadProgressModal:false,showWaitModal:false})
                                checkReceivedData(response.data)
                            })
                            .catch(error=>{
                                        // console.log(error)
                                        setStatus({fetchFlag:false , errorFlag:true})
                                        setFetchState({ ...fetchState ,  showWaitModal: false })
                                        fetchState.funcAction.onError()
                                        errorReceived("در ارتباط با سرور خطایی روی داده است." + "\n" +
                                                                "این مشکل ممکن است در نتیجه عدم اتصال و یا کندی شبکه اینترنت شما باشد"); 
                            })
    }
    //================================================
    const _uploadData=async (strData)=>{

        setFetchState({...fetchState , showUploadProgressModal : true});
        
        const data = new FormData() 
        data.append("objData" , strData )
        fetchState.arrFiles.map( file => data.append(file.name ,  file.file ) )

        
        task= axios({
                                url: `${window.location.href.indexOf("www")!==-1 || window.location.href.indexOf("WWW")!==-1 ?  SERVER_URL_WITH_WWW : SERVER_URL}${fetchState.url}` ,
                                method: 'POST',
                                data ,
                                timeout:180000 , 
                                header:{
                                    'accept': 'application/json',
                                    'Content-Type': `multipart/form-data;`,
                                },
                                onUploadProgress: progressEvent => {
                                            const percentValue=Math.floor (progressEvent.loaded *100 /  progressEvent.total) //* 100
                                                setUploadProgressPrecentValue(percentValue) 
                                        }
                    })
                    .then(response=>{
                        setFetchState({...fetchState , showUploadProgressModal: false, showWaitModal: false })
                        setStatus({ fetchFlag:false , errorFlag:false })
                        checkReceivedData(response.data)
                    })
                    .catch(error=>{
                        // console.log(error)
                        setStatus({ fetchFlag:false , errorFlag:true })
                        setFetchState({...fetchState , showUploadProgressModal: false, showWaitModal: false })
                        errorReceived("در ارتباط با سرور جهت اجرای درخواست خطایی روی داده است." + "\n" +
                                      "این مشکل ممکن است در نتیجه عدم اتصال و یا کندی شبکه اینترنت شما باشد")
                    })
    }
    //================================================
    const  checkReceivedData=(data)=>{

        // wrongJwt()
        // return

        setFetchState({...fetchState , showUploadProgressModal:false,showWaitModal:false })
        if (data.status === "success") {
            if (data.data === "JWT_WRONG")
                wrongJwt();
            else if (data.data==="ACCESS_TO_PAGE_IS_FORBIDDEN")
                closeWindow()
            else {
                if (data.data === 'NO_DATA')
                    data.data = [];
                fetchState.funcAction.onSuccess(data.data);
            }
        } 
        else if(data.status==="error"){
            errorReceived(data.data , data.canTryAgain);
        }     
        else
            errorReceived("در ارتباط با سرور خطایی روی داده است." + "\n" +
                "این مشکل ممکن است در نتیجه عدم اتصال و یا کندی شبکه اینترنت شما باشد");
    }
    //================================================
    const  errorReceived=async (strError , canTryAgain=true)=>{
        setStatus({fetchFlag:false , errorFlag:true})
        setFetchState({...fetchState ,showUploadProgressModal:false,showWaitModal:false })
        fetchState.funcAction.onError()
        const strErrorTitle = "خطا در ارتباط با سرور";

        let strErrorMessage="";
        if (strError == "timeout")
            strErrorMessage = 'امکان ارتباط با سرور وجود ندارد' + '\n' +
                              'لطفا مسیر ارتباط خود با شبکه را بررسی نمایید';
        else
            strErrorMessage=strError

        if(fetchState.showTryAgainModalOnError)
        {
            if(canTryAgain)
                confirmModal.current.show(strErrorTitle,
                                            strErrorMessage, 
                                            "error",
                                            doAction,
                                            "تلاش مجدد", 
                                            fetchState.showBackButton,
                                            false, 
                                            fetchState.funcAction.onBackFromConfirmError)
            else
                alertModal.current.show(strErrorTitle , strErrorMessage , "error")
        }
    }
    //===============================================
    const  wrongJwt=async () => {
        try{
                    await AsyncStorage.removeItem("PROFILE_INFO")
                    props.resetProfileInfo()
            // window.location.reload()
            confirmModal.current.show(  "خطا در اعتبارسنجی ارتباط",
                                        "امنیت ارتباط با سرور از سوی دستگاه شما با مشکل روبرو شده است" + "\n" +
                                        "برای حل مشکل لازم است مجددا به حساب کاربری خود وارد شوید.",
                                        "error",
                                        () =>window.location.href=SITE_URL  , // window.location.reload() , 
                                        "تایید",
                                        false); 
        }
        catch(error){
            // console.log(error)
            confirmModal.current.show("خطا در آماده سازی برنامه",
                                      "آماده سازی اطلاعات اولیه برنامه با مشکل روبرو شده است."+"\n"+
                                      "لازم است برنامه بسته و مجددا باز گردد.",
                                      "error",()=>{window.location.href=SITE_URL/*window.location.reload()*/},"تایید",false)
        }    
    }
    //==============================================
    const closeWindow=()=>{
        confirmModal.current.show(  "خطا در اعتبارسنجی ارتباط",
        "به دلیل وجود یک ناهماهنگی در سطح دسترسی شما , لازم است برنامه یکبار بسته و مجددا باز شود .!!!!" ,
        "error",
        () => window.close(), 
              "تایید و خروج از پنل مدیریت ",
        false); 
    }
    //================================================
    return(
        <div>
            <WaitModal visible={fetchState.showWaitModal} />
            <Alert ref={alertModal} />
            <Confirm ref={confirmModal} />
            <UploadProgressModal visible={fetchState.showUploadProgressModal}  percentValue={uploadProgressPrecentValue} />
        </div>  
    )
})
//================================================
const mapStateToProps = (state) => ({
})
const mapDispachToProps=(dispatch)=>({
    resetProfileInfo : ()=>dispatch(resetProfileInfo()),
})
export default connect(mapStateToProps, mapDispachToProps, null, { forwardRef: true })(Fetch);

