//========================================================================================
import React,{ useEffect }  from 'react'
import { connect } from 'react-redux';
import  { setProfileInfo } from './redux/profile';
import {setInitialInfo} from "./redux/initialInfo"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { View } from 'react-native-web';
import {  Waiting } from './globalComponents/components';
import imgLogoHoriz from "./assets/images/logo/logoNameHoriz.png"
import Styles from './styles';
 
const InitApp=(props)=>{
       //-----------------------------------------------
       useEffect(()=>{
              checkJwtInfo()
       },[])
       useEffect(()=>{
              if(props.profileState.jwt){
                     setTimeout(gotoRoot , 1000)
              }  
       }  ,  [props.profileState.jwt])
       //----------------------------------------------
       const gotoRoot=()=>{
              const extraData=window.location.search
              if(extraData){
                     const indexOfAuthority=extraData.indexOf("?Authority=")
                     const indexOfStatus=extraData.indexOf("&Status=")
                     if(indexOfAuthority!==-1 && indexOfStatus!==-1){
                            const Authority=extraData.substring(indexOfAuthority+11 , indexOfStatus )
                            const Status=extraData.substring(indexOfStatus+8)
                            if(Authority && Status)
                                   setTimeout(()=>{props.navigation.replace("checkTransaction" ,{
                                                                                                                                                   Authority , 
                                                                                                                                                   Status 
                                                                                                                                            })} , 1000)
                            else
                                   props.navigation.replace("mainPage" ,{})
                     }
                     else
                            props.navigation.replace("mainPage" ,{})
              }
              else
                     props.navigation.replace("mainPage" ,{})
       }

       const checkJwtInfo=async ()=>{
              const jsonProfileInfo=await AsyncStorage.getItem("PROFILE_INFO")
              if(jsonProfileInfo)
              {
                     try{
                            const profileInfo=JSON.parse(jsonProfileInfo)
                            if(profileInfo.name  && profileInfo.phone && profileInfo.sex && profileInfo.jwt && profileInfo.objCity  ){
                                   props.setProfileInfo(profileInfo)
                            }
                     }
                     catch{ 
                            setTimeout(()=>{props.navigation.navigate("homePage" ,{})} , 1000)
                            // setTimeout(()=>{props.navigation.replace("getPhoneNumber" ,{})} , 1000)
                     }
              }
              else
                     {
                            setTimeout(()=>{props.navigation.navigate("homePage" ,{})} , 1000)
                            // setTimeout(()=>{props.navigation.replace("getPhoneNumber" ,{})} , 1000)
                     }
       }
       //------------------------------------------------------------------------------------
   return(
       <View style={{flex:1 , justifyContent:"center" , alignItems:"center"}}>
              <View style={Object.assign({}  , Styles.l4,Styles.m6 , Styles.s8)}>
              <img src={imgLogoHoriz}  style={{width:"100%" , marginBottom:20 }} />
              </View>
              <Waiting visible />
       </View>
   )
}

const mapStateToProps=state =>({
       profileState : state.profileReducer
})
const mapDispatchToProps= dispatch =>({
       setProfileInfo  :profileInfo => dispatch(setProfileInfo(profileInfo)) ,
       setInitialInfo : initialInfo => dispatch(setInitialInfo(initialInfo))
})
export default connect(mapStateToProps , mapDispatchToProps) (InitApp);
//=========================================================================================