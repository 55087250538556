//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
import {Section} from '../../globalComponents/components';
import { ContentContainer, MyButton, MyIcon, MyText  ,ImageModal  } from '../../globalComponents/components';
import { ScrollView } from 'react-native-web';
import Navbar from './component/navbar';
 
const PropertiesImageComponent= props =>{
       const {title , image , onClickImage} = props
       return <img src={image}  style={{ height:"100%" , width:"100%" , marginRight:10}} onClick={onClickImage}  />  
}

const PropertiesImageInLargeScreenComponent= props =>{
       const {title , image , onClickImage} = props
       return <div className=' w3-col l2 w3-padding-small  ' onClick={onClickImage} >
              <img src={image} style={{width:"100%"}} />
       </div>
}

const AdvertisingDescription=(props)=>{
//-----------------------------------GLOBAL VAR EREA
const arrGuidImage=[
       require("./../../assets/images/advertisingGuid/1.jpg") , 
       require("./../../assets/images/advertisingGuid/2.jpg") , 
       require("./../../assets/images/advertisingGuid/3.jpg") , 
       require("./../../assets/images/advertisingGuid/4.jpg") , 
]
//-----------------------------------REF EREA
const modalSlideImage=useRef()
//-----------------------------------STATE EREA
//-----------------------------------USE_EFFECT EREA
//-----------------------------------FUNCTION EREA
const onBackClick=()=> props.navigation.navigate("homePage")
const showModalImage = index =>modalSlideImage.current.show(arrGuidImage , index , "#FFFFFF")
//--------------------------------------------------



   return(
      <div className='w3-light-gray'>
       {/* <Navbar arrNavbarButton={[]}  showMenuButton={false}  hasBackButton onBackClick={onBackClick}  /> */}
              <Section contentStyle={{}}>
                     <div className='displayFlexGrow displayFlexRow ' style={{alignItems:"center" , flexWrap:"wrap"}}>
                            <div className='w3-col l7 m12 s12 w3-padding w3-right'>
                                   <h3 style={{fontWeight:"bold"}}>تجربه <span className='w3-text-blue'>رشد و رونق</span> کسب و کار در فضای آنلاین روستا</h3>
                                   <div>تبلیغات «دهوند» یک راه سریع و پربازده برای جذب مخاطب و فروش محصولات و خدمات شما است.</div>
                                   {/* <div className='displayFlexRow  w3-padding'>
                                          <div  className='w3-col l3 m6 s12'>
                                                 <MyButton title="سفارش تبلیغ" iconName="sell" buttonStyle={{padding:"10px 15px" , marginTop:20}}/>
                                          </div>
                                   </div> */}
                            </div>
                            <div className='w3-col l5 m12 s12 '>
                                   <img src={require("./../../assets/images/mainPageImage/advertising.png")} style={{width:"100%"}} />
                            </div>
                     </div>
              </Section>
              <Section containerClassName="w3-light-gray w3-margin-top " contentClassName=" w3-light-gray">
                     <div className='displayFlexGrow displayFlexRow' style={{alignItems:"center" , flexWrap:"wrap"}}>
                            <div className='w3-col l7 w3-padding'>
                                   <div className='w3-text-teal' style={{fontWeight:"bold"}}>نحوۀ عملکرد</div>
                                   <h4>تبلیغات « ده وند » چطور کار می‌کنه؟</h4>
                                   <MyText color="#456e7a"  style={{fontSize:15}}>{"وارد پنل تبلیغات  « دهوند »  شوید و برای تبلیغتان درخواست ثبت کنید."+"\n"+
                                                  "روز هایی را که می خواهید تبلیغتان نمایش داده شود را انتخاب نمایید."+"\n"+
                                                  "کار تمام است"+"\n"+
                                                   "تبلیغ شما در روز های انتخابی در تمامی گوشی های اهالی نمایش داده خواهد شد"+"\n"+
                                                   ""+"\n"}</MyText>
                            </div>
                            {/* <div className='w3-col l5' style={{padding:4}}>
                                   <div className='w3-round-xxlarge ' style={{backgroundColor:"#FFFFFF", borderRadius:"20px 20px 0 0" , padding:5 }}>
                                          <div className='w3-border w3-border-light-gray  w3-round-xxlarge' style={{borderRadius:"20px 20px 0 0 "}}>
                                                 <div className='displayFlexRow w3-border-bottom w3-padding'>
                                                        <div className='displayFlexGrow'>
                                                        <div>
                                                               <div className='w3-text-gray w3-small'>تابلو اعلانات روستا</div>
                                                               <div className='w3-tiny w3-margin-right' >روستای ....</div>
                                                        </div>
                                                        </div>
                                                        <div>
                                                               <MyIcon iconName="search" iconColor="#456e7a" style={{color:"#456e7a"}} iconSize={30}/>
                                                               <MyIcon iconName="more_vert" iconColor="#456e7a" style={{color:"#456e7a"}} iconSize={30}/>    
                                                        </div>
                                                 </div>
                                                 <div className='w3-padding'>
                                                        <div className='w3-padding w3-round-large displayFlexRow' style={{border:"2px dashed #e0e0e0"  , alignItems:"center"}}>
                                                               <img src={require("./../../assets/images/act/imgDef.png")} style={{width:90 , height:90}} />
                                                               <div className='displayFlexGrow w3-padding-small'>
                                                                      <MyText isBold >عنوان تبلیغ شما</MyText>
                                                                      <MyText smallFont>متن تبلیغ شما</MyText>
                                                               </div>
                                                        </div>
                                                 </div>
                                          </div>
                                   </div>
                            </div> */}
                            
                     </div>
                     {/* <div className='displayFlexRow  w3-padding'>
                            <div  className='w3-col l3 m6 s12'>
                                   <MyButton title="سفارش تبلیغ" iconName="sell" buttonStyle={{padding:"10px 15px" , marginTop:20}}/>
                            </div>
                     </div> */}
              </Section>
              <Section containerClassName="w3-light-gray  w3-margin-bottom w3-padding" contentClassName="" >
              <div className='w3-text-teal  w3-padding' style={{fontWeight:"bold"}}>چگونه در دهوند تبلیغات خود را ثبت نماییم</div>
                     <MyText color="#456e7a"  style={{fontSize:15 ,}}>{"برای ثبت تبلیغ لازم است ابتدا نرم افزار ده وند را در گوشی خود نصب داشته باشید."+"\n"+
                                                                                                      "سپس مطابق با تصاویر زیر , ثبت تبلیغ خود را انجام دهید."}</MyText>
                     <div className='w3-margin-top w3-margin-bottom w3-padding-small w3-hide-large ' >
                            <ScrollView horizontal={true} vertical={false}   style={{ height:400}} scrollEnabled >
                                   {
                                          arrGuidImage.map((image , index) =>    <PropertiesImageComponent key={index} image={image} onClickImage={()=>showModalImage(index)} />)
                                   }
                            </ScrollView>
                     </div>
                     <div className='w3-margin-top w3-margin-bottom w3-padding-small w3-hide-small w3-hide-medium  ' style={{}} >

                                   {
                                          arrGuidImage.map((image , index) =>    <PropertiesImageInLargeScreenComponent key={index} image={image} onClickImage={()=>showModalImage(index)} />)
                                   }
                            
                     </div>
              </Section>
              <ImageModal ref={modalSlideImage} />
      </div>
   )
}

export default AdvertisingDescription;
//=========================================================================================