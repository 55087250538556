//======================================================================================================================================
const SET_PROFILE = "SET_PROFILE"
const UPDATE_PROFILE="UPDATE_PROFILE"
const RESET_PROFILE="RESET_PROFILE"
//======================================================================================================================================
export const setProfileInfo=(profileInfo)=> ({type:SET_PROFILE , profileInfo})
export const resetProfileInfo=()=> ({type:RESET_PROFILE })
export const updateProfileInfo=(profileInfo)=> ({type:UPDATE_PROFILE , profileInfo})
//======================================================================================================================================
const profileInit={
       name:'',
       phone :'',
       sex : 1 ,
       avatar :"" ,
       jwt : "",
       objCity:{id:0 , name:''},
       token : "" ,
       aboutMe:'' ,
       canAddNews:false , 
       canAddBulletin:false
}

export default (state = profileInit , action)=>{
       switch(action.type){
              case SET_PROFILE : return action.profileInfo 
              case RESET_PROFILE : return profileInit 
              case UPDATE_PROFILE : return {...state , ...action.profileInfo}
              default : return state
       }
}