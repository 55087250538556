//========================================================================================
import React,{ useState, useImperativeHandle , forwardRef}  from 'react'
import {Linking, ScrollView , View} from "react-native"
// import {Pages} from "react-native-pages"
import { MyIcon, MyImage, MyText, ProfileImage  , ModalContainer, MyButton} from '../globalComponents/components'
import { useNavigation } from '@react-navigation/native'

//-----------------------------------------------------
const MessageComponent= props =>{
       const { id , title , message , icon  , image =null, objButton , onHide=()=>{} }=props

       const navigation=useNavigation()

       const onClick=()=>{
              const url=objButton.url
              if(url.indexOf("http://")===-1 && url.indexOf("https://")===-1){
                     if(url.indexOf("/")===-1)
                            navigation.navigate(url)
                     else{
                            const mainPage=url.substring(0 , url.indexOf("/"))
                            const pageIndex=url.substring(url.indexOf("/")+1)
                            navigation.navigate(mainPage , {pageIndex})
                     }
              }
              else
                     Linking.openURL(objButton.url)
              onHide() 
       }
       return(
              <View style={{flex:1  }}>
                     <ScrollView style={{ flex:1 }} contentContainerStyle={{flexDirection:"row" , paddingHorizontal:5 }}>
                            <View style={{paddingLeft:10}}>
                            {
                                   icon ? <View style={{padding:5 , borderRadius:40 , borderWidth:3 , borderColor:"#e0e0e0"  }}>
                                                 <MyIcon iconName={icon} style={{fontSize:40}} iconSize={40} color="#e57373" />
                                              </View> : null
                            }
                            {
                                   image ? <ProfileImage source={{uri:image}} width={50} style={{borderWidth:3 , borderColor:"#e0e0e0" , padding:2}} /> : null
                            }
                            {
                                   !image && !icon ? <ProfileImage source={require("./../assets/images/logo/logo.png")} width={50} 
                                                                                       style={{borderWidth:3 , borderColor:"#e0e0e0" , padding:2}} /> : null
                            }
                            </View>
                                   <View style={{flex:1 , marginTop:10 }}>
                                          <MyText isBold smallFont color="#29b6f6" style={{borderBottomWidth:2 , borderColor:"#ffcdd2" , paddingBottom:5}}>{title}</MyText>
                                          <MyText isBold smallFont  color="#757575" style={{marginTop:10}}>{message}</MyText>
                                   </View>
                     </ScrollView>
                     {
                            objButton.title ? 
                            <View style={{flexDirection:"row-reverse" , padding:10 }}>
                                   <MyButton onClick={onClick}
                                                    smallFont
                                                    title={objButton.title}
                                                    iconName={objButton.icon ? objButton.icon : ""}
                                                    buttonStyle={{paddingVertical:4 , paddingHorizontal:10}}
                                                    buttonIconStyle={{fontSize:23}}
                                                    buttonTExtStyle={{}} />
                            </View>:null
                     }
              </View>
       )
}
//-----------------------------------------------------
const MdlMessage=forwardRef((props , ref)=>{

const [visible , setVisible]=useState(false)
const [arrMessage , setArrMessage]=useState([])  //[{id , title , icon , message ,  objButton:{icon , title , url }  }]
//-----------------------------------USE_EFFECT EREA
useImperativeHandle(ref , ()=>({
       show : _arrMessage=>{
              setArrMessage(_arrMessage)
              setVisible(true)
       }
}))
//-----------------------------------FUNCTION EREA
const hide=()=>setVisible(false)
//--------------------------------------------------
   return(
                   <ModalContainer visible={visible}
                                              title=''
                                              message=''
                                              hasFlatList
                                              showTitle={false}
                                              showBackButton={true}
                                              closeWithClickBackButton
                                              onBackClick={hide}
                                              containerStyle={{padding:0 , }}
                                              contentStyle={{borderRadius:0 , height:"50%" , backgroundColor:"#FFF" }}
                                              position='bottom'
                                              animation='slideInUp' >
                            <View style={{ flex:1 }}>
                                   {/* <Pages  style={{}} >
                                          {
                                                 arrMessage.map(message => <MessageComponent key={message}   {...message} onHide={hide}  />)
                                          }
                                   </Pages> */}
                            </View>
                 </ModalContainer>
   )
})

export default MdlMessage;
//=========================================================================================