//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
import {View , TouchableOpacity, RefreshControl } from "react-native"
import {  Alert, Confirm , ImageModal, MyButton, MyImage, MyText, NoItem, ProfileImage, Waiting  , AnimatableView, ActionButton } from '../../../globalComponents/components'
import Fetch from '../../../globalComponents/fetch'
import { connect } from 'react-redux'
import {setToastMessage} from "../../../redux/toastMessage"
import ChapterPlaceholder from './chapterPlaceholder'
import { formatNumber } from '../../../globalComponents/rejax'
import  { setProfileInfo, updateProfileInfo } from '../../../redux/profile'
import uuid from 'react-uuid'
import { FlatList } from 'react-native'
import Styles from '../../../styles'

import {} from "@react-navigation/native"
import { useNavigation } from '@react-navigation/native';
import { updateMainAdvertisingInfo } from '../../../redux/mainAdvertising'
import { addChapter, addUpdateChapter, deleteChapter, refreshChapter } from '../../../redux/chapter';
import ExtendedActionButton from '../../../commonComponents/extendedActionButton'
 //----------------------------------------------------------------------------------------
 const ChapterComponent=props =>{
       const {id , chapterAvatar ,writerAvatar , writerName , writerAboutMe , title , onDeleteChapter , onEditChapter  ,
                   chapterTypeName ,onClick , saveDate , likeCount  , commentCount , isMyChapter , showImageInModal , canAddBulletin , canAddNews  } =props

       const [imageWidth , setImageWidth]=useState(20)
       const [showMenu , setShowMenu]=useState(false)



       const onLayout = e => {
              const {x, y, height, width} = e.nativeEvent.layout;
              setImageWidth(width)
       }
       const editChapter=()=>{
              setShowMenu(false)
              onEditChapter(id)
       }
       const deleteChapter=()=>{
              setShowMenu(false)
              onDeleteChapter(id)
       }

       const defImage= chapterTypeName==="BULLETIN" ? require("./../../../assets/images/logo/logo.png") :  require("./../../../assets/images/act/imgDefImage.png")
       const avatar = chapterTypeName==="BULLETIN" ? require("./../../../assets/images/logo/logo.png")
                                                                                 : writerAvatar ? {uri : writerAvatar} : require("./../../../assets/images/act/imgDefManAvatar.jpg")
       const aboutMe = chapterTypeName==="BULLETIN" ? "نگارنده : "+writerName 
                                                                                  : writerAboutMe ? writerAboutMe : "-----------"

       return (
              <View className='' style={{borderBottomWidth:1 , borderColor:"#eeeeee" , padding:5}} >
                     {
                                   showMenu? 
                                   <View style={{position:"absolute" , top:0 , right:0 , bottom:0 , left:0  , zIndex:2}}>
                                          <AnimatableView animation="zoomIn" duration={400}  
                                                                      style={{position:"absolute"  , top:15 , left:50  , backgroundColor:"#FFFFFF" , width:"50%"   , borderRadius:10 , 
                                                                                    zIndex:2 , overflow:"hidden"  , borderColor:"#E0e0e0" ,elevation:10  }}>
                                                 <View style={{flexDirection:"row-reverse"}}>
                                                        <MyButton onClick={()=>setShowMenu(false)} smallFont
                                                                      title='' 
                                                                      iconName='cancel' 
                                                                      buttonStyle={{backgroundColor:"#FFF"  , justifyContent:"flex-end" , borderRadius:0 ,}}
                                                                      buttonIconStyle={{color:"#e57373" , fontSize:25}}
                                                                      buttonTextStyle={{color:"#e57373"}} />
                                                 </View> 

                                                 <View style={{padding:5}}>
                                                        <MyButton onClick={editChapter} smallFont
                                                                      title='بروزرسانی سند' 
                                                                      iconName='refresh' 
                                                                      buttonStyle={{backgroundColor:"#eceff1"  , borderBottomWidth:1 , borderColor:"#e0e0e0" , justifyContent:"flex-end" , borderRadius:0 ,}}
                                                                      buttonIconStyle={{color:"#29b6f6"}}
                                                                      buttonTextStyle={{color:"#456e7a"}} />
                                                        <MyButton onClick={deleteChapter} smallFont
                                                                      title='حذف سند' 
                                                                      iconName='close' 
                                                                      buttonStyle={{backgroundColor:"#eceff1" , justifyContent:"flex-end", borderRadius:0}}
                                                                      buttonIconStyle={{color:"#e57373"}}
                                                                      buttonTextStyle={{color:"#456e7a"}} /> 
                                                 </View> 
                                          </AnimatableView>
                                   </View> : null
                     } 
                     <View style={{flexDirection:"row"}} >
                            <View style={{flex:1}}>
                                   <TouchableOpacity  onPress={onClick}>
                                          <View style={{flexDirection:"row" , opacity:showMenu ?0.2 : 1 }}>
                                                 <View onLayout={onLayout}     style={Object.assign({} , Styles.l1 , Styles.m2 , Styles.s3)} >
                                                        <MyImage  source={chapterAvatar ? {uri : chapterAvatar} : defImage } resizeMode={"cover"}  
                                                                             style={{width:"100%" , height:imageWidth, borderRadius:10 }} />
                                                 </View>
                                                 <View  style={{padding:5 , paddingLeft:0  , justifyContent:"space-between" , flex:1  }}>
                                                        {/*  */}
                                                        <View  style={{ flexDirection:"row-reverse" ,zIndex:10 ,  alignItems:"center" ,marginBottom:5 , paddingBottom:5 }}>
                                                               
                                                               <View style={{flex:1 , flexDirection:"row-reverse" , opacity:showMenu ? 0.2: 1 , paddingLeft:6  }}>
                                                                      <ProfileImage  style={{  elevation:3 }}
                                                                                                  source={avatar}   
                                                                                                  width={40} />
                                                                      <View className='displayFlexGrow' style={{paddingRight:10 , flex:1}}>
                                                                             <MyText isBold tinyFont color="#26a69a" >{chapterTypeName==="BULLETIN" ? "اطلاعیه عمومی از دهیاری روستا" : writerName}</MyText>
                                                                             <MyText tinyFont  color="#a9a9a9">{aboutMe}</MyText>
                                                                      </View>
                                                               </View>
                                                        </View>
                                                        <MyText  smallFont  color="#456e7a"   style={{zIndex:1 , paddingLeft:10 , paddingRight:10}} >{title}</MyText>
                                                        <View className='displayFlexRow' style={{ alignItems:"center" , flexDirection:"row" , paddingLeft:10}}>
                                                               <MyText  tinyFont color="#9e9e9e"  style={{flex:1 }} >{saveDate}</MyText>
                                                               <View className='displayFlexRowReverse' style={{flexDirection:"row-reverse"}}>
                                                               <MyButton iconName="chat" title={commentCount ? formatNumber(commentCount) : "0"} tinyFont isBold
                                                                             buttonStyle={{backgroundColor:"transparent" , padding:4}} 
                                                                             buttonIconStyle={{fontSize:15 , color:"#d0d0d0"}}
                                                                             buttonTextStyle={{color:"#d0d0d0"}} />
                                                               </View>
                                                        </View>
                                                 </View>
                                          </View>
                                   </TouchableOpacity>
                            </View>
                            {
                                   isMyChapter && ((chapterTypeName==="BULLETIN" && canAddBulletin) || 
                                                               (chapterTypeName==="NEWS" && canAddNews) ) ? 
                                          <View>
                                                        <View  style={{   alignItems : "center"   }}>
                                                                                                  <MyButton    title='' 
                                                                                                                iconName='more_vert'  
                                                                                                                onClick={()=>setShowMenu(!showMenu)}
                                                                                                                buttonStyle={{backgroundColor:"transparent" , paddingHorizontal:0 , paddingVertical:0}}
                                                                                                                buttonIconStyle={{color:"#757575"}}
                                                                                                                buttonTExtStyle={{}} /> 
                                                        </View>
                                          </View> : null
                            }
                            
                     </View>
              </View>     
       )
 }
 //----------------------------------------------------------------------------------------
const Chapter=(props)=>{
       const { chapterState   , addChapter  , deleteChapter, refreshChapter  , mustUpdateInfo=false , addUpdateChapter } = props
       const navigation = useNavigation();
       //-----------------------------------------------------------------------------------------------------
       const [ fetchState , setFetchState ]=useState({ fetchFlag:!chapterState.arrChapter.length && !chapterState.finished ? true : false , 
                                                                                      errorFlag:false })
       const [pageWasLoaded , setPageWasLoaded]=useState(false)

       useEffect(()=>{
              setTimeout(()=>setPageWasLoaded(true) , 100)
       } , [])
       //------------------------------------------------------------------------------------------------------------------
       useEffect(()=>{
              if(pageWasLoaded){
                     if(!chapterState.arrChapter.length && !chapterState.finished )
                            getChapterList()
              }           
       } , [pageWasLoaded ])

       useEffect(()=>{
              if(pageWasLoaded)
                     getChapterList()
       } , [chapterState.strSearch])

       useEffect(()=>{
              if(pageWasLoaded && chapterState.refresh)
                     getChapterList()
       } , [chapterState.refresh])
       useEffect(()=>{
              if(mustUpdateInfo)
                     props.refreshChapter()
       } , [mustUpdateInfo])
       //-----------------------------------------------------------------------------------------------------------------
       const alertModal=useRef()
       const fetchModal=useRef()
       const confirmModal=useRef()
       const imageModal=useRef()
       //-----------------------------------------------------------------------------------------------------------------
       const getChapterList=async ()=>{
              if(!pageWasLoaded)
                     return
              const objData={
                     jwt : props.profileState.jwt , 
                     chapterTypeName:"CHAPTER" , 
                     strSearch : chapterState.strSearch , 
                     lastChapterId : chapterState.arrChapter.length ?  chapterState.arrChapter.sort((a , b) => a.id - b.id)[chapterState.arrChapter.length-1].id : 0 
              }
              await fetchModal.current.fetchData("user/getChapterList" , 
                                                                objData , 
                                                                {
                                                                      onStart :  ()=>setFetchState({fetchFlag:true , errorFlag:false}),
                                                                      onError :  ()=>setFetchState({fetchFlag:false , errorFlag:true}),
                                                                      onBackFromConfirmError:()=>setFetchState({fetchFlag:false , errorFlag:false}),
                                                                      onSuccess: responseOfGetChapter
                                                                } , chapterState.arrChapter.length  ? true : false , 
                                                                     chapterState.arrChapter.length  ? true : false , true)
       }
       const responseOfGetChapter=async data => {
              setFetchState({fetchFlag:false , errorFlag:false})
              addChapter(data)
       }
       //----------------------------------------------------------------------------------------
       const initDataToShow=()=>{
              // if(strSearch){
              //        if(isNaN(strSearch))
              //               return chapterState.arrChapter.filter(chapter=>chapter.title.indexOf( strSearch.trim().replace(/ +/g  , " ") )!==-1 )
              //        else
              //               return chapterState.arrChapter.filter(chapter=>chapter.id===parseInt(strSearch) )
              // }      
              // else
                     return chapterState.arrChapter
       }
       //---------------------------------------------------------------------------------------
       const onDeleteChapter= chapterId =>  confirmModal.current.show("حذف سند" , "آیا از حذف این سند اطمینان دارید؟" , "question" , ()=>startToDeleteChapter(chapterId) , "تایید و ادامه")
       const startToDeleteChapter=chapterId  => {
              const objData={
                     jwt : props.profileState.jwt , 
                     chapterId , 
              }
              fetchModal.current.fetchData("user/deleteChapter" , objData , 
                                                                {
                                                                      onSuccess: responseOfDeleteChapter
                                                                } , true , true , true )
       }
       const responseOfDeleteChapter= data => {
              deleteChapter(data)
              props.setToastMessage("حذف سند با موفقیت انجام شد.")
       }
       const onEditChapter= chapterId => {
              const objData={
                     jwt : props.profileState.jwt ,
                     chapterId , 
              }
              fetchModal.current.fetchData("user/getChapterInfo" , objData , 
                                                                {
                                                                      onSuccess : responseOfEditChapter
                                                                } , true , true , true)
       }
       const responseOfEditChapter= data => {
              const chapterInfo={
                     id:data.id,
                     chapterTypeName:data.chapterTypeName , 
                     title:data.title , 
                     matn : data.matn , 
                     arrImageFile : [ ...data.arrImageFile ] , 
                     question:data.question ,
                     arrAnswer : [...data.arrAnswer , {id:uuid() , answer:''} ]
              }
              onAddChapterClick(chapterInfo)
       }
       //---------------------------------------------------------------------------------------
       const loadingComponent=()=>{
              if(fetchState.fetchFlag && !fetchState.errorFlag){
                     if(!chapterState.arrChapter.length )
                            return <View style={{padding:5}}>
                                                 <ChapterPlaceholder  />
                                                 <ChapterPlaceholder  />
                                                 <ChapterPlaceholder  />
                                                 <ChapterPlaceholder  />
                                          </View>
                     else
                            return <Waiting  visible={fetchState.fetchFlag} 
                                                                      flexDirection="column" 
                                                                      backgroundColor="#ffffff" 
                                                                      marginTop={30}
                                                                      color="#546e7a" />
              }
       }
       const errorEmptyComponent=()=>{
              if(!fetchState.fetchFlag){
                     if( fetchState.errorFlag && !chapterState.arrChapter.length && !chapterState.finished )
                            return <View  style={{flex:1 , flexDirection:"row-reverse" , alignItems:"center" , justifyContent:"center"}} >
                                                 <NoItem visible={true} title="خطا در دریافت اطلاعات" 
                                                               message={"در دریافت اطلاعات خطایی روی داده است!!!!"+"\n"+
                                                                             "این مشکل ممکن است در نتیجه قطعی و یا کندی شبکه اینترنت شما باشد"}
                                                               showTryAgainButton={true} tryAgain={getChapterList} />
                                          </View> 
                     if(!fetchState.fetchFlag && !fetchState.errorFlag && !chapterState.arrChapter.length && chapterState.finished)
                            return <View style={{flex:1 , flexDirection:"row-reverse" , alignItems:"center" , justifyContent:"center"}}>
                                                 <NoItem visible={true} title="گزارش برنامه" 
                                                        icon={require("./../../../assets/images/logo/logo.png")}
                                                               message={chapterState.strSearch ? "جستجوی نتیجه ای نداشت!!!" : "هیچ سندی جهت نمایش بافت نشد!!!!"} />
                                          </View>
                     if(!fetchState.fetchFlag && pageWasLoaded)
                            return <NoItem visible={true} title="گزارش برنامه" contentStyle={{boxShadow:"0 0 0 0 #FFFFFF"}}
                                                        icon={require("./../../../assets/images/logo/logo.png")}
                                                        message={ "جستجوی نتیجه ای نداشت!!!" }  />
              }
              else 
                     return null
       }
       const showImageInModal=(imageUrl)=> imageModal.current.show([{url : imageUrl}] , 0)
       //---------------------------------------------------------------------------------------
       const onAddChapterClick=(selectedChapterInfo)=> navigation.navigate("addUpdateChapter" , {   chapterTypeName:"" , 
                                                                                                                                                                               selectedChapterInfo , 
                                                                                                                                                                        //    onAddUpdateChapter :  props.addUpdateChapter  
                                                                                                                                                                        } )
       //---------------------------------------------------------------------------------------
       return(
              <View style={{flex:1}}>
                                   <FlatList     data={ initDataToShow()} 
                                                        style={{flex:1 }} contentContainerStyle={{paddingBottom:100 , paddingTop:10}}
                                                        keyExtractor={(item , index)=>index.toString()}
                                                        refreshing={chapterState.refresh  }
                                                        onEndReachedThreshold={0.15}
                                                        onEndReached={ !chapterState.finished &&  chapterState.arrChapter.length &&   pageWasLoaded   ? getChapterList :  ()=>{}   }
                                                        ListFooterComponent={loadingComponent()}
                                                        ListEmptyComponent={errorEmptyComponent()}     
                                                        refreshControl={ <RefreshControl refreshing={chapterState.refresh}     
                                                        onRefresh={refreshChapter} />}             
                                                        renderItem={({item})=><ChapterComponent      {...item} 
                                                                                                                                     canAddBulletin={props.profileState.canAddBulletin}
                                                                                                                                     canAddNews={props.profileState.canAddNews}
                                                                                                                                     onClick={()=>navigation.navigate("chapterInfo" , { selectedChapter : {...item}  })}
                                                                                                                                     onDeleteChapter={onDeleteChapter} 
                                                                                                                                     showImageInModal={showImageInModal}
                                                                                                                                     onEditChapter={onEditChapter}  />}    />
                                   <ExtendedActionButton iconName='add_comment'  title={"ثبت گفتگوی جدید"} onClick={()=>onAddChapterClick({id:0,
                                                                                                                                                                                                                  chapterTypeName:'' , 
                                                                                                                                                                                                                  title:"" , 
                                                                                                                                                                                                                  matn : "" , 
                                                                                                                                                                                                                  arrImageFile : [  ] , 
                                                                                                                                                                                                                  question:"" ,
                                                                                                                                                                                                                  arrAnswer : [ {id:uuid() , answer:''} ]
                                                                                                                                                                                                           })}  />

                                   <ImageModal ref={imageModal} />
                                   <Fetch ref={fetchModal} />
                                   <Alert ref={alertModal} />
                                   <Confirm ref={confirmModal} /> 
              </View>
       )
}
const mapStateToProps= state => ({
       profileState : state.profileReducer ,  
       initialInfoState : state.initialInfoReducer ,
       scrollTopPagePositionState: state.scrollTopPagePositionReducer ,
       chapterState : state.chapterReducer
})
const mapDispatchToProps= dispatch =>({
       setToastMessage : strMessage => dispatch(setToastMessage(strMessage)) ,
       setProfileInfo : profileInfo => dispatch(setProfileInfo(profileInfo)),
       updateProfileInfo : profileInfo => dispatch(updateProfileInfo(profileInfo)) , 
       updateMainAdvertisingInfo : advertisingInfo => dispatch(updateMainAdvertisingInfo(advertisingInfo)),

       addChapter : chapterInfo => dispatch(addChapter(chapterInfo)) ,
       addUpdateChapter : chapterInfo => dispatch(addUpdateChapter(chapterInfo)) , 
       deleteChapter : chapterId => dispatch(deleteChapter(chapterId)) , 
       refreshChapter : () =>dispatch(refreshChapter()) ,
})
export default connect(mapStateToProps , mapDispatchToProps)(Chapter);
//===========================================================================================
