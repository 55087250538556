
import React from "react"

const Divider =props =>{
       const {title , containerStyle={} , containerClassName="" , contentClassName="" , contentStyle={} , titleStyle={}}=props
       return(
          <div className={`${containerClassName}`} style={containerStyle}>
             <div className='w3-center w3-tiny displayFlexRow ' style={{alignItems:"center"}}>
                      <div className='displayFlexGrow w3-light-gray ' style={{padding:0.2,  }} />
                      <div className=' w3-round-xxlarge w3-light-gray  ' style={Object.assign({}, {padding:"3px 10px" } ,titleStyle   )}>{title}</div>
                      <div className='displayFlexGrow w3-light-gray ' style={{padding:0.2 ,}} />
             </div>
             <div className={`${contentClassName}`} style={contentStyle}>
                {
                   props.children
                }
             </div>
          </div>
       )
    }

    export default Divider