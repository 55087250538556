//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
import { MyButton, MyIcon, MyText , MyImage } from '../globalComponents/components'
import { TouchableOpacity } from 'react-native-web'
 
const ImageFileComponent=props =>{
       const {onDeleteImage , id , filename , onClick=()=>{} } = props
       const imageRef=useRef()
       const [imageWidth , setImageWidth]=useState(0)
       useEffect(()=>{
         if(imageRef.current)
               setImageWidth( imageRef.current.offsetWidth)
       } , [imageRef.current ])

       return(
          <div className='w3-col l2 m3 s4 ' ref={imageRef} style={{height:imageWidth-10 , padding:6 , marginBottom:5 , width:"30%"}}>
             {
                filename ? 
                     <div className='w3-ripple w3-button  w3-round-xlarge  displayFlexRow displayFlexCenter w3-card' 
                           style={{width:"100%" , height:"100%" , position:"relative" , padding:0 }}   >
                                              <TouchableOpacity  onLongPress={onDeleteImage} style={{height:imageWidth , width:imageWidth}} >
                                                    <MyImage source={typeof(id)==="string" ? {uri:URL.createObjectURL(filename)} : {uri:filename}} style={{width:"100%" , height:"100%"}} />
                                              </TouchableOpacity>
                       
                  </div>
                :
                <button className='w3-button w3-ripple w3-card w3-round-xlarge ' style={{padding:0, width:"100%" , height:"100%"}}>
                        <div className='   displayFlexCol displayFlexCenter w3-card ' 
                              style={{width:"100%" , height:"100%" , backgroundColor:"#f4f4f4" , padding:0}} onClick={onClick}  >
                           <MyIcon iconName="add" iconSize={50} color="#d0d0d0" />
                           <MyText isBlock smallFont color="gray">انتخاب تصویر</MyText>
                        </div>
                </button>
             }
            
          </div>
       )
    }

export default ImageFileComponent ;
//=========================================================================================