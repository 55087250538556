//========================================================================================
import React,{useEffect, useState }  from 'react'
import { View , TouchableOpacity } from 'react-native'
import {  AnimatableView, MyButton, MyImage, MyText, ProfileImage } from '../globalComponents/components'
 
const FooterMenuComponent=(props)=>{
       const {id , icon ="", image="" , title  , onClick  , badge  ,activeItemColor , itemColor , disabled=false , isSelectedWithIndex  , currentIndex  }=props
       return(
           <View style={{flex:1 }}>
                <TouchableOpacity onPress={onClick}  disabled={disabled} >
                        <View  style={{paddingTop:5   }}   >
                                {
                                    badge ? 
                                    <View style={{flexDirection:"row" , alignItems:"center" , justifyContent:"center" , position:"absolute" , left:0 , right: 0, top:5 , zIndex:4}} >
                                            <View style={{ backgroundColor:"#ff7043" , width:12 , height:12 , borderRadius:60 , elevation:4 , marginLeft:20 , borderColor:"#e0e0e0" }} />
                                    </View>
                                    : null
                                }
                                <View>
                                    {
                                        icon ? 
                                        <MyButton iconName={icon} title={title}  disabled opacityOnDisabledOff={!disabled}
                                                    tinyFont
                                                    buttonStyle={{flexDirection:"column-reverse" ,  backgroundColor:"transparent" }}
                                                    buttonIconStyle={{color:isSelectedWithIndex===currentIndex ? activeItemColor : itemColor , marginLeft:0 , fontSize:isSelectedWithIndex===currentIndex ? 26 : 23 }}
                                                    buttonTextStyle={{color:isSelectedWithIndex===currentIndex ? activeItemColor : itemColor  }} /> 
                                        : null
                                    }
                                    {
                                        image ?
                                        <View style={{flexDirection:"column" , justifyContent:"center" ,alignItems:"center"}}>
                                                <ProfileImage source={image } width={isSelectedWithIndex===currentIndex ? 33 : 30} style={{ elevation:1  }} />
                                                <MyText tinyFont isBold color={itemColor} >حساب من</MyText>
                                        </View>
                                        :null
                                    }
                                </View>
                        </View>
            </TouchableOpacity>
           </View>
       )
}
const FooterNavbar=(props)=>{
    const {arrFooterMenu , activeItemId=0 , activeItemColor="#00ACC1" , itemColor="#9a9a9a"  , onAddButtonClick=()=>{}  , hasAddButton=false , currentIndex=0 }=props
    //--------------------------------------------------------------------------
    const initArrButtonToShow=()=>{
        let arrFirstButton=[] 
        let arrSecondButton=[]

        if(hasAddButton){
            arrFirstButton=arrFooterMenu.filter((footerMenu , index)=>index< arrFooterMenu.length/2)
            arrSecondButton=arrFooterMenu.filter((footerMenu , index)=>index>= arrFooterMenu.length/2)
            return [[...arrFirstButton] , [...arrSecondButton]]
        }
        else
            return [[...arrFooterMenu]  , []]
    }

    //--------------------------------------------------------------------------
       return(
        <View style={{ backgroundColor:"#FFF"   , borderTopWidth:1 , borderColor:"#EEE",flexDirection:"row"    , 
                                 zIndex:3   , marginBottom:1  , alignItems:"flex-end" , elevation:10 , marginBottom:-1   }}>
                    {/* <AnimatableView animation={ !animationEnabled ?  ""
                                                                                              : showCategory ? fadeIn : fadeOut}   duration={300} 
                                                 style={{flexDirection:"row-reverse"    , zIndex:3   , marginBottom:1 ,borderRadius:25 , alignItems:"flex-end"}}> */}
                    {
                            initArrButtonToShow()[0].map(footerMenu  => <FooterMenuComponent key={footerMenu.id} 
                                                                                                                    { ...footerMenu } 
                                                                                                                    currentIndex={currentIndex}
                                                                                                                    activeItemId={activeItemId} 
                                                                                                                    itemColor={ footerMenu.color ? footerMenu.color :  itemColor} 
                                                                                                                    activeItemColor={footerMenu.activeColor ? footerMenu.activeColor  : activeItemColor} />)
                    }
                    {
                            hasAddButton ?
                                        <View style={{ overflow:"visible" , paddingBottom:20  , flexDirection:"row" , alignItems:"flex-end" , justifyContent:"center" }}>
                                        <View style={{borderRadius:80 , padding:5  , backgroundColor:"#FFF" ,  borderWidth:1 ,borderColor:"#E0E0E0"   }}>
                                            <MyButton onClick={onAddButtonClick}
                                                            title='' 
                                                            iconName='add' 
                                                            buttonStyle={{borderRadius:70 , width:55 , height:55  , backgroundColor:"#ef9a9a" , elevation:1 , alignItems:"center" , justifyContent:"center"}}
                                                            buttonIconStyle={{fontSize:50 , marginLeft:-2  }}  />
                                        </View>
                                    </View> : null
                    }
                    {
                            initArrButtonToShow()[1].map(footerMenu  => <FooterMenuComponent key={footerMenu.id} 
                                                                                                                                        { ...footerMenu } 
                                                                                                                                        currentIndex={currentIndex}
                                                                                                                                        activeItemId={activeItemId} 
                                                                                                                                        itemColor={ footerMenu.color ? footerMenu.color :  itemColor} 
                                                                                                                                        activeItemColor={footerMenu.activeColor ? footerMenu.activeColor  : activeItemColor} />)
                    }
                {/* </AnimatableView>       */}
        </View>
       )
}

export default FooterNavbar;
//=========================================================================================