import React from "react"
import { View} from "react-native"
import { MyButton } from "../globalComponents/components"

const TabComponent =props =>{
       const {arrTabs}=props  //[{id , title , selected , onClick}]
       return(
              <View  style={{  flexDirection:"row-reverse" }}>
              {
                     arrTabs.map( (tab , index) => <View key={index} style={{flex:1 , elevation : 1 , borderLeftColor:"#eeeeee"}}>
                                                                                    <MyButton key={index} isBold={false}
                                                                                                    title={tab.title}  
                                                                                                    disabled={tab.disabled}
                                                                                                    buttonStyle={{flexDirection:"row"  , paddingHorizontal:4 , paddingVertical:8 , justifyContent:"center" ,  alignItems:"center" , 
                                                                                                                         backgroundColor:  tab.selected ? "#00ACC1"  :"#eeeeee" , borderRadius:0}}
                                                                                                    buttonTextStyle={{color:tab.selected ?"#FFFFFF" : "#456e7a"}}
                                                                                                    onClick={!tab.disabled ? tab.selected ? null : tab.onClick 
                                                                                                                                           : null } />
                                                                       </View>)
              }
              </View>
       )
}
export default TabComponent