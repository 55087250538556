//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
import { MyButton, MyIcon, MyText } from '../../../../globalComponents/components';
 
const Footer=(props)=>{
//-----------------------------------GLOBAL VAR EREA
//-----------------------------------REF EREA
//-----------------------------------STATE EREA
//-----------------------------------USE_EFFECT EREA
//-----------------------------------FUNCTION EREA
//--------------------------------------------------
   return(
       <div className='w3-padding w3-teal displayFlexRow' style={{}}>
            <div className='w3-col l3 '>
                <MyText isBold smallFont color="#FFFFFF">ارتباط با ما</MyText>
                <div className='w3-padding' style={{}}>
                    <div className='displayFlexRow w3-padding'>
                        <MyIcon iconName="phone" />
                        <MyText color="#FFFFFF" style={{paddingRight:15}}>تلفن : 09389690438</MyText>
                    </div>
                    <div className='displayFlexRow w3-padding'>
                        <MyIcon iconName="mail" />
                        <MyText color="#FFFFFF" style={{paddingRight:15}}>ایمیل : dehvand.tpel@gmail.com</MyText>
                    </div>
                    <div className='displayFlexRow w3-padding'>
                        <MyIcon iconName="home" />
                        <MyText color="#FFFFFF" style={{paddingRight:15}}>آدرس : فارس - لارستان - کورده مرکزی - خیابان امام خمینی - پلاک 23</MyText>
                    </div>
                </div>
            </div>
       </div>
   )
}

export default Footer;
//=========================================================================================