//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
import { ScrollView, View} from "react-native"
import {PageContainer , MyIcon , MyButton , MyText , MyImage} from "../../../globalComponents/components"
import { connect } from 'react-redux'
import Section from '../../../commonComponents/section'
import Fetch from "../../../globalComponents/fetch"
// import {useBackHandler} from "@react-native-community/hooks"


const AdvertisingDescription=(props)=>{
//-----------------------------------GLOBAL VAR EREA

//-----------------------------------REF EREA
const fetchModal=useRef()
//-----------------------------------STATE EREA
//-----------------------------------USE_EFFECT EREA
useEffect(()=>{
       if(!props.profileState)
              onBackClick()
} , [])
//-----------------------------------FUNCTION EREA
// useBackHandler(()=>{
//        onBackClick()
//        return true
// })
const onBackClick=()=>props.navigation.goBack()

const getCityAdvertisingDayInfo=()=>{
       const objData={
              jwt:props.profileState.jwt
       }
       fetchModal.current.fetchData("user/getCityAdvertisingDayInfo" , objData ,
                                                         {
                                                               onSuccess:responseOfGetCityAdvertisingDayInfo
                                                         } , true , true , true )
}
const responseOfGetCityAdvertisingDayInfo= data => props.navigation.replace("addAdvertising" , { 
                                                                                                                                                                 cityAdvertisingDayInfo: data.cityAdvertisingDayInfo , 
                                                                                                                                                                 dayAdvertisingPrice:data.dayAdvertisingPrice 
                                                                                                                                                                 })
//----------------------------------------------------------------------------
const [imageWidth , setImageWidth]=useState(0)
const onLayout= e => setImageWidth(e.nativeEvent.layout.width)
//----------------------------------------------------------------------------
   return(
       <PageContainer   title={"ثبت تبلیغ در  روستا"} 
                                         subTitle={" روستای "+props.profileState.objCity.name}
                                          containerPadding={0}
                                        showBackIcon
                                        onBackIconClick={onBackClick} hasFlatList >
              <ScrollView className='displayFlexGrow' style={{flex:1}}>
                     <Section contentStyle={{}}>
                            <View className='displayFlexGrow displayFlexRow' style={Object.assign({} , {alignItems:"center" , flexWrap:"wrap" } , )}>
                                   <View className='w3-col l7 m12 s12 w3-padding w3-right' style={{paddingHorizontal:16 , paddingVertical:8}}>
                                          <View style={{ fontSize:20 , flexDirection:"row"}}>
                                                        <MyText isBold bigFont>تجربه </MyText>
                                                        <MyText isBold bigFont className='w3-text-blue' color="blue">رشد و رونق</MyText> 
                                                        <MyText isBold bigFont>کسب و کار در فضای آنلاین روستا</MyText>
                                          </View>
                                          <MyText>تبلیغات «دهوند» یک راه سریع و پربازده برای جذب مخاطب و فروش محصولات و خدمات شما است.</MyText>
                                          
                                   </View>
                                   <View onLayout={onLayout} >
                                          <MyImage source={require("../../../assets/images/act/advertising.png")} resizeMode={"contain"} 
                                                        style={{width:imageWidth , height:260 , borderWidth:1}} />
                                   </View>
                            </View>
                     </Section>
                     <Section containerClassName="w3-light-gray  w3-margin-bottom " contentClassName=" w3-light-gray" containerStyle={{backgroundColor:"#eeeeee" , marginBottom:16 , }}>
                            <View className='displayFlexGrow displayFlexRow' style={{alignItems:"center" , flexWrap:"wrap" , flexDirection:"row-reverse"}}>
                                   <View className='w3-col l7 w3-padding' style={{padding:16}}>
                                          <MyText isBold className='w3-text-blue ' color="#03a9f4" style={{ fontSize:18}}>نحوۀ عملکرد</MyText>
                                          <MyText isBold style={{}}>تبلیغات « ده وند » چطور کار می‌کنه؟</MyText>
                                          <MyText>می‌توانید وارد پنل تبلیغات « ده وند » شوید و برای تبلیغتان درخواست ثبت کنید. پس از تایید درخواست توسط ادمین، تبلیغ مورد نظر در زمان انتخاب‌ شده و در تمامی گوشیهای اهالی روستای شما نمایش داده می‌شود.</MyText>
                                   </View>
                            </View>
                     </Section>
              </ScrollView>
              <View className='displayFlexRow  w3-padding' style={{padding:16}}>
                            <MyButton title="ادامه و ورود به پنل تبلیغات" iconName="sell" buttonStyle={{padding:16, marginTop:20 , backgroundColor:"#00bcd4"}} onClick={getCityAdvertisingDayInfo}/>
              </View>
              <Fetch ref={fetchModal} />
       </PageContainer>
   )
}

const mapStateToProps= state =>({
       profileState:state.profileReducer
})

export default connect(mapStateToProps)(AdvertisingDescription);
//=========================================================================================