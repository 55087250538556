//========================================================================================
import React,{ useState}  from 'react'
import { MyButton, MyImage } from '../../../../globalComponents/components';
import MdlMenu from './mdlMenu';


//--------------------------------
const NavbarButton= props =>{
        const {id , title , icon , onClick}  =props
       return(
              <div>
                     <MyButton title={title} iconName={icon} 
                                      buttonStyle={{backgroundColor:"transparent" , padding:"5px 25px"}}
                                      buttonIconStyle={{}}
                                      buttonTextStyle={{color:"#757575"}} />
              </div>
       )
}
 
const Navbar=(props)=>{
//-----------------------------------GLOBAL VAR EREA
const {arrNavbarButton , gotoAdvertising }=props
const {showMenuButton=false , hasBackButton=false , onBackClick=()=>{}}=props
//-----------------------------------REF EREA
//-----------------------------------STATE EREA
const [showMenu , setShowMenu]=useState(false)
//-----------------------------------USE_EFFECT EREA
//-----------------------------------FUNCTION EREA
//--------------------------------------------------
   return(
       <div>
              {/* نمایش در صفحه بزرگ */}
              <div className=' displayFlexCenter displayFlexCol w3-card w3-padding w3-hide-small w3-hide-medium' style={{zIndex:2  , marginBottom:7}}>
                     <div className='w3-col l9 displayFlexRow  ' style={{}}>
                            <div>
                                   <MyImage source={require("./../../../../assets/images/logo/logoNameHoriz.png")} style={{width:250 , height:50}} resizeMode="contain" />
                            </div>
                            <div className='displayFlexGrow displayFlexRow displayFlexCenter'>
                                   {/* {
                                          arrNavbarButton.map((navbutton , index) =><NavbarButton key={index} {...navbutton} /> )
                                   } */}
                            </div>
                            {/* {
                                   showMenuButton ? 
                                   <div>
                                                 <MyButton title="سفارش تبلیغات" onClick={gotoAdvertising}
                                                               buttonStyle={{backgroundColor:"transparent" , border:"1px solid #26a69a" , borderRadius:10 , padding:"8px 25px" }}
                                                               buttonTextStyle={{color:"#26a69a"}} />
                                   </div> : null

                            } */}
                          
                     </div >
              </div>
               {/* نمایش در صفحه کوچیک */}
              <div className='w3-hide-large'>
                     <div className=' displayFlexRow w3-card   w3-white' style={{alignItems:"center" , marginBottom:8 , padding:5 }}>

                                   <div className='displayFlexGrow'>
                                          <img src={require("./../../../../assets/images/logo/logoNameHoriz.png")} style={{height:45 , width:"auto"}} />
                                   </div>

                     
{/*                             
                            {
                                   showMenuButton ?  
                                   <div>
                                                 <MyButton title="" iconName="menu" onClick={()=>setShowMenu(!showMenu)}
                                                               buttonStyle={{backgroundColor:"transparent" , border:"1px solid #26a69a" , borderRadius:10 , padding:"8px 8px" }}
                                                               buttonIconStyle={{color:"#26a69a"}} />
                                   </div>
                                   :null
                            } */}
                            
                     </div>
              </div>
              <MdlMenu visible={showMenu} arrNavbarButton={arrNavbarButton} onCloseClick={()=>setShowMenu(false)} onSelectItem={()=>{}} onSelectAdvertising={gotoAdvertising} />
       </div>
   )
}

export default Navbar;
//=========================================================================================