//========================================================================================
import React, { useEffect , useRef , useImperativeHandle , forwardRef , useState } from 'react'
import {  TouchableOpacity, View } from 'react-native';
import { ModalContainer, MyButton  , MyImage , MyBorderInput, MyInput, MyText  } from '../../../../../globalComponents/components';
import { connect } from 'react-redux';
import MdlImageCrop from '../../../../../commonComponents/mdlImageCrop';
import  uuid from "react-uuid"
import Styles from '../../../../../styles';

const MdlAddUpdateCategory=forwardRef((props , ref)=>{

    const refFile=useRef()

    const [visible , setVisible]=useState(false)
    const [categoryUUID , setCategoryUUID]=useState("")
    const [categoryInfo , setCategoryInfo]=useState({id:0 ,   // 0 : add--------- !0 : update
                                                                                    name:'' , 
                                                                                    avatar:'',
                                                                                    newAvatar:''   })

    useImperativeHandle(ref , ()=>({
            show:(_categoryInfo)=>{
                setCategoryInfo(_categoryInfo)
                setVisible(true)
                setCategoryUUID(uuid())
            },
            hide:()=>setVisible(false)
    }))
    const hide=()=>setVisible(false)
    const imgAvatar = categoryInfo.newAvatar ?   {  uri : categoryInfo.newAvatar.path  }
                                                                        :    categoryInfo.avatar ? {  uri : categoryInfo.avatar  }  :  require("../../../../../assets/images/act/imgDefImage.png") 
    const addUpdateCategory=()=> props.onAddUpdateCategory({uuid :categoryUUID  , ...categoryInfo , name:categoryInfo.name.trim().replace(/ +/g , " ") })
    //----------------------------------------------------------------------------------------------------------
    const getNewAvatar=(image)=>setCategoryInfo({...categoryInfo , newAvatar:image})
    const changeName=(txtId , newText)=> setCategoryInfo({...categoryInfo  , [txtId] : newText ? newText: ""})
    //---------------------------------------------------------------------------------------------------------
    const [avatarWidth , setAvatarWidth]=useState(0)
    const onLayout=e => setAvatarWidth(e.nativeEvent.layout.width)
    //---------------------------------------------------------------------------------------------------------
   return(
       <ModalContainer visible={visible}
                                   showTitle={true}
                                   position="top"
                                   animation="slideInDown"
                                   title={categoryInfo.id ? "برزورسانی اطلاعات دسته بندی":"ثبت دسته بندی جدید در فروشگاه"}
                                   showBackButton={true}
                                   closeWithClickBackButton
                                   onBackClick={hide}
                                   containerStyle={{padding:4}}
                                   contentStyle={{ }}>
            <View  style={{ marginTop:16}}>
                     <View  style={{ flexDirection:"row" , alignItems:"flex-end" }}>

                            <View onLayout={onLayout}   
                                      style={Object.assign({} , {  borderRadius:avatarWidth , justifyContent:"center" , alignItems:"center" , 
                                                                                   borderColor:"#eeeeee" , borderWidth:1 } , Styles.l1  , Styles.m2, Styles.s3)}>
                                <TouchableOpacity onPress={()=>refFile.current.showModal(400 , 400)} style={{width:"100%" ,justifyContent:"center" , alignItems:"center"}}>
                                    <View   style={{ width:avatarWidth , height:avatarWidth  , padding:4 , 
                                                              borderRadius:avatarWidth , justifyContent:"center" , alignItems:"center"}}  >
                                            <MyImage source={imgAvatar}  style={{width:"100%" , height:"100%" , borderRadius:avatarWidth}} />
                                    </View>
                                </TouchableOpacity>
                            </View>

                            <View style={{flex:1 , marginRight:5 , paddingBottom:10}}>
                                <MyText smallFont isBold color="#456e7a" style={{marginRight:6}}>عنوان دسته بندی : </MyText>
                                   <MyInput id ='name'  noShadow       
                                            placeholder="مثال : دسته کیف و کفش..."
                                            value={categoryInfo.name}
                                            disabled={false}  
                                            onChangeText={changeName}       
                                            password={false}  
                                            maxLength ={100}        
                                            textAlign='right'  
                                            smallFont     
                                            inputStyle={{textAlign:"right"}}   
                                            containerStyle={{borderWidth:1 , borderColor:"#e0e0e0" , borderRadius:10 , paddingVertical:4}}  />   
                            </View>
                     </View>
            </View>
            <View  style={{alignItems:"flex-start"  , justifyContent:"space-between" , flexDirection:"row-reverse" , padding:10 , marginTop:15}}>
                     <MyButton smallFont iconName="save"  
                                      onClick={addUpdateCategory}  title="ثبت و بروزرسانی اطلاعات"
                                      buttonIconStyle={{fontSize:25}} 
                                      buttonStyle={{ paddingVertical:0}} />
                     {
                        categoryInfo.id ? <MyButton smallFont  
                                                                      iconName="close"
                                                                       onClick={()=>props.onDeleteCategory(categoryInfo.id)}  
                                                                       title="حذف" 
                                                                       buttonIconStyle={{fontSize:25}}
                                                                       buttonStyle={{ backgroundColor:"#e57373" , paddingVertical:0}} /> : null
                     }
              </View>
            <MdlImageCrop ref={refFile} onSelectImage={getNewAvatar} />
           
       </ModalContainer>
   )
})

const mapStateToProps=(state)=>({
    categoryState:state.categoryReducer
})
export default  connect(mapStateToProps , null , null , {forwardRef:true})(MdlAddUpdateCategory);
//=========================================================================================