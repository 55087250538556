//========================================================================================
import React,{useEffect  , useRef, useState}  from 'react'
import {View} from "react-native"
import {    Alert , MyText, PageContainer } from '../../../globalComponents/components';
import { connect } from 'react-redux';
import {  setSearchGoods ,setSearchShop , 
               deleteGoods, deleteGoodsCategory, addUpdateGoods,  
               setSelectedGoodsCategoryId,
               setSelectedShopCategoryId} from '../../../redux/bazaar';
import MdlAddUpdateGoods from './mdlAddUpdateGoods';
import Fetch from '../../../globalComponents/fetch';
import { setToastMessage } from '../../../redux/toastMessage';
import { isValidPhoneNumber } from '../../../globalComponents/rejax';
// import { useBackHandler} from "@react-native-community/hooks"
import Footer from '../../../commonComponents/footer';

import CityGoodsList from './cityGoodsList';
import CityShopList from './cityShopList';
import MyGoods from './MyGoods';

import { SceneMap, TabView } from 'react-native-tab-view';
import MyShop from './myShop';
import MdlAddUpdateShopInfo from './mdlAddUpdateMyShopInfo';
import { myShopResetInfo, myShopSetInfo } from '../../../redux/myBazaar';

const Bazaar=(props)=>{
//-----------------------------------------------------------------------------------------------GLOBAL VAR EREA
const refToTop=useRef()
const fetchModal=useRef()
const alertModal=useRef()
const mdlAddUpdateGoods=useRef()
const mdlAddUpdateMyShopInfo=useRef()
//----------------------------------------------------------------------------------------------STATE EREA
const [index , setIndex] = useState(0) //1 : goods -----  2 : shop
//-----------------------------------------------------------------------------------------------
// useEffect(()=>{
//     if(props.route.params?.mustUpdateInfo)
//         setIndex(0)
// } , [props.route.params?.mustUpdateInfo])
useEffect(()=>{
    if(!props.myBazaarState.shopInfo.id && index)
        setIndex(0)
} , [props.myBazaarState.shopInfo.id])
//----------------------------------------------------------------------------------------------FUNCTION EREA
// useBackHandler(()=>{
//     onBackClick()
//     return true
// })
const onBackClick=()=>  props.navigation.goBack()
//===================================================================================================
//مدیریت نمایش کالاها
const onAddGoodsClick=()=>mdlAddUpdateGoods.current.show({
                                                                                                    id:0 , 
                                                                                                    title:'' , 
                                                                                                    description:'' , 
                                                                                                    price:0 , 
                                                                                                    phone:'' , 
                                                                                                    goodsStateId:0 , 
                                                                                                    categoryId:0 , 
                                                                                                    arrImageFile:[]
                                                                                                })
const onAddUpdate=(goodsInfo)=>{
    if(goodsInfo.title.length<5){
           alertModal.current.show("خطا در انجام عملیات","عنوان آگهی را با حداقل 5 حرف وارد نمایید.","error")
           return
    }
    if(goodsInfo.description.length<5){
           alertModal.current.show("خطا در انجام عملیات","متن و توضیحات آگهی را با حداقل 5 حرف وارد نمایید.","error")
           return
    }
    if(!goodsInfo.categoryId){
        alertModal.current.show("خطا در انجام عملیات","دسته بندی و نوع کالا را انتخاب نمایید.","error")
        return
   }
    if(!isValidPhoneNumber(goodsInfo.phone)){
           alertModal.current.show("خطا در انجام عملیات","شماره تماس به درستی وارد نشده است!!!.","error")
        return
    }
 
    const arrNewFile=[]
    for(let image of goodsInfo.arrImageFile)
           if( typeof(image.id)==="string" && image.filename )
                  arrNewFile.push( {  imageId :image.id , filename : image.filename , type : image.type  })
 

const arrNewImage = arrNewFile.length ?  arrNewFile.map( (file , index)=>({
                                                                                                                        name:"imageForGoods"+index , 
                                                                                                                        filename:"imageForGoods.esi"+"_"+file.imageId , 
                                                                                                                        type:file.type , 
                                                                                                                        uri:file.filename }) ) : []

 
   const objData={
           goodsInfo : {
                  id:goodsInfo.id ,
                  title:goodsInfo.title,
                  description : goodsInfo.description,
                  categoryId: goodsInfo.categoryId , 
                  goodsStateId : goodsInfo.goodsStateId , 
                  price : goodsInfo.price , 
                  arrImageFile : goodsInfo.arrImageFile.filter(imageFile => imageFile.filename),
                  phone : goodsInfo.phone ,
                  arrCity : [props.profileState.objCity] ,
                  goodsUUID: goodsInfo.goodsUUID
           },
           jwt:props.profileState.jwt
   }

//       mdlAddUpdateGoods.current.hide()
   fetchModal.current.uploadData( "user/addUpdateGoods" ,
                                                       objData ,
                                                       arrNewImage,
                                                       {
                                                           onSuccess : responseOfaddUpdateGoods ,
                                                           onStart:()=>{},
                                                           onError:()=>{},
                                                       } ,   true , true , true)  
}
const responseOfaddUpdateGoods = data => {
       setIndex(0)
        if(typeof(data)==="string"){
                if(data=="ERROR_IN_SEND_IMAGE_DATA"){
                    alertModal.current.show("خطا در بارگزاری تصویر ",
                                            "در ارسال تصویر مقاله خطایی روی داده است و لازم است تصویر مجددا انتخاب و ارسال گردد.",
                                            "error")
                    return
                }
                if(data=="DATA_IS_NOT_VALIDATE"){
                    alertModal.current.show("خطا در بارگزاری تصویر ",
                                            "اطلاعات ارسال شده معتبر نمی باشد",
                                            "error")
                    return
                }
                if(data=="NO_VALID_IMAGE_FORMAT"){
                    alertModal.current.show("خطا در بارگزاری تصویر ",
                                            "تنها تصاویری با فرمتهای "+"\n"+
                                            "jpg"+"\n"+
                                            "jpeg"+"\n"+
                                            "png"+"\n"+
                                            "برای تصویر پروفایل قابل پذیرش می باشد.",
                                            "error")
                    return
                }
                if(data=="ERROR_IN_SAVE_DATA_LASTLY"){
                    alertModal.current.show("خطا در بارگزاری تصویر ",
                                            "در ثبت نهایی تصویر خطایی روی داده است و لازم است تصویر مجددا انتخاب و ارسال گردد.",
                                            "error")
                    return
                }
        
                if(data.indexOf("CATEGORY_LAST_DELETED_")!==-1){   //CATEGORY_LAST_DELETED_شماره دسته بندی
                         props.deleteGoodsCategory(  parseInt( data.substring(    data.indexOf("CATEGORY_LAST_DELETED_") + 22   ))   )
                         props.setToastMessage("دسته بندی انتخاب شده برای کالا قبلا حذف شده است!!!."+"\n"+"لطفا دسته بندی دیگری را انتخاب نمایید")
                }
        }
        else
        {
                    props.setToastMessage("ثبت و بروزرسانی اطلاعات آگهی  با موفقیت انجام شد.")
                    if(!props.bazaarState.selectedCategoryId || props.bazaarState.selectedCategoryId===data.categoryId){
                        props.addUpdateGoods(  data)
                        props.setToastMessage("آگهی فروش شما با موفقیت در بازار روستا ثبت گردید.")
                    }            
                    else
                        props.setToastMessage("آگهی فروش شما با موفقیت در بازار روستا ثبت و با انتخاب دسته بندی مورد نظر قابل مشاهده می باشد.")  
                    mdlAddUpdateGoods.current.hide()
        }
}
//---------------------------------------------------------------------------------------------------
const checkLastGetMyShopInfo=()=>{
    if(props.myBazaarState.shopInfo.id)
        setIndex(3)
    else
        getMyShopInfo()
}
const getMyShopInfo=()=>{
       const objData={
           jwt : props.profileState.jwt
       }
       fetchModal.current.fetchData("user/getMyShopInfo" , objData , 
                                                          {
                                                               onSuccess : responseOfGetMyShopInfo , 
                                                          } , true , true , true  )
}
const responseOfGetMyShopInfo=data=>{
       if(data==="NO_SHOP_FOUNDED"){
        const title="شما فروشگاهی در ده وند ندارید !!!"+"\n"+  
                            "شما می توانید با ثبت فروشگاه خود در ده وند و معرفی محصولات خود به هم روستاییان , به کسب کار خود رونق ببخشید." +"\n\n"+
                            "اطلاعات فروشگاه : "
            mdlAddUpdateMyShopInfo.current.show( title , {id:0 , shopCategoryId:0 , name :'' , sellerName:'' , phone:'' , description:''} )

              // props.myShopUpdateInfo({arrCity : [props.profileState.objCity]})
              // alertModal.current.show("گزارش برنامه","شما فروشگاهی در ده وند ندارید.."+"\n"+
              //                                           "شما می توانید با ثبت فروشگاه خود در ده وند و معرفی محصولات خود به هم روستاییان , به کسب کار خود رونق ببخشید.","information")
       }
       else{
            props.myShopSetInfo(data)
            setIndex(3)
       }      
}
//------------------------------------------------------------------------------------------------
const onAddMyShopInfo=shopInfo=>{
        const {id , name , sellerName , phone , description , shopCategoryId} = shopInfo
        const objData={
            jwt : props.profileState.jwt , 
            id:0 , 
            name : name.trim().replace( / +/g , " ") , 
            sellerName : sellerName.trim().replace( / +/g , " ")  , 
            phone , 
            shopCategoryId , 
            description : description ? description.trim().replace( / +/g , " ").replace(/\n+/g , "\n")  : "" ,
            arrCity : [props.profileState.objCity]
        }
        fetchModal.current.fetchData("user/setMyShopInfo" , objData , 
                                                        {
                                                                onSuccess:responseOfAddMyShopInfo
                                                        } , true , true , true
        )
}
const responseOfAddMyShopInfo= data =>{
    props.myShopSetInfo(data)
    setIndex(3)
}
//---------------------------------------------------------------------------------------------------
const arrFooterMenu=[
{
        id:1 ,
        icon:'grid_view' , 
        title:'بازار روستا' ,
        isSelectedWithIndex:0 ,  
        onClick:()=> setIndex(0)
 },
 {
    id:2 ,
    icon:'business' , 
    title:'پاساژ روستا' , 
    isSelectedWithIndex:1,  
    onClick:()=> setIndex(1)
},
{
    id:3 ,
    icon:'add_circle' , 
    title:'ثبت آگهی' , 
    onClick:()=>{
        if(index)
            setIndex(0)
        onAddGoodsClick()
    }
},
 {
        id:4 ,
        icon:'bookmarks' , 
        title:' کالا های من' , 
        isSelectedWithIndex:2 ,  
        onClick:()=> setIndex(2)
 },

 {
    id:5,
    icon:'storefront' , 
    title:' فروشگاه من' ,  
    isSelectedWithIndex:3 ,
    onClick:()=>   checkLastGetMyShopInfo()  
},
]
//----------------------------------------------------------------------------------------------
// const renderScene =SceneMap({  CityGoodsList  , CityShopList  , MyGoods , MyShop  });
const renderScene=()=>{
    switch(index){
        case 0 : return <CityGoodsList />
        case 1 : return <CityShopList />
        case 2 : return <MyGoods />
        case 3: return <MyShop />
    }
}

const [routes] = React.useState([
        { key: 'CityGoodsList', title: 'CityGoodsList' },
        { key: 'CityShopList', title: 'CityShopList' },
        { key: 'MyGoods', title: 'MyGoods' },
        {key:  'MyShop' , title:'MyShop'}
]);
//---------------------------------------------------------------------------------------------
const getChapterName=()=>{
    switch(index){
           case 0 : return      "بازار روستا"
           case 1 : return      "فروشگاه های آنلاین روستا"
           case 2 : return "کالا های من در بازار روستا" 
           case 3 : return "مدیریت فروشگاه آنلاین من"
           default : return    "سند"  
    }
}
//----------------------------------------------------------------------------------------------
   return(
       <PageContainer title={getChapterName()} 
                                subTitle={"روستای "+props.profileState.objCity.name}
                                onBackIconClick={onBackClick} 
                                showBackIcon 
                                containerPadding={0}
                                hasFlatList
                                >
            {/* نمایش کالا و ی فروشگاه ها */}
           <View  style={{flex:1 , flexDirection:"column"}}  >
                <View  style={{flex:1  }} ref={refToTop} > 
                    {/* <TabView  lazy swipeEnabled={false}
                                    navigationState={{ index, routes }}
                                    renderScene={renderScene}
                                    renderTabBar={()=>null}
                                    onIndexChange={setIndex}  />  */}
                               {
                                renderScene()
                               }     
                </View>
           </View>
           <Footer arrFooterMenu={arrFooterMenu} currentIndex={index}     />
           
           <MdlAddUpdateGoods ref={mdlAddUpdateGoods} 
                                   arrCategory ={props.bazaarState.arrCategory} 
                                   arrGoodsState={props.bazaarState.arrGoodsState}
                                   onAddUpdate={onAddUpdate}/>

            <MdlAddUpdateShopInfo ref={mdlAddUpdateMyShopInfo} onAddUpdateMyShopInfo={onAddMyShopInfo} />

            <Fetch ref={fetchModal} />
            <Alert ref={alertModal} />
       </PageContainer>
   )
}

const mapStateToProps= state => ({
    profileState : state.profileReducer ,
    bazaarState : state.bazaarReducer ,
    myBazaarState : state.myBazaarReducer , 
})
const mapDispatchToProps= dispatch =>({
    setSearchGoods  : (strSearch) => dispatch(setSearchGoods(strSearch)) ,
    setSearchShop    : (strSearch) => dispatch(setSearchShop(strSearch)) ,
    
    deleteGoodsCategory : categoryId => dispatch(deleteGoodsCategory(categoryId)) ,
    addUpdateGoods : goodsinfo => dispatch(addUpdateGoods(goodsinfo)) ,
    setToastMessage : strMessage =>dispatch(setToastMessage(strMessage)) ,

    setSelectedGoodsCategoryId : categoryId => dispatch(setSelectedGoodsCategoryId(categoryId)) , 
    setSelectedShopCategoryId: categoryId =>dispatch(setSelectedShopCategoryId(categoryId)) , 

    myShopSetInfo : shopInfo=>dispatch(myShopSetInfo(shopInfo)) , 
    myShopResetInfo : ()=>dispatch(myShopResetInfo()) , 

    deleteGoods : goodsId => dispatch(deleteGoods(goodsId))


})
export default connect(mapStateToProps , mapDispatchToProps)(Bazaar);
//=========================================================================================