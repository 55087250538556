//========================================================================================
import React,{useRef , useEffect  , useState, useImperativeHandle, forwardRef}  from 'react'
import {View} from "react-native"
import { ModalContainer, MyButton, MyInput, MySelect, MyText , Alert } from '../../../globalComponents/components'
import { connect } from 'react-redux'
import { isValidNumberString, isValidPhoneNumber } from '../../../globalComponents/rejax'

const InfoComponent= props =>{
       const {id , title , placeholder , value , icon ,onChangeText , maxLength , textAlign="right" , multiline=false , minNumberOfLines=1 , maxNumberOfLines=10 , containerStyle={} , keyboardType="default"} = props
       return(
                     <MyInput id ={id} noShadow hasShadow={false}  smallFont
                                   iconName={icon}
                                   iconColor="#e57373"            
                                     placeholder={placeholder}
                                     title={title}  
                                     value={value}          
                                     disabled={false}  
                                     keyboardType={keyboardType}
                                     onChangeText={onChangeText}       
                                     password={false}  
                                     maxLength ={maxLength}
                                     multiline={multiline}  
                                     minNumberOfLines={minNumberOfLines}   
                                     maxNumberOfLines={maxNumberOfLines}   
                                     inputStyle={{textAlign , paddingVertical:3 , textAlignVertical:"top"}}        
                                     containerStyle={Object.assign({} , {borderWidth:1 , borderColor:"#e0e0e0" , borderRadius:5,  marginTop:10  } , containerStyle)}        
                                     containerClassName='' />   
       )
}
 
const MdlAddUpdateShopInfo=forwardRef((props , ref)=>{
//-----------------------------------GLOBAL VAR EREA
const {onAddUpdateMyShopInfo} = props
//-----------------------------------REF EREA
const alertModal=useRef()
//-----------------------------------STATE EREA
const [visible , setVisible]=useState(false)
const [shopInfo , setShopInfo]=useState({      
                                                                             title:'' , 
                                                                             id:0 , 
                                                                             shopCategoryId:0 , 
                                                                             name:'' , 
                                                                             sellerName:'' , 
                                                                             phone:'' , 
                                                                             description:'' , 
                                                               })
useImperativeHandle(ref , ()=>({
       show:( title="" , shopInfo  )=>{
              setVisible(true)
              setShopInfo({
                                          title , 
                                          ...shopInfo
                                   })
       } , 
}))
//-----------------------------------FUNCTION EREA
const hide=()=> setVisible(false)
const onChangeShopInfo=(txtId , newText)=>{
       if(newText){
              if(txtId==="phone"){
                     if(isValidNumberString(newText))
                            setShopInfo({...shopInfo , phone : newText})
              }
              else
                     setShopInfo({...shopInfo , [txtId]:newText })
       }
       else
              setShopInfo({...shopInfo , [txtId] : ""})
}
const onSaveClick=()=>{
       if(!shopInfo.shopCategoryId){
              alertModal.current.show("خطا در ورود اطلاعات","دسته بندی فروشگاه را انتخاب نمایید.","error")
              return
       }
       if(!shopInfo.name || shopInfo.name.trim().replace(/ +/g , " ").length<3){
              alertModal.current.show("خطا در ورود اطلاعات","نام فروشگاه را با حد اقل 3 حرف وارد نمایید.","error")
              return
       }
       if(!shopInfo.sellerName || shopInfo.sellerName.trim().replace(/ +/g , " ").length<3){
              alertModal.current.show("خطا در ورود اطلاعات","نام صاحب فروشگاه را با حد اقل 3 حرف وارد نمایید.","error")
              return
       }
       if(!isValidPhoneNumber(shopInfo.phone)){
              alertModal.current.show("خطا در ورود اطلاعات","شماهر تماس فروشگاه به درستی وارد نشده است.","error")
              return
       }
       hide()
       onAddUpdateMyShopInfo(shopInfo)
}
const onSelectShopCategory= selectedShopCategoryId => setShopInfo({...shopInfo , shopCategoryId : selectedShopCategoryId})
//--------------------------------------------------
   return(
                   <ModalContainer visible={visible}
                                              title=''
                                              message=''
                                              showBackButton={true}
                                              showTitle={false}
                                              onBackClick={hide}
                                              containerStyle={{padding:10 , marginTop:20}}
                                              contentStyle={{padding:10}}
                                              contentClassName='' 
                                              closeWithClickBackButton
                                              position='top'
                                              animation='slideInDown' >
                            <MyText isBold  smallFont color="#456e7a">{shopInfo.title}</MyText>
                            <View style={{marginTop:5}}>
                                   <MySelect smallFont placeholder="انتخاب دسته بندی فروشگاه" selectButtonStyle={{buttonStyle:{paddingVertical:1 , elevation:0 , borderWidth:1 , borderColor:"#e0e0e0"}}}
                                                                        arrItem={props.bazaarState.arrShopCategory} onSelectItem={onSelectShopCategory} selectedItemId={shopInfo.shopCategoryId} />

                                   <InfoComponent id="name" placeholder=""  title="نام فروشگاه : " icon="storefront"  value={shopInfo.name} onChangeText={onChangeShopInfo} maxLength={24}/>
                                   <InfoComponent id="sellerName" placeholder=""  title="نام صاحب فروشگاه  : " icon="person"  value={shopInfo.sellerName} onChangeText={onChangeShopInfo} maxLength={255}/>
                                   <InfoComponent id="phone" placeholder=""  title="شماره تماس فروشگاه : " icon="phone"  value={shopInfo.phone} onChangeText={onChangeShopInfo} maxLength={11} keyboardType="numeric"/>
                                   <InfoComponent id="description" placeholder=""  title="معرفی : " icon="description"  value={shopInfo.description} onChangeText={onChangeShopInfo} maxLength={2000} multiline 
                                                             minNumberOfLines={5} maxNumberOfLines={10} containerStyle={{alignItems:"flex-start"}}/>

                                   <View style={{flexDirection:"row-reverse" , marginTop:20}}>
                                          <MyButton onClick={onSaveClick} smallFont
                                                           title={shopInfo.id ? "بروزرسانی اطلاعات فروشگاه" : 'ثبت اطلاعات فروشگاه'}
                                                           iconName={shopInfo.id ? "refresh":"save"}
                                                           buttonStyle={{}}
                                                           buttonIconStyle={{}}
                                                           buttonTextStyle={{}} />
                                   </View>
                            </View>
                                           <Alert ref={alertModal}/>                       
                 </ModalContainer>
   )
})

const mapStateToProps= state =>({
       bazaarState : state.bazaarReducer
})
const mapDispatchToProps=dispatch =>({

})
export default connect(mapStateToProps , mapDispatchToProps , null , {forwardRef:true})(MdlAddUpdateShopInfo);
//=========================================================================================