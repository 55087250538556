//========================================================================================
import React, {useEffect , useRef , useState }  from 'react'
import { TouchableOpacity, View } from 'react-native'
import { connect } from 'react-redux'
import GoodsListComponent from '../../GoodsListComponent'
import {  Alert  , Confirm,  MyButton,  MyText, Popup } from '../../../../../globalComponents/components'
import Fetch from "../../../../../globalComponents/fetch"
import MdlAddUpdateGoods from '../../mdlAddUpdateGoods'
import  {  myShopSetGoods, myShopAddUpdateGoods, myShopDeleteGoods, myShopRefreshGoods, 
                myShopDeleteCategory , myShopSetSetSelectedGoodsCategory ,myShopAddUpdateCategory  , myShopUpdateInfo,
                myShopAddUpdateLink,
                myShopDeleteLink,   
                myShopSetGoodsSearch} from '../../../../../redux/myBazaar'
import { setToastMessage } from '../../../../../redux/toastMessage'
import MdlAddUpdateShopCategory from './mdlAddUpdateShopCategory'
import { isValidPersianEnglishNumberName } from '../../../../../globalComponents/rejax'
import ShopDescription from './shopDescription'
import ShopLink from './shopLink'
import ShopGoodsCategoryComponent from '../../components/shopGoodsCategoryComponent'
import MdlImageCrop from '../../../../../commonComponents/mdlImageCrop'
import SearchComponent from '../../../../../commonComponents/searchComponent'
import MdlActOnGoods from '../../mdlActOnGoods'
import MdlGoodsComment from './mdlGoodsComment'

const AccordionComponent= props=>{
    const {id=0 , title="" ,iconName="" , onClick=()=>{} , component=null , isSelected=false ,  containerStyle={}} = props
    return(
        <View style={Object.assign({} , {backgroundColor:"#FFF"  , marginVertical:2    } , containerStyle)}>
            <TouchableOpacity onPress={onClick} activeOpacity={0.7}>
                    <View style={{flexDirection:"row" ,backgroundColor:"#eceff1" , alignItems:"center" , paddingHorizontal:5 , elevation:1  }}>
                        <View style={{flex:1}}>
                            <MyButton onClick={onClick} tinyFont={!isSelected} smallFont={isSelected} disabled opacityOnDisabledOff isBold={isSelected}
                                        title={title}
                                        //  iconName={iconName}
                                        buttonStyle={{ justifyContent:"flex-start" , paddingVertical:6  , borderWidth:0 , backgroundColor:"transparent"  }}
                                        buttonIconStyle={{fontSize:27 , color:"#e57373"}}
                                        buttonTextStyle={{color:isSelected ? "#29b6f6" :"#456e7a"}} />
                        </View>
                        <MyButton onClick={()=>{}} disabled opacityOnDisabledOff
                                        title='' 
                                        iconName={isSelected ? "keyboard_arrow_up" : "keyboard_arrow_down"} 
                                        buttonStyle={{backgroundColor:"transparent" , borderRadius:30  ,paddingHorizontal:0 , paddingVertical:0 }}
                                        buttonIconStyle={{fontSize:isSelected ? 30 : 25 , color:isSelected ? "#26c6da" : "#90a4ae"}}
                                        buttonTExtStyle={{}} />
                    </View>
            </TouchableOpacity>
            {
                isSelected ?   component  : null
            }
        </View>
    )
}
 
const ShopProductList=props=>{
    //-----------------------------------------------------
    const {arrGoodsState} =props.bazaarState
    const {goodsInfo ,shopInfo  } = props.myBazaarState
    const { onUpdateDescriptionClick } = props
    //-----------------------------------------------------

    const mdlAddUpdateGoods=useRef()
    const mdlAddUpdateShopCategory=useRef()
    const fetchModal=useRef()
    const alertModal=useRef()
    const confirmModal=useRef()
    const popupModal=useRef()
    const mdlImageCroper=useRef()
    const mdlActOnGoods=useRef()
    const mdlGoodsComment=useRef()
    //-------------------------------------------------------
    const [ fetchState , setFetchState ]=useState({ fetchFlag:false , errorFlag:false });
    const [strSearch  ,setStrSearch]=useState("")
    const [selectedAccordionPanelId , setSelectedAccordionPanelId]=useState(4)
    //-------------------------------------------------------
    useEffect(()=>{
             if(goodsInfo.refresh) {
                getGoodsList() 
             }
    } , [goodsInfo.refresh])    
    useEffect(()=>{
        if(shopInfo.id && !goodsInfo.arrGoods.length && !goodsInfo.finished){
            getGoodsList()
        }
    } , [shopInfo.id])                                                          
    //-------------------------------------------------------
    const getGoodsList=async ()=>{
        const objData={
               jwt : props.profileState.jwt,
               lastGoodsId : goodsInfo.arrGoods.length ? goodsInfo.arrGoods[goodsInfo.arrGoods.length-1].id : 0 ,
               categoryId : goodsInfo.selectedCategoryId ,
               strSearch : goodsInfo.strSearch , 
               shopId : shopInfo.id  ,
               showMyGoods : true , 
               mustGetCategoryAndState : true
           }

           await fetchModal.current.fetchData("user/getGoodsList" ,objData , 
                                                           {
                                                                   onStart : ()=>setFetchState({fetchFlag : true , errorFlag : false}) ,
                                                                   onError : ()=>setFetchState({fetchFlag : false , errorFlag : true}) ,
                                                                   onSuccess : responseOfGetGoods,
                                                                   onBackFromConfirmError : ()=>setFetchState({fetchFlag : false , errorFlag : false}) ,
                                                           } , 
                                                           false ,
                                                           goodsInfo.arrGoods.length ? true : false ,
                                                           true
                                                               )
    }
    const responseOfGetGoods=data =>{
            setFetchState({fetchFlag:false , errorFlag:false})
            props.setMyShopGoods(data.goodsInfo)
    }
    //-------------------------------------------------------
    const onAddGoods=()=>{
        if(shopInfo.arrCategory.length)
            mdlAddUpdateGoods.current.show({
                                                                        id:0 , 
                                                                        title:'' , 
                                                                        description:'' , 
                                                                        price:0 , 
                                                                        phone:'' , 
                                                                        goodsStateId:0 , 
                                                                        categoryId:0 , 
                                                                        arrImageFile:[]
                                                                    })
        else
                                                                    alertModal.current.show('گزارش برنامه','هیچ دسته بندی برای محصولات فروشگاه ثبت نشده است!!!'+"\n\n"+
                                                                                                                                    "برای معرفی محصولات خود , لازم است حداقل یک دسته بندی محصول در فروشگاه ثبت نمایید.",'error');
    }
    const onUpdateGoods=(selectedGoodsInfo)=>mdlAddUpdateGoods.current.show(goodsInfo.arrGoods.filter(goods=> goods.id===selectedGoodsInfo.id)[0] )
    const onAddUpdateGoods=(goodsInfo)=>{
            if(goodsInfo.title.length<5){
                    alertModal.current.show("خطا در انجام عملیات","عنوان آگهی را با حداقل 5 حرف وارد نمایید.","error")
                    return
            }
            if(goodsInfo.description.length<5){
                    alertModal.current.show("خطا در انجام عملیات","متن و توضیحات آگهی را با حداقل 5 حرف وارد نمایید.","error")
                    return
            }
            if(!goodsInfo.categoryId){
                    alertModal.current.show("خطا در انجام عملیات","دسته بندی و نوع کالا را انتخاب نمایید.","error")
                    return
            }
    
            const arrNewImage = goodsInfo.arrImageFile.filter(file => typeof(file.id)==="string" )
                                                                                .map( (image , index)=>({
                                                                                                                                    name:"imageForGoods"+index  , 
                                                                                                                                    filename:'imageForGoods.esi'+"_"+image.id ,
                                                                                                                                    type:image.type , 
                                                                                                                                    uri : image.uri
                                                                                                                            }) )

        const objData={
                        goodsInfo : {
                            id:goodsInfo.id ,
                            shopId : shopInfo.id ,
                            title:goodsInfo.title,
                            description : goodsInfo.description,
                            categoryId: goodsInfo.categoryId , 
                            goodsStateId : goodsInfo.goodsStateId , 
                            price : goodsInfo.price , 
                            arrImageFile : goodsInfo.arrImageFile.filter(imageFile => imageFile.filename),
                            phone : goodsInfo.phone ,
                            arrCity : [props.profileState.objCity] ,
                            goodsUUID: goodsInfo.goodsUUID
                        },
                        jwt:props.profileState.jwt
        }

    fetchModal.current.uploadData( "user/addUpdateGoods" ,
                                                            objData ,
                                                            arrNewImage,
                                                            {
                                                            onSuccess : responseOfaddUpdateGoods ,
                                                            onStart:()=>{},
                                                            onError:()=>{},
                                                            } , 
                                                            true , )  
    }
    const responseOfaddUpdateGoods = data => {
            if(typeof(data)==="string"){
                    if(data=="ERROR_IN_SEND_IMAGE_DATA"){
                        alertModal.current.show("خطا در بارگزاری تصویر ",
                                                "در ارسال تصویر مقاله خطایی روی داده است و لازم است تصویر مجددا انتخاب و ارسال گردد.",
                                                "error")
                        return
                    }
                    if(data=="DATA_IS_NOT_VALIDATE"){
                        alertModal.current.show("خطا در بارگزاری تصویر ",
                                                "اطلاعات ارسال شده معتبر نمی باشد",
                                                "error")
                        return
                    }
                    if(data=="NO_VALID_IMAGE_FORMAT"){
                        alertModal.current.show("خطا در بارگزاری تصویر ",
                                                "تنها تصاویری با فرمتهای "+"\n"+
                                                "jpg"+"\n"+
                                                "jpeg"+"\n"+
                                                "png"+"\n"+
                                                "برای تصویر پروفایل قابل پذیرش می باشد.",
                                                "error")
                        return
                    }
                    if(data=="ERROR_IN_SAVE_DATA_LASTLY"){
                        alertModal.current.show("خطا در بارگزاری تصویر ",
                                                "در ثبت نهایی تصویر خطایی روی داده است و لازم است تصویر مجددا انتخاب و ارسال گردد.",
                                                "error")
                        return
                    }
            
                    if(data.indexOf("GOODS_LAST_DELETED_")!==-1){
                    // setGoodsInfo({
                    //                             ...goodsInfo , 
                    //                             arrGoods : goodsInfo.arrGoods.filter(goods => goods.id !== parseInt(data.substring(  data.indexOf("GOODS_LAST_DELETED_") + 19   ))   ) 
                    //                     })
                        props.myShopDeleteGoods(parseInt(data.substring(  data.indexOf("GOODS_LAST_DELETED_") + 19   )))
                        props.setToastMessage("این آگهی قبلا حذف شده است!!!!")
                    }
                    if(data.indexOf("CATEGORY_LAST_DELETED_")!==-1){   //CATEGORY_LAST_DELETED_شماره دسته بندی
                    // setGoodsInfo({
                    //     ...goodsInfo , 
                    //     arrGoods : goodsInfo.arrGoods.filter(goods => goods.categoryId !==  parseInt( data.substring(    data.indexOf("CATEGORY_LAST_DELETED_") + 22   ))   ) 
                    // })
                        props.myShopDeleteCategory(  parseInt( data.substring(    data.indexOf("CATEGORY_LAST_DELETED_") + 22   ))   )
                        props.setToastMessage("دسته بندی انتخاب شده برای کالا قبلا حذف شده است!!!."+"\n"+"لطفا دسته بندی دیگری را انتخاب نمایید")
                    }
            }
            else
            {
                    props.setToastMessage("ثبت و بروزرسانی اطلاعات آگهی  با موفقیت انجام شد.")
                    const currentSelectedCategory = goodsInfo.selectedCategoryId
                    if(!currentSelectedCategory || currentSelectedCategory===data.categoryId){
                        // setGoodsInfo({
                        //         ...goodsInfo , 
                        //         arrGoods : goodsInfo.arrGoods.filter(goods => goods.id===data.id).length ? goodsInfo.arrGoods.map(goods => goods.id===data.id ? data : goods )    
                        //                                                                                                                                         :  [  data , ...goodsInfo.arrGoods]
                        // })
                        props.myShopAddUpdateGoods(data)
                        props.setToastMessage("آگهی فروش شما با موفقیت در بازار روستا ثبت گردید.")
                    }            
                    else
                        props.setToastMessage("آگهی فروش شما با موفقیت در بازار روستا ثبت و با انتخاب دسته بندی مورد نظر قابل مشاهده می باشد.")   
                    mdlAddUpdateGoods.current.hide()
            }
    }
    //--------------------------------------------------------
    const onRefresh=()=>{
        props.myShopRefreshGoods()
        // setGoodsInfo({
        //                             ...goodsInfo , 
        //                             arrGoods:[] ,
        //                             finished:false , 
        //                             refresh:true
        //                     })
    }
    //--------------------------------------------------------
    const onDelete= selectedGoodsInfo =>{

        confirmModal.current.show(    "حذف کالا از بازار",
                                                         "آیا از حذف این کالا از بازار روستا اطمینان دارید ؟ ","qustion",
                                                         ()=>startToDeleteGoods(selectedGoodsInfo.id) , 
                                                         "تایید و ادامه")
    }
    const startToDeleteGoods=  goodsId =>{
        const objData={
                jwt : props.profileState.jwt ,
                goodsId
        }
        fetchModal.current.fetchData("user/deleteGoods" , objData , 
                                                            {
                                                                onSuccess : responseOfDeleteGoods
                                                            } , true , true , true)
    }
    const responseOfDeleteGoods= data =>{
        props.myShopDeleteGoods(data)
        props.setToastMessage("حذف کالا از بازار با موفقیت انجام شد.")
    }
    //-------------------------------------------------------
    const onAddCategoryClick=()=>mdlAddUpdateShopCategory.current.show({id:0 , name:'' , avatar:'' , })
    const onUpdateCategoryClick= id =>mdlAddUpdateShopCategory.current.show(shopInfo.arrCategory.filter(category => category.id===id)[0])
    const addUpdateMyCategory=(categoryInfo)=>{
            if(!isValidPersianEnglishNumberName(categoryInfo.name , 2 , 100)){
                alertModal.current.show('خطا در ورود اطلاعات','نام دسته بندی را با حداقل 2 حرف وارد نمایید!!!','error');
                return
            }

            const newAvatarFile=categoryInfo.newAvatar ?[{
                                                                                            name:"newCategoryAvatar" ,
                                                                                            filename:"newCategoryAvatar.esi" , 
                                                                                            uri:categoryInfo.newAvatar.path ,
                                                                                            type:categoryInfo.newAvatar.mime
                                                                                        }]   :[]
            const objData={
                jwt:props.profileState.jwt ,
                id:categoryInfo.id , 
                name:categoryInfo.name ,
                shopId :shopInfo.id ,
                uuid:categoryInfo.uuid
            }
            fetchModal.current.uploadData("user/addUpdateCategory" , objData , newAvatarFile , 
                                                                 {
                                                                    onSuccess : responseOfAddUpdateCategory
                                                                 } , true , true)
    }
    const  responseOfAddUpdateCategory= data =>{
            mdlAddUpdateShopCategory.current.hide()
            if(typeof(data)==="string"){
                if(data.indexOf("CATEGORY_LAST_DELETED_")){
                    props.myShopDeleteCategory(  parseInt( data.substring(    data.indexOf("CATEGORY_LAST_DELETED_") + 22   ))   )
                    props.setToastMessage("دسته بندی انتخاب شده قبلا حذف شده است!!!")
                }
            }
            else{
                props.myShopAddUpdateCategory(data)
                props.setToastMessage("ثبت بروزرسانی اطلاعات دسته بندی های فروشگاه با موفقیت انجام شد")
            }
    }
    //--------------------------------------------------------
    const onDeleteCategoryClick= id =>{
        const selectedCategory = shopInfo.arrCategory.filter(category => category.id===id)[0]
            confirmModal.current.show("حذف دسته بندی فروشگاه",
                                                              "آیا از حذف  دسته بندی"+"\n"+
                                                              selectedCategory.name+"\n"+
                                                              " اطمینان دارید؟"+"\n"+
                                                              "توجه داشته باشید که در صورت تایید , محصولات مربوط به این دسته بندی نیز  از فروشگاه حذف خواهند شد.",
                                                              "question",()=>deleteCategory(id) , "تایید و ادامه")
    }
    const deleteCategory=(id)=>{
        mdlAddUpdateShopCategory.current.hide()
            const objData={
                jwt:props.profileState.jwt , 
                categoryId: id
            }
            fetchModal.current.fetchData("user/deleteCategory" , objData , 
                                                             {
                                                                onSuccess : responseOfDeleteCategory
                                                             } , true , true , true  )
    }
    const responseOfDeleteCategory= data =>{
        props.myShopDeleteCategory(data)
        props.setToastMessage("حذف دسته بندی فروشگاه با موفقیت انجام شد")
    }
    //-------------------------------------------------------UPDATE SHOP AVATAR
    const onUpdateShopAvatar = ()=>mdlImageCroper.current.showModal()
    const setNewAvatar=(newImage)=>{
                    const objData={jwt : props.profileState.jwt}
                    const fileResult = newImage ? [ {name:'myShopAvatar' , filename:'myShopAvatar.esi' , type:newImage.mime , uri:newImage.path  } ]  : null
                    fetchModal.current.uploadData("user/setMyShopAvatar" ,
                                                                            objData ,
                                                                            fileResult ,
                                                                            {
                                                                                onSuccess : responseOfSetNewAvatar ,
                                                                                onStart:()=>{} ,
                                                                                onError:()=>{}
                                                                            }  )  
    }
    const responseOfSetNewAvatar=(data)=>{
            if(typeof(data)==="string"){
                    if(data=="ERROR_IN_SEND_IMAGE_DATA"){
                    alertModal.current.show("خطا در بارگزاری تصویر پروفایل",
                                                "در ارسال تصویر  خطایی روی داده است و لازم است تصویر مجددا انتخاب و ارسال گردد.",
                                                "error")
                    return
                    }
                    if(data=="DATA_IS_NOT_VALIDATE"){
                    alertModal.current.show("خطا در بارگزاری تصویر ",
                                                "اطلاعات ارسال شده معتبر نمی باشد",
                                                "error")
                    return
                    }
                    if(data=="NO_VALID_IMAGE_FORMAT"){
                    alertModal.current.show("خطا در بارگزاری تصویر ",
                                                "تنها تصاویری با فرمتهای "+"\n"+
                                                "jpg"+"\n"+
                                                "jpeg"+"\n"+
                                                "png"+"\n"+
                                                "برای تصویر  قابل پذیرش می باشد.",
                                                "error")
                    return
                    }
                    if(data=="ERROR_IN_SAVE_DATA_LASTLY"){
                    alertModal.current.show("خطا در بارگزاری تصویر ",
                                                "در ثبت نهایی تصویر خطایی روی داده است و لازم است تصویر مجددا انتخاب و ارسال گردد.",
                                                "error")
                    return
                    }
                    if(data=="ERROR_IN_CONNECT_DATABASE"){
                    alertModal.current.show("خطا در بارگزاری تصویر ",
                                                "در ثبت اطلاعات خطایی رود داده است"+"\n"+
                                                "لطفا پس از لحظاتی مجددا تلاش نمایید.",
                                                "error")
                    return
                    }
            }
            props.myShopUpdateInfo( data  )
            props.setToastMessage("بروزرسانی  اطلاعات با موفقیت انجام شد.")
    }
    //-------------------------------------------------------------------------------------------------
    const onUpdateDescriptionInfo=(itemId , newValue)=>{
        let message="";
        let placeholder=""
        let maxLength=255 ;
        let multiLines=false
        let defValue=""
        switch(itemId) {
            case "name":
                                    message="نام فروشگاه را در کادر زیر وازد نمایید"
                                    placeholder="نام فروشگاه ...."
                                    maxLength=255
                                    defValue=shopInfo.name
                                    break;
            case "sellerName":
                                    message="نام صاحب امتیاز فروشگاه را در کادر زیر وازد نمایید"
                                    placeholder="نام صاحب امتیاز فروشگاه ...."
                                    maxLength=50
                                    defValue=shopInfo.sellerName
                                    break;
            case "phone":
                                    message="شماره تماس فروشگاه را در کادر زیر وازد نمایید"
                                    placeholder="شماره تماس فروشگاه ...."
                                    maxLength=11
                                    defValue=shopInfo.phone
                                    break;
            case "description":
                                    message="توضیحات و آدرس فروشگاه را در کادر زیر وازد نمایید"
                                    placeholder="توضیحات و آدرس فروشگاه ...."
                                    maxLength=2000
                                    defValue=shopInfo.description
                                    multiLines=true
                                    break;
            case "shopCategoryId" : 
                                        updateDescriptionInfo(itemId , newValue);
                                        return
            default:
                break;
        }
        popupModal.current.show(itemId , "" ,message , "question" , updateDescriptionInfo , ()=>{} , "بروزرسانی اطلاعات"  ,defValue , placeholder , maxLength , "default" , multiLines , true )
    }
    const updateDescriptionInfo=(itemId , newText)=>{
        if(newText){
            let keyItem=""
            let newValue=""
                switch(itemId){
                    case "name"  :
                                        keyItem="NAME"
                                        newValue=newText.trim().replace(/ +/g , " ")
                                        if(newValue.length<5){
                                                alertModal.current.show("خطا در ورود اطلاعات","نام فروشگاه را با حداقل 5 حرف وارد نتمایید","error")
                                                return
                                        }
                                        break
                    case "sellerName"  :
                                        keyItem="SELLER_NAME"
                                        newValue=newText.trim().replace(/ +/g , " ")
                                        if(newValue.length<5){
                                                alertModal.current.show("خطا در ورود اطلاعات","نام فروشگاه را با حداقل 5 حرف وارد نتمایید","error")
                                                return
                                        }
                                        break
                    case "phone"  :
                                        keyItem="PHONE"
                                        newValue=newText.trim().replace(/ +/g , " ")
                                        if(newValue.length<5){
                                                alertModal.current.show("خطا در ورود اطلاعات","نام فروشگاه را با حداقل 5 حرف وارد نتمایید","error")
                                                return
                                        }
                                        break
                    case "description"  :
                                        keyItem="DESCRIPTION"
                                        newValue=newText.trim().replace(/ +/g , " ")
                                        if(newValue.length<5){
                                                alertModal.current.show("خطا در ورود اطلاعات","نام فروشگاه را با حداقل 5 حرف وارد نتمایید","error")
                                                return
                                        }
                                        break
                    case "shopCategoryId"  :
                                        keyItem="SHOP_CATEGORY_ID"
                                        newValue=newText
                                        break
                    default : return  
                }

                const objData={
                                            jwt : props.profileState.jwt , 
                                            keyItem , 
                                            newValue ,
                                            shopId : shopInfo.id  ,
                }

                fetchModal.current.fetchData("user/updateMyShopInfo" , 
                                                                   objData ,
                                                                   {
                                                                    onSuccess : responseOfUpdateDescriptioninfo
                                                                   } , true , true , true
                   )
        }
    }
    const responseOfUpdateDescriptioninfo=data => {
        props.myShopUpdateInfo(data)
        props.setToastMessage("بروزرسانی اطلاعات با موفقیت انجام شد")
    }
    //------------------------------------------------------------------------------------------------
    const onAddUpdateLink=(linkInfo)=>{
           const objData={
                jwt:props.profileState.jwt , 
                link:linkInfo.link , 
                socialNetworkId : linkInfo.socialNetworkId ,
                shopId : shopInfo.id  ,
           }
           fetchModal.current.fetchData("user/addUpdateLink" , objData , 
                                                             {
                                                                onSuccess : responseOfAddUpdateLink
                                                             } , true , true , true
           )
    }
    const responseOfAddUpdateLink= data => {
        props.myShopAddUpdateLink(data)
        props.setToastMessage("بروزرسانی اطلاعات با موفقیت انجام شد")
    }
    //-------------------------------------------------------------------------------------------------
    const deleteLink=(id)=>{
        mdlAddUpdateShopCategory.current.hide()
            const objData={
                jwt:props.profileState.jwt , 
                linkId: id ,
                shopId : shopInfo.id
            }
            fetchModal.current.fetchData("user/deleteLink" , objData , 
                                                             {
                                                                onSuccess : responseOfDeleteLink
                                                             } , true , true , true  )
    }
    const responseOfDeleteLink= data =>{
        props.myShopDeleteLink(data)
        props.setToastMessage("حذف آدرس فروشگاه با موفقیت انجام شد")
    }
    //------------------------------------------------------------------------------------------------
    const onChangeText=(txtId , newText)=>  setStrSearch(newText ? newText : "")
    const onSearchButtonClick=()=>{
            const str= strSearch ? strSearch.replace(/ +/g , " ") : ""
            props.myShopSetGoodsSearch(str)
            setStrSearch(str)
    }
    //-----------------------------------------------------------------------------------------------
    const onSelectGoods= goodsInfo =>mdlActOnGoods.current.show(goodsInfo)
    //-------------------------------------------------------------------------------------------------
    const onShowGoodsComment= goodsInfo => mdlGoodsComment.current.show(goodsInfo)
    //-------------------------------------------------------------------------------------------------
    const actButton=[
                                        {
                                                id:1 , 
                                                title:'پاسخ دهی به نظر و سوال مشتریان' , 
                                                iconName:'comment' , 
                                                onClick: goodsInfo=>onShowGoodsComment(goodsInfo)             
                                        } , 
                                        {
                                            id:2 , 
                                            title:'بروزرسانی اطلاعات کالا' , 
                                            iconName:'refresh' , 
                                            onClick: goodsInfo=>onUpdateGoods(goodsInfo)             
                                        } , 
                                        {
                                            id:3 , 
                                            title:'حذف کالا از بازار روستا' , 
                                            iconName:'close' , 
                                            onClick: goodsInfo=>onDelete(goodsInfo)              
                                        } , 
                                ]
    //-----------------------------------------------------------------------------------------------
    const searchAndAddGoodsButton=()=>{
        return (
            <View style={{flexDirection:"row" , alignItems:"center" , marginTop:5}}>
                <View style={{flex:1}}>
                    <SearchComponent onChangeText={onChangeText} value={strSearch}   onSearchButtonClick={onSearchButtonClick} placeholder="جستجوی نام کالا...."  
                                                hideSearchButton={strSearch===goodsInfo.strSearch} />
                </View>
                {
                    !strSearch && !goodsInfo.strSearch ?
                        <MyButton onClick={onAddGoods}   tinyFont
                                        title='محصول جدید' 
                                        iconName='add' 
                                        buttonStyle={{backgroundColor:"#26c6da" , paddingVertical:2}}
                                        buttonIconStyle={{}}
                                        buttonTExtStyle={{}} /> : null
                }
            </View>
        )
    }
    //------------------------------------------------------------------------------------------------
    const arrAccordionItem=[
        {
            id:1 ,  
            iconName:'description' , 
            title:'معرفی , آدرس , شماره تماس  و توضیحات فروشگاه ' , 
            component:<ShopDescription onUpdateDescriptionInfo={onUpdateDescriptionInfo} onShopAvatarClick={onUpdateShopAvatar} onUpdateDescriptionClick={onUpdateDescriptionClick}  /> ,
            onClick : ()=>   setSelectedAccordionPanelId( selectedAccordionPanelId=== 1 ? 0 : 1)
        } , 
        {
            id:2 , 
            iconName:'link' , 
            title:'صفحه فروشگاه در شبکه های اجتماعی' , 
            component:<ShopLink onAddUpdateSocialNetworkLink={onAddUpdateLink} onDeleteSocialNetworkLink={deleteLink} />,
            onClick : ()=>   setSelectedAccordionPanelId( selectedAccordionPanelId=== 2 ? 0 : 2)
        } ,
         {
            id:3, 
            iconName:'apps' , 
            title:'دسته بندی محصولات فروشگاه' , 
            component:shopInfo.id ? <ShopGoodsCategoryComponent arrCategory={shopInfo.arrCategory} 
                                                                                                    onAddCategory={onAddCategoryClick}  
                                                                                                    canAddUpdateCategory={true} 
                                                                                                    showTitle={true} 
                                                                                                    isUserShopCategory={true}
                                                                                                    containerStyle={{ borderRadius:10 , backgroundColor:"#FFF" , elevation:0 , marginTop:5 , 
                                                                                                                                paddingHorizontal:10 , paddingVertical:0 , paddingTop:0 , paddingBottom:0 , marginBottom:10}}
                                                                                                    buttonContainerStyle={{}} 
                                                                                                    buttonTextStyle={{color:"#456e7a"}}
                                                                                                    buttonTextColor="gray"
                                                                                                    // onSelectCategory={props.setSelectedCategoryId}
                                                                                                    // onLongSelectCategory={onUpdateCategoryClick}
                                                                                                    onSelectCategory={onUpdateCategoryClick}
                                                                                                    selectedCategoryId={goodsInfo.selectedCategoryId} />  : null ,
            onClick : ()=>   setSelectedAccordionPanelId( selectedAccordionPanelId=== 3 ? 0 : 3)
        } , 
        {
            id:4, 
            iconName:'widgets' , 
            title:'مدیریت  محصولات فروشگاه' ,
            containerStyle:{flex:selectedAccordionPanelId=== 4 ? 1 : 0}  , 
            component:<View style={{flex:1  }}>
                                    <GoodsListComponent shopId={props.myBazaarState.shopInfo.id} 
                                                                                    isMyGoods={true} 
                                                                                    useComboBaxForSelectCategory={true}
                                                                                    fetchState={fetchState}
                                                                                    headerComponent={searchAndAddGoodsButton()}
                                                                                    onAddCategory={onAddCategoryClick}
                                                                                    goodsInfo={goodsInfo} 
                                                                                    arrCategory={shopInfo.arrCategory} 
                                                                                    arrGoodsState={arrGoodsState}
                                                                                    selectedCategoryId={goodsInfo.selectedCategoryId}
                                                                                    strSearch={goodsInfo.strSearch}
                                                                                    getGoods={getGoodsList}
                                                                                    onRefresh={onRefresh}
                                                                                    onSelectGoods={onSelectGoods} 
                                                                                    showAddNewGoods  /> 
                                </View>,
            onClick : ()=>   setSelectedAccordionPanelId( selectedAccordionPanelId=== 4 ? 0 : 4)
        } , 
    ]

    //-------------------------------------------------------------------------------------------------
   return (
    <View style={{flex:1 , padding:5 }}>
        {
            arrAccordionItem.map(accordionItem =><AccordionComponent key={accordionItem.id} {...accordionItem} isSelected={accordionItem.id===selectedAccordionPanelId} />)
        }
        

        {/* <ExtendedActionButton title="ثبت محصول جدید" iconName='add-box' onClick={onAddGoods}  /> */}
                    
        <MdlActOnGoods ref={mdlActOnGoods} arrActButton={actButton} />
        <MdlAddUpdateGoods ref={mdlAddUpdateGoods} 
                              arrCategory ={shopInfo.arrCategory} 
                              arrGoodsState={props.bazaarState.arrGoodsState} 
                              onAddUpdate={onAddUpdateGoods}
                               mustGetPhoneNumber={false} />
        <MdlGoodsComment ref={mdlGoodsComment} />
        <MdlAddUpdateShopCategory ref={mdlAddUpdateShopCategory} onAddUpdateCategory={addUpdateMyCategory} onDeleteCategory={onDeleteCategoryClick}  />
        <MdlImageCrop ref={mdlImageCroper} onSelectImage={setNewAvatar}  />
         <Fetch ref={fetchModal} />
        <Alert ref={alertModal} />
        <Confirm ref={confirmModal} />
        <Popup ref={popupModal} />
    </View>
   )
}

const mapStateToProps= state => ({
                                                            myBazaarState : state.myBazaarReducer ,
                                                            bazaarState     : state.bazaarReducer ,
                                                            profileState   : state.profileReducer
                                                    })
const mapDispathToProps= dispatch =>({
                                                                        myShopAddUpdateCategory : categoryInfo => dispatch(myShopAddUpdateCategory(categoryInfo)) , 
                                                                        myShopAddUpdateGoods : ( goodsInfo) => dispatch(myShopAddUpdateGoods( goodsInfo)) , 
                                                                        myShopDeleteCategory : categoryId =>dispatch(myShopDeleteCategory(categoryId)) , 
                                                                        setToastMessage : strMessage => dispatch(setToastMessage(strMessage)) ,
                                                                        myShopRefreshGoods : ()=>dispatch(myShopRefreshGoods()) ,
                                                                        setSelectedCategoryId : categoryId => dispatch(myShopSetSetSelectedGoodsCategory(categoryId)) , 
                                                                        setMyShopGoods :  goodsInfo =>  dispatch(myShopSetGoods(  goodsInfo )) ,
                                                                        myShopDeleteGoods : goodsId => dispatch(myShopDeleteGoods( goodsId)) ,
                                                                        myShopUpdateInfo : shopInfo => dispatch(myShopUpdateInfo(shopInfo)) ,
                                                                        myShopAddUpdateLink : linkInfo => dispatch(myShopAddUpdateLink(linkInfo)) ,
                                                                        myShopDeleteLink : linkId => dispatch(myShopDeleteLink(linkId)) ,
                                                                        myShopSetGoodsSearch : strSearch => dispatch(myShopSetGoodsSearch(strSearch))
                                                            })

export default connect(mapStateToProps , mapDispathToProps )( ShopProductList);
//=========================================================================================