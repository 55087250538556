
import imgInstagram   from    "./../assets/images/socialNetwork/instagram.png"
import imgWhatsapp    from    "./../assets/images/socialNetwork/whatsapp.png"
import imgTelegram    from    "./../assets/images/socialNetwork/telegram.png"
import imgSorush      from    "./../assets/images/socialNetwork/sorush.png"
import imgEitaa       from    "./../assets/images/socialNetwork/eitaa.png"
import imgRobika      from    "./../assets/images/socialNetwork/robika.png"
import imgBale        from    "./../assets/images/socialNetwork/bale.png"

export     const      SERVER_URL =               "https://deh-vand.ir/"                 //*/"http://localhost:4000/"
export     const      SERVER_URL_WITH_WWW =      "https://www.deh-vand.ir/"             //*/"http://localhost:4000/"
export     const      SITE_URL =                 "https://deh-vand.ir/"                 //*/"http://localhost:4000/"
export     const      FTP_SERVER_URL =           "https://dl.deh-vand.ir/"

export const arrAdminNavbar=[
                                {
                                    id:1,
                                    pageName:'bulletin' ,
                                    title:'تابلو اعلانات روستا  ',
                                    icon:'space_dashboard',
                                    selected:true,
                                    show:true
                                },
                                {
                                    id:2,
                                    pageName:'news' ,
                                    title:'خبرهای  ',
                                    icon:'newspaper',
                                    selected:false,
                                    show:true
                                },
                                {
                                    id:3,
                                    pageName:'conversation' ,
                                    title:'گفتگو ها  ',
                                    icon:'chat',
                                    selected:false,
                                    show:true
                                },
                                {
                                    id:4,
                                    pageName:'announcement' ,
                                    title:'اطلاعیه',
                                    icon:'campaign',
                                    selected:false,
                                    show:true
                                },
                                {
                                    id:5,
                                    pageName:'advertising' ,
                                    title:'آگهی های تبلیغاتی',
                                    icon:'sell',
                                    selected:false,
                                    show:true,
                                    disabled:true
                                },
                                {
                                    id:6,
                                    pageName:'bazaar' ,
                                    title:'مدیریت بازار',
                                    icon:'storefront',
                                    selected:false,
                                    show:true ,
                                    disabled:true , 
                                },
                                {
                                    id:7,
                                    pageName:'peoples' ,
                                    title:'سرپرستان خانوار روستا ',
                                    icon:'groups',
                                    selected:false,
                                    show:true
                                },
                                {
                                    id:8,
                                    pageName:'bill' ,
                                    title:'قبوض عمومی روستا',
                                    icon:'receipt_long',
                                    selected:false,
                                    show:true
                                },
                                {
                                    id:9,
                                    pageName:'album' ,
                                    title:'آلبوم روستا',
                                    icon:'photo_library',
                                    selected:false,
                                    show:true,
                                    disabled:true
                                },
                                {
                                    id:10,
                                    pageName:'message' ,
                                    title:"جدیدترین پیام ها" ,
                                    icon:'email',
                                    selected:false,
                                    show:false
                                } ,
                                {
                                    id:11,
                                    pageName:'payment' ,
                                    title:'جدیدترین پرداخت ها',
                                    icon:'payment',
                                    selected:false,
                                    show:false
                                } ,
                                {
                                    id:12,
                                    pageName:'setting' ,
                                    title:'تنظیمات',
                                    icon:'settings',
                                    selected:false,
                                    show:true
                                } ,                                                                        
                            ]

export const arrSocialNetwork= [
                                    {
                                        id:1 , 
                                        name:'اینستاگرام' , 
                                        icon:imgInstagram
                                    } , 
                                    {
                                        id:2 , 
                                        name:'واتس آپ' , 
                                        icon:imgWhatsapp
                                    } , 
                                    {
                                        id:3 , 
                                        name:'تلگرام' , 
                                        icon:imgTelegram
                                    } , 
                                    {
                                        id:4 , 
                                        name:'روبیکا' , 
                                        icon:imgRobika
                                    } , 
                                    {
                                        id:5 , 
                                        name:'ایتا' , 
                                        icon:imgEitaa
                                    } , 
                                    {
                                        id:6 , 
                                        name:'بله' , 
                                        icon:imgBale
                                    } , 
                                    {
                                        id:7 , 
                                        name:'سروش' , 
                                        icon:imgSorush
                                    } , 
                                ]