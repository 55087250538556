//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
import {  ContentContainer, ImageModal, ModalSlideImage, MyButton, MyIcon, MyText  ,Section } from '../../globalComponents/components';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import Navbar from './component/navbar';
import { ScrollView } from 'react-native-web';
import Footer from './component/footer';
import {FTP_SERVER_URL, SERVER_URL} from "./../../globalDef"
import AdvertisingDescription from './advertising';
const  PropertyComponent= props =>{
       const {title , icon , matn }=props
       return(
              <div className={`  w3-col  l4 m5 `}  style={{marginBottom:2 , padding:6}}>
                     <div className='displayFlexRow  w3-round-xlarge' style={{ backgroundColor:"#f5f5f5" , padding:5}}>
                            <MyIcon iconName={icon}  color="#e91e63" iconSize={40} style={{}}/>
                            <div className='displayLFexCol  w3-padding w3-round-xlarge displayFlexGrow  ' style={{}}>
                                   <MyText isBold  color="#26a69a" isBlock  style={{}}>{title}</MyText>
                                   <MyText>{matn}</MyText>
                            </div>
                     </div>
              </div>
       )
}
const PropertiesImageComponent= props =>{
       const {title , image , onClickImage} = props
       return <img src={image}  style={{ height:"100%" , width:"100%" , marginRight:10}} onClick={onClickImage}  />  
  
}
const PropertiesImageInLargeScreenComponent= props =>{
       const {title , image , onClickImage} = props
       return <div className=' w3-col l2 w3-padding-small  ' onClick={onClickImage} >
              <img src={image} style={{width:"100%"}} />
       </div>
}
const Home=(props)=>{
//-----------------------------------GLOBAL VAR EREA
const arrProperties=[
       {
              id:1 , 
              icon:'feed',
              title:'تابلو اعلانات روستا' , 
              matn:'دهیاری روستا از طریق این بخش می توانند اهالی را در جریان جدیدترین اعلانات و اطلاعاعیه های عمومی قرار دهند' , 
       },
       {
              id:2 , 
              icon:'newspaper',
              title:'خبر های روستا' , 
              matn:'شما از طریق این بخش می توانید به جدید ترین اخبار و اتفاقات روستا دسترسی داشته باشد' , 
       },
       {
              id:3 , 
              icon:'campaign',
              title:'اطلاعیه ها در روستا' , 
              matn:'جدیدترین اطلاعیه های  روستا در این بخش قرار می گیرند.'+"\n"+
                        "شما می توانید اطلاعیه های مورد نظر خود را در این بخش قرار دهید"+"\n"+
                        "(اطلاعیه های ترحیم و تبریک و ...)" , 
       },
       {
              id:4 , 
              icon:'question_answer',
              title:'گفتگو ها در روستا' , 
              matn:"گفتگو ها در باره مسائل و مشکلات روستا در این بخش قابل مشاهده است."+"\n"+
                       "شما نیز می توانید با ایجاد گفتگوی جدید درباره مشکلات ایجاد شده در روستا با دیگر اهالی به بحث و گفتگو بپردازید.", 
       },
       {
              id:5 , 
              icon:'storefront',
              title:'بازار روستا' , 
              matn:'هرچه می خواهید از بازار روستا بخرید و هرچه نیاز ندارید بفروشید.'+"\n"+
                       "اگر صاحب کسب و کاری هستید با ایجاد فروشگاه آن را به دیگران معرفی کنید" , 
       },
       {
              id:6 , 
              icon:'receipt_long',
              title:'قبوض روستا' , 
              matn:'قبوض روستا, مانند قبوض جمع آوری زباله را به راحتی و با اطمینان پرداخت نمایید'+"\n"+
                       "می توانید تاریخچه پرداخت ها قبلی  قبوض خود را نیز در این بخش مشاهده نمایید" , 
       },
]
const arrPropertiesImage = [
       require("./../../assets/images/mainPageImage/bulletin.png") ,
       require("./../../assets/images/mainPageImage/news.png") ,
       require("./../../assets/images/mainPageImage/announcement.png") ,
       require("./../../assets/images/mainPageImage/conversation.png") ,
       require("./../../assets/images/mainPageImage/bazaar.png") ,
       require("./../../assets/images/mainPageImage/bill.png") ,
]
const arrNavbarButton=[
       // {
       //        id:1 , 
       //        title:'امکانات و ویژگی ها' , 
       //        icon:'' , 
       //        onCLick:()=>{}
       // } ,
       // {
       //        id:2 , 
       //        title:'سوالات متداول' , 
       //        icon:'' , 
       //        onCLick:()=>{}
       // } ,
       // {
       //        id:3 , 
       //        title:'وبلاگ ' , 
       //        icon:'' , 
       //        onCLick:()=>{}
       // },
       // {
       //        id:4 , 
       //        title:'درباره ما ' , 
       //        icon:'' , 
       //        onCLick:()=>{}
       // },
       {
              id:5 , 
              title:'ارتباط با ما ' , 
              icon:'' , 
              onCLick:()=>{}
       }
]
//-----------------------------------REF EREA
const modalSlideImage=useRef()
//-----------------------------------STATE EREA
//-----------------------------------USE_EFFECT EREA
//-----------------------------------FUNCTION EREA
const showModalImage = index =>modalSlideImage.current.show(arrPropertiesImage , index , "#FFFFFF")
const gotoAdvertising=()=>props.navigation.navigate("advertising")
const gotoApp=()=>{
       // if(isMobile || isTablet)
              props.navigation.navigate("getPhoneNumber")
       // else
       //        alert("متاسفانه"+"\n"+
       //                   "در حال حاضر وب اپلیکیشن ده وند تنها روی موبایل و تبلت در دسترس بوده و "+"\n"+
       //                   "اپلیکشن سازگار با دسکتاپ و لپ تاپ در حال آماده سازی می باشد.")
}
//--------------------------------------------------
   return(
      <ContentContainer containerClassName="w3-white" contentClassName="w3-animate-left">
        <Navbar arrNavbarButton={arrNavbarButton} gotoAdvertising={gotoAdvertising} showMenuButton   />
       <ContentContainer className=''>

                            {/* توضیحات اجمالی درباره اپلیکیشن */}
                            <Section containerClassName="w3-padding-small">
                                   <div className='displayFlexRowReverse ' style={{marginTop:20 , alignItems:"center" , flexWrap:"wrap" , justifyContent:"center"}}>
                                          <div className='w3-col l3 m5 s7'>
                                                 <img src={require("./../../assets/images/logo/logo.png")} style={{width:"100%"}} />
                                          </div>
                                          <div className='w3-col l9 m12 s12'>
                                                 <div>
                                                               <h3 style={{fontWeight:"bold"}}>اپلیکیشن دهوند</h3>
                                                                      <MyText bigFont isBlock className="w3-margin-right" >{  "دهوند یک شبکۀ اجتماعی‌روستایی است که هم‌زمان امکان"+"\n"+
                                                                                                                              " گفتگو درباره مسائل و مشکلات روستا"+"\n"+
                                                                                                                              "مشاهده اخبار مهم روستا"+"\n"+
                                                                                                                              "ثبت و مشاهده اطلاعیه های روستا (اطلاعیه ترحیم و تبریک و....)"+"\n"+
                                                                                                                              "مشاهده بازار روستا و امکان خرید و فروش "+"\n"+
                                                                                                                              "  را برای شما فراهم می‌کند."
                                                                                                                           }</MyText>
                                                 </div>
                                                 <div className='displayFlexRow w3-margin-top' style={{flexWrap:"wrap"}}>
                                                        <div className='displayFlexGrow w3-padding-small'>
                                                               <MyButton bigFont title="دانلود اپلیکیشن اندروید" icon=""  
                                                                             onClick={()=>window.location.href=`${FTP_SERVER_URL}static/application/dehvand.apk`}
                                                                             buttonStyle={{backgroundColor:"#26a69a" , padding:"7px 15px"}}
                                                                             buttonIconStyle={{}}
                                                                             buttonTextStyle={{}} />
                                                        </div>
                                                        <div className='displayFlexGrow w3-padding-small'>
                                                               <MyButton bigFont title="دانلود وب اپلیکیشن ios" icon="" onClick={gotoApp} 
                                                                             buttonStyle={{backgroundColor:"#26a69a" , padding:"7px 15px"  }}
                                                                             disabled
                                                                             buttonIconStyle={{}}
                                                                             buttonTextStyle={{}} />
                                                        </div>   
                                                 </div>
                                          </div>
                                   </div>
                            </Section>
                             {/* امکانات و ویژگی های دهوند */}
                             <Section contentClassName="w3-padding-small-">
                                   <div className=' w3-padding-small' style={{marginTop:50  , backgroundColor:"#FFFFFF"}}>
                                          <h3  className='w3-right-align' style={{fontWeight:"bold"}}>امکانات و ویژگی های ده وند</h3>
                                          <div className='displayFlexRow ' style={{flexWrap:"wrap" , alignItems:"stretch" , justifyContent:"center"}}>
                                          {
                                                 arrProperties.map((property , index) => <PropertyComponent key={index} {...property} />)
                                          }
                                          </div>
                                   </div>
                            </Section>
                            <Section >
                                   <div className='w3-margin-top w3-margin-bottom w3-padding-small  ' >
                                          <ScrollView horizontal={true} vertical={false}   style={{ height:400}} scrollEnabled  showsHorizontalScrollIndicator >
                                                 {
                                                        arrPropertiesImage.map((image , index) =>    <PropertiesImageComponent key={index} image={image} onClickImage={()=>showModalImage(index)} />)
                                                 }
                                          </ScrollView>
                                   </div>
                                   {/* <div className='w3-margin-top w3-margin-bottom w3-padding-small w3-hide-small w3-hide-medium  ' style={{}} >

                                                 {
                                                        arrPropertiesImage.map((image , index) =>    <PropertiesImageInLargeScreenComponent key={index} image={image} onClickImage={()=>showModalImage(index)} />)
                                                 }
                                          
                                   </div> */}
                            </Section>
                            {/* تبلیغات در دهوند */}
                            <Section containerStyle={{backgroundColor:"#f5f5f5"}} containerClassName="w3-padding-small" >
                            
                                   <div className='w3-col l7'>
                                          {/* <div>
                                                        <h3 style={{fontWeight:"bold"}}>تبلیغات در دهوند</h3>
                                                               <MyText bigFont isBlock className="w3-margin-right" >{"با تبلیغ کالا و خدمات خود در دهوند با کمترین هزینه در تمام روستا دیده خواهید شد"+"\n"+
                                                                                           " ما با شماییم تا کالا و خدمات شما را بهترین شکل ممکن به هم روستاییان شما معرفی کنیم."+"\n"}</MyText>
                                          </div>
                                          <div className='displayFlexRow w3-margin  w3-hide-small '>
                                                 <MyButton title="سفارش تبلیغات" onClick={gotoAdvertising}
                                                               buttonStyle={{backgroundColor:"transparent" , border:"1px solid #26a69a" , borderRadius:10 , padding:"8px 25px" }}
                                                               buttonTextStyle={{color:"#26a69a"}} />
                                          </div>
                                          <div className='w3-col l5'>
                                                 <img className='w3-round-xxlarge ' src={require("./../../assets/images/mainPageImage/advertising.png")} style={{width:"100%"}} />
                                          </div> */}
                                          {/* <div className=' w3-margin w3-hide-large w3-hide-medium '>
                                                 <MyButton title="سفارش تبلیغات" onClick={gotoAdvertising}
                                                               buttonStyle={{backgroundColor:"transparent" , border:"1px solid #26a69a" , borderRadius:10 , padding:"8px 25px" }}
                                                               buttonTextStyle={{color:"#26a69a"}} />
                                          </div> */}
                                          
                                   </div>
                            </Section>

                            <AdvertisingDescription />
                           
                            {/* معرفی بازار روستا */}
                            <Section containerStyle={{ padding:10}} contentStyle={{padding:"20px 0 40px 0"}} containerClassName="w3-padding-small" >
                                   
                                   <div className='w3-col l7 m12 w3-right'>
                                          <h3 style={{fontWeight:"bold"}}>ایجاد فروشگاه در ده وند</h3>
                                          <MyText bigFont isBlock className="w3-margin-right" >
                                                 {"شما می توانید با ایجاد فروشگاه در دهوند کالا و خدمات خود را در معرض دید اهالی روستای  قرار دهید "+
                                                        " و با دسته بندی و مدیریت محصولات خود به کار وکاسبی خود در سطح روستا رونق بهتری ببخشید."+"\n"}</MyText>
                                   </div>
                                   <div className='w3-col l4 m8 س12  w3-center'>
                                          <PhotoProvider>
                                                 <PhotoView src={require("./../../assets/images/mainPageImage/shop.png")}>
                                                 <img className=' ' src={require("./../../assets/images/mainPageImage/shop.png")} style={{width:"60%"}} />
                                                 </PhotoView>
                                          </PhotoProvider>
                                   </div>
                                   
                            </Section>
                            {/* ارتباط با ما */}
                            {/* <Section containerStyle={{ }} contentStyle={{padding:"0 0 50px 0"}} containerClassName="w3-padding-small" >
                                   <h3>ارتباط با ما</h3>
                            </Section> */}
                            <Section containerClassName="w3-teal w3-padding-small">
                                   <Footer />
                            </Section>

                     <ImageModal ref={modalSlideImage} />
       </ContentContainer>
      </ContentContainer>
       
   )
}

export default Home;
//=========================================================================================