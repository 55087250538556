//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
import {  View , ScrollView} from "react-native"
import { Alert, MyButton, MySlideShow, MyText, NoItem, PageContainer, ProfileImage ,ImageModal  } from '../../../globalComponents/components';
import Fetch from '../../../globalComponents/fetch';
import { connect } from 'react-redux';
import SurveyComponent from '../../../commonComponents/surveyComponent';
import { setToastMessage } from '../../../redux/toastMessage';
// import {useBackHandler} from "@react-native-community/hooks"
import { useNavigation } from '@react-navigation/native';
import Styles from '../../../styles';
import MdlImagePicker from '../../../commonComponents/mdlImagePicker';
import ExtendedActionButton from '../../../commonComponents/extendedActionButton';
import ChapterGoodsInfoPlaceholder from '../../../commonComponents/chapterGoodsInfoPlaceholder';
//-----------------------------------------------------------------------------------------------
const ChapterInfo= props =>{
//-----------------------------------GLOBAL VAR EREA
const {selectedChapter }=props.route.params   
const chapterId=selectedChapter.id
const navigation = useNavigation();
//-----------------------------------REF EREA
const fetchModal=useRef()
const alertModal=useRef()
const mdlImagePicker=useRef()
const imageModal=useRef()
//-----------------------------------STATE EREA
const [scrollViewY , setScrollViewY]=useState(0)
const [ fetchState , setFetchState ]=useState({ fetchFlag:true , errorFlag:false });
const [ surveyFetchState  ,setSurveyFetchState]=useState({fetchFlag:false , errorFlag:false})
const [selectedChapterInfo , setSelectedChapterInfo] =useState({
                                                                                                                id:0 ,
                                                                                                                chapterTypeName : "" , 
                                                                                                                writerAvatar :"" , // selectedChapter.writerAvatar ,
                                                                                                                title : "" , //selectedChapter.title,
                                                                                                                writerName:"" , //selectedChapter.writerName ,
                                                                                                                writerAboutMe:'' , 
                                                                                                                saveDate:"" ,  
                                                                                                                matn:'', 
                                                                                                                question:'',
                                                                                                                arrAnswer:[] ,  // [{  id , answer:'' , percent  }]
                                                                                                                selectedAnswerId:0 , 
                                                                                                                managerDescription:'' ,  
                                                                                                                arrImageFile:[] , //[   {    id , filename    }   ],
                                                                                                                commentable:0,
                                                                                                                likeCount:0 ,
                                                                                                                dislikeCount:0 , 
                                                                                                                isLiked:false , 
                                                                                                                commentCount:0
                                                                                                         })
//-----------------------------------USE_EFFECT EREA
useEffect(()=>{
       setTimeout(()=> getChapterInfo()   , 700 )
} , [])
//-----------------------------------FUNCTION EREA
const getChapterInfo=()=>{
       const objData={
              jwt : props.profileState.jwt ,
              chapterId , 
              chapterTypeName :selectedChapter.chapterTypeName
       }
       fetchModal.current.fetchData("user/getChapterInfo" , objData , 
                                                          {
                                                               onStart : ()=>setFetchState({fetchFlag:true , errorFlag:false}),
                                                               onError: ()=>setFetchState({fetchFlag:false , errorFlag:true}),
                                                               onSuccess : ResponseOfGetChapterInfo
                                                          } , false , false , false)
}
const ResponseOfGetChapterInfo = data =>{
       setFetchState({fetchFlag:false , errorFlag:false})
       setSelectedChapterInfo({...selectedChapterInfo ,...data})
}
//--------------------------------------------------
// useBackHandler(()=>{
//        onBackClick()
//        return true
// } )
const onBackClick=()=>navigation.goBack()
//--------------------------------------------------
const setMyAnswerInSurvey= answerId =>{
       const objData={
              jwt : props.profileState.jwt,
              chapterId ,
              answerId
       }
       fetchModal.current.fetchData("user/setMyAnswerInSurvey" , objData ,
                                                          {
                                                               onStart : ()=>setSurveyFetchState({fetchFlag:true , errorFlag:false}),
                                                               onError: ()=>setSurveyFetchState({fetchFlag:false , errorFlag:true}),
                                                               onSuccess : responseOfSetMyAnswerInSurvey ,
                                                               onBackFromErrorConfirm : ()=> setSurveyFetchState({fetchFlag:false , errorFlag:false})
                                                          })
}
const responseOfSetMyAnswerInSurvey= data => setSelectedChapterInfo(Object.assign({} , selectedChapterInfo , data ))
//--------------------------------------------------
const avatar = selectedChapterInfo.chapterTypeName==="BULLETIN"     ? require("./../../../assets/images/logo/logo.png")
                                                                                                         : selectedChapterInfo.writerAvatar ?  {uri : selectedChapterInfo.writerAvatar} :
                                                                                                                                                                        require("./../../../assets/images/act/imgDefManAvatar.jpg")
const chapterWriter= selectedChapterInfo.chapterTypeName==="BULLETIN" ? "دهیاری روستا" : selectedChapterInfo.writerName
//-----------------------------------------------------------------------------------------------
const [imageWidth , setImageWidth]=useState(0)
const onLayout = e => setImageWidth(e.nativeEvent.layout.width)
const onScrollFlatList = event =>  setScrollViewY(Math.floor(event.nativeEvent.contentOffset.y))
//-----------------------------------------------------------------------------------------------
   return(
       <PageContainer title="" 
                                subTitle={selectedChapterInfo.title.length<40 ? selectedChapterInfo.title : selectedChapterInfo.title.substring(0 , 40 )+"..."} 
                                showHeader={false} 
                                showBackIcon 
                                onBackIconClick={onBackClick}  
                                containerPadding={0} 
                                hasFlatList>
              <View style={{flexDirection:"row"  ,position:"absolute" , top:0 , right:0 , left:0 , zIndex:2 , alignItems:"center" , 
                                      padding:10 , backgroundColor:`rgba(255 , 255 , 255 , ${scrollViewY/200} )` , borderBottomWidth:1 , borderColor :`rgba(230 , 230 , 230 , ${scrollViewY/200} )` }}>
                     <MyButton onClick={onBackClick}
                                      title='' 
                                      iconName='arrow_forward' 
                                      buttonStyle={{backgroundColor:"transparent"}}
                                      buttonIconStyle={{color:"#909090"}}
                                      buttonTExtStyle={{}} />
                     <MyText isBold color={`rgba( 120 , 120 , 120 , ${scrollViewY/200} )`} style={{paddingRight:5 }} >
                                   {selectedChapterInfo.title.length<40 ? selectedChapterInfo.title : selectedChapterInfo.title.substring(0 , 40 )+"..."}
                     </MyText>
              </View>

              <View style={{flex:1 }} >
                     {
                            // نمایش لودینگ برنامه
                            fetchState.fetchFlag ? 
                            <View  style={{}}>
                                   <ChapterGoodsInfoPlaceholder />
                           </View>:null
                     }
                     {
                            // نمایش خطا در دریافت اطلاعات
                            fetchState.errorFlag ? 
                            <View  style={{marginTop:40}}>
                                   <NoItem visible  message={"در دریافت اطلاعات خطایی روی داده است!!!"+"\n"+
                                                                             "این مشکل ممکن است در نتیجه قطعی و یا کندی شبکه اینترنت شما باشد."} 
                                                               title="خطا در دریافت اطلاعات"
                                                               showTryAgainButton tryAgain={getChapterInfo} />
                           </View>:null
                     }
                     {
                            // نمایش اطلاعات سند و نظرسنجی در صورت وجود
                            selectedChapterInfo.id   ?  
                            <View style={{flex:1}}>
                                   <ScrollView onScroll={onScrollFlatList}    style={{backgroundColor:"#FFFFFF" ,flex:1 }}>
                                          {
                                                 selectedChapterInfo.arrImageFile.length ? 
                                                 <View onLayout={onLayout}  style={Object.assign({} , {height:imageWidth/1.4} , Styles.l2 , Styles.m12 , Styles.s12)}>
                                                        <MySlideShow arrImage={selectedChapterInfo.arrImageFile.map(imageFile => ({url : imageFile.filename}) )} 
                                                                             width="100%" height={"100%"} style={{}}   />
                                                 </View> : null
                                          }
                                          <View  style={{flexDirection:"row" , padding:16}}>
                                                 <ProfileImage source={avatar} width={45} style={{elevation:1}} />
                                                 <View style={{marginRight:10 , flex:1}}>
                                                        <MyText isBlock smallFont isBold color="#26a69a" style={{}} >{chapterWriter}</MyText>
                                                        <MyText isBlock tinyFont  color="#a9a9a9">{selectedChapterInfo.writerAboutMe ?selectedChapterInfo.writerAboutMe : "--------------"}</MyText>
                                                 </View>
                                          </View>
                                          <View style={{padding:16}} >
                                                 <MyText isBold color="#000"  >{selectedChapterInfo.title}</MyText>
                                                 <MyText smallFont  color="#757575" style={{marginTop:10}}  >{selectedChapterInfo.matn}</MyText>
                                          </View>

                                          {
                                                 selectedChapterInfo.question && selectedChapterInfo.arrAnswer.length ? 
                                                 <View  style={{padding:16, color:"gray"}}>
                                                        <SurveyComponent question={selectedChapterInfo.question}
                                                                                    arrAnswer={selectedChapterInfo.arrAnswer}
                                                                                    onSelectAnswer={setMyAnswerInSurvey} 
                                                                                    userAnswerSelectedId={selectedChapterInfo.selectedAnswerId}
                                                                                    showLoading={ surveyFetchState.fetchFlag } />
                                                 </View> : null
                                          }
                                   </ScrollView>
                                   <ExtendedActionButton iconName="mark_unread_chat_alt"  onClick={()=>navigation.navigate("chapterComment" , {
                                                                                                                                     chapterId:selectedChapterInfo.id,
                                                                                                                                     chapterTitle:selectedChapterInfo.title
                                                                                                                              })}
                                                                         title={selectedChapterInfo.commentCount ? "تعداد نظرات : "+selectedChapterInfo.commentCount : ""} />
                            </View>  : null
                     } 
              </View>
              {/* </ImageBackground> */}
              <MdlImagePicker  ref={mdlImagePicker}  />
              <ImageModal ref={imageModal} />
           <Fetch ref={fetchModal}/>
           <Alert ref={alertModal} />
       </PageContainer>
   )
}
//-----------------------------------------------------------------------------------------------
const mapStateToProps= state =>({
       profileState : state.profileReducer
})
const mapDispatchToProps= dispatch =>({
       setToastMessage : strMessage => dispatch(setToastMessage(strMessage))
})
export default connect(mapStateToProps , mapDispatchToProps)(ChapterInfo);
//==============================================================================================