//=============================================================================================================================================
const ADD_GOODS="ADD_GOODS"
const SET_SEARCH_GOODS="SET_SEARCH_GOODS"
const SET_SELECTED_GOODS_CATEGORY_ID="SET_SELECTED_GOODS_CATEGORY_ID"
const DELETE_GOODS_CATEGORY = "DELETE_GOODS_CATEGORY"

const SET_SELECTED_SHOP_CATEGORY_ID="SET_SELECTED_SHOP_CATEGORY_ID"

const REFRESH_GOODS="REFRESH_GOODS"
const ADD_UPDATE_GOODS="ADD_UPDATE_GOODS"
const DELETE_GOODS="DELETE_GOODS"
const ADD_SHOP = "ADD_SHOP"
const SET_SEARCH_SHOP="SET_SEARCH_SHOP"
const REFRESH_SHOP = "REFRESH_SHOP"
//=============================================================================================================================================
export const addGoods=(arrCategory ,arrShopCategory , arrGoodsState  , goodsInfo)=>({type:ADD_GOODS , bazaarInfo:{arrCategory , arrShopCategory ,  arrGoodsState  , goodsInfo} })
export const setSearchGoods = (strSearch) =>({type:SET_SEARCH_GOODS , strSearch})
export const setSelectedGoodsCategoryId= categoryId => ({type:SET_SELECTED_GOODS_CATEGORY_ID , categoryId})
export const deleteGoodsCategory = categoryId => ({type : DELETE_GOODS_CATEGORY , categoryId})

export const setSelectedShopCategoryId= categoryId => ({type:SET_SELECTED_SHOP_CATEGORY_ID , categoryId})

export const refreshGoods=()=>({type:REFRESH_GOODS })
export const addUpdateGoods= goodsInfo =>({type:ADD_UPDATE_GOODS , goodsInfo})
export const deleteGoods=goodsId =>({type:DELETE_GOODS , goodsId})

export const addShop=(shopInfo)=>({type : ADD_SHOP , shopInfo})
export const setSearchShop=(strSearch)=>({ type:SET_SEARCH_SHOP , strSearch })
export const refreshShop=()=>({type:REFRESH_SHOP})

//=============================================================================================================================================
const bazaarInit={
       arrGoodsState:[] , 
       arrCategory:[] , // [ {id  , name , avatar}   ] ,
       arrShopCategory:[]  ,
       selectedCategoryId: 0 , 
       selectedShopCategoryId:0 , 
       lastShowGoodsCategory:false , 
       lastShowShopCategory:false , 
       goodsInfo:{
              strSearch:'' ,
              arrGoods:[] , ////[{id , title , price , goodsStateId , saveDate , sellerName , sellerAvatar , goodsAvatar}]
              finished:false ,
              refresh:false,
       } ,
       shopInfo:{
              arrShop:[] , 
              finished:false ,
              refresh:false ,  
              strSearch:'' ,
       }
}

export default ( state = bazaarInit , action  )=>{
       switch(action.type){
              case ADD_GOODS : return {
                                                        ...state ,
                                                        arrGoodsState: action.bazaarInfo.arrGoodsState.length ? action.bazaarInfo.arrGoodsState  : state.arrGoodsState  , 
                                                        arrCategory : action.bazaarInfo.arrCategory.length ? action.bazaarInfo.arrCategory : state.arrCategory , 
                                                        arrShopCategory : action.bazaarInfo.arrShopCategory.length ? action.bazaarInfo.arrShopCategory : state.arrShopCategory , 
                                                        goodsInfo:{
                                                               ...state.goodsInfo , 
                                                               arrGoods : [...state.goodsInfo.arrGoods  , ...action.bazaarInfo.goodsInfo.arrGoods] ,
                                                               finished : action.bazaarInfo.goodsInfo.finished ,
                                                               refresh:false
                                                        }    
                                                 }
              case SET_SEARCH_GOODS : return { 
                                                        ...state ,
                                                        selectedCategoryId : 0 ,  
                                                        goodsInfo :{
                                                               ...state.goodsInfo , 
                                                               strSearch : action.strSearch , 
                                                               arrGoods:[] ,
                                                               finished:false ,
                                                               refresh:true,
                                                        }  
                                                 }
              case SET_SELECTED_GOODS_CATEGORY_ID :  return{
                                                                                    ...state ,
                                                                                    selectedCategoryId :  state.selectedCategoryId=== action.categoryId ? 0 : action.categoryId ,
                                                                                    goodsInfo:{
                                                                                           strSearch : "" ,
                                                                                           arrGoods:[] ,
                                                                                           finished:false ,
                                                                                           refresh:true,  
                                                                                    } 
                                                                             }    
              case DELETE_GOODS_CATEGORY : return    {
                                                                             ...state , 
                                                                             arrCategory: state.arrCategory.filter(category => category.id!==action.categoryId) , 
                                                                             selectedCategoryId  : state.selectedCategoryId===action.categoryId ? 0 : state.selectedCategoryId , 
                                                                             goodsInfo : state.selectedCategoryId===action.categoryId ? 
                                                                                                                                                                               {
                                                                                                                                                                                      strSearch : "" ,
                                                                                                                                                                                      arrGoods:[] ,
                                                                                                                                                                                      finished:false ,
                                                                                                                                                                                      refresh:true,  
                                                                                                                                                                               }  
                                                                                                                                                                        :  state.goodsInfo
              }                                                       
              case REFRESH_GOODS  : return {
                                                        ...state , 
                                                        goodsInfo : {
                                                               ...state.goodsInfo , 
                                                               arrGoods:[] ,
                                                               finished:false ,
                                                               refresh:true,  
                                                        }
                                                 }
              case ADD_UPDATE_GOODS : return{
                     ...state , 
                     goodsInfo:{
                            ...state.goodsInfo ,
                            arrGoods : state.goodsInfo.arrGoods.filter(goods => goods.id===action.goodsInfo.id).length ?
                                                                                                                                         state.goodsInfo.arrGoods.map(goods => goods.id===action.goodsInfo.id ? action.goodsInfo   : goods)
                                                                                                                                     : [action.goodsInfo , ...state.goodsInfo.arrGoods]
                     }
              }
              case DELETE_GOODS:return{
                     ...state , 
                     goodsInfo : {
                            ...state.goodsInfo , 
                            arrGoods : state.goodsInfo.arrGoods.filter(goods => goods.id!==action.goodsId)
                     }
              }


              case ADD_SHOP : return {
                                                        ...state , 
                                                        shopInfo : {
                                                               ...state.shopInfo , 
                                                               arrShop : [...state.shopInfo.arrShop , ...action.shopInfo.arrShop] ,
                                                               finished :action.shopInfo.finished , 
                                                               refresh:false
                                                        }
                                                 }
              case SET_SEARCH_SHOP : return {
                                                        ...state ,
                                                        shopInfo : {
                                                               ...state.shopInfo , 
                                                               arrShop:[] , 
                                                               finished:false , 
                                                               refresh:true ,
                                                               strSearch : action.strSearch
                                                        }
                                                 }
              case REFRESH_SHOP : return {
                                                        ...state , 
                                                        shopInfo : {
                                                               ...state.shopInfo , 
                                                               arrShop:[] , 
                                                               finished:false ,
                                                               refresh:true
                                                        }
                                                 }
              case SET_SELECTED_SHOP_CATEGORY_ID :  return{
                     ...state ,
                     selectedShopCategoryId : state.selectedShopCategoryId===action.categoryId  ? 0 : action.categoryId,
                     shopInfo:{
                            arrShop:[] , 
                            finished:false ,
                            refresh:true , 
                            strSearch:'' ,
                     }
              }     
              default:return state
       }
}