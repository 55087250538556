import React , {useEffect , useRef, useState} from 'react';
import { connect } from 'react-redux';
import { PageContainer , Alert, Confirm} from '../../../globalComponents/components'
import Fetch from '../../../globalComponents/fetch'
// import { useBackHandler } from '@react-native-community/hooks';
import { setToastMessage } from '../../../redux/toastMessage';
import ChatComponent from '../../../commonComponents/chatComponent';

import {socket} from "../../../socket"
//======================================================================================================================           
const ChapterComment = (props) =>{
    //--------------------------------------
    const { chapterId , chapterTitle }=props.route.params   
    //---------------------------------------
    // useBackHandler(()=>{
    //     onBackClick()
    //     return true
    // })
    const onBackClick=()=>props.navigation.goBack()
    //---------------------------------------
    const alertModal=useRef()
    const fetchModal=useRef()
    const confirmModal=useRef()
    //-----------------------------------------------------------------------------------
    const [commentInfo , setCommentInfo]=useState({arrMessage:[] , finished:false , refresh:false})
    const [ fetchState , setFetchState ]=useState({ fetchFlag:false , errorFlag:false });
    const [newComment , setNewComment]=useState(null)
    //------------------------------------------------------------------------------------  
    useEffect(()=>{
              socket.on("SELECT_CHAPTER_ROOM" ,  msg=>socket.emit("SELECT_CHAPTER_ROOM" , "CHAPTER_ID_"+chapterId)   )
              const setMessage= data =>  setNewComment(data) 
              socket.on("setMessage" ,  setMessage   )

              return ()=>{
                                        socket.off("SELECT_CHAPTER_ROOM" ,  msg=>socket.emit("SELECT_CHAPTER_ROOM" , "CHAPTER_ID_"+chapterId)   )
                                        socket.off("setMessage" ,  setMessage  )        
                                }
    } ,[])   
    useEffect(()=>{
        if(newComment){
            // RNBeep.PlaySysSound(RNBeep.AndroidSoundIDs.TONE_SUP_RADIO_ACK)
            newComment.hasAnimation=true
            newComment.isMyMessage = newComment.writerId===props.profileState.id ? true : false
            setCommentInfo({...commentInfo , arrMessage:[newComment , ...commentInfo.arrMessage]})
            setNewComment(null)
        }
    } , [newComment]) 
    useEffect(()=>{
        if(commentInfo.arrMessage.filter(message => message.hasAnimation).length){
            setTimeout(()=>setCommentInfo({   ...commentInfo ,  arrMessage:commentInfo.arrMessage.map(message => ({...message , hasAnimation:false}))    }) , 2000)
        }
    } , [commentInfo.arrMessage.length])                      
    //-----------------------------------------
    const getMessage=()=>{
        const objData={
            jwt : props.profileState.jwt ,
            chapterId , 
            lastCommentId : commentInfo.arrMessage.length ? commentInfo.arrMessage[commentInfo.arrMessage.length-1].id : 0
     }
     fetchModal.current.fetchData("user/getCommentList" , objData , 
                                                        {
                                                             onStart : ()=>setFetchState({fetchFlag:true , errorFlag:false}) , 
                                                             onError : ()=>setFetchState({fetchFlag:false , errorFlag:true}) , 
                                                             onSuccess:responseOfGetMessageList,
                                                             // onBackFromErrorConfirm:()=>setMessageFetchState({fetchFlag:false , errorFlag:false}) , 
                                                        } , false , true , true)
 }
 const responseOfGetMessageList=  data   =>{
    // alert(JSON.stringify(data))
       setFetchState({fetchFlag:false , errorFlag:false})
       for(let comment of data.arrComment)
            comment.isMyMessage = comment.writerId===props.profileState.id ? true : false
       setCommentInfo({ arrMessage : [...commentInfo.arrMessage , ...data.arrComment] , finished : data.finished, refresh:false })
 }
    //--------------------------------------
    const sendMessage=(newMessage /* {uuid , matn , arrFIle} */)=>{
        if(newMessage.matn.replace(/\n+/g , "\n").replace(/ +/g , " ").length<2){
            alertModal.current.show("خطا در ورود اطلاعات","متن پیام را با حداقل 5 حرف وارد نمایید","error")
            return
     }

     const arrNewImage=newMessage.arrFile.filter(file => typeof(file.id)==="string" )
                                                                   .map( (image ,index) => ({
                                                                                                                     name :"imageForComment"+index ,
                                                                                                                     filename:'imageForComment.esi'+image.id,
                                                                                                                     uri:image.uri ,//.replace("file:///" , "file://") ,
                                                                                                                     type:image.type,    
                                                                                                         }))
                                                                                                         

     // const arrNewImage = arrFile.length ?  arrFile.map( (file , index)=>({name:"imageForMessage"+index  , file}) ) : []

     const objData={
                                 chapterId , 
                                  comment:newMessage.matn.replace(/\n+/g , "\n").replace(/ +/g , " ") , 
                                  uuid:newMessage.uuid , 
                                  jwt:props.profileState.jwt ,
                         }

     fetchModal.current.uploadData( "user/setComment" ,
                                                             objData ,
                                                             arrNewImage,
                                                             {
                                                                    onSuccess : ()=>{} , 
                                                                    onStart:()=>{},
                                                                    onError:()=>{},
                                                             } , 
                                                             true ,true , true )  
    }
    // const responseOfSendMessage= data =>{
    //             // alert(JSON.stringify(data))
    //         data.hasAnimation=true
    //         data.isMyMessage = data.writerId===props.profileState.id ? true : false
    //         setCommentInfo({...commentInfo ,  arrMessage : [data , ...commentInfo.arrMessage ]} )
    //         if(data.isMyMessage)
    //                 props.setToastMessage("ثبت شما ثبت شد.")   
    // }
    //-----------------------------------------

    return(
        <PageContainer //title="بحث ها پیرامون گفتگو" 
                                 showBackIcon={true} 
                                 onBackIconClick={onBackClick} 
                                 backgroundColor="#e0f7fa"
                                subTitle={ chapterTitle.length>30 ? chapterTitle.substring(0, 30)+"..." : chapterTitle }
                                containerPadding={0} hasFlatList >
                            {/* نمایش خطا در دریافت اطلاعات  */}
                <ChatComponent  messageInfo={commentInfo}
                                          fetchState={fetchState}
                                          onGetMessage={getMessage} 
                                          onSendMessage={sendMessage}
                                          canSendmessage={true} 
                                          isMessageOrComment="COMMENT"
                                          myId={props.profileState.id}
                                          strErrorMessage={"در دریافت اطلاعات خطایی روی داده است!!!!."+"\n"+"این مشکل ممکن است در نتیجه کندی و یا قطعی اینترنت شما باشد."} 
                                          strNoMessage={"هیچ نظر و دیدگاهی ثبت نشده است!!!"+"\n\n"+
                                                                  "به عنوان اولین نفر , نظر و دیدگاه خود را در باره این گفتگو در کادر زیر وازد نمایید."}   />
                <Alert ref={alertModal} />
                <Fetch ref={fetchModal} />
                <Confirm ref={confirmModal} />
           </PageContainer>
    )
}
//==================================================================
const mapStateToProps=(state)=>({
    profileState:state.profileReducer,
})
const mapDispatchToProps=(dispatch)=>({
    setToastMessage : strMessage => dispatch(setToastMessage(strMessage))
})
export default connect(mapStateToProps , mapDispatchToProps)( ChapterComment );