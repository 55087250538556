//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
import { connect } from 'react-redux';
import GoodsListComponent from './GoodsListComponent';
import { View } from 'react-native';
import Fetch from "../../../globalComponents/fetch"
import { addGoods , refreshGoods, setSearchGoods, setSelectedGoodsCategoryId } from '../../../redux/bazaar';
import ShopGoodsCategoryComponent from './components/shopGoodsCategoryComponent';
import { useNavigation } from '@react-navigation/native';
import SearchComponent from '../../../commonComponents/searchComponent';
import { MyText } from '../../../globalComponents/components';
 
const CityGoodsList=(props)=>{
//-----------------------------------GLOBAL VAR EREA
const navigation=useNavigation()
const {goodsInfo , arrCategory , arrGoodsState  , selectedCategoryId    } = props.bazaarState
//-----------------------------------REF EREA
const fetchModal=useRef()
//-----------------------------------STATE EREA
const [ fetchState , setFetchState ]=useState({ fetchFlag:false , errorFlag:false });
const [strSearch , setStrSearch]=useState("")
//-----------------------------------USE_EFFECT EREA
useEffect(()=>{
       if((!goodsInfo.arrGoods.length && !goodsInfo.finished) || goodsInfo.refresh)  
              getGoodsList() 
} , [goodsInfo.refresh])
useEffect(()=>{
       if(goodsInfo.strSearch)
              setStrSearch(goodsInfo.strSearch)
} , [])
//-----------------------------------FUNCTION EREA
const getGoodsList=async ()=>{
       const objData={
              jwt : props.profileState.jwt,
              lastGoodsId: goodsInfo.arrGoods.length ? goodsInfo.arrGoods[goodsInfo.arrGoods.length-1].id : 0 ,
              categoryId : selectedCategoryId ,
              strSearch : goodsInfo.strSearch , 
              shopId:0  ,
              showMyGoods : false ,
              mustGetCategoryAndState: arrCategory.length ? false :  true
          }
          await fetchModal.current.fetchData("user/getGoodsList" ,objData , 
                                                          {
                                                                  onStart : ()=>setFetchState({fetchFlag : true , errorFlag : false}) ,
                                                                  onError : ()=>setFetchState({fetchFlag : false , errorFlag : true}) ,
                                                                  onSuccess : responseOfGetGoods,
                                                                  onBackFromConfirmError : ()=>setFetchState({fetchFlag : false , errorFlag : false}) ,
                                                          } , 
                                                          false ,
                                                          goodsInfo.arrGoods.length ? true : false ,
                                                          true
                                                              )
}
const responseOfGetGoods=data => {
       // alert(JSON.stringify(data))
       setFetchState({fetchFlag:false , errorFlag:false})
       props.addGoods(data)
}
//--------------------------------------------------
const onRefresh=()=>props.refreshGoods()
//--------------------------------------------------
const onSelectCategoryId= categoryId =>   props.setSelectedCategoryId(categoryId)
//---------------------------------------------------
const gotoGoodsInfo=(selectedGoodsInfo)=>{
       navigation.navigate( "goodsInfo" , {
                                                                             goodsId:selectedGoodsInfo.id  , 
                                                                             shopId:0 , 
                                                                             selectedGoodsInfo 
                                                               }) 
}
//--------------------------------------------------
const onChangeText=(txtId , newText)=>  setStrSearch(newText ? newText : "")
const onSearchButtonClick=()=>{
       const str= strSearch ? strSearch.replace(/ +/g , " ") : ""
       props.setSearchGoods(str)
       setStrSearch(str)
}
//--------------------------------------------------
const headerComponent=()=>{
       return <ShopGoodsCategoryComponent arrCategory={arrCategory} 
                                                                  containerStyle={{}} 
                                                                  onSelectCategory={onSelectCategoryId}
                                                                  selectedCategoryId={selectedCategoryId}   />
}

   return(
      <View style={{flex:1}}>
       <View style={{paddingHorizontal:5}}>
              <SearchComponent onChangeText={onChangeText} value={strSearch} onSearchButtonClick={onSearchButtonClick} hideSearchButton={strSearch===goodsInfo.strSearch} />
       </View>
        <GoodsListComponent  isMyGoods={false}
                                          shopId={0}
                                          headerComponent={headerComponent}
                                          fetchState={fetchState}
                                          goodsInfo={goodsInfo} 
                                          arrCategory={arrCategory} 
                                          arrGoodsState={arrGoodsState}
                                          selectedCategoryId={selectedCategoryId}
                                          strSearch={goodsInfo.tempStrSearch}
                                          getGoods={getGoodsList}
                                          onRefresh={onRefresh}
                                          onSelectGoods={gotoGoodsInfo}
                                          setSelectedCategoryId={onSelectCategoryId}  /> 
       <Fetch ref={fetchModal} />
      </View>
   )
}

const mapStateToProps= state => ({
       profileState : state.profileReducer ,
       bazaarState : state.bazaarReducer ,
   })
   const mapDispatchToProps= dispatch =>({
              refreshGoods : ()=>dispatch(refreshGoods()), 
              setSelectedCategoryId : categoryId => dispatch(setSelectedGoodsCategoryId(categoryId)) ,
              addGoods :   data  =>  dispatch(addGoods(data.arrCategory,data.arrShopCategory ,data. arrGoodsState , data.goodsInfo)) ,
              setSearchGoods : strSearch => dispatch(setSearchGoods(strSearch))
   })
   export default connect(mapStateToProps , mapDispatchToProps)(CityGoodsList);
//=========================================================================================