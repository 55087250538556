import { Dimensions , View } from "react-native"
import { MySkeleton } from "../../../../globalComponents/components"

const ShopInfoPlaceholder= ()=>{
       const avatarPanelWidth= Dimensions.get("window").width
       return (
              <View  style={{}}>
                     <MySkeleton style={{padding:10 }}>
                                   <View style={{flexDirection:"row-reverse" , marginTop:10 , alignItems:"center"}}  >
                                          <View style={{width:avatarPanelWidth/3.5 , height:avatarPanelWidth/3.5 , padding:10}} >
                                                 <View  style={{width:"100%" , height:"100%" , borderRadius:avatarPanelWidth}} width="100%" height="100%"  />
                                          </View>   
                                          
                                          <View style={{flex:1 ,padding:0 , margin:10 }}>
                                                 <View  style={{width:"100%" , height:25 , borderRadius:10 , marginTop:5}} />
                                                 <View  style={{width:"100%" , height:25 , borderRadius:10 , marginTop:5}} />
                                                 <View  style={{width:"100%" , height:25 , borderRadius:10 , marginTop:5}} />   
                                          </View>
                                   </View>
                                   <View style={{paddingHorizontal:10}}>
                                          <View style={{width:"100%" , height:75 ,borderRadius:10 }} /> 
                                   </View>

                                   <View style={{paddingHorizontal:10 , marginTop:10}}>
                                          <View style={{width:"100%" , height:75 ,borderRadius:10 }} /> 
                                   </View>

                                   <View style={{paddingHorizontal:10 , marginTop:10}}>
                                          <View style={{width:"100%" , height:75 ,borderRadius:10 }} /> 
                                   </View>

                     </MySkeleton>
              </View>
       )
}

export default ShopInfoPlaceholder