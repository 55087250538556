//========================================================================================
import React,{ useState }  from 'react'
import { View , ScrollView , TouchableOpacity, Dimensions ,  } from 'react-native';
import { MyButton , MyText , MyImage  } from '../../../../globalComponents/components';
 

const CategoryComponent= props =>{
    const {id , name , avatar , onSelectCategory=()=>{} , onLongSelectCategory=()=>{} , isUserShopCategory=false , selectedCategoryId=0 , disabled=false , buttonTextStyle={} , panelWidth}  =props
    const imdDef= require("./../../../../assets/images/act/imgDefImage.png")
    return(
            <TouchableOpacity onPress={()=>onSelectCategory(id)} 
                                           onLongPress={()=>onLongSelectCategory(id)} 
                                           style={{paddingHorizontal:2  ,width:panelWidth , marginBottom:isUserShopCategory ?  0 : 5   }} 
                                           disabled={disabled} >
                       <View style={{justifyContent:"center" , alignItems:"center"}}>
                                <View style={{flexDirection:"row-reverse" , justifyContent:"center" , alignItems:"center" , backgroundColor:"transparent" , backgroundColor:"#eceff1" , borderRadius:40 , 
                                                        padding:isUserShopCategory ? 2 : 8 ,
                                                        width:panelWidth/1.5 , height:panelWidth/1.5 ,overflow:"hidden" ,  elevation:1 ,
                                                        borderColor:isUserShopCategory ? "#cfd8dc" : "transparent"  ,
                                                        borderWidth:isUserShopCategory ? 3 : 0 ,
                        
                                                        


                                                        opacity :!selectedCategoryId || selectedCategoryId===id ? 1 : 0.1 }}>
                                    <MyImage  resizeMode="cover" source={avatar ? {uri:  avatar } : imdDef} style={{width:"100%", 
                                                                                                                                                                      height:"100%" , 
                                                                                                                                                                      
                                                                                                                                                                      borderRadius:isUserShopCategory ? 100  : 0   }}   />

                                </View>
                                <MyText isBold={selectedCategoryId===id}  tinyFont color={"#456e7a"}
                                            style={Object.assign({} , {marginTop:4 , textAlign:"center" , 
                                                                                        opacity :!selectedCategoryId || selectedCategoryId===id ? 1 : 0.1 , transform: [{ scaleX: -1 }]} , buttonTextStyle)}>{name}</MyText>
                                                                                
                                {
                                    selectedCategoryId===id ? 
                                    <View style={{position:"absolute" , top:0 , right:0 , width:20 , height:20 , borderRadius:20 , borderWidth:1,
                                                        borderColor:"#e0e0e0" , backgroundColor:"#FFF" , padding:2 , justifyContent:"center" ,alignItems:"center"}}>
                                        <View style={{width:"100%" , height:"100%" , borderRadius:10 , backgroundColor:"#26c6da"}} />
                                    </View> : null
                                }
                       </View>
            </TouchableOpacity>
    )
}
const CategoryContainer=props =>{
    const { isUserShopCategory } = props
    if(isUserShopCategory)
        return <ScrollView horizontal style={{ paddingBottom:6  ,transform: [{ scaleX: -1 }], flexDirection:"row-reverse" , flexWrap:"wrap"  }} 
                                       contentContainerStyle={{direction:"revert"}} 
                                       showsHorizontalScrollIndicator={false} >
                    {
                        props.children
                    }
                    </ScrollView>
        else
        return <View style={{ paddingBottom:6  ,transform: [{ scaleX: -1 }], flexDirection:"row-reverse" , flexWrap:"wrap"  }} 
                            contentContainerStyle={{direction:"revert"}} 
                            showsHorizontalScrollIndicator={false} >
                        {
                            props.children
                        }
                    </View>
}
const ShopGoodsCategoryComponent=(props)=>{
    //-----------------------------------GLOBAL VAR EREA
    const {arrCategory=[] , selectedCategoryId=0 , showTitle=false , onSelectCategory=()=>{} ,categoryPanelWidth=Dimensions.get("window").width , isUserShopCategory=false , 
               canAddUpdateCategory=false , onAddCategory=()=>{} , onLongSelectCategory=()=>{} , 
               containerStyle={} , buttonTextStyle={} , buttonImageStyle={} , buttonTextColor="#FFF" , showInHorizontalScrollView=false } = props
    //-----------------------------------REF EREA
    //-----------------------------------STATE EREA
    //-----------------------------------USE_EFFECT EREA
    const imagePanelWidth =Math.floor(categoryPanelWidth/5)
   return(
        <View   style={Object.assign({} , { paddingTop:10 , backgroundColor:"#FFF" ,  marginBottom:15   } , containerStyle)}>
            {
                showTitle ? <MyText tinyFont style={{marginBottom:7}} > دسته بندی های فروشگاه</MyText> : null
            }
                <CategoryContainer  isUserShopCategory={isUserShopCategory}  >
                    {
                        arrCategory.map(category =><CategoryComponent  key={category.id}  panelWidth={imagePanelWidth} 
                                                                                                        isUserShopCategory={isUserShopCategory}
                                                                                                        showInHorizontalScrollView={showInHorizontalScrollView}
                                                                                                        {...category} 
                                                                                                        buttonTextStyle={buttonTextStyle}
                                                                                                        buttonTextColor={buttonTextColor}
                                                                                                        onSelectCategory={onSelectCategory} 
                                                                                                        onLongSelectCategory={onLongSelectCategory}
                                                                                                        selectedCategoryId={selectedCategoryId} />)
                    }
                    {
                        canAddUpdateCategory ? 
                        <View style={{marginLeft:10  , justifyContent:"space-between" }}>
                            <View style={{borderRadius:50 , width:56 , height:56 , padding:3 , borderWidth:1 , borderColor:"#e0e0e0" }}>
                                <MyButton onClick={onAddCategory}
                                                title='' 
                                                iconName='add' 
                                                buttonStyle={{borderRadius:40 , backgroundColor:"#e0e0e0" , height:"100%" , width:"100%"}}
                                                buttonIconStyle={{color:"#FFF" , fontSize:40}}
                                                buttonTExtStyle={{}} />
                            </View>
                        </View> :null

                    }
                </CategoryContainer>
    </View>
   )
}
export default ShopGoodsCategoryComponent;
//=========================================================================================