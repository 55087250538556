//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
import {View} from "react-native-web"
import { PageContainer  , MyText , MyInput , Alert ,MyImage, Waiting} from '../../globalComponents/components';
import Fetch from '../../globalComponents/fetch';
import { connect } from 'react-redux';
import { setToastMessage } from '../../redux/toastMessage';
import { isValidMobilNumber, isValidNumberString } from '../../globalComponents/rejax';
import imgLogoNameHoriz from "./../../assets/images/logo/logoNameHoriz.png"

import Styles from '../../styles';

const GetPhoneNumber=(props)=>{
    const fetchModal=useRef()
    const alertModal=useRef()

    const [imageWidth , setImageWidth]=useState(10)
    //----------------------------------------------------------------------------------
    const cityId=1 //در حال حاضر به صورت پیش فرض روستا فقط کورده است
    //----------------------------------------------------------------------------------
    const [phone , setPhone]=useState("")
    const [fetchState , setFetchState]=useState({ fetchFlag: false , errroFlag:false })
    //---------------------------------------------------------------------------------
    useEffect(()=>{
        if(phone.length===11)
            checkPhoneAndGetActivationCode()
    } , [phone])
    //---------------------------------------------------------------------------------
    const changePhone=(txtId , newText)=> {
        if(newText)
            {
                if(isValidNumberString(newText))
                    setPhone(newText  )
            }
        else
            setPhone("")
    }
    //---------------------------------------------------------------------------------
    const checkPhoneAndGetActivationCode=()=>{
        if(!isValidMobilNumber(phone)){
            alertModal.current.show("خطا در ورود اطلاعات",
                                                        "شماره موبایل وارد شده نادرست است!!!.",
                                                    "error")
            return
        }
        const objData={
            cityId , 
            phone:phone ,
            role:"USER"
        }

        fetchModal.current.fetchData("getActivationCode" , 
                                                            objData ,
                                                             {
                                                                    onStart:()=>setFetchState({fetchFlag:true , errroFlag:false}),
                                                                    onError:()=>setFetchState({fetchFlag:false , errroFlag:true}),
                                                                    onSuccess:responseOfCheckPhone ,
                                                                    onBackFromConfirmError:()=>setFetchState({fetchFlag:false , errroFlag:false})
                                                            } , false , true , true )
    }
    const responseOfCheckPhone=(data)=>{
        setFetchState({fetchFlag:false , errroFlag:false})
        if(data==="ACTIVATION_CODE_SENDED")
            props.navigation.replace("getActivationCode" ,{ phone , cityId}  )
    }
    //---------------------------------------------------------------------------------
    const onLayout=(event)=> {
        const {x, y, height, width} = event.nativeEvent.layout;
        setImageWidth(width)
      }
    //----------------------------------------------------------------------------------
   return(   
                <PageContainer title="ورود به برنامه"
                                        headerColor="#FFFFFF" 
                                        headerTextColor="#456e7a" 
                                        backgroundColor="#FFFFFF" 
                                        showHeader={false} animation="slideInRight" hasFlatList >
                    <View style={Styles._pages._getPhoneNumber.container}>
                        <View  style={Styles._pages._getPhoneNumber.containerUp}>
                             <View  onLayout={onLayout} style={Object.assign({} , Styles.l3 , Styles.m4  , Styles.s7  , {} )}>
                                <MyImage source={imgLogoNameHoriz}  style={{width:"100%"  , height:Math.floor(imageWidth/3.3)}} resizeMode="cover" />
                            </View> 
                            <MyText   style={{marginTop:16}}>شماره تماس خود را وارد نمایید </MyText>
                        
                            <MyInput value={phone} 
                                            id="phone"
                                            placeholder=""
                                            iconName="call"
                                            iconColor="#e57373"
                                            textAlign="center"
                                            maxLength={11}
                                            keyboardType="numeric"
                                            autoFocus
                                            bigFont={phone ? true : false}
                                            smallFont={phone ? false : true}
                                            disabled={fetchState.fetchFlag}
                                            containerStyle={{width:250  , marginTop:35 , borderRadius:20 , borderWidth:1 , padding:5     }}
                                            inputStyle={{textAlign:"center" ,fontSize:14 }} 
                                            onChangeText={changePhone} />
                        </View>
                        <View  style={Styles._pages._getPhoneNumber.containerDown}>
                                    <Waiting visible={fetchState.fetchFlag && !fetchState.errroFlag} />
                        </View>
                    </View>
                    <Fetch ref={fetchModal} />
                    <Alert ref={alertModal} />
            </PageContainer>
   )
}

const mapStateToProps=(state)=>({
    profileState:state.profileReducer
})
const mapDispatchToProps=(dispatch)=>({
    setToastMessage : strMessage => dispatch(setToastMessage(strMessage))
})
export default connect(mapStateToProps , mapDispatchToProps) (GetPhoneNumber);
//=========================================================================================