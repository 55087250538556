//========================================================================================
import React,{ useState, useImperativeHandle, forwardRef}  from 'react'
import { ModalContainer  , MyButton, MyImage, MyText} from './../../../globalComponents/components'
import { View } from 'react-native'
import { formatNumber } from '../../../globalComponents/rejax'
 
const MdlActOnGoods=forwardRef((props , ref)=>{
//-----------------------------------GLOBAL VAR EREA
const {arrActButton} = props
//-----------------------------------REF EREA
const [visible , setVisible]=useState(false)
const [goodsInfo , setGoodsInfo]=useState({
                                                                              id:0 , 
                                                                              title:'' , 
                                                                              price:"" , 
                                                                              saveDate:'' , 
                                                                              description:'' , 
                                                                              arrImageFile:[]

                                                                        })
//-----------------------------------USE_EFFECT EREA
useImperativeHandle(ref , ()=>({
            show : _goodsInfo =>{
                                                   setGoodsInfo(_goodsInfo)
                                                   setVisible(true)
                                          }
}))
//-----------------------------------FUNCTION EREA
const hide=()=>setVisible(false)
const onSelectActButton= actButton =>{
       hide()
       actButton.onClick(goodsInfo)
}
//--------------------------------------------------
const avatar= goodsInfo.arrImageFile.length ? {uri: goodsInfo.arrImageFile[0].filename } : require("./../../../assets/images/act/imgDefImage.png")
   return(
                   <ModalContainer visible={visible}
                                              title=''
                                              message=''
                                              showTitle={false}
                                              showBackButton={true}
                                              onBackClick={hide}
                                              containerStyle={{padding:0}}
                                              contentStyle={{borderRadius:0 , width:"100%"}}
                                              contentClassName=''
                                              closeWithClickBlankPlace 
                                              position='bottom'
                                              animation='slideInUp' >
                                                <View style={{padding:10 , backgroundColor:"#eceff1" , borderRadius:10  , marginBottom:20 , flexDirection:"row-reverse"}}>
                                                   <View style={{flex:1,  marginRight:10}}>
                                                      <MyText isBold tinyFont color="#29b6f6">عنوان کالا : </MyText>
                                                      <MyText isBold  tinyFont color="#e57373" >{goodsInfo.title}</MyText>
                                                      <MyText tinyFont color="#9a9a9a">{`قیمت :  ${goodsInfo.price ? `${formatNumber(goodsInfo.price)}  تومان` :"تماس بگیرید"}`}</MyText>
                                                   </View>
                                                   <View>
                                                      <MyImage source={avatar} style={{width:60 , height:60 , borderRadius:60}} />
                                                   </View>
                                       
                                                </View>
                                               {
                                                 arrActButton.map(actButton => <MyButton   key={actButton.id} smallFont
                                                                                                                      onClick={()=>onSelectActButton(actButton)}
                                                                                                                       title={actButton.title} 
                                                                                                                       iconName={actButton.iconName} 
                                                                                                                       buttonStyle={{backgroundColor:"transparent" , borderBottomWidth:1 , borderColor:"#e0e0e0" , 
                                                                                                                                                justifyContent:"flex-end" , flexDirection:"row-reverse" , paddingVertical:6}}
                                                                                                                       buttonIconStyle={{color:"#e57373"}}
                                                                                                                       buttonTextStyle={{color:"#456e7a"}} />)
                                               }                   
                 </ModalContainer>
   )
})

export default MdlActOnGoods;
//=========================================================================================