//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
import {FlatList, RefreshControl , View  ,TouchableOpacity, Dimensions} from "react-native"
import { Alert, Confirm , Waiting , NoItem , MyText  , MyImage } from '../../../globalComponents/components';
import Fetch from "../../../globalComponents/fetch"
import { connect } from 'react-redux';
import { addShop, setSelectedShopCategoryId , refreshShop, setSearchShop  } from '../../../redux/bazaar';
import defImage from "../../../assets/images/act/imgDefImage.png"
import GoodsShopPlaceholder from '../../../commonComponents/goodsShopPlaceholder';
import {useNavigation} from "@react-navigation/native"
import logoImage from "../../../assets/images/logo/logo.png"
import ShopGoodsCategoryComponent from './components/shopGoodsCategoryComponent';
import Styles from '../../../styles';
import SearchComponent from '../../../commonComponents/searchComponent';
//-----------------------------------------------------------------------------------------------
const ShopComponent=props =>{
    const {id , shopAvatar , name , sellerName    ,onClick , phone , isExpired , categoryName  } =props

    const [imageWidth , setImageWidth ]=useState(0)
    const onLayout=e => setImageWidth(e.nativeEvent.layout.width)

    return (
            <TouchableOpacity  style={{  }} onPress={()=>onClick(id)} disabled={isExpired} >
                <View  style={{borderBottomWidth:1 , borderColor:"#eeeeee" ,  padding:2 , paddingHorizontal:10 , paddingBottom:6 , flexDirection:"row-reverse" ,   }} >
                        <View onLayout={onLayout}   style={Object.assign({} ,{height:imageWidth , borderRadius:10 , alignItems:"flex-end" }, Styles.l1 , Styles.m2 , Styles.s3)} >
                            <MyImage  source={shopAvatar ? {uri:shopAvatar} : defImage }  style={{width:imageWidth , height:"100%" , borderRadius:5}} />
                        </View>
                        
                        <View  style={{padding:5 , paddingRight:10 , flex:1  }}>
                            <View style={{justifyContent:"space-between" , flex:1}}>
                                    <MyText  isBold color="#000"   style={{}}>{name}</MyText>
                                    <MyText  tinyFont    style={{}}>{`سرپرست  :  ${sellerName}`}</MyText> 
                                   <View>
                                        <MyText isBold  tinyFont color="#e57373"   style={{}}>{`شماره تماس :  ${phone}`}</MyText> 
                                        <MyText  isBold tinyFont color="#9a9a9a"    style={{}}>{`دسته بندی :  ${categoryName}`}</MyText>   
                                   </View>
                            </View>
                        </View>
                        {
                            isExpired ? 
                            <View style={{position:"absolute" , top:0 , right:0 , left:0 , bottom:0 , backgroundColor:"rgba(255,255,255,0.6)" , flexDirection:"row" , padding:2 }}>
                                <View style={{width:imageWidth , height:"100%"}}/>
                                <View style={{ flex:1 , paddingHorizontal:10 , justifyContent:"center" , alignItems:"center"}}>
                                        <MyText smallFont isBold color="#e57373" style={{ textAlign:"center" , backgroundColor:"#FFFFFF" }}>{"این فروشگاه به صورت موقت"+"\n"+
                                                                                                                                                                                                                "غیرفعال شده است"}</MyText>
                                </View>
                            </View> : null
                        }
                </View>
            </TouchableOpacity>
    )
}
const ShopListComponent=(props)=>{
        //-----------------------------------------------------------------------------------------------GLOBAL VAR EREA
        const navigation=useNavigation()
        const {shopInfo  , arrShopCategory , selectedShopCategoryId} = props.bazaarState
        //----------------------------------------------------------------------------------------------REF EREA
        const alertModal=useRef()
        const confirmModal=useRef()
        const fetchModal=useRef()
        //----------------------------------------------------------------------------------------------STATE EREA
        const [ fetchState , setFetchState ]=useState({ fetchFlag:false , errorFlag:false });
        const [pageWasLoaded , setPageWasLoaded]=useState( false)

        const [strSearch , setStrSearch]=useState("")
        //----------------------------------------------------------------------------------------------USE_EFFECT EREA
        useEffect(()=>{
            setTimeout(()=>setPageWasLoaded(true) , 100)
        } , [])
        useEffect(()=>{
            if(shopInfo.strSearch)
                setStrSearch(shopInfo.strSearch)
        } , [])
        useEffect(()=>{
            if(pageWasLoaded){
                if(shopInfo.refresh || ( !shopInfo.finished && !shopInfo.arrShop.length ) )
                    getShopList()
            } 
            else{
                if( !shopInfo.finished && !shopInfo.arrShop.length )
                        setFetchState({fetchFlag:true , errorFlag:false})
            }
        } , [shopInfo.refresh , pageWasLoaded])
        //----------------------------------------------------------------------------------------------FUNCTION EREA
        const getShopList=()=>{
            const objData={
                jwt : props.profileState.jwt,
                lastShopId: shopInfo.arrShop.length ? shopInfo.arrShop[shopInfo.arrShop.length-1].id : 0 ,
                strSearch : shopInfo.strSearch ,
                categoryId : selectedShopCategoryId , 
            }
            fetchModal.current.fetchData("user/getShopList" ,objData , 
                                                            {
                                                                    onStart : ()=>setFetchState({fetchFlag : true , errorFlag : false}) ,
                                                                    onError : ()=>setFetchState({fetchFlag : false , errorFlag : true}) ,
                                                                    onSuccess : responseOfGetShop,
                                                                    onBackFromConfirmError : ()=>setFetchState({fetchFlag : false , errorFlag : false}) ,
                                                            } , 
                                                            false ,
                                                            shopInfo.arrShop.length ? true : false ,
                                                            true
                                                                )
        }
        const responseOfGetShop= data =>{
            setFetchState({fetchFlag : false , errorFlag : false}) 
            props.addShop(data)
        }
        //----------------------------------------------------------------------------------------------
        const initDataToShow=()=> shopInfo.arrShop
        
        //----------------------------------------------------------------------------------------------
        const getSelectedShopInfo= shopId  =>  navigation.navigate("shopInfo" , {   shopId   })
        //----------------------------------------------------------------------------------------------
        const onSelectCategory= categoryId => props.setSelectedShopCategoryId(selectedShopCategoryId===categoryId ? 0 :  categoryId)
        //-----------------------------------------------------------------------------------------------
        const loadingComponent=()=>{
                                                        if(fetchState.fetchFlag && !fetchState.errorFlag){
                                                                if(!shopInfo.arrShop.length )
                                                                return <View style={{padding:5}}>
                                                                                <GoodsShopPlaceholder  />
                                                                                <GoodsShopPlaceholder />
                                                                                <GoodsShopPlaceholder />
                                                                            </View>
                                                                else
                                                                return <Waiting  visible={fetchState.fetchFlag} 
                                                                                            flexDirection="column" 
                                                                                            backgroundColor="#ffffff" 
                                                                                            marginTop={30}
                                                                                            color="#546e7a" />
                                                        }
        }
        const errorEmptyComponent=()=>{
                        if(!fetchState.fetchFlag){
                                if( fetchState.errorFlag && !shopInfo.arrShop.length && !shopInfo.finished )
                                        return <View style={{flex:1 , flexDirection:"row-reverse" , alignItems:"center" , justifyContent:"center"}}>
                                                            <NoItem visible={true} title="خطا در دریافت اطلاعات" 
                                                                        message={"در دریافت اطلاعات خطایی روی داده است!!!!"+"\n"+
                                                                            "این مشکل ممکن است در نتیجه قطعی و یا کندی شبکه اینترنت شما باشد"}
                                                                        showTryAgainButton={true} tryAgain={getShopList} />
                                                    </View> 
                                if(!fetchState.fetchFlag && !fetchState.errorFlag && !shopInfo.arrShop.length && shopInfo.finished)
                                        return <View style={{flex:1 , flexDirection:"row-reverse" , alignItems:"center" , justifyContent:"center"}}>
                                                            <NoItem visible={true} title="گزارش برنامه" contentStyle={{boxShadow:"0 0 0 0 #FFFFFF"}}
                                                                        icon={logoImage}
                                                                        message={shopInfo.strSearch ? "جستجوی نتیجه ای نداشت!!!" : "هیچ فروشگاهی جهت نمایش یافت نشد!!!!"} 
                                                                        // showTryAgainButton={true} tryAgain={getShopList} 
                                                                        />
                                                    </View>
                                if(!fetchState.fetchFlag && pageWasLoaded)
                                        return <NoItem visible={true} title="گزارش برنامه" contentStyle={{boxShadow:"0 0 0 0 #FFFFFF"}}
                                                                icon={logoImage}
                                                                message={ "جستجوی نتیجه ای نداشت!!!" }  />
                        }
                        else 
                        return null
        }
        //---------------------------------------------------------------------------------------------
        const onChangeText=(txtId , newText)=>  setStrSearch(newText ? newText : "")
        const onSearchButtonClick=()=>{
            const str= strSearch ? strSearch.replace(/ +/g , " ") : ""
            props.setSearchShop(str)
            setStrSearch(str)
        }
        //-----------------------------------------------------------------------------------------------
        return(
            <View  style={{   flex:1 }}  >
                <View style={{paddingHorizontal:10}}>
                        <SearchComponent onChangeText={onChangeText} value={strSearch} onSearchButtonClick={onSearchButtonClick}  hideSearchButton={strSearch===shopInfo.strSearch} />
                </View>
                <ShopGoodsCategoryComponent arrCategory={arrShopCategory} 
                                                                onSelectCategory={onSelectCategory} 
                                                                selectedCategoryId={selectedShopCategoryId} />
                <FlatList data={ initDataToShow()} contentContainerStyle={{}}
                                        style={{flex:1 ,}}
                                        keyExtractor={(item , index)=>index.toString()}
                                        refreshing={shopInfo.refresh && fetchState.fetchFlag && shopInfo.arrShop.length }
                                        onEndReachedThreshold={0.15}
                                        onEndReached={ !shopInfo.finished &&  shopInfo.arrShop.length &&   pageWasLoaded   ? getShopList :  ()=>{}   }
                                        ListFooterComponent={loadingComponent()}
                                        ListEmptyComponent={errorEmptyComponent()}     
                                        refreshControl={ <RefreshControl refreshing={false}     onRefresh={props.refreshShop} />}             
                                        renderItem={({item})=><ShopComponent     {...item} 
                                                                                                           categoryName={props.bazaarState.arrShopCategory.filter(category => category.id===item.categoryId).length ?
                                                                                                                                   props.bazaarState.arrShopCategory.filter(category => category.id===item.categoryId)[0].name : ""
                                                                                                           }  
                                                                                                            onClick={getSelectedShopInfo} />}    />
                <Fetch ref={fetchModal}/>
                <Alert ref={alertModal}/>
                <Confirm ref={confirmModal}/>
            </View>
        )
}
//--------------------------------------------------------------------------------------------------
const mapStateToProps= state => ({
    profileState : state.profileReducer ,
    bazaarState : state.bazaarReducer,
})
const mapDispatchToProps= dispatch =>({
    addShop : (shopInfo) =>dispatch(addShop(shopInfo)) ,
    refreshShop : ()=>dispatch(refreshShop()) , 
    setSelectedShopCategoryId : categoryId => dispatch(setSelectedShopCategoryId(categoryId)) ,
    setSearchShop : strSearch => dispatch(setSearchShop(strSearch))
})
export default connect(mapStateToProps , mapDispatchToProps)(ShopListComponent);
//==================================================================================================