//========================================================================================
import React,{useRef , useEffect  , useState, forwardRef, useImperativeHandle}  from 'react'
import { Dimensions, View } from 'react-native';

import {  MyButton, MyText  , Alert ,Confirm , NoItem , MyIcon , MySkeleton, PageContainer} from './../../../../globalComponents/components';
// import { useBackHandler } from '@react-native-community/hooks';
import { connect } from 'react-redux';
import { myShopSetInfo ,  myShopResetInfo , myShopUpdateInfo  } from '../../../../redux/myBazaar';
import Fetch from "../../../../globalComponents/fetch"
import { setToastMessage } from '../../../../redux/toastMessage';
import JDate from "jalali-date"
import MdlWarningOnSave from './mdlWarningOnSave';
import MdlMembershipPriceSelect from './mdlMembershipPriceSelect';
import MdlFactor from '../../../../commonComponents/mdlFactor';
import { isValidPhoneNumber } from '../../../../globalComponents/rejax';
import ShopProduct from './shopProduct';
import MdlAddUpdateShopInfo from '../mdlAddUpdateMyShopInfo';
//-------------------------------------
const MyShop=(props)=>{
//-----------------------------------GLOBAL VAR EREA
const {shopInfo  }  =props.myBazaarState
//-----------------------------------REF EREA
const fetchModal=useRef()
const alertModal=useRef()
const confirmModal=useRef()
const mdlWarning =useRef()
const mdlMembershipPriceSelect=useRef()
const mdlFactor=useRef()
const mdlAddUpdateMyShopInfo=useRef()
//-----------------------------------STATE EREA
// useBackHandler(()=>{
//        onBackClick()
//        return true
// })
const onBackClick=()=>props.navigation.goBack()
//-----------------------------------USE_EFFECT EREA
//----------------------------------
const onAddMyShopInfoClick=()=>{
       mdlAddUpdateMyShopInfo.current.show("بروزرسانی اطلاعات فروشگاه",{...shopInfo})
}
const onAddMyShopInfo=shopInfo=>{
        const {id , name , sellerName , phone , description , shopCategoryId} = shopInfo
        const objData={
            jwt : props.profileState.jwt , 
            id:0 , 
            name : name.trim().replace( / +/g , " ") , 
            sellerName : sellerName.trim().replace( / +/g , " ")  , 
            phone , 
            shopCategoryId , 
            description : description ? description.trim().replace( / +/g , " ").replace(/\n+/g , "\n")  : "" ,
            arrCity : [props.profileState.objCity]
        }
        fetchModal.current.fetchData("user/setMyShopInfo" , objData , 
                                                        {
                                                                onSuccess:responseOfAddMyShopInfo
                                                        } , true , true , true
        )
}
const responseOfAddMyShopInfo= data =>props.myShopSetInfo(data)
//-----------------------------------------
const onAddUpdateShop=()=>{
       const arrWarrning=[]
       const shopInfo = props.myBazaarState.copyOfShopInfo
       if(!shopInfo.name || shopInfo.name.trim().replace(/ +/g , " ").length<4){
           alertModal.current.show("خطا در ورود اطلاعات","نام فروشگاه را با حداقل 4 حرف وارد نمایید.","error")
           return
       }
       if(!shopInfo.sellerName || shopInfo.sellerName.trim().replace(/ +/g , " ").length <5){
           alertModal.current.show("خطا در ورود اطلاعات","نام سرپرست فروشگاه را با حداقل 5 حرف وارد نمایید .","error")
           return
       }
       if(!isValidPhoneNumber(shopInfo.phone)){
           alertModal.current.show("خطا در ورود اطلاعات","شماره تماس فروشگاه به درستی وارد نشده است.!!!","error")
           return
       }
       if(!shopInfo.address || shopInfo.address.trim().replace(/ +/g , " ").replace(/\n+/g , "\n").length <5){
           alertModal.current.show("خطا در ورود اطلاعات","آدرس فروشگاه را با حداقل 5 حرف وارد نمایید.","error")
           return
       }
       if(!shopInfo.description || shopInfo.description.trim().replace(/ +/g , " ").replace(/\n+/g , "\n").length <5){
           alertModal.current.show("خطا در ورود اطلاعات","توضیحات فروشگاه را با حداقل 5 حرف وارد نمایید.","error")
           return
       }
       if(!shopInfo.shopCategoryId ){
           alertModal.current.show("خطا در ورود اطلاعات","دسته بندی فروشگاه به درستی انتخاب نشده است."+shopInfo.shopCategoryId,"error")
           return
       }
       if(!shopInfo.shopAvatar && !shopInfo.newShopAvatar)
           arrWarrning.push({title : "لوگوی فروشگاه ثبت نشده است" , subTitle:'به بخش < پروفایل > مراجعه نمایید'})
       if(!shopInfo.sellerAvatar && !shopInfo.newSellerAvatar)
           arrWarrning.push({title : "تصویر سرپرست فروشگاه ثبت نشده است" , subTitle:'به بخش < پروفایل > مراجعه نمایید'})
       if(!shopInfo.banner)
           arrWarrning.push({title : "هیچ بنر و شعاری برای فروشگاه ثبت نشده است" , subTitle:'به بخش < مشخصات > مراجعه نمایید'})
       if(!shopInfo.arrImageFile.length)
           arrWarrning.push({title : "هیچ عکسی برای فروشگاه ثبت نشده است" , subTitle:'به بخش < تصاویر فروشگاه > مراجعه نمایید'})
//       if(!shopInfo.arrCategory.length)
//            arrWarrning.push({title : "هیچ دسته بندی محصولی برای فروشگاه ثبت نشده است" , subTitle:'به بخش < دسته بندی ها > مراجعه نمایید'})
       if(!shopInfo.arrLink.length)
           arrWarrning.push({title : "هیچ آدرس صفحه ای در شبکه های اجتماعی برای فروشگاه ثبت نشده است" , subTitle:'به بخش < لینک ها > مراجعه نمایید'})
   
       if(arrWarrning.length)
           mdlWarning.current.show(arrWarrning);
       else
           addUpdateShopInfo()
}
const addUpdateShopInfo=async ()=>{
const shopInfo = props.myBazaarState.copyOfShopInfo
       const objData={
                                   id:props.myBazaarState.shopInfo.id , 
                                   name:shopInfo.name.trim().replace(/ +/g , " ") , 
                                   sellerName:shopInfo.sellerName.trim().replace(/ +/g , " ") , 
                                   phone : shopInfo.phone , 
                                   banner : shopInfo.banner ? shopInfo.banner.trim().replace(/ +/g , " ") : "" , 
                                   description : shopInfo.description.trim().replace(/ +/g , " ").replace(/\n+/g , "\n")  , 
                                   address : shopInfo.address.trim().replace(/ +/g , " ").replace(/\n+/g , "\n")  , 
                                   // arrCategory : shopInfo.arrCategory , 
                                   arrLink : shopInfo.arrLink , 
                                   arrCity:shopInfo.arrCity ,
                                   shopCategoryId : parseInt(shopInfo.shopCategoryId) , 
                                   arrImageFile : shopInfo.arrImageFile ,
                                   jwt : props.profileState.jwt
       }


       //دریافت عکس جدید برای فروشنده
       const  newSellerAvatarFile=shopInfo.newSellerAvatar ? [{
                                                                                                         name:"imageForSellerAvatar",
                                                                                                         filename:"imageForSellerAvatar.esi" , 
                                                                                                         type:shopInfo.newSellerAvatar.mime , 
                                                                                                         uri:shopInfo.newSellerAvatar.path
                                                                                                  }] :  []
       //دریافت عکس جدید برای فروشنده
       const newShopAvatarFile=shopInfo.newShopAvatar ?  [{
                                                                                                  name:"imageForShopAvatar",
                                                                                                  filename:"imageForShopAvatar.esi" , 
                                                                                                  type:shopInfo.newShopAvatar.mime , 
                                                                                                  uri:shopInfo.newShopAvatar.path
                                                                                           }]  : []
       //دریافت عکس های جدید برای فروشگاه
       const arrNewShopImageFile =  shopInfo.arrImageFile.filter(image=>typeof(image.id)==="string" && image.filename)
                                                                                           .map((file , index)=>({
                                                                                                                                     name:"imageForShop"+index  , 
                                                                                                                                     filename:'imageForShop.esi'+"_"+file.id ,
                                                                                                                                     type:file.type , 
                                                                                                                                     uri : file.filename
                                                                                                                              })) 
       
       // //دریافت عکس های جدید برای دسته بندی های فروشگاه
       // const arrNewCategoryImageFile= shopInfo.arrCategory.filter(category => category.newAvatar)
       //                                                                                     .map((category , index)=>({
       //                                                                                                                                      name:"imageForCategory"+index  , 
       //                                                                                                                                      filename:'imageForCategory.esi'+"_"+category.id ,
       //                                                                                                                                      type:category.mime, 
       //                                                                                                                                      path : category.newAvatar.path
       //                                                                                                                               }))

       const arrNewImageFile=[...newSellerAvatarFile ,...newShopAvatarFile , ...arrNewShopImageFile]// , ...arrNewCategoryImageFile ]

       fetchModal.current.uploadData( "user/setMyShopInfo" ,
                                                        objData ,
                                                        arrNewImageFile,
                                                        {
                                                        onSuccess : responseOfAddUpdateShop ,
                                                        onStart:()=>{},
                                                        onError:()=>{},
                                                        } , 
                                                        true , )      
}
const responseOfAddUpdateShop= data=>{
              if(typeof(data)==="string"){
              if(data=="ERROR_IN_SEND_IMAGE_DATA"){
                     alertModal.current.show("خطا در بارگزاری تصویر ",
                                          "در ارسال تصویر مقاله خطایی روی داده است و لازم است تصویر مجددا انتخاب و ارسال گردد.",
                                          "error")
                     return
              }
              if(data=="DATA_IS_NOT_VALIDATE"){
                     alertModal.current.show("خطا در بارگزاری تصویر ",
                                          "اطلاعات ارسال شده معتبر نمی باشد",
                                          "error")
                     return
              }
              if(data=="NO_VALID_IMAGE_FORMAT"){
                     alertModal.current.show("خطا در بارگزاری تصویر ",
                                          "تنها تصاویری با فرمتهای "+"\n"+
                                          "jpg"+"\n"+
                                          "jpeg"+"\n"+
                                          "png"+"\n"+
                                          "برای تصویر پروفایل قابل پذیرش می باشد.",
                                          "error")
                     return
              }
              if(data=="ERROR_IN_SAVE_DATA_LASTLY"){
                     alertModal.current.show("خطا در بارگزاری تصویر ",
                                          "در ثبت نهایی تصویر خطایی روی داده است و لازم است تصویر مجددا انتخاب و ارسال گردد.",
                                          "error")
                     return
              }
              if(data==="ABOUT_US_DESCRIPTION_LAST_DELETED"){
                     alertModal.current.show("خطا در نجام عملیات",
                                                               "این بلوک توضیح قبلا  حذف شده است !!!",
                                                               "error")
                     return
              }

              if(data==="SHOP_LAST_DELETED"){
                     props.myShopResetInfo()
                     props.setToastMessage("این فروشگاه  قبلا حذف شده است و امکان ثبت تغییرات در آن وجود ندارد!!!!")
              }
       }
       else
       {
              props.myShopSetInfo(data)
              props.setToastMessage("ثبت و بروزرسانی اطلاعات فروشگاه با موفقیت انجام شد.")
       }
}
//-------------------------------------
const getMembershipPrice=()=>{
       const objData={
           jwt:props.profileState.jwt
       }
       fetchModal.current.fetchData("user/getMembershipPrice", objData , 
                                                         {
                                                               onSuccess:responseOfGetMembershipPrice
                                                         } ,true , true , true )
}
const responseOfGetMembershipPrice= data => mdlMembershipPriceSelect.current.show(data)
   
const onSelectMembershipPrice= membershipPriceId => {
mdlMembershipPriceSelect.current.hide()
const objData={
       jwt:props.profileState.jwt , 
       membershipPriceId
}
fetchModal.current.fetchData("user/payMembershipPrice" , objData , 
                                                        {
                                                        onSuccess: responseOfPayMembershipPrice
                                                        } , true , true , true)
}
const responseOfPayMembershipPrice=data =>{
if(typeof(data)==="string"){
              if(data.indexOf("LAST_TRANSACTION_VALIDATE_")!==-1){
              const newExpireDate=parseInt(data.substring(26))
              props.myShopUpdateInfo({expireDate:newExpireDate})
              props.setToastMessage("تمدید اعتبار فروشگاه شما قبلا انجام شده است.")
              }
}
else{
              if(data.message && data.data){
              if(data.message==="PAY_LINK_CREATED"){
                     mdlFactor.current.show("فاکتور تمدید اعتبار فروشگاه",
                                                        "فاکتور تمدید اعتبار فروشگاه با موفقیت برای شما ایجاد گردیده و آماده ی پرداخت می باشد.",
                                                        data.data.link ,
                                                        data.data.amount ,
                                                        data.data.factorId )
              }
              }
}
}
const backToPayShopMembership=()=>{
       props.myShopResetInfo()
       onBackClick()
}
// const onCancelAddUpdateShopInfo=()=>{
//        if(shopInfo.id) 
//               return 
//        else{
              
//        }
// }
// const leftComponent=()=>{
//        return (
//               <MyButton onClick={onAddMyShopInfoClick}
//                                title='' 
//                                iconName='edit' 
//                                buttonStyle={{ elevation:5 , }}
//                                buttonIconStyle={{}}
//                                buttonTExtStyle={{}} />
//        )
// }
//---------------------------------------------------------

const mustPayMembershipPrice = props.myBazaarState.shopInfo.id && props.myBazaarState.shopInfo.expireDate < Math.floor( new Date().getTime()/1000 ) ? true : false
   return(
       // <PageContainer title="فروشگاه آنلاین من"
       //                          subTitle={"روستای "+props.profileState.objCity.name}
       //                          showBackIcon
       //                          onBackIconClick={onBackClick}
       //                          containerPadding={0}
       //                          hasFlatList >
       <View style={{flex:1 }}>
             {/* {
                    fetchState.fetchFlag ?  <ShopInfoPlaceholder />  : null
              } */}
              {/* {
              fetchState.errorFlag ? <NoItem visible title="خطا در دریافت اطلاعات"  containerStyle={{}} 
                                                               containerClassName="w3-card-none " 
                                                               contentStyle={{boxShadow:"0 0 0 0 #FFFFFF"}} contentCalssName=""
                                                               message={"در دریافت اطلاعات فروشگاه خطایی روی داده است!!!!"+"\n"+
                                                                             "این مشکل ممکن است در نتیجه کندی و یا قطعی شبکه اینترنتدشما باشد."} 
                                                                             tryAgain={getMyShopInfo} showTryAgainButton />:null
              } */}
              {
                     //  !fetchState.fetchFlag && !fetchState.errorFlag ? 
                     shopInfo.id ? 
                      <View style={{flex:1 }}>
                            {
                                   props.myBazaarState.shopInfo.id ? 
                                   <View className='w3-padding w3-card' style={{ backgroundColor:"#00acc1" , elevation:1 }}>
                                   <MyText isBlock   tinyFont color="#FFF" style={{textAlign:"center"  , backgroundColor:"#00acc1"}}>{"تاریخ اتمام اعتبار فروشگاه : "+(new JDate(new Date( props.myBazaarState.shopInfo.expireDate*1000)).format("dddd DD MMM YYYY"))}</MyText>
                                   </View> : null
                            }
                            
                            {
                                   mustPayMembershipPrice ?
                                   <View style={{position:"absolute" , top:0 ,right:0 , left:0 , bottom:0 ,  justifyContent:"center"  , padding:10 , zIndex:2}}>
                                          <View style={{alignItems:"flex-start"  , padding:10 ,elevation:5, backgroundColor:"#FFF" , margin:10 ,flexDirection:"row"   }}>
                                                 <View className='' >
                                                        <MyIcon iconName="notifications" color="#e57373" iconSize={30}    />
                                                 </View>
                                                 <View style={{}}>
                                                        <MyText isBlock isBold smallFont  color="#e57373">مدت اعتبار فروشگاه شما در ده وند به اتمام رسیده است</MyText>
                                                        <MyText isBlock smallFont  className="" style={{marginRight:7}}>جهت جلوگیری از مسدود شدن فروشگاه خود , لطفا اعتبار فروشگاه خود را تمدیدی نمایید</MyText>
                                                        <View className='displayFlexRowReverse w3-padding' style={{padding:16}}>
                                                        <MyButton smallFont  title="تمدید اعتبار" iconName="sell" buttonStyle={{paddingVertical:2 , paddingHorizontal:10}} onClick={getMembershipPrice} />
                                                        </View>
                                                 </View>
                                          </View>
                                   </View>
                                   :
                                   null 
                            }

                            <View  style={{flex:1 ,  opacity  :  props.myBazaarState.shopInfo.id && props.myBazaarState.shopInfo.expireDate < Math.floor( new Date().getTime()/1000 )  ? 0.2 : 1 }}>
                                   <ShopProduct  onUpdateDescriptionClick={onAddMyShopInfoClick} />
                            </View>
                      </View> : null
              }
             <MdlWarningOnSave ref={mdlWarning} addUpdateShopInfo={addUpdateShopInfo} />
            <MdlMembershipPriceSelect ref={mdlMembershipPriceSelect} onSelectMembershipPrice={onSelectMembershipPrice} />
            <MdlAddUpdateShopInfo ref={mdlAddUpdateMyShopInfo} onAddUpdateMyShopInfo={onAddMyShopInfo} />
            <MdlFactor ref={mdlFactor} onLinkClick={backToPayShopMembership} />
             <Fetch ref={fetchModal} />
              <Alert ref={alertModal} />
              <Confirm ref={confirmModal} />
              </View>
       // </PageContainer>
       
   )
}

const mapSTateToProps= state => ({
       profileState : state.profileReducer ,
       myBazaarState:state.myBazaarReducer
   })
   const mapDispatchToProps= dispatch =>({
       myShopSetInfo : shopInfo => dispatch(myShopSetInfo(shopInfo)) ,
       myShopResetInfo : ()=>dispatch(myShopResetInfo()) ,
       myShopUpdateInfo :  arrCity =>dispatch(myShopUpdateInfo(arrCity)) , 
       setToastMessage : strMessage => dispatch(setToastMessage(strMessage)) ,
   })
export default connect(mapSTateToProps , mapDispatchToProps)(MyShop);
//=========================================================================================