//===================================================================================================================
const ADD_INITIAL_INO="ADD_INITIAL_INO"
const UPDATE_INITIAL_INFO="UPDATE_INITIAL_INFO"
//===================================================================================================================
export const setInitialInfo= initialInfo => ({type:ADD_INITIAL_INO , initialInfo})
export const updateInitialInfo= initialInfo => ({type:UPDATE_INITIAL_INFO , initialInfo})
//===================================================================================================================
const initialInfoInit={
       newAnnouncementCount:0 , 
       newAlbumCount:0 , 
       newBillCount:0 , 
       hasNewMessage:false , 
       isPeopleInCity: false , 
       finished:false 
}

export default (state=initialInfoInit , action)=>{
       switch(action.type){
              case ADD_INITIAL_INO : return action.initialInfo
              case UPDATE_INITIAL_INFO : return Object.assign({} , state , action.initialInfo)
              default :return state
       }
}