//========================================================================================
import React,{ useState, forwardRef, useImperativeHandle}  from 'react'
import { ModalContainer, MyButton, MyImage, MyText } from '../globalComponents/components'
import { formatNumber } from '../globalComponents/rejax'

const MdlFactor=forwardRef((props , ref)=>{
//-----------------------------------GLOBAL VAR EREA
//-----------------------------------REF EREA
//-----------------------------------STATE EREA
const [visible , setVisible]=useState(false)
const [factorInfo , setFactorInfo]=useState({
                                                                              title :'' , 
                                                                              message:'' , 
                                                                              link:'' ,
                                                                              amount:0 ,  
                                                                              factorId:0
                                                                           })
//-----------------------------------USE_EFFECT EREA
//-----------------------------------FUNCTION EREA
const hide=()=>setVisible(false)

useImperativeHandle(ref , ()=>({
    show:(title , message , link , amount , factorId)=>{
         setFactorInfo({
                                    title,
                                    message,
                                    link,
                                    amount , 
                                    factorId
                                 })
        setVisible(true)
    }
}))
const onClickToPay=()=>{
   try{
         window.ReactNativeWebView.postMessage(factorInfo.link)
   }
   catch{
         window.open(factorInfo.link  , "_blank")
   }
   setTimeout(()=>props.onLinkClick() , 2000)
}
//--------------------------------------------------
   return(
                   <ModalContainer visible={visible}
                                              title={factorInfo.title}
                                              message={factorInfo.message}
                                              showBackButton={true}
                                              onBackClick={hide}
                                              containerStyle={{}}
                                              containerClassName='w3-padding'
                                              contentStyle={{width:"100%"}}
                                              contentClassName='' 
                                              position='center'
                                              animation='zoomIn' >
                        <div className='displayFlexCol displayFlexCenter w3-margin-top'>
                              <MyImage source={require("./../assets/images/logo/logo.png")} style={{width:60 , height:60 , marginBottom:7}} />
                              <div>
                                 <MyText isBold isBlock  smallFont color="#4a4a4a">مبلغ قابل پرداخت : </MyText>
                                 <MyText  isBold isBlock color="#e57373" className="w3-center">{ formatNumber(factorInfo.amount)+" (تومان) "}</MyText>
                              </div>
                              <div>
                                 <MyText isBold isBlock  smallFont color="#4a4a4a" style={{marginTop:10}}>شماره فاکتور جهت پیگیری : </MyText>
                                 <MyText  isBold isBlock color="#e57373" className="w3-center">{factorInfo.factorId.toString() }</MyText>
                              </div>
                              
                        </div>
                        <div className='w3-padding w3-margin-top'>
                           <MyButton onClick={onClickToPay}
                                            title="پرداخت فاکتور" 
                                            iconName="sell" 
                                            buttonStyle={{}}
                                            buttonIconStyle={{}}
                                            buttonTExtStyle={{}} />
                        </div>
                 </ModalContainer>
   )
})

export default MdlFactor;
//=========================================================================================