//========================================================================================
import React,{ useState, useImperativeHandle, forwardRef}  from 'react'
import {View} from "react-native"
import { ModalContainer, MyButton, MyImage, MyText, ProfileImage } from '../../../globalComponents/components';
import { formatNumber } from '../../../globalComponents/rejax';
import Styles from '../../../styles';
 
//-----------------------------------------------------------------------------------------
const BillInfoItemComponent= props =>{
       const {_key , value , keyColor="#26c6da" , valueColor="#9a9a9a"} = props
       return(
              <View  style={{flexDirection:"row" , borderBottomWidth:1 , borderBottomColor:"#f5f5f5" , paddingHorizontal:8 , paddingVertical:4}}>
                     <MyText smallFont  color={keyColor} >{_key+ " : "}</MyText>
                     <MyText smallFont  color={valueColor}  style={{marginRight:16 , flex:1 , textAlign:"right"}}>{value}</MyText>
              </View>
       )
}
//-----------------------------------------------------------------------------------------
const MdlBillInfo=forwardRef((props , ref)=>{
//-----------------------------------GLOBAL VAR EREA
//-----------------------------------REF EREA
//-----------------------------------STATE EREA
const [visible , setVisible]=useState(false)
const [billInfo  , setBillInfo]=useState({
                                                                             id:0 ,
                                                                             isCustomBill:false , 
                                                                             billName:'' , 
                                                                             billTypeDescription:'' ,
                                                                             billAvatar:'' , 
                                                                             durationNumber:0 , 
                                                                             durationName:'' ,                         
                                                                             saveDate:'' , 
                                                                             startDate:'' , 
                                                                             endDate:'' , 
                                                                             paymentDeadLine:'' , 
                                                                             paymentDeadLineTimestamp:0 , 
                                                                             billDescription:'' , 
                                                                             arrInputKey:[] , 
                                                                             arrInputValue : [] , 
                                                                             billPrice:0 , 
                                                                             previousDebt:0  , 
                                                                             refId:0 , 
                                                                             payDate:""
                                                                      })
//-----------------------------------USE_EFFECT EREA
useImperativeHandle(ref , ()=>({
       show: _billInfo =>{
              setBillInfo(_billInfo)
              setVisible(true)
       } , 
       hide : ()=>setVisible(false)
}))
//-----------------------------------FUNCTION EREA
const hide = ()=>setVisible(false)
//--------------------------------------------------
const avatar = billInfo.billAvatar ? {uri : billInfo.billAvatar} : require("../../../assets/images/act/imgDefImage.png")
const initInfoToShow=()=>{
       const billState = billInfo.refId ?  "پرداخت شده "   :  billInfo.paymentDeadLineTimestamp > new Date().getTime()  ? "در انتظار پرداخت" : "اتمام مهلت پرداخت"
       const arrKeyValue=[]
       if(billInfo.billName)
              arrKeyValue.push({_key:"عنوان قبض" , value:billInfo.billName ,  keyColor:"#26a69a" , valueColor:'#29b6f6'})
       if(billInfo.durationName)
              arrKeyValue.push({_key:"نام دوره" , value:billInfo.durationName,  keyColor:"#26a69a" , valueColor:'#29b6f6' })
       if(billInfo.durationNumber)
              arrKeyValue.push({_key:"شماره دوره" , value:billInfo.durationNumber.toString() ,  keyColor:"#26a69a" , valueColor:'#8a8a8a' })

       if(billInfo.startDate)
              arrKeyValue.push({_key:"از تاریخ" , value:billInfo.startDate ,  keyColor:"#26a69a" , valueColor:'#7cb342'})
       if(billInfo.endDate)
       arrKeyValue.push({_key:"تا تاریخ" , value:billInfo.endDate ,  keyColor:"#26a69a" , valueColor:'#7cb342'})
       arrKeyValue.push({_key:"تاریخ انتشار" , value:billInfo.saveDate,  keyColor:"#26a69a" , valueColor:'#7cb342' })
       arrKeyValue.push({_key:"مهلت پرداخت" , value:billInfo.paymentDeadLine,  keyColor:"#26a69a" , valueColor:'#7cb342' })

       for(let i=0 ; i<billInfo.arrInputKey.length ; i++)
              arrKeyValue.push({_key:billInfo.arrInputKey[i] , value:billInfo.arrInputValue[i] ? isNaN(billInfo.arrInputValue[i]) ? billInfo.arrInputValue[i] : formatNumber(billInfo.arrInputValue[i])  
                                                                                                                                                         : "-------------" ,
                                                 keyColor:"#26a69a" , 
                                                 valueColor:'#8a8a8a'
                                           })

       
       if(!billInfo.isCustomBill){
              arrKeyValue.push({_key:"مبلغ قبض ( تومان )" , value : formatNumber(billInfo.billPrice),  keyColor:"#26a69a" , valueColor:'#fb8c00' })
              arrKeyValue.push({_key:"بدهی قبلی ( تومان )" , value:billInfo.previousDebt ? formatNumber(billInfo.previousDebt) : "-------------" ,  keyColor:"#26a69a" , valueColor:'#fb8c00' })
       }
       arrKeyValue.push({_key:"مبلغ قابل پرداخت ( تومان )" , value:formatNumber(billInfo.billPrice + (billInfo.previousDebt ? billInfo.previousDebt : 0 ))  ,  keyColor:"#26a69a" , valueColor:'#fb8c00'})
       arrKeyValue.push({_key:"وضعیت پرداخت " , value:billState,  keyColor:"#26a69a" , valueColor:'#8a8a8a' })
       return arrKeyValue   
}
//--------------------------------------------------
const [avatarWidth , setAvatarWidth]=useState(0)
const onLayout= e =>setAvatarWidth(e.nativeEvent.layout.width)
//--------------------------------------------------
   return(
       <ModalContainer visible={visible} 
                                  title=""
                                  showTitle={false}
                                  closeWithClickBackButton
                                  showBackButton={true}
                                  containerClassName=""
                                  containerStyle={{padding:0}}
                                  contentStyle={{width:"100%" , maxHeight:"90%"  , borderRadius:0}}
                                  contentClassName=""
                                  position="bottom"
                                  animation="slideInUp"
                                  onBackClick={hide}>
              <View  style={{alignItems:"center" , justifyContent:"center" , flexDirection:"row"}}>
                     <View  onLayout={onLayout}   style={Object.assign({} , {} , Styles.l1 , Styles.m3,Styles.s4)}>
                            <MyImage source={avatar}  style={{ borderRadius:avatarWidth , 
                                                                                    width: avatarWidth, 
                                                                                     height:avatarWidth}}  />
                     </View>
              </View>
              <View className='w3-margin-top w3-padding'>
                     {
                            initInfoToShow().map((billInfo , index) => <BillInfoItemComponent key={index} {...billInfo} />)
                     }
              </View>
              {
                     !billInfo.refId && billInfo.paymentDeadLineTimestamp> new Date().getTime() ? 
                     <View  style={{flexDirection:"row-reverse" , margin:16}}>
                            <MyButton title="پرداخت قبض" iconName="sell" onClick={()=>props.onPayClick(billInfo.id, billInfo.isCustomBill)} 
                                             buttonStyle={{paddingHorizontal:30 , paddingVertical:3 , backgroundColor:"#00bcd4"}} />
                     </View> 
                     : null
              }
              {
                     billInfo.refId ? 
                     <View style={{justifyContent:"center"  ,alignItems:"center" , marginTop:10}} >
                            <MyText isBold smallFont color="#e57373" 
                                         style={{backgroundColor:"#e1f5fe",borderRadius:10 ,  paddingHorizontal:16 , paddingVertical:8  , textAlign:"center"  }}  >{"تاریخ پرداخت  : "+billInfo.payDate}</MyText>
                     </View>:null
              }
              {
                     !billInfo.refId && billInfo.paymentDeadLineTimestamp< new Date().getTime()?
                     <View >
                            <MyText isBold  smallFont style={{color:"#e57373" }}  >{"مهلت پرداخت این قبض به اتمام رسیده و امکان پرداخت آن وجود ندارد"}</MyText>
                     </View>:null

              }
       </ModalContainer>
   )
})

export default MdlBillInfo;
//=========================================================================================