//========================================================================================
import React,{useRef , useEffect  , useState, useImperativeHandle, forwardRef}  from 'react'
import {ScrollView, StyleSheet, View  } from "react-native"
import { Alert, ModalContainer, MyBorderInput, MyButton  , MyIcon, MyInput, MySelect, MyText } from '../../../globalComponents/components';
import TabComponent from '../../../commonComponents/tabComponent';
import Divider from '../../../commonComponents/divider';
import ImageFileComponent from "../../../commonComponents/imageFileComponent"
import { formatNumber, isValidNumberString } from '../../../globalComponents/rejax';
import uuid from 'react-uuid';
import { connect } from 'react-redux';
import MdlImagePicker from '../../../commonComponents/mdlImagePicker';
// import { useKeyboard } from '@react-native-community/hooks';

const MdlAddUpdateGoods=forwardRef((props , ref)=>{
//---------------------------------------------------------------------------------------------------------------------GLOBAL VAR EREA
const {onAddUpdate , arrCategory , arrGoodsState , mustGetPhoneNumber=true  }=props
// const keyboard=useKeyboard()
//---------------------------------------------------------------------------------------------------------------------REF EREA
const alertModal=useRef()
const mdlGetImage=useRef()
//---------------------------------------------------------------------------------------------------------------------STATE EREA
const [visible , setVisible]=useState(false)
const [panelId , setPanleId]=useState(1)
const [tabChange , setTabChange]=useState(false)
const [pageWasLoaded , setPageWasLoaded]=useState(false)
const [goodsInfo , setGoodsInfo]=useState({
                                                                             id:0 , 
                                                                             title:'' , 
                                                                             description:'' , 
                                                                             price:0 , 
                                                                             phone:'' , 
                                                                             goodsStateId:0 , 
                                                                             categoryId:0 , 
                                                                             arrImageFile:[]
                                                                      })
const [goodsUUID , setGoodsUUID]=useState("")
//---------------------------------------------------------------------------------------------------------------------USE_EFFECT EREA
useEffect(()=>{
       setTimeout(()=>setPageWasLoaded(true) , 100)
} , [])
useEffect(()=>{
       if(pageWasLoaded)
              setTabChange(true)
} , [panelId])
useImperativeHandle(ref , ()=>({
       show: (_goodsInfo)=>{
                                                 setGoodsInfo({..._goodsInfo})
                                                 setVisible(true) 
                                                  setGoodsUUID(uuid())
                                          } ,
       hide:()=>{
              setVisible(false)
              setPanleId(1)
              setPageWasLoaded(false)
              setTabChange(false)
              setGoodsInfo({
                     id:0 , 
                     title:'' , 
                     description:'' , 
                     price:0 , 
                     phone:'',
                     goodsStateId:0 , 
                     categoryId:0 , 
                     arrImageFile:[]
              })
       }
}))
//-----------------------------------FUNCTION EREA
const hide=()=>{
       setVisible(false)
       setPanleId(1)
       setPageWasLoaded(false)
       setTabChange(false)
       setGoodsInfo({
              id:0 , 
              title:'' , 
              description:'' , 
              price:0 , 
              goodsStateId:0 , 
              categoryId:0 , 
              arrImageFile:[]
       })
}
const onChangeAddUPdategoodsInfo=(txtId , newText)=> {
       if(txtId==="price"){
              if(newText ){
                     newText= newText.replace(/,/g , "")
                     if(isValidNumberString(newText))
                            setGoodsInfo({...goodsInfo , price:parseInt(newText)})
              }
              else
                     setGoodsInfo({...goodsInfo , price:0})
       }
       else if(txtId==="phone"){
              if(newText ){
                     if(isValidNumberString(newText))
                            setGoodsInfo({...goodsInfo , phone: newText.trim()})
              }
              else
                     setGoodsInfo({...goodsInfo , phone:""})
       }
       else
              setGoodsInfo({...goodsInfo , [txtId] : newText ? newText  : ""   })
}
//--------------------------------------------
const onChangedStateId= itemId => setGoodsInfo({...goodsInfo , goodsStateId:itemId})
const onChangedCategoryId= itemId => setGoodsInfo({...goodsInfo ,categoryId:  itemId })
const onDeleteImage= imageId =>  setGoodsInfo({...goodsInfo , arrImageFile:goodsInfo.arrImageFile.filter(imageFIle => imageFIle.id!==imageId)})
const handleImageUpload = async (arrImage) => { 
       if(arrImage.length) 
              setGoodsInfo({...goodsInfo , arrImageFile:[  ...goodsInfo.arrImageFile ,    
                                                                                         ...arrImage.map(file => ({ ...file , filename:file.uri  ,  id:uuid()   }    ))  
                                                                                     ]})
 };
//--------------------------------------**nh----------------
const arrTab=[
       {
              id:1 , 
              title:'مشخصات کالا' , 
              onClick:()=>setPanleId(1) , 
              selected:panelId===1
       },
       {
              id:2 , 
              title:' تصاویر کالا' , 
              onClick:()=>setPanleId(2) , 
              selected:panelId===2
       },
]

const onSaveClick=()=>{
       if(goodsInfo.arrImageFile.length>10){
              alertModal.current.show('خطا در ثبت اطلاعات','برای هر کالا حداکثر 10 عکس قابل انتخاب می باشد'+"\n"+
                                                                                        "لطفا عکس های انتخاب شده مجددا را بررسی نمایید",'error');
       }
       onAddUpdate({ 
                                   ...goodsInfo , 
                                   title:goodsInfo.title ? goodsInfo.title.trim().replace(/\n+/g, "\n").replace(/ +/g , " ") :""   , 
                                   description : goodsInfo.description ? goodsInfo.description.trim().replace(/\n+/g, "\n").replace(/ +/g , " ") :""   , 
                                   goodsUUID
                            })
}

//--------------------------------------------------
   return(
       <ModalContainer visible={visible}
                                  showTitle={false}
                                  titleContainerStyle={{paddingBottom:10  , borderBottomWidth:0}}
                                  showBackButton={false}
                                  closeWithClickBackButton
                                  title={goodsInfo.id ? "بروزرسانی اطلاعات کالا" : "ثبت اطلاعات کالای جدید"}
                                  onBackClick={hide} 
                                  position="bottom" 
                                  animation="slideInUp"
                                  containerStyle={{padding:0 }}
                                  contentStyle={{width:"100%" , height:"90%" , paddingHorizontal:0 , flexDirection:"column"  , borderRadius:0  }} hasFlatList>
              <View  style={{ flex:1  }}>
                      <TabComponent arrTabs={arrTab} />
                     <ScrollView  style={{ flex:1 ,  paddingHorizontal:8 ,marginTop:6   }}>
                            {
                                   panelId===1 ? 
                                   <Divider title="ثبت عنوان و مشخصات" containerStyle={{marginTop:6}} containerClassName={` w3-small- ${!tabChange ? "" :"w3-animate-left"}  `}
                                                                                           contentStyle={{marginBottom:20}} contentClassName="w3-padding-small ">

                                          <View className='' style={{marginHorizontal:5 , marginVertical:20 }}>
                                                 <MySelect selectButtonStyle={{buttonStyle:{} , buttonIconStyle:{} , buttonTextStyle:{}}} 
                                                                      arrItem={arrCategory} 
                                                                      placeholder="انتخاب دسته بندی و نوع کالا" 
                                                                      selectedItemId={goodsInfo.categoryId}
                                                                      onSelectItem={onChangedCategoryId} />

                                                 <MySelect containerStyle={{marginTop:16}}
                                                                      selectButtonStyle={{buttonStyle:{} , buttonIconStyle:{} , buttonTextStyle:{}}} 
                                                                      arrItem={arrGoodsState} 
                                                                      placeholder="انتخاب وضعیت استفاده از کالا"
                                                                      selectedItemId={goodsInfo.goodsStateId}
                                                                      onSelectItem={onChangedStateId} />
                                          </View>
                                          <View style={{paddingHorizontal:5}}>
                                                 <MyInput id ='title'      
                                                                title={!mustGetPhoneNumber ? "عنوان و نام کالا : " : "عنوان آگهی : "} 
                                                               // iconName="title"   
                                                               iconColor="#00ACC1"      
                                                               placeholder=""
                                                               titleSmallFont
                                                               value={goodsInfo.title}          
                                                               onChangeText={ onChangeAddUPdategoodsInfo }       
                                                               password={false}  
                                                               maxLength={255}   
                                                               textAlign='right'
                                                               underlineColorAndroid="tranparent"
                                                               inputStyle={{textAlign:"right" ,color:"gray" }} 
                                                               containerStyle={{ backgroundColor:"#FFFFFF",borderWidth:1 , borderColor:"#EEE" , alignItems:"flex-start" , borderRadius:10 ,marginTop:10 , paddingVertical:4}}  />
                                                 {
                                                        mustGetPhoneNumber ? 

                                                               <MyInput id ='phone'     //iconName="phone"  
                                                                             iconColor="#00ACC1"    isBold={false}   title="شماره تماس : "
                                                                             placeholder="" 
                                                                             value={goodsInfo.phone}          
                                                                             onChangeText={ onChangeAddUPdategoodsInfo }       
                                                                             password={false}  
                                                                             maxLength={11}
                                                                             keyboardType="numeric"         
                                                                             textAlign='right' 
                                                                             containerStyle={{ backgroundColor:"#FFFFFF",borderWidth:1 , borderColor:"#EEE" , alignItems:"flex-start" , borderRadius:10 ,marginTop:10 , paddingVertical:4}}
                                                                             inputStyle={{textAlign:"center"}} 
                                                                             inputClassName="w3-center" />
                                                        : null 
                                                 }

                                                 <MyInput id ='price' title="قیمت کالا ( تومان ) :"      //iconName="sell"  iconColor="#00ACC1"      
                                                               isBold={false}
                                                               placeholder=""  
                                                               value={goodsInfo.price ? formatNumber(goodsInfo.price) : ""}          
                                                               onChangeText={ onChangeAddUPdategoodsInfo }       
                                                               password={false}  
                                                               maxLength={15}  
                                                               keyboardType="numeric"         
                                                               textAlign='right' 
                                                               containerStyle={{ backgroundColor:"#FFFFFF",borderWidth:1 , borderColor:"#EEE" , alignItems:"flex-start" , borderRadius:10 ,marginTop:10 , paddingVertical:4}}
                                                               inputStyle={{textAlign:"center"}} 
                                                               inputClassName="w3-center" /> 
                                          

                                                 <MyInput id ='description' title="توضیحات :"     //iconName="toc"  iconColor="#00ACC1"      
                                                               isBold={false}
                                                               placeholder=""
                                                               value={goodsInfo.description}          
                                                               onChangeText={ onChangeAddUPdategoodsInfo }       
                                                               password={false}  
                                                               maxLength={2000}    
                                                               multiline
                                                               minNumberOfLines={10}
                                                               maxNumberOfLines={30}   
                                                               containerStyle={{ backgroundColor:"#FFFFFF",borderWidth:1 , borderColor:"#EEE" , alignItems:"flex-start" , justifyContent:"flex-start" , borderRadius:10 ,marginTop:10 , paddingVertical:4}}
                                                               inputContainerStyle={{alignItems:"flex-start" ,borderWidth:1}}
                                                               inputStyle={{textAlign:"right" , color:"gray" , paddingBottom:10  , textAlignVertical:"top" , paddingRight:5}}  />
                                          </View>
                                   </Divider>
                                   :
                                   <Divider title="انتخاب عکس های آگهی"  containerStyle={{marginTop:6}}   >
                                          {
                                                 goodsInfo.arrImageFile.length ? 
                                                <MyText tinyFont color="#e57373" isBold style={{textAlign:"center"}}>برای حذف یک تصویر , تصویر مورد نظر را کلیک کنید و نگه دارید</MyText> : null
                                          }
                                          <View   style={{padding:2 , marginBottom:20 , flexDirection:"row-reverse" , flexWrap:"wrap"}}>
                                          {
                                          [{id :uuid() , filename:'' }  , 
                                                 ...goodsInfo.arrImageFile].map((imageFile , index) => <ImageFileComponent    key={index} 
                                                                                                                                                                               {...imageFile} 
                                                                                                                                                                               onDeleteImage={()=>onDeleteImage(imageFile.id)} 
                                                                                                                                                                               onClick={()=>mdlGetImage.current.showModal(true , 1280 , 1280 , handleImageUpload )} />   )
                                          }
                                          </View>
                                   </Divider>
                            }
                     </ScrollView>
                     {/* {
                            !keyboard.keyboardShown ? */}
                            <View  style={{paddingHorizontal:15  , paddingVertical:8 , borderTopWidth:1 , borderColor:"#eeeeee" , flexDirection:"row-reverse" ,justifyContent:"space-between"}}>
                                   <MyButton onClick={onSaveClick} smallFont
                                                 title={goodsInfo.id ? "بروزرسانی اطلاعات کالا" :"ثبت اطلاعات کالا"} 
                                                 iconName={goodsInfo.id ? "refresh" :"save"}
                                                 buttonStyle={{backgroundColor:"#00ACC1" , padding:6}}
                                                 buttonIconStyle={{fontSize:25}}
                                                 buttonTExtStyle={{}} />
                                   <MyButton onClick={hide} smallFont
                                                 title="بازگشت"
                                                 iconName="close"
                                                 buttonStyle={{backgroundColor:"#e57373" , padding:6}}
                                                 buttonIconStyle={{fontSize:25}}
                                                 buttonTExtStyle={{}} />
                            </View>  
                            {/* : null */}
                     {/* } */}
                     
              </View>
              <MdlImagePicker ref={mdlGetImage} />
              <Alert ref={alertModal} />
              {/* <input type={"file"} accept="image/png, image/jpeg" ref={refFileImage} multiple  onChange={handleImageUpload} style={{display:"none"}} /> */}
       </ModalContainer>
   )
})



const pickerSelectStyles = StyleSheet.create({
       inputIOS: {
         fontSize: 16,
         paddingVertical: 12,
         paddingHorizontal: 10,
         borderWidth: 1,
         borderColor: 'gray',
         borderRadius: 4,
         color: 'black',
         paddingRight: 30, // to ensure the text is never behind the icon
       },
       inputAndroid: {
         fontSize: 16,
         paddingHorizontal: 10,
         paddingVertical: 2,
         borderWidth: 0.5,
         borderRadius:10 , 
         borderColor: 'purple',
         color: '#e57373',
         backgroundColor:"#eeeeee" ,
         textAlign:"right" , 
         flex:1 , 
         borderWidth:1 , 
         
         paddingRight: 30, // to ensure the text is never behind the icon
       },
     });






const mapStateToProps=state =>({
       myBazaarState:state.myBazaarReducer,
       bazaarState : state.bazaarReducer ,
       profileState : state.profileReducer
})
const mapDispatchToProps = dispatch =>({
})
export default connect(mapStateToProps , mapDispatchToProps , null , {forwardRef:true}) (MdlAddUpdateGoods);
//=========================================================================================