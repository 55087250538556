import { View  ,Dimensions } from "react-native-web"
import { MySkeleton } from "../globalComponents/components"

const ChapterGoodsInfoPlaceholder =()=>{
       return(
              <View style={{padding:0}}>

                     <MySkeleton width="100%"  height={Math.floor(Dimensions.get("window").width-10)/1.5-10}  style={{borderRadius:10 ,}} />
                     <View style={{padding:5 , flexDirection:"row" , marginTop:10 , marginBottom:20}}>
                            <MySkeleton width={45} height={45} style={{borderRadius:45}} />
                            <View style={{flex:1 , marginBottom:10}}>
                                   <MySkeleton  width={"50%"} height={5}  style={{ borderWidth:1 , flex:1  , padding:5 , borderRadius:10 , marginRight:10}}  />
                                   <MySkeleton  width={"80%"} height={5} style={{ borderWidth:1 , flex:1  , padding:5 , borderRadius:10 , marginRight:10}}  />
                            </View>
                     </View>

                     <View style={{ paddingHorizontal:10}} >
                            <MySkeleton width={"90%"} height={15} style={{width:"100%" , height:20 , borderRadius:2 , marginTop:10}} />
                     </View>
                     <View style={{ paddingHorizontal:10}} >
                            <MySkeleton width={"100%"} height={15} style={{width:"100%" , height:20 , borderRadius:2 , marginTop:10}} />
                     </View>
                     <View style={{paddingHorizontal:10}} >
                            <MySkeleton width={"50%"} height={15} style={{width:"100%" , height:20 , borderRadius:2 , marginTop:10}} />
                     </View>
                     <View style={{paddingHorizontal:10}} >
                            <MySkeleton width={"60%"} height={15} style={{width:"100%" , height:20 , borderRadius:2 , marginTop:10}} />
                     </View>

                
              </View>
       )
}

export default ChapterGoodsInfoPlaceholder