
import React from "react"
import { MySkeleton } from "../globalComponents/components"
const GoodsShopPlaceholder=()=>{
       return(
              <div className='displayFlexRow   ' style={{alignItems:"center" ,margin:"5px 10px 0 10px " , padding:"8px 2px" , backgroundColor:"#f5f5f5"}}>
                     <div className='w3-padding-small' style={{width:90, height:90}}>
                            <MySkeleton width="100%"  height="100%" style={{borderRadius:10}} />
                     </div>
                     <div className='displayFlexGrow ' style={{padding:"0 10px 0 0 "}}>
                            <MySkeleton width="60%"  height={20} style={{borderRadius:6}} />
                            <MySkeleton width="80%"  height={10} style={{borderRadius:6}} />
                            <MySkeleton width="90%"  height={10} style={{borderRadius:6}} />
                     </div>
              </div>
       )
}

export default GoodsShopPlaceholder