import { combineReducers } from "redux";
import profileReducer from "./profile"
import toastMessageReducer from "./toastMessage"
import initialInfoReducer from "./initialInfo";
import bazaarReducer from "./bazaar";
import myBazaarReducer from "./myBazaar";
import mainAdvertisingReducer from "./mainAdvertising";

import chapterReducer from "./chapter";
import announcementReducer from "./announcement";

export default combineReducers({
       profileReducer,
       initialInfoReducer , 
       bazaarReducer , 
       myBazaarReducer  , 
       toastMessageReducer ,
       mainAdvertisingReducer ,

       chapterReducer,
       announcementReducer
})