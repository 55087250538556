//========================================================================================
import React,{}  from 'react'
import {View , } from "react-native"
import { MyButton , MyText } from '../globalComponents/components'
 
const ExtendedActionButton=(props)=>{
//--------------------------------------------------
const {title="" , iconName="" , onClick=()=>{}} =props
//--------------------------------------------------
   return(
       <View style={{position:"absolute" , bottom:10 , left:20  , flexDirection:"row-reverse" }}>
              <MyButton onClick={onClick}
                            title='' 
                            iconName={iconName}
                            buttonStyle={{padding:10 , borderRadius:50 , paddingHorizontal:10 , paddingVertical:10 , elevation:5 , borderWidth:1 , borderColor:"#e4e4e4"}}
                            buttonIconStyle={{fontSize:30}}
                            buttonTExtStyle={{}} />
              <MyText tinyFont isBold isBlock  style={{position:"absolute" , left:35 , bottom:0 , backgroundColor:"#EEE" , color:"#456e7a" ,
                                                               paddingHorizontal:10 , borderRadius:15 ,elevation:3 , borderColor:"#e0e0e0" , whiteSpace:"nowrap" , padding:"0 5px" }}>
                     {title}
              </MyText> 
       </View>
   )
}

export default ExtendedActionButton;
//=========================================================================================