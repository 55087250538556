//========================================================================================
import React,{ useEffect, useRef, useState }  from 'react'
import {NavigationContainer , createNavigationContainerRef } from "@react-navigation/native"
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import GetPhoneNumber from './pages/login/getPhoneNumber';
import getActivationCode from './pages/login/getActivationCode';
import GoodsShopList from './pages/root/bazaar';
import goodsInfo from './pages/root/bazaar/goodsInfo';
import shopInfo from './pages/root/bazaar/shopInfo';
// import contactUs from './pages/root/contactUs';

import AdvertisingDescription from "./pages/root/advertising/description"
import AddAdvertising from "./pages/root/advertising/addAdvertising"

import Bill from './pages/root/bill';
import checkTransaction from './pages/root/checkTransaction';
import AddAnnouncement from "./pages/root/announcement/add"

import { ContentContainer, ToastMessage } from './globalComponents/components';
import { setToastMessage } from './redux/toastMessage';
import ShareFolderMessage from './pages/root/shareFolderMessage';

import Home from './pages/homePage/home';
import Advertising from './pages/homePage/advertising';
import InitApp from './initApp';


import AddUpdateChapter from './pages/root/chapter/chapterAddUpdate';
import ChapterInfo from './pages/root/chapter/chapterInfo';

import Profile from "./pages/root/profile";

import {connect} from "react-redux"
import chapterComment from './pages/root/chapter/chapterComment';
import MainPage from './pages/root/mainPage';
import { View } from 'react-native-web';









const Stack = createNativeStackNavigator();
const navigationRef = createNavigationContainerRef()

const Navigation=(props)=>{
//-----------------------------------GLOBAL VAR EREA
const options={ headerShown: false , animation:"slide_from_right" }
//-----------------------------------REF EREA
const toastModal=useRef()
//-----------------------------------STATE EREA
const getTransactionInfo=()=>{
       const strSearch= window.location.search
       if(strSearch && strSearch.indexOf("token")!==-1 && strSearch.indexOf("status"!==-1)){
              const tokenIndex=strSearch.indexOf("token")
              const statusIndex=strSearch.indexOf("status") 

              return { token   : strSearch.substring(tokenIndex+6 , statusIndex-1) ,   status : strSearch.substring(statusIndex+7)} 
       }
       else
              return {token:'' , status:''}
}
const [transactionInfo , setTransactionInfo]=useState(getTransactionInfo())

//-----------------------------------USE_EFFECT EREA

useEffect(()=>{
       if(props.toastMessageState){
           toastModal.current.show(props.toastMessageState)
           setTimeout(()=>props.setToastMessage("") , 3000)
       // Toast.success(props.toastMessageState)
       }
   },[props.toastMessageState])


//-----------------------------------FUNCTION EREA
// const linking = {
//        prefixes: [  'tpel://dehvandapp' , 
//                            'https://deh-vand.ir' , 
//                            'https://www.deh-vand.ir' , 
//                            'http://www.deh-vand.ir' , 
//                            'http://deh-vand.ir' , 
//                            'http://localhost:3000' ,
//                             `${SITE_URL}` ],
//        config: {
//               screens: {
//                      checkTransaction: {
//                             path: 'checkTransaction'
//                      },
//               }
//        }
// };
//--------------------------------------------------

return(
       <View style={{flex:1 }}>
                     <NavigationContainer    ref={navigationRef}  >
                            <Stack.Navigator  initialRouteName="initApp"    >
                                   <Stack.Group>
                                          <Stack.Screen options={options}      name="initApp" >
                                          {(props) => <InitApp {...props} transactionInfo={transactionInfo} />}
                                          </Stack.Screen>
                                   </Stack.Group>
                                   <Stack.Group>
                                          <Stack.Screen options={options}   name="homePage" component={Home} />
                                          <Stack.Screen options={options}   name="advertising" component={Advertising} />
                                   </Stack.Group>
                                          <Stack.Group >
                                          <Stack.Screen options={options}      name="getPhoneNumber"  component={GetPhoneNumber} />
                                          <Stack.Screen options={options}      name="getActivationCode"  component={getActivationCode} />
                                   </Stack.Group>
                                   <Stack.Group>
                                          <Stack.Screen options={options}      name="profile" component={Profile} />
                                   </Stack.Group>
                                   <Stack.Group>
                                          <Stack.Screen options={options}      name="bill"  component={Bill} />
                                   </Stack.Group>
                                   <Stack.Group>
                                          <Stack.Screen options={options}      name="mainPage"                     component={MainPage} />
                                          <Stack.Screen options={options}      name="addUpdateChapter"       component={AddUpdateChapter} />  
                                          <Stack.Screen options={options}      name="chapterInfo"                component={ChapterInfo} />  
                                          <Stack.Screen options={options}      name="chapterComment"            component={chapterComment} /> 
                                          <Stack.Screen options={options}      name="addAnnouncement"         component={AddAnnouncement} />    
                                          <Stack.Screen options={options}      name="shareFolderMessage"    component={ShareFolderMessage} />
                                   </Stack.Group>
                                   <Stack.Group>
                                          <Stack.Screen options={options}     name="bazaar"  component={GoodsShopList} />
                                          <Stack.Screen options={options}     name="goodsInfo"  component={goodsInfo} />
                                          <Stack.Screen options={options}     name="shopInfo"  component={shopInfo} />
                                   </Stack.Group>
                                   {/* <Stack.Group>
                                          <Stack.Screen options={options}    name="contactUs"  component={contactUs} />
                                   </Stack.Group> */}
                                   <Stack.Group>
                                          <Stack.Screen options={options}    name="advertisingDescription"  component={AdvertisingDescription}   />
                                          <Stack.Screen options={options}    name="addAdvertising"  component={AddAdvertising} />
                                   </Stack.Group>
                                   <Stack.Group>
                                          <Stack.Screen options={options}   name="checkTransaction"    component={checkTransaction}/>
                                   </Stack.Group>
                            </Stack.Navigator>
                     </NavigationContainer>
  
              <ToastMessage  ref={toastModal} />
              {/* <ToastManager /> */}
       </View>
   )
}

const mapStateToProps= state =>({
       toastMessageState :state.toastMessageReducer
   })
   
   const mapDispatchToProps= dispatch =>({
       setToastMessage : strMessage => dispatch(setToastMessage(strMessage)),
   })
export default  connect( mapStateToProps  , mapDispatchToProps )(Navigation);
//=========================================================================================