//========================================================================================
import React,{ }  from 'react'
import {View  , Dimensions} from "react-native"
import {isTablet} from "react-device-detect"
import { MySkeleton } from '../../../globalComponents/components';
 
const ChapterPlaceholder=(props)=>{
//-----------------------------------GLOBAL VAR EREA
//-----------------------------------FUNCTION EREA
const {width}=Dimensions.get("screen")
//--------------------------------------------------
   return(
      <View  style={{margin:"5px 5px 0 5px " , padding:"8px 2px" , backgroundColor:"#f5f5f5"  , margin:6 , padding:6}} >
            <MySkeleton>
                  <View className='displayFlexRowReverse' style={{alignItems:"center" , flexDirection:"row"}}>
                           <View style={{width:50 , height:50 , borderRadius:25}} />
                           <View  style={{padding:"0 10px 0 0 " , justifyContent:"flex-start" , flex:1 , alignItems:"flex-end" , paddingRight:10 ,}}>
                              <View width="40%"  height={10} style={{borderRadius:6 , marginBottom:6}} />
                              <View width="60%"  height={10} style={{borderRadius:6 }} />
                           </View>
                  </View>
                  <View style={{width:"100%" , height: isTablet ? width/4  : width/3, marginTop:5 , borderRadius:10}} />
            </MySkeleton>
      </View>
     
   )
}

export default ChapterPlaceholder;
//=========================================================================================