

import EStyleSheet from 'react-native-extended-stylesheet';
import {Dimensions, Platform} from "react-native"

const {width , height}=Dimensions.get("window")

const isMobile  = width<600 ? true : false
const isTablet = width>=600 && width<992 ?true : false
const isDesktop= width>=992 ? true : false

const Styles={

    tinyFontSize:{fontSize:'$tinyFont'},
    smallFontSize:{fontSize:'$smallFont'},
    normalFontSize:{fontSize:'$normalFont'},
    bigFontSize:{fontSize:'$bigFont'},

    l1: isDesktop ? {width:`${8.33*1}%`} : {} ,
    l2: isDesktop ? {width:`${8.33*2}%`} : {} ,
    l3: isDesktop ? {width:`${8.33*3}%`} : {} ,
    l4: isDesktop ? {width:`${8.33*4}%`} : {} ,
    l5: isDesktop ? {width:`${8.33*5}%`} : {} ,
    l6: isDesktop ? {width:`${8.33*6}%`} : {} ,
    l7: isDesktop ? {width:`${8.33*7}%`} : {} ,
    l8: isDesktop ? {width:`${8.33*8}%`} : {} ,
    l9: isDesktop ? {width:`${8.33*9}%`} : {} ,
    l10: isDesktop ? {width:`${8.33*10}%`} : {} ,
    l11: isDesktop ? {width:`${8.33*11}%`} : {} ,
    l12: isDesktop ? {width:`${8.33*12}%`} : {} ,

    m1: isTablet ? {width:`${8.33*1}%`} : {} ,
    m2: isTablet ? {width:`${8.33*2}%`} : {} ,
    m3: isTablet ? {width:`${8.33*3}%`} : {} ,
    m4: isTablet ? {width:`${8.33*4}%`} : {} ,
    m5: isTablet ? {width:`${8.33*5}%`} : {} ,
    m6: isTablet ? {width:`${8.33*6}%`} : {} ,
    m7: isTablet ? {width:`${8.33*7}%`} : {} ,
    m8: isTablet ? {width:`${8.33*8}%`} : {} ,
    m9: isTablet ? {width:`${8.33*9}%`} : {} ,
    m10: isTablet ? {width:`${8.33*10}%`} : {} ,
    m11: isTablet ? {width:`${8.33*11}%`} : {} ,
    m12: isTablet ? {width:`${8.33*12}%`} : {} ,

    s1: isMobile ? {width:`${8.33*1}%`} : {} ,
    s2: isMobile ? {width:`${8.33*2}%`} : {} ,
    s3: isMobile ? {width:`${8.33*3}%`} : {} ,
    s4: isMobile ? {width:`${8.33*4}%`} : {} ,
    s5: isMobile ? {width:`${8.33*5}%`} : {} ,
    s6: isMobile ? {width:`${8.33*6}%`} : {} ,
    s7: isMobile ? {width:`${8.33*7}%`} : {} ,
    s8: isMobile ? {width:`${8.33*8}%`} : {} ,
    s9: isMobile ? {width:`${8.33*9}%`} : {} ,
    s10: isMobile ? {width:`${8.33*10}%`} : {} ,
    s11: isMobile ? {width:`${8.33*11}%`} : {} ,
    s12: isMobile ? {width:`${8.33*12}%`} : {} ,

    _className:{
        displayFlexRow:{flexDirection:"row-reverse"  },
        displayFlexRowReverse:{flexDirection:"row"} ,
        displayFlexCol : {flexDirection:"column"},
        displayFlexGrow:{flex:1} ,
        displayFLexCenter: {alignItems:"center" , justifyContent:"center"}
    },
    _elements:{
        container:{flex:1,padding:10},
        viewCenterContainer:{justifyContent:"center",alignItems:'center'},
        _txt:{fontFamily:'$appFont',fontSize:'$normalFont',textAlign:'right', color:"$textColor"},
        _txtBold:{fontFamily:'$appFontBold' ,fontWeight:Platform.OS==="ios" ? "bold" : "normal",fontSize:'$normalFont',textAlign:'right' , color:"$textColor"},
        _title:{ fontFamily: '$appFontBold', fontSize:'$bigFont', color: '$textColor',textAlign:'right' },
        _error:{fontFamily: '$appFont', fontSize: 13, color: '$textColor',textAlign:'center' },
        _input:{
            container:{borderRadius:10,borderColor:'rgba(0,0,0,0.4)',
                       paddingHorizontal:5 ,flexDirection:'row',justifyContent:"center"},
            input:{fontFamily: '$appFont', fontSize: 13, color: 'black',textAlign:"center",flex:1,borderRadius:10,fontWeight: 'normal'},
            inputBold:{fontFamily: '$appFontBold', fontSize: 13, color: 'black',textAlign:"center",flex:1,borderRadius:10,fontWeight: 'normal'},
        },
        _header: {
            // container: { backgroundColor: '#78909c' },
            container: { backgroundColor: '$headerColor'  },
            menu: { color: '$headerIconColor', fontSize: 30, fontWeight: 'bold' },
            title: { fontSize:'$bigFont', fontFamily: '$appFontBold', color: '$headerTextColor' },
            icon: { color: '$headerIconColor', fontSize: 32 },
        },
        _picker: { borderColor: 'black', borderWidth: 2,padding:0,marginVertical:0 },
    },
    _components:{
        _profileImageComponent:{
            profileImageContainer: { padding: 0, borderRadius: 58,
                borderColor: 'rgba(52,52,52,.3)', borderWidth: 1,backgroundColor: 'white',},
            profileImage:{width:110,height:110,borderRadius: 55},
        },
        _profileImageSmallComponent:{
            profileImageContainer: { padding: 1, borderRadius: 45,elevation: 7, 
                borderColor: 'rgba(52,52,52,.3)', borderWidth: 1,backgroundColor: 'white',},
            profileImage:{width:84,height:84,borderRadius: 42},
        },
        _headerIcon: {
            icon: { padding: 0, borderRadius: 30, position: 'absolute', top: 37,elevation: 5, 
                    borderColor: 'rgba(52,52,52,.2)', borderWidth: 1,backgroundColor: 'white',},
        },
        _button:{
            button:{borderRadius:8,paddingHorizontal:10,paddingVertical:6,borderWidth:1,justifyContent:'center',alignItems:'center'},
            text:{fontFamily:'$appFontBold',fontSize:'$normalFont',textAlign:'center'},
            container:{flex:1,alignItems:'center',marginTop:40}
        },
        _menuItem:{
            celCenter:{ width:100,justifyContent:'center' , alignItems:'center' , padding:0,borderWidth:0,borderColor:"transparent" },
            celStyle:{justifyContent:'center' , alignItems:'center',borderWidth:0,borderColor:"transparent"},
            text:{ fontFamily: '$appFontBold', fontSize: 13, color: '#ffffff',textAlign:"center",marginTop:10,marginBottom:10 },
            image:{borderColor:'rgba(52,52,52,.2)' ,borderWidth:1, borderRadius:45,
                padding:2, backgroundColor:'white' , elevation:4 },
            // badge:{borderColor:'rgba(52,52,52,.2)' ,borderWidth:1, borderRadius:8,marginTop:-20,borderColor:"white",
            //        padding:0,paddingHorizontal:10, backgroundColor:'brow-reversen' , elevation:5 },
            // badgeText:{ fontFamily: '$appFontBold', fontSize: 15, color: 'white',textAlign:"center" },
            badge:{borderRadius:15,marginTop:-20,borderColor:'white',
                padding:0,paddingHorizontal:10, backgroundColor:'#d32f2f' , elevation:8 },
            badgeText:{ fontFamily: '$appFontBold', fontSize: 13, color: 'white',textAlign:"center" },
        },
        _modal:{
            container : {flex:1,padding:20},
            centerContent: {maxWidth:300 , justifyContent: 'center', alignItems: 'center',
                            backgroundColor:'white' , borderRadius:8 , padding:100},
            content: { marginHorizontal: 10 , marginVertical:10  , padding:5 },
            title: { fontFamily: '$appFontBold', fontSize:'$bigFont', color: 'brow-reversen' },
            text:{fontFamily:'$appFontBold' , fontSize:'$normalFont' , color:'grey'},
            button: { marginTop: 10, backgroundColor: "#546e7a", borderRadius: 7, marginHorizontal: 5,paddingVertical:0 },
            buttonTitle: { fontFamily: '$appFont', color: "#fff",paddingHorizontal:5,paddingVertical:0,margin:0 },
            labelBold:{fontFamily:'$appFont' , color:"#546e7a" , padding:2},
            label: { fontFamily: '$appFont', color: "#546e7a",paddingTop:10 },
            input: { fontFamily: '$appFontBold', color: "#546e7a",fontSize:'$normalFont'},
            inputBold: { fontFamily: '$appFontBold', color: "#546e7a",fontWeight:13 },
            item:{marginHorizontal:1 , marginTop:7 , borderColor:"grey" , borderWidth:1,padding:0,borderRadius:7},
        },
    },
    _pages:{
            _getPhoneNumber:{
                    container:{flexDirection:"column" , flex:1   } , 
                    containerUp:{ flexDirection:"column" , flex:1 , alignItems:"center" , justifyContent:"flex-end"   },
                    imageContainer:{} ,
                    containerDown:{ flexDirection:"column" , flex:1 , alignItems:"center" , justifyContent:"flex-start" ,marginTop:16 },
            },
            _getActivationCode:{
                container:{flexDirection:"column" , flex:1   } , 
                containerUp:{ flexDirection:"column" , flex:1 , alignItems:"center" , justifyContent:"flex-end"   },
                imageContainer:{} ,
                containerDown:{ flexDirection:"column" , flex:1 , alignItems:"center" , justifyContent:"flex-start" ,marginTop:16 },
                counterText:{backgroundColor:"#eeeeee" , borderRadius:20 , padding:"2px 20px" , direction:"rtl" , color:"#a0a0a0"}
            },
    },
    _chapter:{
        _content:{
                container:{flexDirection:"column" , flex:1 }
        },
        _footer:{
            container:{elevation:2,marginTop:15 ,  backgroundColor:"#eeeeee"     , padding:10 },
            _item:{
                    container:{paddingTop:5 , paddingBottom:3 , paddingRight:15 , paddingLeft:20, flexDirection:"row-reverse" , justifyContent:"center" , alignItems:"center" , flex:1 }
            }
        }
    }
}

export default Styles;