//========================================================================================
import React,{useRef , useEffect  , useState}  from 'react'
import { ImageBackground, View , FlatList ,TouchableOpacity, Dimensions  } from 'react-native';
import {NoItem ,Waiting , MyInput ,MyButton , ImageModal ,MyImage  ,ProfileImage , MyText , MySkeleton ,AnimatableView } from "../globalComponents/components"
import MdlImagePicker from './mdlImagePicker';
import Divider from './divider';
import uuid from "react-uuid"
//============================================================================
const MessagePlaceholder=()=>{
       return(
              <View>
                      <MySkeleton style={{padding:8 , marginBottom:8,highlightColor:"#FFF"  }}  >
                            <View style={{alignItems:"flex-end" , paddingVertical:8 , paddingHorizontal:8  , flexDirection:"row-reverse" , marginBottom:8}}>
                                   <View  style={{width:40, height:40 , borderRadius:70}} />
                                   <View  style={{paddingRight:10 ,marginLeft:30 , marginRight:10 , borderRadius:20 , borderBottomRightRadius:0 , height:80, flex:1 }}/>
                            </View>

                            <View style={{alignItems:"flex-end" , paddingVertical:8 , paddingHorizontal:8 , flexDirection:"row" , marginBottom:8}}>
                                   <View  style={{width:40, height:40 , borderRadius:70}} />
                                   <View  style={{paddingRight:10 ,marginLeft:10 , marginRight:100 , borderRadius:20 , borderBottomLeftRadius:0 , height:80, flex:1 }}/>
                            </View>

                            <View style={{alignItems:"flex-end" , paddingVertical:8 , paddingHorizontal:8  , flexDirection:"row-reverse" , marginBottom:8}}>
                                   <View  style={{width:40, height:40 , borderRadius:70}} />
                                   <View  style={{paddingRight:10 ,marginLeft:100 , marginRight:10 , borderRadius:20 , borderBottomRightRadius:0 , height:80, flex:1 }}/>
                            </View>

                            <View style={{alignItems:"flex-end" , paddingVertical:8 , paddingHorizontal:8  , flexDirection:"row" , marginBottom:8}}>
                                   <View  style={{width:40, height:40 , borderRadius:70}} />
                                   <View  style={{paddingRight:10 ,marginLeft:10 , marginRight:100 , borderRadius:20 , borderBottomLeftRadius:0 , height:80, flex:1 }}/>
                            </View>
                     </MySkeleton>
              </View>
       )
}
const NewsImageView=props =>{
       const {imagePanelWidth , arrImage}  =props
       const mdlSlideImage=useRef()

       const imageWidth = arrImage.length===1 ? imagePanelWidth-3
                                                                        : arrImage.length===2 ?   imagePanelWidth/2 : imagePanelWidth/3
   
       const showSlideImage=(index) => mdlSlideImage.current.show(arrImage.map(image => ({id:image.id , url : image.filename}) ) , index)
   
       return(
           <View style={{marginBottom:3 }} >
                     <View  style={{flexDirection:"row" ,flexWrap:"wrap",  justifyContent:"center",padding:3  }}  >
                                   {
                                          arrImage.map((imageUrl , index) =><TouchableOpacity key={index} onPress={()=>showSlideImage(index)}>
                                                                                                         <View style={{width:imageWidth , justifyContent:"center" , alignItems:"center" , padding:1}}>
                                                                                                                <View style={{width:"100%" , height:imageWidth-2   , backgroundColor:"#FFFFFF" , borderRadius:8 , justifyContent:"center" , alignItems:"center" }}  >
                                                                                                                       <MyImage source={{uri : imageUrl.filename}}  style={{width:"100%" , height:"100%"  , borderRadius:8  }} />
                                                                                                                </View>
                                                                                                         </View>
                                                                                                  </TouchableOpacity>)
                                   }
                     </View>
                     <ImageModal ref={mdlSlideImage} />
           </View>
       )
}
const MessageComponent= props =>{
       const {id , message , ptm , time , arrImage , avatar , hasAnimation=false } = props

       const countImageWidth=()=>{
              let  width=Dimensions.get("window").width-42
              switch(arrImage.length){
                     case 1 : width=Math.floor(width/2) ; break
                     case 2 : width=Math.floor(width*60/100) ; break
                     default : width=Math.floor(width*80/100) ; break
              }
              return width
       }

       const messageContent=<View  style={{ paddingHorizontal:16  , flexDirection:ptm ? "row" :"row-reverse" ,alignItems:"flex-end"}}>
                                                 {/* <ProfileImage source={avatar} width={40}  style={{}} /> */}
                                                 <View    style={Object.assign({} , {  flexDirection:"column" } , !ptm ? {marginRight:10} : {marginLeft:10} , 
                                                                                                                                                   !arrImage.length ? {maxWidth:"80%"} :{} , 
                                                                                                                                                   arrImage.length===1 ? {width:"50%"}  :{} ,
                                                                                                                                                   arrImage.length===2 ? {width:"60%"}  :{} , 
                                                                                                                                                   arrImage.length>2 ? {width:"80%"}  :{} ,    )}>
                                                        
                                                        <View   style={{  marginTop:16 ,borderRadius:20 , borderBottomRightRadius : ptm ? 0 : 20 ,
                                                                                    borderBottomLeftRadius : !ptm ? 0 : 20 , elevation:5 ,
                                                                                    backgroundColor:ptm ? "#fff9c4" : "#FFFFFF", padding:4 , paddingBottom:1  }} >
                                                               {
                                                                      arrImage.length ? <NewsImageView arrImage={arrImage} imagePanelWidth={countImageWidth()} />: null
                                                               }
                                                               <View style={{paddingHorizontal:10}}>
                                                                      <MyText isBlock smallFont style={{}} >{message}</MyText>
                                                                      <MyText tinyFont  dir="ltr" color="#039be5" isBlock  style={{textAlign:"left" }}>{time}</MyText>
                                                               </View>
                                                        </View> 
                                                 </View>
                                          </View>



       if(hasAnimation)
              return(
                     <AnimatableView animation="zoomIn">
                     {
                            messageContent
                     }
                     </AnimatableView>
              )
       else
              return messageContent
}
const CommentComponent=props =>{
       const { writerName , writerAvatar ,writerAboutMe  , comment , time   , isMyMessage=false , arrImage  , hasAnimation=false } = props
       
       const countImageWidth=()=>{
              let  width=Dimensions.get("window").width-42
              switch(arrImage.length){
                     case 1 : width=Math.floor(width/2) ; break
                     case 2 : width=Math.floor(width*60/100) ; break
                     default : width=Math.floor(width*80/100) ; break
              }
              return width
       }
       
       const avatar= writerAvatar ? {uri : writerAvatar} : require("../assets/images/act/imgDefManAvatar.jpg")
       const messageContent = <View  style={{ paddingHorizontal:16    , flexDirection:isMyMessage ? "row" :"row-reverse" ,alignItems:"flex-end"}}>
                                                 <ProfileImage source={avatar} width={40}  style={{elevation:1}} />
                                                 <View     style={Object.assign({} , {  flexDirection:"column"} , 
                                                                                                         !isMyMessage ? {marginRight:10} : {marginLeft:10} , 
                                                                                                         !arrImage.length ? {maxWidth:"80%"} :{} , 
                                                                                                         arrImage.length===1 ? {width:"50%"}  :{} ,
                                                                                                         arrImage.length===2 ? {width:"60%"}  :{} , 
                                                                                                         arrImage.length>2 ? {width:"80%"}  :{} ,    )}>
                                                        
                                                        <View  style={{ marginTop:16 ,borderRadius:15 , borderBottomRightRadius : isMyMessage ? 0 : 20 ,borderBottomLeftRadius : !isMyMessage ? 0 : 20 ,
                                                                             backgroundColor:isMyMessage ? "#fff9c4" : "#FFF" , paddingBottom:1 , elevation:5   }} >
                                                               {
                                                                      arrImage.length ? <NewsImageView arrImage={arrImage} imagePanelWidth={countImageWidth()} />: null
                                                               }
                                                               {
                                                                      !isMyMessage ? 
                                                                      <View style={{paddingHorizontal:10}}>
                                                                             <MyText   style={{fontSize:10}} >{writerName}</MyText>
                                                                             <MyText   color="#039be5" style={{fontSize:10}} >{writerAboutMe ? writerAboutMe : "-----"}</MyText>
                                                                      </View> : null
                                                               }
                                                               <View style={{paddingHorizontal:10}}>
                                                               <MyText isBlock smallFont style={{marginTop:5}} >{comment}</MyText>
                                                               <MyText tinyFont  dir="ltr" color="#039be5" isBlock  style={{textAlign:"left" }}>{time}</MyText>
                                                               </View>
                                                        </View> 
                                                 </View>
                                             </View>

       if(hasAnimation)
              return(
                     <AnimatableView animation="zoomIn">
                     {
                            messageContent
                     }
                     </AnimatableView>
              )
       else
              return messageContent
}
const  DateMessageComponent= props =>{
       const {date , arrMessage=[]  , isMessageOrComment} = props
       const arrNewMessage=[...arrMessage]
       arrNewMessage.reverse()
       return (
              <Divider containerStyle={{marginBottom:35 }} title={date} >
                     {

                            arrNewMessage.map(message => isMessageOrComment==="MESSAGE" ? <MessageComponent key={message.id} {...message}  /> 
                                                                                                                                  :  <CommentComponent key={message.id} {...message}  />)
                     }
              </Divider>
       )
}
const ImageComponent = props =>{
       const { file , onClickImage , imageWidth  , onDeleteImage}=props
       return (
           <TouchableOpacity onPress={()=>onClickImage(file.id)} onLongPress={()=>onDeleteImage(file.id)}>
               <View style={{padding:2 , backgroundColor:"#FFFFFF" , borderRadius:8 , marginRight:3 , marginBottom:3 , width:imageWidth, height:imageWidth }}>
                   <MyImage resizeMode="cover" source={file} style={{width:"100%" , height:"100%" , borderRadius:6}}/>
           </View>
           </TouchableOpacity>
       )
}
//============================================================================
const ChatComponent=(props)=>{
       //-----------------------------------GLOBAL VAR EREA
       const {  onGetMessage=()=>{}   , 
                     onSendMessage=()=>{} , 
                     messageInfo=[] ,
                     children ,  
                     fetchState  , 
                     strErrorMessage , 
                     strNoMessage  , 
                     isMessageOrComment="MESSAGE"  } = props
       //-----------------------------------REF EREF
       const mdlImagepicker=useRef()
       const imageModal=useRef()
       //-----------------------------------STATE EREA
       const [newMessage , setNewMessage]=useState({uuid:uuid() , matn :'' , arrFile:[]})
       //-----------------------------------USE_EFFECT EREA
       useEffect(()=>{
              if( !messageInfo.arrMessage.length  &&  !messageInfo.finished )
              onGetMessage()
       } , [])
       useEffect(()=>{
       if(messageInfo.refresh)
              onGetMessage()
       },[messageInfo.refresh])
       //-----------------------------------FUNCTION EREA
       const onChangeNewMessage= (txtId , newText)=> setNewMessage({...newMessage , matn : newText.trim() ? newText : ""} )
       const onDeleteImage= imageId =>setNewMessage({...newMessage , arrFile : newMessage.arrFile.filter(image => image.id!==imageId)})
       const onClickImage= imageId => {
              const selectedImageState = newMessage.arrFile.filter(file => file.id===imageId)[0]
              imageModal.current.show(newMessage.arrFile.map(imageInfo => ({...imageInfo , url:imageInfo.uri})) , newMessage.arrFile.indexOf(selectedImageState) )
              // imageModal.current.show([{...selectedImageState , url : selectedImageState.uri}] )
       }
       const selectImageFromCamera=()=> mdlImagepicker.current.showModal(true , 1280 , 1280 ,onSelectImage  )
       const onSelectImage= (arrImage )=> setNewMessage({
                                                                                           ...newMessage , arrFile : [...newMessage.arrFile , 
                                                                                           ...arrImage.map(image => ({...image , id:uuid() })   )   ]
                                                                                           })
       const loadMore=()=>{
              if(!messageInfo.finished && messageInfo.arrMessage.length)
                     onGetMessage()
       }
       const onSendMessageClick=()=>{
              onSendMessage(newMessage)
              setNewMessage({
                     uuid:uuid(),
                     matn:'' , 
                     arrFile:[]
              })
       }
       //---------------------------------------
       const [imageWidth , setImageWidth]=useState(0)
       const onlayout = e => setImageWidth(e.nativeEvent.layout.width)
       //--------------------------------------------------
       const initMessageToShow=()=>{
              const arrMessage=[]
              for(let comment of messageInfo.arrMessage){
                  if(arrMessage.filter(dateMessage => dateMessage.date===comment.date).length){
                          for(let dateComment of arrMessage){
                                  if(dateComment.date===comment.date)
                                          dateComment.arrMessage.push({...comment})
                          }
                  }
                  else
                          arrMessage.push({date:comment.date , arrMessage:[{...comment}] })
              }
              return arrMessage
       }
       return(
              <View style={{flex:1}}>
                     <ImageBackground  style={{flex:1 }} source={require("../assets/images/act/imgBack.jpg")}>
                            <View style={{flex:1 }}>
                                   <NoItem visible={fetchState.errorFlag  && !fetchState.fetchFlag &&   !messageInfo.arrMessage.length}
                                          title="گزارش برنامه"  containerStyle={{backgroundColor:"transparent"}}
                                          message={strErrorMessage }
                                          showTryAgainButton={true}
                                          tryAgain={onGetMessage}/>
                                   {/* نمایش هیچ آیتمی جهت نماش وجود ندارد */}
                                   <NoItem    visible={!fetchState.errorFlag && !fetchState.fetchFlag && !messageInfo.arrMessage.length &&  !messageInfo.refresh }
                                                 title="گزارش برنامه" 
                                                 icon={require("../assets/images/logo/logo.png")} containerStyle={{backgroundColor:"transparent"}}
                                                 message={strNoMessage}
                                                 // showTryAgainButton={true}
                                                 tryAgain={onGetMessage}/>
                                   {
                                          fetchState.fetchFlag && !messageInfo.arrMessage.length ?   <MessagePlaceholder /> : null
                                   }

                                   <FlatList data={  initMessageToShow()  } 
                                                 inverted={true}
                                                 ListHeaderComponent={children}
                                                 renderItem={({item})=> <DateMessageComponent {...item} isMessageOrComment={isMessageOrComment}   />  }
                                                 keyExtractor={(item , index)=>index.toString()}
                                                 contentContainerStyle={{paddingTop:15 , paddingBottom:10  }}
                                                 onEndReached={loadMore}
                                                 onEndReachedThreshold={0.15}
                                                 ListFooterComponent={()=><Waiting  visible={fetchState.fetchFlag && !messageInfo.refresh && messageInfo.arrMessage.length}
                                                                                                         marginTop={30} 
                                                                                                         title="لطفا منتظر بمانید" />}   />

                            </View>
                            
                            <View style={{}}  >
                                   {
                                          newMessage.arrFile.length ? 
                                          <View onLayout={onlayout} 
                                                    style={{padding:5 , flexDirection:"row" , flexWrap:"wrap", /*position:"absolute" ,*/  zIndex:3 , backgroundColor:"rgba(0,0,0,0.2)"   }}>
                                          {
                                                 newMessage.arrFile.map((file , index) => <ImageComponent key={index} file={file} {...file}
                                                                                                                                                 onClickImage={onClickImage}
                                                                                                                                                 onDeleteImage={onDeleteImage} 
                                                                                                                                                 imageWidth={imageWidth/4-10} />)
                                          }
                                          </View> : null
                                   }
                                   <View  style={{alignItems:"center"  , padding:10  , flexDirection:"row"    , borderColor:"#f0f0f0" , alignItems:"flex-end"  }}>
                                          {
                                                 newMessage.matn.trim() ? 
                                                               <AnimatableView style={{paddingLeft:10}} animation="zoomIn" duration={400}>
                                                                      <MyButton   onClick={onSendMessageClick}
                                                                                                                title='' 
                                                                                                                iconName='send' 
                                                                                                                buttonStyle={{backgroundColor:"#26c6da" , borderRadius:30   , 
                                                                                                                                         paddingHorizontal:7 , paddingVertical:7 ,elevation:2  }}
                                                                                                                buttonIconStyle={{color:"#FFFFFF", fontSize:25 , borderRadius:40}}
                                                                                                                buttonTExtStyle={{}} />
                                                               </AnimatableView> : null
                                          }
                                          {/* <View style={{  flexDirection:"column" , flex:1 , padding:6}}> */}
                                                 
                                                 <View style={{flexDirection:"row" , flex:1 ,  backgroundColor:"#FFFFFF"  , borderRadius:15 , paddingHorizontal:10    , alignItems:"flex-end" , elevation:1 }}>
                                                        <MyInput id ='newMessage'            
                                                                      placeholder='پیام خود را بنویسید....'    
                                                                      value={newMessage.matn}          
                                                                      disabled={false}  
                                                                      onChangeText={onChangeNewMessage}       
                                                                      password={false}  
                                                                      maxLength  ={5000}  
                                                                      maxNumberOfLines={6}
                                                                      multiline
                                                                      underlineColorAndroid="transparent"       
                                                                      textAlign='right'        
                                                                      inputStyle={{textAlign:"right"   ,backgroundColor:"#FFFFFF"  ,padding:0 ,elevation:0 , border:"0 sloid #EEE" , boxShadow:"0  0 0 0 #EEE"}}
                                                                      containerStyle={{ borderRadius:30 , flex:1 , border:"0 sloid #EEE"  }}   /> 
                                                        <View style={{flexDirection:"row-reverse" , marginBottom:5}}>
                                                               <MyButton   onClick={selectImageFromCamera}
                                                                                    title='' 
                                                                                    iconName='photo_camera' 
                                                                                    buttonStyle={{backgroundColor:"#FFFFFF" , borderRadius:20}}
                                                                                    buttonIconStyle={{color:"#9a9a9a", fontSize:23}}
                                                                                    buttonTextStyle={{}} /> 
                                                        </View>
                                                 </View> 
                                          {/* </View> */}
                                   </View>
                            </View>
                     </ImageBackground>
                     <MdlImagePicker  ref={mdlImagepicker} />
                     <ImageModal ref={imageModal} />
                     {/* <Alert ref={alertModal} />
                     <Fetch ref={fetchModal} />
                     <Confirm ref={confirmModal} /> */}
              </View>
       )
}

export default ChatComponent;
//=========================================================================================