import React from "react"
import {View} from "react-native"
import { MyInput ,MyButton, AnimatableView } from "../globalComponents/components"

const SearchComponent =props =>{
       const  {value="" , onChangeText=()=>{} , onSearchButtonClick=()=>{} , placeholder="جستجو...." , hideSearchButton=false} = props
       return(
              <View  style={{ flexDirection:"row"  , padding:5 ,alignItems:"stretch"}}>
                     <MyInput id ='strSearch'      smallFont
                            placeholder={placeholder}
                            iconName="search"  
                            iconColor="#bdbdbd"
                            value={value}         
                            disabled={false}  
                            onChangeText={onChangeText}       
                            password={false}  
                            underlineColorAndroid="#FFFFFFF"       
                            inputStyle={{padding:2 , marginBottom:0 ,textAlign:"right" , padding:1 , paddingRight:10 , paddingVertical:2 }} 
                            containerStyle={{  borderRadius:5 , marginLeft:4 , backgroundColor:"#FFF" , flex:1 , borderWidth:1 , borderColor:"#bdbdbd" , padding:"2px 10px" }} /> 
                     {/* <View style={{borderWidth:1}}> */}
                     {
                            value  || !hideSearchButton ? 
                            <MyButton     smallFont title="" 
                                                 iconName="search" 
                                                 buttonStyle={{borderRadius:6, backgroundColor:"#f0f0f0" ,paddingVertical:3, borderRadius:5 , borderRadius:20}} 
                                                 buttonIconStyle={{fontSize:29 , color:"#00bcd4"}}
                                                 onClick={ onSearchButtonClick } />  : null

                     }
                    
                     {/* </View> */}
              </View>
       )
}

export default SearchComponent